import classnames from 'classnames';
import { Link } from 'react-router';

import checkThinGlyph from 'glyphs/check-thin.svg';

import AuctionItem from 'constants/auctionItem';
import BaseClass from 'components/ui/shared/base';
import Sprite from 'components/ui/shared/sprite';
import { CheckoutResponse } from 'store/shared/api/graph/interfaces/types';
import { Chevron } from 'components/ui/shared/chevrons';
import { FormDialogBody, FormSection } from 'layouts/formLayouts/formDialogLayouts';
import { Route } from 'store/routing/routes';
import { SecondaryTitleLight } from 'layouts/list/listItemLayout';
import { t, tPlural } from 'utils/intlUtils';

import style from './checkoutSuccessForm.scss';

class CheckoutSuccessForm extends BaseClass<{
  auctionItem?: AuctionItem;
  checkoutResponse?: CheckoutResponse;
  isSingleForm?: boolean;
  onClose: () => void;
}> {
  render() {
    const { auctionItem, checkoutResponse, isSingleForm, onClose } = this.props;
    const excludedFromCheckout = checkoutResponse?.excludedFromCheckout || 0;
    const expired = checkoutResponse?.expired || 0;
    const successfullyCheckedOut = checkoutResponse?.successfullyCheckedOut || 0;

    /**
     * The displayed single form success message.
     * Only one of the fields in `checkoutResponse` can be positive.
     */
    const getSingleFormMessage = () => {
      if (excludedFromCheckout > 0) {
        return t('vehicle_excluded_from_single_checkout');
      }
      if (expired > 0) {
        return t('vehicle_checked_out_with_defaults');
      }
      return t('vehicle_checked_out_successfully');
    };

    return (
      <>
        <FormDialogBody>
          <FormSection flexDirection="column">
            <div className={style.successContainer}>
              {successfullyCheckedOut > 0 && (
                <div className={style.spriteCircle}>
                  <Sprite className={style.sprite} glyph={checkThinGlyph} />
                </div>
              )}

              {isSingleForm && <h4 className={style.title}>{getSingleFormMessage()}</h4>}

              {!isSingleForm && (
                <>
                  {successfullyCheckedOut > 0 && (
                    <h4 className={style.title}>
                      {tPlural('x_vehicles_checked_out_successfully', successfullyCheckedOut, [successfullyCheckedOut])}
                    </h4>
                  )}
                  {successfullyCheckedOut === 0 && expired > 0 && (
                    <h4 className={classnames(style.title, style.gray)}>
                      {tPlural('x_vehicles_checked_out_with_defaults', expired, [expired])}
                    </h4>
                  )}
                  {successfullyCheckedOut === 0 && expired === 0 && excludedFromCheckout === 0 && (
                    <h4 className={classnames(style.title, style.gray)}>{t('vehicle_checked_out_previously')}</h4>
                  )}
                  {successfullyCheckedOut !== 0 && expired > 0 && (
                    <SecondaryTitleLight className={style.expiredText}>
                      {tPlural('x_vehicles_checked_out_with_defaults', expired, [expired])}
                    </SecondaryTitleLight>
                  )}
                  {excludedFromCheckout > 0 && (
                    <SecondaryTitleLight className={style.excludedText}>
                      {tPlural('x_vehicles_excluded_from_bulk_checkout', excludedFromCheckout, [excludedFromCheckout])}
                    </SecondaryTitleLight>
                  )}
                </>
              )}
            </div>

            {isSingleForm && excludedFromCheckout !== 1 && (
              <Link className={style.links} onClick={onClose} to={`${Route.FINISHED_PURCHASED}?id=${auctionItem?.id}`}>
                <div>{t('view_purchased_vehicle')}</div>
                <Chevron />
              </Link>
            )}

            {!isSingleForm && (
              <Link className={style.links} onClick={onClose} to={Route.FINISHED_PURCHASED}>
                <div>{t('view_purchased_vehicles')}</div>
                <Chevron />
              </Link>
            )}
          </FormSection>
        </FormDialogBody>
      </>
    );
  }
}

export default CheckoutSuccessForm;
