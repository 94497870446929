import classnames from 'classnames';
import { Link } from 'react-router';
import { MouseEventHandler, useMemo } from 'react';

import carfaxCaGlyph from 'glyphs/carfax-ca.svg';
import exclamationGlyph from 'glyphs/warning.svg';
import externalGlyph from 'glyphs/external-link.svg';

import Sprite from 'components/ui/shared/sprite';
import { CarfaxAnnouncement, CarfaxAnnouncementType } from 'store/shared/api/graph/interfaces/types';
import { SlideOutComponentProps } from 'components/sections/inventoryItem/details/slideOut/slideOut';
import { t } from 'utils/intlUtils';

import style from './carfaxSummary.scss';

interface Props extends SlideOutComponentProps {
  /** Announcements from carfax report. */
  announcements: CarfaxAnnouncement[];
  /** CSS styling to overwrite default style. */
  className?: string;
  /** Function called when view full report link clicked */
  onReportLinkClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  /** The url to the carfax report */
  reportUrl?: string | null;
}

const CarfaxSummary = ({ announcements, className, onReportLinkClick, reportUrl }: Props) => {
  /**
   * Return a collection of carfax claims.
   */
  const claims = useMemo(() => {
    return announcements?.filter((announcement) => announcement.type === CarfaxAnnouncementType.DAMAGE_CLAIM) || [];
  }, [announcements]);

  /**
   * Returns the jurisdiction announcement of the summary.
   */
  const jurisdiction = useMemo(() => {
    return announcements?.find((announcement) => announcement.type === CarfaxAnnouncementType.JURISDICTION);
  }, [announcements]);

  /**
   * Return a collection of carfax additional disclosures.
   */
  const additionalDisclosures = useMemo(() => {
    return (
      announcements?.filter((announcement) => announcement.type === CarfaxAnnouncementType.ADDITIONAL_DISCLOSURE) || []
    );
  }, [announcements]);

  return (
    <div className={classnames(style.summary, className)} data-testid="carfax-summary">
      <div className={style.lineItem}>
        <Sprite className={style.carfaxLogo} glyph={carfaxCaGlyph} />
      </div>
      {!!claims.length && (
        <div className={classnames(style.label, style.red)}>
          {t('accident_damage_records_found')}
          <Sprite className={style.warningGlyph} glyph={exclamationGlyph} />
        </div>
      )}
      {claims.map((claim, index) => {
        return (
          <div key={`${claim.name}-${index}`} className={style.lineItem}>
            {claim.name}
            <span className={style.detail}>{claim.detail}</span>
          </div>
        );
      })}
      {jurisdiction && (
        <div className={style.lineItem}>
          <span className={style.jurisdiction}>{`${t('jurisdictions_registered_x', [jurisdiction.detail])}`}</span>
        </div>
      )}
      {!!additionalDisclosures.length && <div className={style.label}>{t('additional_disclosures')}</div>}
      {additionalDisclosures.map((claim, index) => {
        return (
          <div key={`${claim.name}-${index}`} className={style.lineItem}>
            {claim.name}
          </div>
        );
      })}
      {reportUrl && (
        <Link className={style.link} onClick={onReportLinkClick} target="_blank" to={reportUrl}>
          {t('view_full_report')}
          <Sprite className={style.externalIcon} glyph={externalGlyph} />
        </Link>
      )}
    </div>
  );
};

export default CarfaxSummary;
