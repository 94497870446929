/**
 * Enum for auctionItem status values.
 *
 * @readonly
 * @enum {string}
 */
export const STATUS = Object.freeze({
  UPCOMING: 'UPCOMING',
  LIVE: 'LIVE',
  IN_IF_BID: 'IN_IF_BID',
  PENDING_DELIVERY: 'PENDING_DELIVERY',
  AWAITING_CHECKOUT: 'AWAITING_CHECKOUT',
  SOLD: 'SOLD',
  NO_SALE: 'NO_SALE',
  SALE_CANCELLED: 'SALE_CANCELLED',
  FAILED: 'FAILED',
});

/**
 * Enum for auctionItem time slot status'
 *
 * @readonly
 * @enum {string}
 */
export const TIME_SLOT_STATUS = Object.freeze({
  WAITING: 'WAITING',
  INIT: 'INIT',
  RUNNING: 'RUNNING',
  END: 'END',
});

export enum AuctionItemSort {
  AUCTION = 'AUCTION',
  BUY_NOW_PRICE = 'BUY_NOW_PRICE',
  CHECKOUT_EXPIRY = 'CHECKOUT_EXPIRY',
  CREATED = 'CREATED',
  DATE_CANCELLED = 'DATE_CANCELLED',
  DATE_RAN = 'DATE_RAN',
  DATE_SOLD = 'DATE_SOLD',
  ENDING = 'ENDING',
  IF_BID_AMOUNT_APART = 'IF_BID_AMOUNT_APART',
  MOST_AUTOBIDS = 'MOST_AUTOBIDS',
  MOST_WATCHED = 'MOST_WATCHED',
  PENDING_DELIVERY = 'PENDING_DELIVERY',
  RECOMMENDED = 'RECOMMENDED',
  RESERVE_PRICE = 'RESERVE_PRICE',
  STARTING_BID = 'STARTING_BID',
  UPDATED = 'UPDATED',
  VEHICLE_SCORE = 'VEHICLE_SCORE',
}
