import { handleActions } from 'redux-actions';

import InventoryItemType from 'constants/inventoryItem';
import { InventoryItem } from './inventoryItemDetailsModels';
import { formatCalendarDate } from 'utils/dateUtils';
import {
  inventoryItemAddNote,
  inventoryItemArchived,
  inventoryItemDetailsAuditLogLoaded,
  inventoryItemDetailsAuditLogUpdating,
  inventoryItemDetailsCleared,
  inventoryItemDetailsLoaded,
  inventoryItemDetailsUpdating,
  preloadInventoryItem,
  updateConsignerLocations,
  updateInventoryItem,
} from './inventoryItemDetailsActions';

export const inventoryItemDetailsReducer = handleActions(
  {
    [inventoryItemDetailsCleared().type]: () => new InventoryItem(),

    [inventoryItemDetailsUpdating().type]: (state) => state.setUpdating(),

    [inventoryItemDetailsLoaded().type]: (state, action) => {
      return state.setLoaded().merge({
        details: { ...action.payload },
      });
    },

    [updateInventoryItem().type]: (state, action) => {
      if (!state?.details?.id) {
        return state;
      }

      return state.set('details', {
        ...state.details,
        ...action.payload,
      });
    },

    [preloadInventoryItem().type]: (state, action) => {
      if (action.payload) {
        return state.setLoaded().set('details', action.payload);
      }
      return state;
    },

    [inventoryItemAddNote().type]: (state, action) => {
      return state.merge({
        details: {
          ...state.details,
          notes: [action.payload.note, ...(state.details?.notes || [])],
        } as InventoryItemType,
      });
    },

    [inventoryItemArchived().type]: (state, action) => {
      return state.merge({
        details: {
          ...state.details,
          archived: action.payload.archived,
        } as InventoryItemType,
      });
    },

    [updateConsignerLocations().type]: (state, action) => {
      return (
        state.set(
          'consignerLocations',
          action.payload.edges.map((locationEdge) => locationEdge.node)
        ) || []
      );
    },

    [inventoryItemDetailsAuditLogUpdating().type]: (state) => {
      return state.merge({ logEntries: state.logEntries.setUpdating() });
    },

    [inventoryItemDetailsAuditLogLoaded().type]: (state, action) => {
      // Group log entries by date
      const formattedLogs = (action.payload || []).reduce((logs, log) => {
        const formattedDate = formatCalendarDate(log.created);
        const matchingIndex = logs.findIndex((l) => l && l.title === formattedDate);

        if (matchingIndex > -1) {
          logs[matchingIndex].logs.push(log);
          return logs;
        }
        return [...logs, { title: formattedDate, logs: [log] }];
      }, []);

      return state.merge({
        logEntries: state.logEntries.setLoaded().set('results', formattedLogs),
      });
    },
  },
  new InventoryItem()
);
