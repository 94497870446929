import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import { AuthResponse } from 'store/shared/api/graph/interfaces/types';

interface AuthModel {
  /** The MFA authResponse */
  authResponse?: AuthResponse;

  /** Whether is auction access mode or not. */
  isAuctionAccessMode: boolean;

  /** Whether is logged in or not. */
  isLoggedIn: boolean;

  /** True if user is required to login via MFA */
  isVerificationCodeRequired: boolean;
}

export type AuthProps = ServerRecordOf<AuthModel>;

/**
 * Initial state of model
 */
export const InitialState = ServerRecord<AuthModel>({
  authResponse: undefined,
  isAuctionAccessMode: false,
  isLoggedIn: false,
  isVerificationCodeRequired: false,
});
