import { useCallback, useEffect, useState } from 'react';

import FocusMask from 'components/ui/masks/focusMask';

interface Props {
  element: HTMLElement | undefined;
  elementPadding?: number;
}

const FocusElementMask = ({ element, elementPadding }: Props) => {
  const [elementBounds, setElementBounds] = useState<DOMRect>();
  const padding = elementPadding ?? 10;

  /**
   * Resize handler
   */
  const onResize = useCallback(() => {
    setElementBounds(element?.getBoundingClientRect());
  }, [element]);

  /**
   * Add resize event listener on mount
   */
  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [element, onResize]);

  // Force resize when `element` changes
  useEffect(onResize, [element, onResize]);

  if (!element || !elementBounds) {
    return null;
  }

  const width = elementBounds?.width + padding * 2;
  const height = elementBounds?.height + padding * 2;
  const x = elementBounds?.x - padding;
  const y = elementBounds?.y - padding;

  return <FocusMask height={height} width={width} x={x} y={y} />;
};

export default FocusElementMask;
