import ApiRequest from 'store/shared/api/apiRequest';
import {
  AuctionLane,
  AuctionLaneBidIncrement,
  AuctionLaneReservation,
  AuctionLaneReservationCreateInput,
  AuctionLaneReservationUpdateInput,
  AuctionTimeSlot,
  AuctionTimeSlotLane,
  MutationauctionLaneBidIncrementUpdateArgs,
  MutationauctionPhysicalLaneAssignClerkArgs,
  QueryauctionLaneArgs,
  QueryauctionLaneReservationsArgs,
  QueryauctionTimeSlotArgs,
  QueryauctionTimeSlotLaneArgs,
} from 'store/shared/api/graph/interfaces/types';

export const getAuctionTimeSlot = (options: QueryauctionTimeSlotArgs) =>
  ApiRequest<{ auctionTimeSlot: AuctionTimeSlot }>({
    data: {
      operationName: 'getAuctionTimeSlot',
      query: `
        query($auctionTimeSlotId: String!) {
          auctionTimeSlot(auctionTimeSlotId: $auctionTimeSlotId) {
            id
            auction {
              id
              title
              videoStreamConfig {
                accountId
                subscriptionToken
              }
            }
            auctionTimeSlotLanes {
              active
              auctionClerk {
                id
                firstName
                lastName
              }
              auctionLaneId
              controlType
              description
              id
              name
              startRunNumber
              type
              assignedItemsCount
              unassignedItemsCount
            }
            cutOffTime
            startTime
            status
            itemCount
          }
        }
      `,
      variables: options,
    },
  });

export const getAuctionLane = (options: QueryauctionLaneArgs) =>
  ApiRequest<{ auctionLane: AuctionLane }>({
    data: {
      operationName: 'getAuctionLane',
      query: `
        query($auctionLaneId: String!) {
          auctionLane(auctionLaneId: $auctionLaneId) {
            auctionId
            id
            name
            type
            bidIncrements {
              increment
              defaultSelected
            }
          }
        }
      `,
      variables: options,
    },
  });

export const getAuctionTimeSlotLane = (options: QueryauctionTimeSlotLaneArgs) =>
  ApiRequest<{ auctionTimeSlotLane: AuctionTimeSlotLane }>({
    data: {
      operationName: 'getAuctionTimeSlotLane',
      query: `
        query($auctionTimeSlotLaneId: String!) {
          auctionTimeSlotLane(auctionTimeSlotLaneId: $auctionTimeSlotLaneId) {
            id
            bidIncrements {
              increment
              defaultSelected
            }
          }
        }
      `,
      variables: options,
    },
  });

export const auctionLaneBidIncrementUpdate = ({
  auctionLaneId,
  bidIncrements,
}: MutationauctionLaneBidIncrementUpdateArgs) => {
  return ApiRequest<{ auctionLaneBidIncrementUpdate: AuctionLaneBidIncrement[] }>({
    data: {
      operationName: 'auctionLaneBidIncrementUpdate',
      query: `
        mutation($auctionLaneId: String!, $bidIncrements: [AuctionLaneBidIncrementInput]!) {
          auctionLaneBidIncrementUpdate(auctionLaneId: $auctionLaneId, bidIncrements: $bidIncrements) {
            increment
            defaultSelected
          }
        }
      `,
      variables: {
        auctionLaneId,
        bidIncrements,
      },
    },
  });
};

export const auctionPhysicalLaneAssignClerk = (options: MutationauctionPhysicalLaneAssignClerkArgs) =>
  ApiRequest<{ auctionPhysicalLaneAssignClerk: AuctionTimeSlotLane }>({
    data: {
      operationName: 'auctionPhysicalLaneAssignClerk',
      query: `
        mutation($auctionTimeSlotLaneId: String!) {
          auctionPhysicalLaneAssignClerk(auctionTimeSlotLaneId: $auctionTimeSlotLaneId) {
            id
            auctionClerk {
              id
              firstName
              lastName
            }
          }
        }
    `,
      variables: options,
    },
  });

export const createAuctionLaneReservation = (input: AuctionLaneReservationCreateInput) =>
  ApiRequest<{ auctionLaneReservationCreate: Pick<AuctionLaneReservation, 'id'> }>({
    data: {
      operationName: 'auctionLaneReservationCreate',
      query: `
        mutation ($input: AuctionLaneReservationCreateInput!) {
          auctionLaneReservationCreate(input: $input) {
            id
          }
        }
      `,
      variables: { input },
    },
  });

export const updateAuctionLaneReservation = (input: AuctionLaneReservationUpdateInput) =>
  ApiRequest<{ auctionLaneReservationUpdate: AuctionLaneReservation }>({
    data: {
      operationName: 'auctionLaneReservationUpdate',
      query: `
        mutation ($input: AuctionLaneReservationUpdateInput!) {
          auctionLaneReservationUpdate(input: $input) {
            id
          }
        }
      `,
      variables: { input },
    },
  });

export const getRunNumberReservations = ({ auctionTimeSlotLaneId }: QueryauctionLaneReservationsArgs) =>
  ApiRequest<{ auctionLaneReservations: AuctionLaneReservation[] }>({
    data: {
      operationName: 'getRunNumberReservations',
      query: `
        query ($auctionTimeSlotLaneId: String!) {
          auctionLaneReservations(auctionTimeSlotLaneId: $auctionTimeSlotLaneId) {
            active
            company {
                id
                name
              }
            global
            id
            note
            runNumberStart
            runNumberEnd
          }
        }
      `,
      variables: {
        auctionTimeSlotLaneId,
      },
    },
  });
