import ApiRequest from 'store/shared/api/apiRequest';
import InventoryItem from 'constants/inventoryItem';
import { InventoryItemcomparablesArgs, QueryinventoryItemArgs } from 'store/shared/api/graph/interfaces/types';
import { auctionItemListSchema } from 'store/auctionItemsList/auctionItemsSchemas';
import { listItemPhotoFragment } from 'store/shared/api/graph/fragments/photos';
import { locationFragment } from 'store/shared/api/graph/fragments/common';

export const getComparables = ({
  inventoryItemId,
  soldDateDesc = true,
}: QueryinventoryItemArgs & InventoryItemcomparablesArgs) =>
  ApiRequest<{ inventoryItem: InventoryItem }>({
    data: {
      operationName: 'getComparables',
      query: `
      query($inventoryItemId: String!, $soldDateDesc: Boolean!) {
        inventoryItem(inventoryItemId: $inventoryItemId) {
          comparables(soldDateDesc: $soldDateDesc) {
            count
            list {
              id
              reserveMet
              status
              isAssured
              asIs
              auction {
                id
              }
              listPrice {
                formattedAmountRounded
              }
              transactionDetails {
                created
              }
              inventoryItem {
                id
                location {
                  distanceAway
                  countryCode
                }
                company {
                  id
                }
                year
                make
                model
                trim
                captureType
                vin
                mileage {
                  formattedAmount
                }
                photos(first: 1) {
                  thumb: url(width: 360, height: 360)
                }
                options(selected: true) {
                  id
                  name
                }
                extensiveVehicleConditionScore
                conditionReport {
                  declarations(selected: true) {
                    id
                    name
                  }
                  carfaxCanadaClaimAmount {
                    amount
                    formattedAmountRounded
                  }
                  carfaxCanadaReportUrl
                  overallConditionRating
                }
              }
            }
          }
        }
      }
    `,
      variables: {
        inventoryItemId,
        soldDateDesc,
      },
    },
  });

export const getComparablesForStaff = ({
  inventoryItemId,
  soldDateDesc = true,
}: QueryinventoryItemArgs & InventoryItemcomparablesArgs) =>
  ApiRequest<{ inventoryItem: InventoryItem }>({
    data: {
      operationName: 'getComparablesForStaff',
      query: `
      query($inventoryItemId: String!, $soldDateDesc: Boolean!) {
        inventoryItem(inventoryItemId: $inventoryItemId) {
          comparables(soldDateDesc: $soldDateDesc) {
            count
            list {
              ${auctionItemListSchema}
            }
          }
        }
      }
      ${listItemPhotoFragment}
      ${locationFragment}
    `,
      variables: {
        inventoryItemId,
        soldDateDesc,
      },
    },
  });
