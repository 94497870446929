import { MouseEventHandler, useCallback, useMemo } from 'react';

import AuctionItem from 'constants/auctionItem';
import AuctionLocationRow from 'components/sections/auctionItem/details/status/statusRows/auctionLocationRow';
import AuditLogRow from 'components/sections/auctionItem/details/status/statusRows/auditLogRow';
import RanBeforeRow from 'components/sections/auctionItem/details/status/statusRows/ranBeforeRow';
import ReservePriceRow from 'components/sections/auctionItem/details/status/statusRows/reservePriceRow';
import StaffUserRows from 'components/sections/auctionItem/details/status/statusRows/staffUserRows/staffUserRows';
import User from 'constants/user';
import { AuctionItemStatus } from 'store/shared/api/graph/interfaces/types';
import { Row, Rows } from 'components/sections/auctionItem/details/status/statusRows/statusRows';
import { formatCalendarTime } from 'utils/dateUtils';
import { getAuctionNameByDetails } from 'utils/formatting/auctionItemFormatUtils';
import { isAuctionStaff } from 'utils/userUtils';
import { t } from 'utils/intlUtils';

import style from './upcomingAuction.scss';

interface Props {
  /** The auction item details. */
  details: AuctionItem;
  /** Click handler for show audit log. */
  onShowAuditLog: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show auto-bids. */
  onShowAutobids: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show ran before. */
  onShowRanBefore: MouseEventHandler<HTMLButtonElement>;
  /** Click handler on transport estimate row click. */
  onShowTransportEstimate: (auctionItem: AuctionItem) => void;
  /** The current user. */
  user: User;
}

const UpcomingAuction = ({
  details,
  details: { auction, auctionTimeSlot, guaranteedAuctionPrice, inventoryItem, startingBid, status, topOffer },
  onShowAuditLog,
  onShowAutobids,
  onShowRanBefore,
  onShowTransportEstimate,
  user,
}: Props) => {
  const auctionTitle = getAuctionNameByDetails(details);
  const auctionTime = formatCalendarTime(auctionTimeSlot?.startTime);
  const autoBidAmount = details.autoBids?.myAutoBid?.maxBid?.formattedAmountRounded;
  const isStaffUser = useMemo(() => isAuctionStaff(user, auction.id), [auction.id, user]);

  /**
   * Handles Transport Estimate onClick event
   */
  const handleTransportEstimateOnClick = useCallback(
    () => onShowTransportEstimate(details),
    [details, onShowTransportEstimate]
  );

  return (
    <div className={style.upcomingAuction} data-testid="upcoming-auction">
      <Rows>
        <Row name={t('auction')}>{auctionTitle}</Row>
        {guaranteedAuctionPrice && (
          <Row dataTestId="status-row-gap" name={t('guaranteed_auction_price')}>
            {guaranteedAuctionPrice?.amount === -1 ? '-' : guaranteedAuctionPrice?.formattedAmount}
          </Row>
        )}
        <AuctionLocationRow inventoryItem={inventoryItem} />
        <Row name={t('auction_date')}>{auctionTime}</Row>
        <ReservePriceRow auctionItem={details} user={user} />
        {startingBid &&
          startingBid.amount > 0 &&
          (isStaffUser || !topOffer || status === AuctionItemStatus.UPCOMING) && (
            <Row name={t('starting_bid')}>{startingBid.formattedAmountRounded}</Row>
          )}
        {autoBidAmount && (
          <Row className={style.autobidAmount} name={t('my_autobid')}>
            {autoBidAmount}
          </Row>
        )}
        <StaffUserRows auctionItem={details} onShowAutobids={onShowAutobids} />
        <Row name={t('transport_estimate')} onClick={handleTransportEstimateOnClick} />
        <RanBeforeRow details={details} onClick={onShowRanBefore} />
        <AuditLogRow auctionId={auction.id} onClick={onShowAuditLog} user={user} />
      </Rows>
    </div>
  );
};

export default UpcomingAuction;
