interface Props {
  height: number | string;
  width: number | string;
  x: number | string;
  y: number | string;
}

const FocusMask = ({ height, width, x, y }: Props) => {
  return (
    <div className="box" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
      <svg id="innerbox" style={{ width: '100%', height: '100%' }}>
        <defs>
          <mask id="hole">
            <rect fill="white" height="100%" width="100%"></rect>
            <rect fill="black" height={height} id="focus-mask-rect" rx={6} width={width} x={x} y={y}></rect>
          </mask>
        </defs>

        {/* Create a rect, fill it with the color and apply the above mask */}
        <rect fill="rgba(0,0,0, .6)" height="100%" mask="url(#hole)" width="100%"></rect>
      </svg>
    </div>
  );
};

export default FocusMask;
