import { handleActions } from 'redux-actions';
import { merge } from 'lodash-es';

import { InitialState } from './usersModels';
import {
  usersListLoaded,
  usersListError,
  facetGroupsLoaded,
  pageInfoLoaded,
  usersListIsLoading,
  usersListCleared,
  usersListUpdateItem,
} from './usersActions';
import { setInArray } from 'utils/arrayUtils';

export const usersReducer = handleActions(
  {
    [usersListIsLoading().type]: (state, action) => state.set('isLoading', action.payload),

    [usersListLoaded().type]: (state, action) => {
      const {
        userConnection: { edges, pageInfo, facetGroups },
      } = action.payload;

      return state.setLoaded().merge({
        resultList: edges.map((userEdge) => userEdge.node),
        facetGroups,
        pageInfo,
      });
    },

    [usersListCleared().type]: () => new InitialState(),

    [pageInfoLoaded().type]: (state, action) => state.set('pageInfo', action.payload),

    [facetGroupsLoaded().type]: (state, action) => state.set('facetGroups', action.payload),

    [usersListError().type]: (state, action) => state.setError(action.payload),

    [usersListUpdateItem().type]: (state, action) => {
      const userNext = action.payload;
      const userIndex = state.resultList.findIndex((item) => item?.id === userNext.id);

      if (userIndex === -1) {
        return state.merge({ resultList: [userNext, ...state.resultList] });
      }

      const existingItem = state.resultList[userIndex];
      const updatedItem = merge({}, existingItem, userNext);
      return state.merge({ resultList: setInArray(state.resultList, userIndex, updatedItem) });
    },
  },
  new InitialState()
);
