import { useEffect, useState } from 'react';

/**
 * Returns the queried element
 */
export const useElementQuerySelector = (querySelector: string | undefined) => {
  const [element, setElement] = useState<HTMLElement>();

  useEffect(() => {
    if (querySelector) {
      setElement(document.querySelector(querySelector) as HTMLElement);
    }
  }, [querySelector]);

  return element;
};
