import moment from 'moment/moment';
import { useCallback, useMemo } from 'react';

import AuctionItem from 'constants/auctionItem';
import Countdown from 'components/ui/countdowns/countdown';
import ScoreBadge from 'components/ui/shared/scores/scoreBadge';
import { AuctionItemFormat, AuctionItemStatus, AuctionTimeSlotStatus } from 'store/shared/api/graph/interfaces/types';
import { Badges, NoteBadge } from 'components/sections/inventoryItem/details/inventoryItemBadges';
import { ListItem, ListItemBody } from 'layouts/list/listItemLayout';
import { Route } from 'store/routing/routes';
import { TimerFormats } from 'constants/enums/dateAndTime';
import { UserAction } from 'logging/analytics/events/userActions';
import { formatCalendarTime } from 'utils/dateUtils';
import { getAuctionItemPageTitle } from 'utils/formatting/auctionItemFormatUtils';
import { getVehicleScore } from 'utils/inventoryItemUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';

import style from './watchlistItem.scss';

interface Props {
  /** The auction item. */
  auctionItem: AuctionItem;
  /** The system's time offset. */
  timeOffset: number;
}

const WatchlistItem = ({ auctionItem, timeOffset }: Props) => {
  /**
   * Returns true if the auctionItem is in a live auction.
   */
  const isLiveAuction = useMemo(() => {
    const { auctionTimeSlot, format, status } = auctionItem;
    const isMatchingFormat = [AuctionItemFormat.AUCTION, AuctionItemFormat.AUCTION_PHYSICAL].includes(format);
    const isMatchingStatus = [AuctionItemStatus.UPCOMING, AuctionItemStatus.LIVE].includes(status);
    const isRunningAuctionTimeSlot = auctionTimeSlot?.status === AuctionTimeSlotStatus.RUNNING;

    return isMatchingFormat && isMatchingStatus && isRunningAuctionTimeSlot;
  }, [auctionItem]);

  /**
   * Returns the route based on the auction status.
   * If the auction is live, the route will be the live auction page.
   * Otherwise, the route will be the browse page.
   */
  const auctionItemRoute = useMemo(() => {
    return isLiveAuction
      ? `${Route.BUY_LIVE_AUCTION}?auctionTimeSlotId=${auctionItem.auctionTimeSlot?.id}&id=${auctionItem.id}`
      : `${Route.BROWSE}?id=${auctionItem.id}`;
  }, [auctionItem.auctionTimeSlot?.id, auctionItem.id, isLiveAuction]);
  const pageTitle = useMemo(() => getAuctionItemPageTitle(auctionItem), [auctionItem]);

  /**
   * Returns the formatted vehicle title, based on make and year.
   */
  const formattedVehicleTitle = useMemo(() => {
    const { make, year } = auctionItem.inventoryItem;
    return [year, make].join(' ');
  }, [auctionItem.inventoryItem]);

  /**
   * Returns the formatted vehicle subtitle, based on model and trim.
   */
  const formattedVehicleSubTitle = useMemo(() => {
    const { model, trim } = auctionItem.inventoryItem;
    return [model, trim].join(' ');
  }, [auctionItem.inventoryItem]);

  /**
   * Returns the vehicle score of the inventory item.
   */
  const vehicleScore = useMemo(() => {
    const { autoGradeScore, captureType, conditionReport, extensiveVehicleConditionScore, location } =
      auctionItem.inventoryItem;
    return getVehicleScore({
      autoGradeScore,
      captureType,
      countryCode: location?.countryCode,
      extensiveVehicleConditionScore,
      overallConditionRating: conditionReport?.overallConditionRating || undefined,
    });
  }, [auctionItem.inventoryItem]);

  /**
   * Returns the auction time or countdown based on the auction status.
   */
  const renderAuctionTime = useMemo(() => {
    if (auctionItem.status === AuctionItemStatus.UPCOMING || isLiveAuction) {
      const auctionStartTime = formatCalendarTime(auctionItem.auctionTimeSlot?.startTime);
      return <div className={style.auctionTime}>{auctionStartTime}</div>;
    }

    const endTime = moment(auctionItem.timerEnd).add(timeOffset, 'ms');
    return endTime?.isValid() ? (
      <Countdown className={style.auctionTime} end={endTime} timeFormat={TimerFormats} />
    ) : null;
  }, [auctionItem.auctionTimeSlot?.startTime, auctionItem.status, auctionItem.timerEnd, isLiveAuction, timeOffset]);

  /**
   * Sends tracking info on the item clicked
   */
  const onClickListItem = useCallback(() => {
    trackUserActionWithAuctionItemAttributes(UserAction.MY_WATCHLIST_ITEM_CLICK, auctionItem);
  }, [auctionItem]);

  return (
    <ListItem
      key={`watchlist-item-${auctionItem.id}`}
      className={style.listItem}
      isLink
      linkClassName={style.listItemLink}
      onClick={onClickListItem}
      to={auctionItemRoute}
    >
      <ListItemBody className={style.body}>
        <div>
          <div className={style.pageTitle}>{pageTitle}</div>
          <div className={style.details}>
            <div className={style.vehicleTitle}>{formattedVehicleTitle}</div>
            <div className={style.vehicleSubTitle}>{formattedVehicleSubTitle}</div>
            <div className={style.vin}>{auctionItem.inventoryItem?.vin}</div>
          </div>
          <div className={style.auctionTitle}>{auctionItem.auction?.title}</div>
          {renderAuctionTime}
        </div>
        <div>
          <Badges className={style.badges}>
            {!!vehicleScore && <ScoreBadge {...vehicleScore} />}
            {Number(auctionItem.inventoryItem?.notes?.length) > 0 && <NoteBadge />}
          </Badges>
        </div>
      </ListItemBody>
    </ListItem>
  );
};

export default WatchlistItem;
