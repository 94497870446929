// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#378ccb","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","type-body-1":"type-body-1_baf959f","type-body-2":"type-body-2_c6957dc","detail":"detail_b477d40","type-body-3":"type-body-3_d70d922","type-body-4":"type-body-4_be204cd","type-section-title":"type-section-title_de63ed6","type-notes":"type-notes_d46bdb3","type-item-title":"type-item-title_a1e8bb3","type-item-subtitle":"type-item-subtitle_a7be68d","type-subtitle":"type-subtitle_c1d1ded","type-title":"type-title_bebf63a","sectionInner":"sectionInner_d8e323e","rep":"rep_c769cc6","linkedRep":"linkedRep_e8ca592","name":"name_acef36e","imgContainer":"imgContainer_f9e7a41","userImage":"userImage_ed6f779"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#378ccb\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"type-body-1\":\"type-body-1_baf959f\",\"type-body-2\":\"type-body-2_c6957dc\",\"detail\":\"detail_b477d40\",\"type-body-3\":\"type-body-3_d70d922\",\"type-body-4\":\"type-body-4_be204cd\",\"type-section-title\":\"type-section-title_de63ed6\",\"type-notes\":\"type-notes_d46bdb3\",\"type-item-title\":\"type-item-title_a1e8bb3\",\"type-item-subtitle\":\"type-item-subtitle_a7be68d\",\"type-subtitle\":\"type-subtitle_c1d1ded\",\"type-title\":\"type-title_bebf63a\",\"sectionInner\":\"sectionInner_d8e323e\",\"rep\":\"rep_c769cc6\",\"linkedRep\":\"linkedRep_e8ca592\",\"name\":\"name_acef36e\",\"imgContainer\":\"imgContainer_f9e7a41\",\"userImage\":\"userImage_ed6f779\"}";
        // 1743691151658
        var cssReload = require("../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  