import {
  Client as ConversationsClient,
  Conversation,
  ConversationUpdateReason,
  JSONObject,
  Message,
  MessageUpdateReason,
  Paginator,
  Participant,
  ParticipantUpdateReason,
  User,
  UserUpdateReason,
} from '@twilio/conversations';
import { cloneDeep } from 'lodash-es';

import { ConversationMessage, ConversationMessageType } from 'store/shared/api/graph/interfaces/types';
import { ConversationMessageState } from 'store/chat/chatModels';
import {
  getConversationAccessToken,
  getConversationAuctioneerAccessToken,
} from 'store/shared/api/graph/queries/conversationAccessToken';
import { ConnectionState, MessageMetaData, RegisteredConversations } from 'io/twilio/simulcastChatManagerTypes';

/** Identical to Twilio's `ConversationEvents`, but grouped in arrays */
interface ConversationEvents {
  messageAdded: { (message: Message): void }[];
  messageRemoved: { (message: Message): void }[];
  messageUpdated: { (data: { message: Message; updateReasons: MessageUpdateReason[] }): void }[];
  participantJoined: { (participant: Participant): void }[];
  participantLeft: { (participant: Participant): void }[];
  participantUpdated: { (data: { participant: Participant; updateReasons: ParticipantUpdateReason[] }): void }[];
  removed: { (conversation: Conversation): void }[];
  typingEnded: { (participant: Participant): void }[];
  typingStarted: { (participant: Participant): void }[];
  updated: { (data: { conversation: Conversation; updateReasons: ConversationUpdateReason[] }): void }[];
}

const defaultConversationEvents: ConversationEvents = {
  messageAdded: [],
  messageRemoved: [],
  messageUpdated: [],
  participantJoined: [],
  participantLeft: [],
  participantUpdated: [],
  removed: [],
  typingEnded: [],
  typingStarted: [],
  updated: [],
};

/**
 * @example
 * const chatManager = new SimulcastChatManager();
 * chatManager.init();
 * chatManager.registerConversation(<auctionTimeSlotLaneId>);
 * chatManager.onMessageAdded((message) => console.info('onMessageAdded', message);
 * ...
 * chatManager.terminate();
 */
class SimulcastChatManager {
  /** The logged-in user's conversationAccessToken for Twilio */
  conversationAccessToken: string | null;
  /** A collection of different event callbacks; applied when registering a conversation */
  conversationEvents: ConversationEvents = cloneDeep(defaultConversationEvents);
  /** The instance of our Twilio ConversationsClient */
  conversationsClient: ConversationsClient | null;
  /** The map of registered conversations */
  registeredConversations: RegisteredConversations = {};
  /** The map of registered conversation requests */
  registeredConversationRequests: Record<string, string> = {};
  /** A collection of user identities to track whether the user is online or offline. */
  subscribedOnlineStatusUsers: string[] = [];

  /**
   * Initialize conversation client
   */
  async init(
    connectionStateCallback: (state: ConnectionState) => void,
    isAuctioneer?: boolean,
    auctionTimeSlotLaneId?: string,
    onlineStatusChanged?: (userId: string, onlineStatus: boolean) => void
  ): Promise<void> {
    if (!this.conversationAccessToken) {
      try {
        await this.fetchToken(auctionTimeSlotLaneId, !!isAuctioneer);

        if (this.conversationAccessToken) {
          this.conversationsClient = new ConversationsClient(this.conversationAccessToken);

          this.conversationsClient.on('tokenAboutToExpire', async () => {
            await this.fetchToken(auctionTimeSlotLaneId, !!isAuctioneer);

            if (this.conversationAccessToken) {
              await this.conversationsClient?.updateToken(this.conversationAccessToken);
            }
          });

          this.conversationsClient.on('connectionStateChanged', (state: string) => {
            let connectionState: ConnectionState = ConnectionState.DISCONNECTED;
            switch (state) {
              case 'connected':
                connectionState = ConnectionState.CONNECTED;
                break;
              case 'unknown':
              case 'error':
                connectionState = ConnectionState.ERROR;
                break;
              case 'denied':
                connectionState = ConnectionState.UNAUTHORIZED;
                break;
            }

            connectionStateCallback(connectionState);

            // Listener for when subscribed users go online/offline
            this.conversationsClient?.on(
              'userUpdated',
              ({ user, updateReasons }: { user: User; updateReasons: UserUpdateReason[] }) => {
                if (
                  this.subscribedOnlineStatusUsers.includes(user.identity) &&
                  updateReasons.includes('reachabilityOnline')
                ) {
                  onlineStatusChanged?.(user.identity, !!user.isOnline);
                }
              }
            );
          });
          this.registeredConversations = {};
        } else {
          connectionStateCallback(ConnectionState.UNAUTHORIZED);
        }
      } catch (err) {
        console.error(err.message);
        connectionStateCallback(ConnectionState.UNAUTHORIZED);
      }
    }
  }

  async fetchToken(auctionTimeSlotLaneId: string | undefined, isAuctioneer: boolean) {
    if (isAuctioneer) {
      const response = auctionTimeSlotLaneId ? await getConversationAuctioneerAccessToken(auctionTimeSlotLaneId) : null;
      this.conversationAccessToken = response?.data?.data?.conversationAuctioneerAccessToken || null;
    } else {
      const response = await getConversationAccessToken();
      this.conversationAccessToken = response?.data?.data?.conversationAccessToken || null;
    }
  }

  /**
   * Determine if the chat manager has already been initialized
   */
  isInitialized(): boolean {
    return this.conversationAccessToken !== null;
  }

  /**
   * Terminate conversation client
   */
  async terminate(): Promise<void> {
    await this.conversationsClient?.shutdown();

    this.conversationAccessToken = null;
    this.conversationEvents = cloneDeep(defaultConversationEvents);
    this.conversationsClient = null;
    this.registeredConversations = {};
    this.registeredConversationRequests = {};
    this.subscribedOnlineStatusUsers = [];
  }

  /**
   * Registers a conversation based on a AuctionTimeSlotLaneId, and attaches callbacks for any event changes
   */
  async listenToConversation(conversationId: string): Promise<void> {
    let conversation: Conversation | undefined = this.registeredConversations[conversationId];
    if (conversation || this.registeredConversationRequests[conversationId]) {
      // The conversation is already registered and being listened to, or a request for it has already been made
      return;
    }

    // Make note of conversation request to prevent multiple requests to Twilio
    this.registeredConversationRequests[conversationId] = conversationId;

    conversation = await this.conversationsClient?.getConversationByUniqueName(conversationId).catch((err) => {
      throw err; // Could be legit error - lane could be closed
    });

    if (conversation) {
      delete this.registeredConversationRequests[conversationId];
      this.registeredConversations[conversationId] = conversation;

      const {
        messageAdded,
        messageRemoved,
        messageUpdated,
        participantJoined,
        participantLeft,
        participantUpdated,
        removed,
        typingEnded,
        typingStarted,
        updated,
      } = this.conversationEvents;

      conversation.on('messageAdded', (message) => messageAdded.forEach((fn) => fn(message)));
      conversation.on('messageRemoved', (message) => messageRemoved.forEach((fn) => fn(message)));
      conversation.on('messageUpdated', (data) => messageUpdated.forEach((fn) => fn(data)));
      conversation.on('participantJoined', (participant) => participantJoined.forEach((fn) => fn(participant)));
      conversation.on('participantLeft', (participant) => participantLeft.forEach((fn) => fn(participant)));
      conversation.on('participantUpdated', (data) => participantUpdated.forEach((fn) => fn(data)));
      conversation.on('removed', (removedConversation) => removed.forEach((fn) => fn(removedConversation)));
      conversation.on('typingEnded', (participant) => typingEnded.forEach((fn) => fn(participant)));
      conversation.on('typingStarted', (participant) => typingStarted.forEach((fn) => fn(participant)));
      conversation.on('updated', (data) => updated.forEach((fn) => fn(data)));
    }
  }

  removeAllListeners(): void {
    Object.values(this.registeredConversations).forEach((conversation) => {
      conversation.removeAllListeners();
    });

    this.registeredConversations = {};
  }

  removeListeners(conversationId: string): void {
    const conversation: Conversation = this.registeredConversations[conversationId];
    if (conversation) {
      conversation.removeAllListeners();
      delete this.registeredConversations[conversationId];
    }
  }

  /**
   * After connected to the chat client - we can subscribe to any channel joins / adds
   */
  listenToConversationsAdded(
    auctionTimeSlotLaneId: string,
    conversationAddedCallback: (conversationId: string) => void
  ) {
    if (this.conversationsClient) {
      this.conversationsClient.on('conversationAdded', (addedConversation: Conversation) => {
        if (
          addedConversation?.uniqueName &&
          !this.registeredConversations[addedConversation.uniqueName] &&
          (addedConversation?.attributes as any).auctionTimeSlotLaneId === auctionTimeSlotLaneId
        ) {
          conversationAddedCallback(addedConversation.uniqueName);
        }
      });
    }
  }

  /**
   * Send a message directly to the Twillio conversation API.  Callers, should try / catch
   * errors thrown by this and handle them appropriately.
   */
  async sendMessage(conversationId: string, extraData: MessageMetaData, message: ConversationMessage) {
    const conversation: Conversation = this.registeredConversations[conversationId];
    if (!conversation) {
      throw new Error(`Conversation ID: ${conversationId} is not registered`);
    }

    await conversation.sendMessage(message.message, {
      ...extraData,
      author: message.createdById,
      broadcast: message.type === ConversationMessageType.LIVE_LANE_BROADCAST,
    });
  }

  /**
   * Given a conversationID will make an asynchronous call to Twillio to return unread messages for a particular conversation
   */
  async getUnreadMessageCount(conversationId: string) {
    const conversation: Conversation = this.registeredConversations[conversationId];
    if (!conversation) {
      // No listener added yet, assume 0
      return 0;
    }

    try {
      let unreadMessages: number | null = await conversation.getUnreadMessagesCount();
      if (unreadMessages === null) {
        unreadMessages = await conversation.getMessagesCount();
      }

      return unreadMessages;
    } catch (e) {
      // If Twilio throws an exception, just assume no unread messages.
      return 0;
    }
  }

  /**
   * Resets the unread messages count for a particular conversation
   */
  async setAllMessagesAsRead(conversationId: string) {
    const conversation: Conversation = this.registeredConversations[conversationId];
    if (conversation) {
      try {
        await conversation.setAllMessagesRead();
      } catch (e) {
        // Do nothing - if Twilio is down, just wait for it to come up again
      }
    }
  }

  /**
   * Method to load all the Twillio messages into a conversation.  Usually done "on-demand" when the
   * conversation is loaded into the chat message pane.
   */
  loadAllMessages = async (conversationId: string): Promise<ConversationMessageState[]> => {
    const conversation: Conversation = this.registeredConversations[conversationId];
    if (conversation) {
      const messages: Paginator<Message> = await conversation.getMessages(500);

      return messages?.items?.map<ConversationMessageState>((message) => {
        return {
          auctionItemId: (message.attributes as JSONObject)?.auctionItemId?.toString(),
          created: message.dateCreated?.toISOString() || '',
          createdById: message.author,
          currentBidAmount: (message.attributes as JSONObject)?.currentBidAmount?.valueOf() as number,
          id: message.sid,
          message: message.body,
          type: (message.attributes as JSONObject).broadcast
            ? ConversationMessageType.LIVE_LANE_BROADCAST
            : ConversationMessageType.LIVE_LANE,
        };
      });
    }

    return [];
  };

  async subscribeToUserOnlineStatus(conversationId: string, userId: string) {
    try {
      const conversation = await this.conversationsClient?.getConversationByUniqueName(conversationId);
      const participants: Participant[] | undefined = await conversation?.getParticipants();
      const participant: Participant | undefined = participants?.find((p) => p.identity === userId);
      const user: User | undefined = await participant?.getUser();

      if (user) {
        this.subscribedOnlineStatusUsers.push(user.identity);
        return user.isOnline;
      }

      return false;
    } catch (e) {
      // Can't access Twilio API, so assume offline.
      return false;
    }
  }

  // ===========================================================================================
  // EVENT INTERFACE ---------------------------------------------------------------------------

  /**
   * onMessageAdded
   */
  onMessageAdded(fn: ConversationEvents['messageAdded'][0]) {
    this.conversationEvents.messageAdded.push(fn);
  }

  /**
   * onMessageRemoved
   */
  onMessageRemoved(fn: ConversationEvents['messageRemoved'][0]) {
    this.conversationEvents.messageRemoved.push(fn);
  }

  /**
   * onMessageUpdated
   */
  onMessageUpdated(fn: ConversationEvents['messageUpdated'][0]) {
    this.conversationEvents.messageUpdated.push(fn);
  }

  /**
   * onParticipantJoined
   */
  onParticipantJoined(fn: ConversationEvents['participantJoined'][0]) {
    this.conversationEvents.participantJoined.push(fn);
  }

  /**
   * onParticipantLeft
   */
  onParticipantLeft(fn: ConversationEvents['participantLeft'][0]) {
    this.conversationEvents.participantLeft.push(fn);
  }

  /**
   * onParticipantUpdated
   */
  onParticipantUpdated(fn: ConversationEvents['participantUpdated'][0]) {
    this.conversationEvents.participantUpdated.push(fn);
  }

  /**
   * onRemoved
   */
  onRemoved(fn: ConversationEvents['removed'][0]) {
    this.conversationEvents.removed.push(fn);
  }

  /**
   * onTypingEnded
   */
  onTypingEnded(fn: ConversationEvents['typingEnded'][0]) {
    this.conversationEvents.typingEnded.push(fn);
  }

  /**
   * onTypingStarted
   */
  onTypingStarted(fn: ConversationEvents['typingStarted'][0]) {
    this.conversationEvents.typingStarted.push(fn);
  }

  /**
   * onUpdated
   */
  onUpdated(fn: ConversationEvents['updated'][0]) {
    this.conversationEvents.updated.push(fn);
  }
}

export default SimulcastChatManager;
