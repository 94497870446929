import { handleActions } from 'redux-actions';

import { InventoryItemsResults } from './inventoryItemsListModels';
import { inventoryItemAddNote } from 'store/inventoryItemDetails/inventoryItemDetailsActions';
import {
  inventoryItemsListIsLoading,
  inventoryItemsListLoaded,
  inventoryItemsListClear,
  inventoryItemsListUpdate,
} from './inventoryItemsListActions';
import { isLatestRequestSequence } from 'utils/apiUtils';
import { setInArray } from 'utils/arrayUtils';

export const inventoryItemsListReducer = handleActions(
  {
    [inventoryItemsListIsLoading().type]: (state) => state.set('isLoading', true),

    [inventoryItemsListLoaded().type]: (state, action) => {
      if (!isLatestRequestSequence(action.payload?.requestSequence)) {
        return state;
      }

      const inventoryItemConnectionResponse = action.payload?.inventoryItemConnection;

      return state.setLoaded().merge({
        resultList: inventoryItemConnectionResponse?.edges?.map((result) => {
          return {
            ...result.node,
          };
        }),
        pageInfo: inventoryItemConnectionResponse?.pageInfo,
        facetGroups: inventoryItemConnectionResponse?.facetGroups,
      });
    },

    [inventoryItemsListClear().type]: (state) => {
      return state.unsetLoading().merge(new InventoryItemsResults());
    },

    [inventoryItemsListUpdate().type]: (state) => state.setUpdating(),

    [inventoryItemAddNote().type]: (state, action) => {
      const { inventoryItemId, note } = action.payload;
      const { resultList } = state;

      const index = resultList.findIndex((item) => item?.id === inventoryItemId);
      if (index !== -1) {
        const inventoryItem = resultList[index]!;
        inventoryItem?.notes?.push(note);

        return state.merge({ resultList: setInArray(resultList, index, inventoryItem) });
      }

      return state;
    },
  },
  new InventoryItemsResults()
);
