import { Link } from 'react-router';
import { MouseEvent, MouseEventHandler, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import editGlyph from 'glyphs/edit.svg';
import externalGlyph from 'glyphs/external-link.svg';

import Button from 'components/ui/shared/button';
import CarfaxSummary from 'components/sections/inventoryItem/details/conditionReport/carfaxSummary';
import Loading from 'components/ui/loading/loading';
import NoResults from 'components/ui/noResults/noResults';
import SlideOut from 'components/ui/slideOuts/slideOut';
import Sprite from 'components/ui/shared/sprite';
import SystemBanner from 'containers/notifications/systemBanners/systemBanner';
import { AppState } from 'store/configureStore';
import { CarfaxAnnouncements, CarfaxReportStatus } from 'store/shared/api/graph/interfaces/types';
import { SlideOutComponentProps } from 'components/sections/inventoryItem/details/slideOut/slideOut';
import { isAuctionStaff } from 'utils/userUtils';
import { splitOnNewLine } from 'utils/componentUtils';
import { t } from 'utils/intlUtils';

import style from './carfaxSlideOut.scss';

interface Props extends SlideOutComponentProps {
  /** Announcements from carfax report */
  carfaxAnnouncements?: CarfaxAnnouncements;
  /** True when claims exist */
  claimsExist: boolean;
  /** True when the slide out is loading */
  isLoading: boolean;
  /** Whether the slide out is open or not */
  isOpen: boolean;
  /** Function triggered when slide out closes */
  onClose: () => void;
  /** Function when edit button clicked */
  onEdit?: (event: MouseEvent<HTMLButtonElement>) => void;
  /** Function used to refresh to carfax summary */
  onRefresh: () => void;
  /** Function called when view full report link clicked */
  onReportLinkClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
}

const CarfaxSlideOut = ({
  carfaxAnnouncements,
  claimsExist,
  isLoading,
  isOpen,
  onRefresh,
  onReportLinkClick,
  onEdit,
  onClose,
}: Props) => {
  const user = useSelector((state: AppState) => state.app.user);
  const reportStatus = carfaxAnnouncements?.reportStatus ?? CarfaxReportStatus.NO_MVDA_REPORT;
  const reportUrl = carfaxAnnouncements?.reportUrl;

  /**
   * Renders edit icon in slide out header.
   */
  const headerEditButton = useMemo(
    () => (
      <Button
        className={style.editButton}
        dataTestId="edit-button"
        onClick={(event) => {
          onEdit?.(event);
          onClose();
        }}
        theme="none"
      >
        <Sprite glyph={editGlyph} />
      </Button>
    ),
    [onClose, onEdit]
  );

  /**
   * Slide out content rendered when no MVDA report available or expired.
   */
  const renderPlaceholder = useCallback(
    (title: string, message: string) => {
      return (
        <NoResults title={title}>
          <div className={style.message}>{splitOnNewLine(message)}</div>
          {reportUrl && (
            <Link className={style.link} onClick={onReportLinkClick} target="_blank" to={reportUrl}>
              {t('view_full_report')}
              <Sprite className={style.externalIcon} glyph={externalGlyph} />
            </Link>
          )}
        </NoResults>
      );
    },
    [onReportLinkClick, reportUrl]
  );

  /**
   * Function called on view report banner button clicked.
   */
  const onViewReportClick = useCallback(() => {
    if (reportUrl) {
      window.open(reportUrl, '_blank');
    }
  }, [reportUrl]);

  /**
   * Slide out content rendered when MVDA report available.
   */
  const renderCarfaxSummary = useMemo(() => {
    const getBannerProps = () => {
      if (reportStatus === CarfaxReportStatus.PENDING) {
        return { buttonText: t('refresh'), text: t('carfax_report_pending_message'), onButtonClick: onRefresh };
      }
      if (reportStatus === CarfaxReportStatus.NO_MVDA_REPORT) {
        return {
          buttonText: t('view_report'),
          text: t('carfax_report_no_report_message'),
          onButtonClick: onViewReportClick,
        };
      }
      if (reportStatus === CarfaxReportStatus.FAILED) {
        return isAuctionStaff(user)
          ? {
              buttonText: t('view_report'),
              text: t('carfax_report_failed_staff_message'),
              onButtonClick: onViewReportClick,
            }
          : {
              buttonText: t('view_report'),
              text: t('carfax_report_failed_user_message'),
              onButtonClick: onViewReportClick,
            };
      }

      return null;
    };

    const bannerProps = getBannerProps();
    return (
      <>
        {bannerProps && <SystemBanner className={style.banner} {...bannerProps} />}
        <CarfaxSummary
          announcements={carfaxAnnouncements?.announcements || []}
          className={style.summary}
          onReportLinkClick={onReportLinkClick}
          reportUrl={reportUrl}
        />
      </>
    );
  }, [
    carfaxAnnouncements?.announcements,
    onRefresh,
    onReportLinkClick,
    onViewReportClick,
    reportStatus,
    reportUrl,
    user,
  ]);

  /**
   * Slide out content rendered based on report status.
   */
  const renderContent = useMemo(() => {
    if (
      reportStatus === CarfaxReportStatus.PENDING ||
      reportStatus === CarfaxReportStatus.VALID ||
      reportStatus === CarfaxReportStatus.FAILED ||
      (reportStatus === CarfaxReportStatus.NO_MVDA_REPORT && claimsExist)
    ) {
      return renderCarfaxSummary;
    }
    if (reportStatus === CarfaxReportStatus.NO_MVDA_REPORT) {
      return renderPlaceholder(t('unable_to_load_summary'), t('unable_to_load_summary_message'));
    }
    if (reportStatus === CarfaxReportStatus.EXPIRED) {
      return renderPlaceholder(t('report_expired'), t('report_expired_message'));
    }
    return null;
  }, [claimsExist, renderCarfaxSummary, renderPlaceholder, reportStatus]);

  return (
    <SlideOut
      headerActions={onEdit ? headerEditButton : undefined}
      headerClassName={style.header}
      isOpen={isOpen}
      onClose={onClose}
      title={t('carfax_summary')}
    >
      {renderContent}
      <Loading hasFullWidth isLoading={isLoading} isModalTransparent />
    </SlideOut>
  );
};

export default CarfaxSlideOut;
