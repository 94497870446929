import classnames from 'classnames';
import { Link } from 'react-router';
import { Suspense, useCallback, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import BrowseProductTour from 'components/ui/productTours/browseProductTour';
import CheckoutNotification from 'containers/navigation/checkoutNotificationButton';
import ClearActingUserButton from 'containers/navigation/clearActingUserButton';
import Logo from 'components/ui/logo/logo';
import Navigation from 'components/core/navigation/navigation';
import SubNavigation from 'components/core/navigation/subNavigation';
import SystemBanners from 'containers/notifications/systemBanners/systemBanners';
import UserImage from 'components/ui/shared/images/userImage';
import UserNotificationsButton from 'containers/navigation/userNotificationsButton';
import WatchlistButton from 'components/sections/watchlist/watchlistButton';
import { AppState } from 'store/configureStore';
import { FeatureFlag } from 'constants/featureFlags';
import { Route } from 'store/routing/routes';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { getSubNavigationMenuItems, MenuItem } from 'components/core/navigation/subNavigationMenuItems';
import { hasFeatureFlag } from 'utils/featureFlagUtils';
import { kebabCaseToCamelCase } from 'utils/stringUtils';

import style from './navigationContainer.scss';

export const NAVIGATION_CONTAINER_ID = 'navigation-container';

const stateConnect = (state: AppState) => ({
  /** Authorization information. */
  auth: state.app.auth,
  /** Current logged in user. */
  user: state.app.user.toJS(),
});

const connector = connect(stateConnect);

type Props = RouterProps & ConnectedProps<typeof connector>;

const NavigationContainer = ({ auth, user, location, routes }: Props) => {
  /**
   * Memoized menu items
   */
  const menuItems: MenuItem | undefined = useMemo(() => {
    // Get SubNavigation's menu item's based on current route path
    const urlSegments = location.pathname.split('/');
    const baseUrlSegment = kebabCaseToCamelCase(urlSegments[1]);
    return baseUrlSegment ? getSubNavigationMenuItems()?.[baseUrlSegment] : undefined;
  }, [location.pathname]);

  /**
   * Check if the notifications slide out should be open by default
   */
  const isNotificationsSlideOutDefaultOpen = useCallback(() => {
    return (location.pathname as Route) === Route.NOTIFICATION_SETTINGS;
  }, [location.pathname]);

  if (!auth.isLoggedIn || !user.id || routes[routes?.length - 1]?.isNavigationDisabled) {
    return null;
  }

  return (
    <>
      <header className={style.container} data-testid="nav-container-header" id={NAVIGATION_CONTAINER_ID}>
        <SystemBanners />
        <div
          className={classnames(style.firstLine, user?.sessionActingAsOtherUser && style.actingAsUser)}
          data-testid="navigation-primary"
        >
          <Logo className={style.logo} isLarge />
          <Navigation user={user} />
          <div className={style.menu}>
            <ClearActingUserButton isVisible={!!user?.sessionActingAsOtherUser} user={user} />
            <Suspense fallback={<div />}>
              <CheckoutNotification showNotificationBadge user={user} />
              <WatchlistButton />
              <UserNotificationsButton className={style.icon} isDefaultOpen={isNotificationsSlideOutDefaultOpen()} />
              <Link className={style.userLink} data-testid="nav-account" to={Route.ACCOUNT}>
                <UserImage containerClassName={style.icon} imageUrl={user.photoUrl || undefined} />
              </Link>
            </Suspense>
          </div>
        </div>
        <SubNavigation menu={menuItems} user={user} />
      </header>

      {hasFeatureFlag(FeatureFlag.BROWSE) && (
        // Browse Product Tour
        <BrowseProductTour />
      )}
    </>
  );
};

export default withRouter(connector(NavigationContainer));
