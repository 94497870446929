import { handleActions } from 'redux-actions';

import { InitialState } from './companiesModels';
import { companiesListIsLoading, companiesListLoaded, companiesListCleared } from './companiesActions';

export const companiesReducer = handleActions(
  {
    [companiesListIsLoading().type]: (state, action) => state.set('isLoading', action.payload),

    [companiesListLoaded().type]: (state, action) => {
      const {
        companyConnection: { edges, pageInfo, facetGroups },
      } = action.payload;

      return state.setLoaded().merge({
        resultList: edges.map((companyEdge) => companyEdge.node),
        facetGroups,
        pageInfo,
      });
    },

    [companiesListCleared().type]: () => new InitialState(),
  },
  new InitialState()
);
