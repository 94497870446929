import ApiRequest from 'store/shared/api/apiRequest';
import { AuctionItemConnection, QueryauctionItemConnectionArgs } from 'store/shared/api/graph/interfaces/types';
import { pageInfo } from 'store/shared/api/graph/schema/connection';

export const getWatchlist = (options: QueryauctionItemConnectionArgs) =>
  ApiRequest<{ auctionItemConnection: AuctionItemConnection }>({
    data: {
      operationName: 'getWatchlist',
      query: `
        query(
          $after: String,
          $before: String,
          $filterBy: String,
          $first: Int,
          $formats: [AuctionItemFormat],
          $last: Int,
          $sort: [Sort],
          $status: [AuctionItemStatus]
        ) {
          auctionItemConnection(
            after: $after,
            before: $before,
            filterBy: $filterBy,
            first: $first,
            formats: $formats,
            last: $last,
            sort: $sort,
            status: $status
          ) {
            edges {
              node {
                id
                auction {
                  title
                }
                auctionTimeSlot {
                  id
                  startTime
                  status
                }
                format
                inventoryItem {
                  id
                  autoGradeScore
                  captureType
                  company {
                    id
                    name
                  }
                  conditionReport {
                    overallConditionRating
                  }
                  extensiveVehicleConditionScore
                  location {
                    countryCode
                  }
                  make
                  model
                  notes {
                    id
                  }
                  trim
                  vin
                  year
                }
                status
                timerEnd
              }
            }
            ${pageInfo}
          }
      }
    `,
      variables: {
        after: options?.after,
        before: options?.before,
        filterBy: options?.filterBy,
        first: options?.first,
        formats: options?.formats,
        last: options?.last,
        sort: options?.sort,
        status: options?.status,
      },
    },
  });
