import { useEffect } from 'react';

import AuthService from 'store/shared/services/authService';
import { AUTH_STORAGE_NAME } from 'utils/authUtils';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { usePrevious } from 'hooks/usePrevious';

/**
 * Listens to our Auth localStorage for token changes.
 * If the token is updated, refresh the page (aka the user)
 */
export const useAuthConnection = () => {
  const [userToken] = useLocalStorage(AUTH_STORAGE_NAME);
  const userTokenPrev = usePrevious(userToken);

  useEffect(() => {
    if (AuthService.user.id && userTokenPrev && userToken !== userTokenPrev) {
      window.location.reload();
    }
  }, [userToken, userTokenPrev]);
};
