import AuctionItem from 'constants/auctionItem';
import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import {
  AuctionItemSavedFilter,
  Facet,
  FacetGroup,
  FacetedPageInfo,
  FilterSection,
} from 'store/shared/api/graph/interfaces/types';

export interface AuctionItemsResultModel {
  /** List of auction item end times. */
  auctionItemEndTimes: Facet[];
  /** Auction items facet groups. */
  facetGroups: FacetGroup[];
  /** The available filter sections/filters */
  filterSections: FilterSection[];
  /** Auction items filters. */
  filters: FiltersModel;
  /** Whether is adding auction item or not. */
  isAdding: boolean;
  /** Count of new auction items. */
  newItemsCount: number;
  /** Information about pagination. */
  pageInfo: FacetedPageInfo;
  /** List of auction items. */
  resultList: AuctionItem[];
  /** Saved auction item filters. */
  savedFilters: AuctionItemSavedFilter[] | null;
  /** Sort information. */
  sort: SortModel;
  /** List of submitting auction item ids. */
  submittingItemIds: string[];
}

export type AuctionItemsResultsProps = ServerRecordOf<AuctionItemsResultModel>;
export type AuctionItemsResultsPropsJs = ReturnType<AuctionItemsResultsProps['toJS']>;

const sliderInitialValues = Object.freeze({
  mileage: [0, 300000],
  vehicleScore: [0, 5],
  title: undefined,
  year: [1900, 2017],
});

interface FiltersModel {
  /** Mileage */
  mileage: number[];
  /** Mileage title */
  mileageTitle: string | undefined;
  /** Vehicle score */
  vehicleScore: number[];
  /** Vehicle score title */
  vehicleScoreTitle: string | undefined;
  /** Year */
  year: number[];
  /** Year title */
  yearTitle: string | undefined;
}

export const Filters = {
  mileage: sliderInitialValues.mileage,
  mileageTitle: sliderInitialValues.title,
  vehicleScore: sliderInitialValues.vehicleScore,
  vehicleScoreTitle: sliderInitialValues.title,
  year: sliderInitialValues.year,
  yearTitle: sliderInitialValues.title,
};

interface SortModel {
  /** True, sort by ascending else descending */
  ascending: boolean;
  /** Sort Id */
  sortId: string | null;
}

export const Sort: SortModel = {
  ascending: false,
  sortId: null,
};

export const AuctionItemsResults = ServerRecord<AuctionItemsResultModel>({
  auctionItemEndTimes: [],
  facetGroups: [],
  filterSections: [],
  filters: Filters,
  isAdding: false,
  newItemsCount: 0,
  pageInfo: null,
  resultList: [],
  savedFilters: null,
  sort: Sort,
  submittingItemIds: [],
});
