import ApiRequest from 'store/shared/api/apiRequest';
import InventoryItem from 'constants/inventoryItem';
import {
  AuctionItemConnection,
  CountryCode,
  InventoryItemConnection,
  InventoryItemMetaData,
  InventoryItemPhotoCreateInput,
  PhotosDTO,
  VehicleCaptureType,
} from 'store/shared/api/graph/interfaces/types';
import { ClearableFieldInput } from 'constants/enums/forms';
import {
  duplicateVehicleSchema,
  getBuilderOptionFragments,
  getCommonStaticTypes,
  getCommonVehicleProperties,
  getVehicleBuilderSchema,
  inventoryItemSchema,
} from './addModifySchemas';
import { nullifyEmptyString } from 'utils/stringUtils';

export const createInventoryItem = ({
  vin = null,
  stockNumber = null,
  auctionLocationId = null,
  locationId = null,
  pickupLocationId = null,
  year = null,
  makeId = null,
  minimalCR = null,
  modelId = null,
  subModelId = null,
  trimId = null,
  trim = null,
  mileage = {
    amount: null,
    unit: 'km',
  },
  lastKnownMileage = {
    amount: null,
    unit: 'km',
  },
  lastKnownMileageDate = null,
  bodyType = null,
  captureType = null,
  chargingCable = null,
  exteriorColor = null,
  interiorColor = null,
  options = null,
  numberOfDoors = null,
  numberOfPassengers = null,
  driveTrain = null,
  transmission = null,
  displacement = null,
  cylinders = null,
  fuelType = null,
  validateVin = false,
  decodeVin = false,
  persist = false,
  sellerNotes = '',
  conditionReport: {
    carfaxCanadaReportUrl = null,
    carfaxCanadaClaimAmount = {
      amount: null,
    },
    overallConditionRating = 0,
    declarations = [],
  },
  photos = [],
  consignerId = null,
}) => {
  return ApiRequest<{ inventoryItemCreate: InventoryItem }>({
    data: {
      operationName: 'createInventoryItem',
      query: `
        mutation (
          $consignerId: String
          ${getCommonStaticTypes}
        ){
          inventoryItemCreate(
            consignerId: $consignerId,
            ${getCommonVehicleProperties}
          ) {
            ${getVehicleBuilderSchema()}
          }
        }
        ${getBuilderOptionFragments}
      `,
      variables: {
        vin,
        auctionLocationId,
        stockNumber,
        locationId,
        pickupLocationId: nullifyEmptyString(pickupLocationId || locationId),
        year,
        makeId,
        minimalCR,
        modelId,
        subModelId,
        trimId: trimId === 'OTHER' ? null : trimId,
        trim: trim === '' ? null : trim,
        mileageAmount: mileage.amount,
        mileageUnit: mileage.unit,
        lastKnownMileageAmount:
          lastKnownMileage?.amount === null ? null : lastKnownMileage?.amount || ClearableFieldInput.NUMBER,
        lastKnownMileageUnit: lastKnownMileage?.unit === '-' ? null : lastKnownMileage?.unit,
        lastKnownMileageDate: lastKnownMileageDate === '-' ? null : lastKnownMileageDate,
        bodyType,
        chargingCable,
        captureType: captureType || VehicleCaptureType.SELF_CAPTURE,
        exteriorColor,
        interiorColor,
        options,
        numberOfDoors,
        numberOfPassengers,
        transmission,
        driveTrain,
        displacement,
        cylinders,
        fuelType,
        validateVin,
        decodeVin,
        persist,
        overallConditionRating,
        carfaxCanadaClaimAmount: carfaxCanadaClaimAmount?.amount,
        carfaxCanadaReportUrl,
        sellerNotes,
        declarations,
        photos,
        consignerId,
      },
    },
  });
};

export const updateInventoryItem = ({
  inventoryItemId = null,
  vin = null,
  stockNumber = null,
  locationId = null,
  pickupLocationId = null,
  year = null,
  makeId = null,
  minimalCR = null,
  modelId = null,
  trimId = null,
  trim = null,
  subModelId = null,
  mileage = {
    amount: null,
    unit: 'km',
  },
  lastKnownMileage = {
    amount: null,
    unit: 'km',
  },
  lastKnownMileageDate = null,
  bodyType = null,
  chargingCable = null,
  exteriorColor = null,
  interiorColor = null,
  options = [],
  numberOfDoors = null,
  numberOfPassengers = null,
  driveTrain = null,
  transmission = null,
  displacement = null,
  cylinders = null,
  fuelType = null,
  validateVin = false,
  persist = false,
  sellerNotes = '',
  conditionReport: {
    carfaxCanadaReportUrl = null,
    carfaxCanadaClaimAmount = {
      amount: null,
    },
    overallConditionRating = 0,
    declarations = [],
  },
  tireCondition,
  photos = [],
}) => {
  return ApiRequest<{ inventoryItemUpdate: InventoryItem }>({
    data: {
      operationName: 'updateInventoryItem',
      query: `
        mutation (
          $inventoryItemId: String!,
          ${getCommonStaticTypes}
        ){
          inventoryItemUpdate(
            inventoryItemId: $inventoryItemId,
            ${getCommonVehicleProperties}
          ) {
            ${getVehicleBuilderSchema()}
          }
        }
        ${getBuilderOptionFragments}
      `,
      variables: {
        inventoryItemId,
        vin,
        stockNumber,
        locationId,
        pickupLocationId: nullifyEmptyString(pickupLocationId || locationId),
        year,
        makeId,
        minimalCR,
        modelId,
        subModelId,
        trimId: trimId === 'OTHER' ? null : trimId,
        trim: trim === '' ? null : trim,
        mileageAmount: mileage.amount,
        mileageUnit: mileage.unit,
        lastKnownMileageAmount:
          lastKnownMileage?.amount === null ? null : lastKnownMileage?.amount || ClearableFieldInput.NUMBER,
        lastKnownMileageUnit: lastKnownMileage?.unit === '-' ? null : lastKnownMileage?.unit,
        lastKnownMileageDate: lastKnownMileageDate === '-' ? null : lastKnownMileageDate,
        bodyType,
        chargingCable,
        exteriorColor,
        interiorColor,
        options,
        numberOfDoors,
        numberOfPassengers,
        transmission,
        driveTrain,
        displacement,
        cylinders,
        fuelType,
        validateVin,
        persist,
        overallConditionRating,
        carfaxCanadaClaimAmount: carfaxCanadaClaimAmount.amount,
        carfaxCanadaReportUrl,
        sellerNotes,
        declarations,
        tireCondition: {
          ...tireCondition,
          secondSetOfTiresHasRims: tireCondition?.secondSetOfTires ? tireCondition?.secondSetOfTiresHasRims : undefined,
        },
        photos,
      },
    },
  });
};

export const getInventoryItemById = (inventoryItemId = null) => {
  return ApiRequest<{ inventoryItemUpdate: InventoryItem }>({
    data: {
      operationName: 'getInventoryItemById',
      query: `
        mutation (
          $inventoryItemId: String!,
        ){
          inventoryItemUpdate(
            inventoryItemId: $inventoryItemId,
            vehicle: {
              persist: false,
          }) {
            ${getVehicleBuilderSchema(true)}
          }
        }
        ${getBuilderOptionFragments}
      `,
      variables: {
        inventoryItemId,
      },
    },
  });
};

export const submitAttachCarfax = ({ inventoryItemId = null }) => {
  return ApiRequest<{ inventoryItemCarfaxCanadaAttachReport: Pick<InventoryItem, 'id' | 'conditionReport'> }>({
    data: {
      query: `
        mutation($inventoryItemId: String!) {
          inventoryItemCarfaxCanadaAttachReport(inventoryItemId: $inventoryItemId) {
            id
            conditionReport {
              carfaxCanadaReportStatus
              carfaxCanadaReportUrl
              declarations: declarations(selected: true) {
                id
                name
                selected
              }
            }
          }
        }
      `,
      variables: {
        inventoryItemId,
      },
    },
  });
};

export const inventoryItemPhotoCreate = ({
  contentType,
  damageLocation = null,
  inventoryItemId,
  photoType,
  shotCode = null,
  thumbnails = null,
}: InventoryItemPhotoCreateInput) => {
  return ApiRequest<{ inventoryItemPhotoCreate: PhotosDTO }>({
    data: {
      operationName: 'inventoryItemPhotoCreate',
      query: `
        mutation (
          $contentType: String!,
          $damageLocation: String,
          $inventoryItemId: String!,
          $photoType: PhotoType!,
          $shotCode: Int,
          $thumbnails: String,
        ){
          inventoryItemPhotoCreate (photoInput: {
            contentType: $contentType,
            damageLocation: $damageLocation,
            inventoryItemId: $inventoryItemId,
            photoType: $photoType,
            shotCode: $shotCode,
            thumbnails: $thumbnails,
          }) {
            photoDTOs {
              damageLocation
              downloadUrl
              id
              shotCode
              status
              thumbnails
              type
              uploadUrl
            }
          }
        }
      `,
      variables: {
        contentType,
        damageLocation,
        inventoryItemId,
        photoType,
        shotCode,
        thumbnails,
      },
    },
  });
};

export const getDuplicateVins = (vin) => {
  return ApiRequest<{
    cancelled: AuctionItemConnection;
    inIfBid: AuctionItemConnection;
    noSale: AuctionItemConnection;
    parked: InventoryItemConnection;
    pendingDelivery: AuctionItemConnection;
    purchased: AuctionItemConnection;
    runList: AuctionItemConnection;
    sold: AuctionItemConnection;
    timedAuction: AuctionItemConnection;
  }>({
    data: {
      operationName: 'getDuplicateVins',
      query: `
      query($vin: String) {
        timedAuction: auctionItemConnection(
          first: 4,
          status: [LIVE],
          formats:[APPRAISAL, GROUNDED],
          keyword: $vin
        ) {
          ${duplicateVehicleSchema}
        }
        runList: auctionItemConnection(
          first: 4,
          status: [UPCOMING],
          formats: [AUCTION],
          sort: [{id: "CREATED", ascending: false}]
          keyword: $vin
        ) {
          ${duplicateVehicleSchema}
        }
        parked: inventoryItemConnection(
          first: 4,
          keyword: $vin
        ) {
          edges {
            node {
              id
              created
              ${inventoryItemSchema}
            }
          }
        }
        inIfBid: auctionItemConnection(
          first: 4,
          status: [IN_IF_BID],
          sort: [{id: "UPDATED", ascending: false}]
          keyword: $vin
        ) {
          ${duplicateVehicleSchema}
        }
        pendingDelivery: auctionItemConnection(
          first: 4,
          status: [PENDING_DELIVERY],
          sort: [{id: "UPDATED", ascending: false}]
          keyword: $vin
        ) {
          ${duplicateVehicleSchema}
        }
        sold: auctionItemConnection(
          first: 4,
          status: [SOLD],
          transactionType: SELLING,
          sort: [{id: "DATE_SOLD", ascending: false}]
          keyword: $vin
        ) {
          ${duplicateVehicleSchema}
        }
        purchased: auctionItemConnection(
          first: 4,
          status: [SOLD],
          transactionType: BUYING,
          sort: [{id: "DATE_SOLD", ascending: false}]
          keyword: $vin
        ) {
          ${duplicateVehicleSchema}
        }
        noSale: auctionItemConnection(
          first: 4,
          status: [NO_SALE],
          sort: [{id: "UPDATED", ascending: false}]
          keyword: $vin
        ) {
          ${duplicateVehicleSchema}
        }
        cancelled: auctionItemConnection(
          first: 4,
          status: [SALE_CANCELLED],
          sort: [{id: "UPDATED", ascending: false}]
          keyword: $vin
        ) {
          ${duplicateVehicleSchema}
        }
      }
      fragment thumbnailListFrag on Photo {
        thumb: url (width: 110, height: 110)
      }
    `,
      variables: {
        vin,
      },
    },
  });
};

export const getDeclarationsMeta = ({ countryCode }: { countryCode: CountryCode }) =>
  ApiRequest<{ inventoryItemMetaData: InventoryItemMetaData }>({
    data: {
      operationName: 'getDeclarationsMeta',
      query: `
      query($countryCode: CountryCode!) {
        inventoryItemMetaData {
          vehicleMetaData {
            declarations (countryCode: $countryCode) {
              id
              name
              type
            }
          }
        }
      }
    `,
      variables: {
        countryCode,
      },
    },
  });
