import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import User from 'constants/user';
import { FacetGroup, FacetedPageInfo } from 'store/shared/api/graph/interfaces/types';

interface UserModel {
  /** Facet groups used to filter search results. */
  facetGroups: FacetGroup[];
  /** Information about pagination. */
  pageInfo: FacetedPageInfo;
  /** List of users. */
  resultList: User[];
}

export type UsersProps = ServerRecordOf<UserModel>;

export const InitialState = ServerRecord<UserModel>({
  facetGroups: [],
  pageInfo: null,
  resultList: [],
});
