import { handleActions } from 'redux-actions';

import { HomeDashboardInitialState } from './homeDashboardModels';
import { isLoading, isUpdating, isNotLoading, homeDashboardLoaded, homeDashboardClear } from './homeDashboardActions';
import { parseQueryConnectionResponse } from 'utils/apiUtils';

export const homeDashboardReducer = handleActions(
  {
    [isLoading().type]: (state) => state.setLoading(),

    [isUpdating().type]: (state) => state.setUpdating(),

    [isNotLoading().type]: (state) => state.unsetLoading(),

    [homeDashboardLoaded().type]: (state, action) => {
      // Omit auctions with zero upcoming units
      const nextAuctions = parseQueryConnectionResponse(action?.payload?.nextAuctions);
      const nextAuction = nextAuctions?.filter((auction) => auction?.itemCount)?.[0];

      return state.setLoaded().merge({
        results: {
          ...action.payload,
          liveLanes: action.payload?.liveLanes ?? [],
          nextAuction,
        },
      });
    },

    [homeDashboardClear().type]: (state) => {
      return state.unsetLoading();
    },
  },
  new HomeDashboardInitialState()
);
