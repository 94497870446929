import ApiRequest from 'store/shared/api/apiRequest';
import { QueryauctionItemConnectionArgs } from 'store/shared/api/graph/interfaces/types';
import { getPermittedAuctionIds } from 'utils/privateLabelUtils';

export const getHomeDashboard = ({ salesRepId = undefined }: QueryauctionItemConnectionArgs) => {
  const auctionIds = getPermittedAuctionIds();

  return ApiRequest({
    data: {
      operationName: 'getHomeDashboard',
      query: `
      query(
        $auctionIds: [String],
        $salesRepId: String,
        $salesRepIds: [String]
      ) {
        liveLanes: liveLanes(auctionIds: $auctionIds) {
          timeSlotId
          liveItem {
            id
            totalBids
            winningBid {
              consignerId
            }
            uniqueBidders
          }
        }
        nextAuctions: auctionTimeSlotConnection(
          first: 10
          sort: { id: "START_TIME", ascending: true }
          status: WAITING
        ) {
          edges {
            node {
              auction {
                title
              }
              itemCount
              startTime
            }
          }
        }
        upcoming: auctionItemConnection(
          first:0, 
          after: "0", 
          status: UPCOMING, 
          formats: [AUCTION, AUCTION_PHYSICAL],
          auctionTimeSlotStatus: WAITING,
          archived: false,
          auctionIds: $auctionIds,
          salesRepId: $salesRepId
        ) {
          facetGroups(name: ["status"]) {
            name
            facets {
              count
              localizedName
              name
              selected
            }
          }
        }
        recommended: auctionItemConnection(
          first: 4,
          after: "0",
          status: [UPCOMING, LIVE],
          filterBy: "Recommended",
          archived: false,
          auctionIds: $auctionIds,
          salesRepId: $salesRepId
        ) {
          edges {
            node {
              id
              listPrice {
                formattedAmountRounded
              }
              inventoryItem {
                id
                year
                make
                model
                trim
                mileage {
                  formattedAmount
                }
                transmission
                displacement
                cylinders
                fuelType
                primaryPhoto: photos(first: 1) {
                  url(width: 238, height: 148)
                }
                company {
                  name
                }
                location {
                  name
                  city
                  regionCode
                }
              }
            }
          }
        }
        appraisals: auctionItemConnection(
          first: 1,
          after: "0",
          status: [LIVE]
          formats: [APPRAISAL],
          archived: false,
          auctionIds: $auctionIds,
          salesRepId: $salesRepId
        ) {
          facetGroups (name:["filterBy"]) {
            name
            facets {
              count
              localizedName
              name
              selected
            }
          }
          pageInfo {
            totalEdges
          }
        },
        buyNow: auctionItemConnection(
          first: 1,
          after: "0",
          status: [LIVE]
          formats: [GROUNDED],
          archived: false,
          auctionIds: $auctionIds,
          salesRepId: $salesRepId
        ) {
          facetGroups (name:["filterBy"]) {
            name
            facets {
              count
              localizedName
              name
            }
          }
          pageInfo {
            totalEdges
          }
        }
        timedOffer: auctionItemConnection(
          first: 1
          after: "0"
          status: [LIVE]
          formats: TIMED_OFFER
          archived: false
          auctionIds: $auctionIds
          salesRepId: $salesRepId
        ) {
          facetGroups (name:["filterBy"]) {
          name
          facets {
            count
            name
          }
        }
          pageInfo {
            totalEdges
          }
        } 
        parked: inventoryItemConnection(
          first: 1,
          after: "0",
          archived: false,
          salesRepId: $salesRepId
        ) {
          pageInfo {
            totalEdges
          }
        }
        ifBid: auctionItemConnection(
          first: 1,
          after: "0",
          status: [IN_IF_BID],
          archived: false,
          auctionIds: $auctionIds,
          salesRepId: $salesRepId
        ) {
          facetGroups (name:["filterBy"]) {
            name
            facets {
              count
              localizedName
              name
              selected
            }
          }
          pageInfo {
            totalEdges
          }
        }
        boughtSold: auctionItemConnection(
          first: 1,
          after: "0",
          status: [SOLD],
          archived: false,
          auctionIds: $auctionIds,
          salesRepId: $salesRepId
        ) {
          facetGroups (name:["transactionType"]) {
            name
            facets {
              count
              localizedName
              name
            }
          }
        }
        awaitingCheckout: auctionItemConnection(
          first: 1,
          after: "0",
          status: [AWAITING_CHECKOUT],
          archived: false,
          auctionIds: $auctionIds,
          salesRepId: $salesRepId
        ) {
          facetGroups (name:["transactionType"]) {
            name
            facets {
              count
              localizedName
              name
            }
          }
        }
        recentActivity: auctionItemConnection(
          first: 4,
          after: "0",
          status: [IN_IF_BID, PENDING_DELIVERY, SOLD, NO_SALE, SALE_CANCELLED],
          archived: false,
          auctionIds: $auctionIds,
          salesRepId: $salesRepId
        ) {
          facetGroups (name:["status"]) {
            name
            facets {
              count
              localizedName
              name
            }
          }
          pageInfo {
            totalEdges
          }
          edges {
            node {
              id
              format
              status
              isMyItem
              transactionType
              deliveryDate
              colorScheme
              timerText
              timerStart
              timerEnd
              auction {
                title
              }
              auctionTimeSlot {
                startTime
              }
              buyer {
                company {
                  id
                  name
                }
              }
              startingBid {
                formattedAmountRounded
              }
              listPrice {
                formattedAmountRounded
              }
              bidTimeline {
                list {
                  amount {
                    formattedAmountRounded
                  }
                }
              }
              nextBidAmount {
                formattedAmountRounded
              }
              watchers {
                isWatched
              }
              autoBids {
                myAutoBid {
                  maxBid {
                    formattedAmountRounded
                  }
                }
              }
              topOffer {
                amount {
                  formattedAmountRounded
                }
              }
              ifBidTimeline {
                ending
                amountApart {
                  formattedAmountRounded
                }
                awaitingMyResponse
                inIfBidWithCompany {
                  name
                }
              }
              transactionDetails {
                purchasePrice {
                  formattedAmountRounded
                }
                created
              }
              history {
                count
              }
              inventoryItem {
                id
                year
                make
                model
                trim
                mileage {
                  formattedAmount
                }
                transmission
                displacement
                cylinders
                fuelType
                completionPercentage
                primaryPhoto: photos(first: 1) {
                  url(width: 238, height: 148)
                }
                company {
                  name
                }
                location {
                  name
                  city
                  regionCode
                }
              }
              updated
            }
          }
        }
        inspectionConnection(
          first:1, 
          after:"0", 
          status: [PENDING, CLAIMED, IN_PROGRESS],
          salesRepId: $salesRepIds
        ) {
          edges {
            node {
              id
              status
              requestedInspectionDate
              requestedInspectionTime
              numberOfItemsRequested
              acceptedInspectionProposal {
                proposedInspectionTime
                createdBy {
                  firstName
                  lastName
                  photoUrl(width:100, height:100)
                }
              }
              inspectedInventoryItemCount
              createdBy {
                email
              }
            }
          }
        }
        broadcasts {
          id
          type
          message
          start
        }
      }`,
      variables: {
        auctionIds,
        salesRepId,
        salesRepIds: salesRepId ? [salesRepId] : null,
      },
    },
  });
};
