import classnames from 'classnames';
import { ReactElement, Children, cloneElement, memo, useId, ReactNode, HTMLAttributes } from 'react';

import ArchiveGlyph from 'glyphs/operationIcons/archive-item.svg';
import AssuredGlyph from 'glyphs/assured.svg';
import AutobidGlyph from 'glyphs/autobid.svg';
import AutogradeGlyph from 'glyphs/autograde.svg';
import BuyerShieldGlyph from 'glyphs/buyerShield.svg';
import CarfaxCaGlyph from 'glyphs/carfax-ca.svg';
import FileGlyph from 'glyphs/file.svg';
import HoldbackGlyph from 'glyphs/holdback.svg';
import RefreshGlyph from 'glyphs/refresh.svg';
import StarGlyph from 'glyphs/star.svg';
import VerifiedGlyph from 'glyphs/verified.svg';

import BadgeTooltip from 'components/sections/inventoryItem/details/badgeTooltip';
import Sprite, { Glyph } from 'components/ui/shared/sprite';
import { rangeCA, ScoreRange } from 'components/ui/shared/scores/scoreRanges';
import { t } from 'utils/intlUtils';
import { useScoreRangeValue } from 'components/ui/shared/scores/useScoreRangeValue';

import style from './inventoryItemBadges.scss';

interface BadgesProps {
  /** The component to render. */
  children?: (ReactElement<BadgeProps> | null | false)[];
  /** The className to overwrite default styles */
  className?: string;
  /** True to show tooltip for all badges */
  showTooltips?: boolean;
}

interface BadgeProps {
  /** The className to overwrite default styles */
  className?: string;
  /** True to show tooltip */
  showTooltip?: boolean;
}

interface BadgeWithGlyphOverwriteProps extends BadgeProps {
  /** Used to overwrite existing glyph (if there are multiple variants) */
  glyph?: Glyph;
  /** The className to overwrite glyph default styles */
  glyphClassName?: string;
}

interface AsIsBadgeProps extends BadgeProps {
  /** Theme for the badge */
  theme?: 'red' | 'white';
}

interface ScoreBadgeProps extends BadgeProps {
  /** The condition score */
  score: number | undefined;
}

interface EBlockScoreBadgeProps extends ScoreBadgeProps {
  /** Score range to return the proper badge color */
  range?: ScoreRange;
  /** True, show `Score` label */
  showLabel?: boolean;
}

interface TextBadgeProps extends BadgeProps {
  /** The text label displayed next to the badge. */
  text?: string;
  /** Optional tooltip description to overwrite the default description. */
  tooltipDescription?: string;
}

interface UnreservedProps extends BadgeProps {}

interface CarfaxBadgeProps extends BadgeProps {
  /** True when claims exist on carfax report */
  claimsExist: boolean;
}

export const Badges = ({ children, className, showTooltips = true }: BadgesProps) => (
  <div className={classnames(style.badges, className)} data-testid="badges-container">
    {Children.map(children, (child) => {
      if (!child) {
        return null;
      }

      /**
       * Skips injecting props to `HTMLElements`. In case we add non-badge components
       * inside <Badge />
       */
      if (typeof child.type !== 'object') {
        return child;
      }

      return cloneElement(child, {
        showTooltip: child.props.showTooltip ?? showTooltips,
      });
    })}
  </div>
);

export const ArchivedBadge = memo(({ className, showTooltip }: BadgeProps) => {
  const id = useId();

  return (
    <>
      <OutlinedBadge className={classnames(style.archivedOutline, className)} data-testid="archived-badge">
        <Sprite className={style.archived} glyph={ArchiveGlyph} />
      </OutlinedBadge>
      <BadgeTooltip
        anchorId={id}
        description={t('archive_icon_description')}
        portalIdPrefix="archived-tooltip"
        showTooltip={showTooltip}
        title={t('archived')}
      />
    </>
  );
});

/**
 * @deprecated
 */
export const AsIsBadge = memo(({ className, showTooltip, theme = 'red' }: AsIsBadgeProps) => {
  const id = useId();

  return (
    <>
      <div className={classnames(style.asIs, style[theme], className)} data-testid="as-is-badge" data-tooltip-id={id}>
        {t('as_is')}
      </div>
      <BadgeTooltip
        anchorId={id}
        description={t('as_is_icon_description')}
        portalIdPrefix="as-is-tooltip"
        showTooltip={showTooltip}
        title={t('as_is')}
      />
    </>
  );
});

export const AssuredBadge = memo(({ className, glyph, glyphClassName, showTooltip }: BadgeWithGlyphOverwriteProps) => {
  const id = useId();

  return (
    <>
      <div
        className={classnames(style.badge, style.verified, className)}
        data-testid="assured-badge"
        data-tooltip-id={id}
      >
        <Sprite className={glyphClassName} glyph={glyph || AssuredGlyph} />
      </div>
      <BadgeTooltip
        anchorId={id}
        description={t('assurance_icon_description')}
        portalIdPrefix="assurance-tooltip"
        showTooltip={showTooltip}
        title={t('assurance')}
      />
    </>
  );
});

export const AutoBidBadge = memo(({ className, text, showTooltip, tooltipDescription }: TextBadgeProps) => {
  const id = useId();

  return (
    <>
      {text ? (
        <OutlinedBadge className={style.autobidOutlined} data-testid="autobid-badge">
          <span className={style.autobidA}>A</span>
          {text}
        </OutlinedBadge>
      ) : (
        <div className={classnames(style.badge, style.autobid)} data-testid="autobid-badge" data-tooltip-id={id}>
          <Sprite className={className} glyph={AutobidGlyph} />
        </div>
      )}
      <BadgeTooltip
        anchorId={id}
        description={tooltipDescription ?? t('autobid_icon_description')}
        portalIdPrefix="autobid-tooltip"
        showTooltip={showTooltip}
        title={t('autobid')}
      />
    </>
  );
});

export const AutogradeScoreBadge = memo(({ className, score, showTooltip }: ScoreBadgeProps) => {
  const id = useId();

  return (
    <>
      <OutlinedBadge
        className={classnames(style.autogradeScore, className)}
        data-testid="autograde-score-badge"
        id={id}
      >
        <Sprite className={style.autogradeGlyph} glyph={AutogradeGlyph} />
        <div>{score !== undefined && score >= 0 ? score : '-'}</div>
      </OutlinedBadge>
      <BadgeTooltip
        anchorId={id}
        description={t('autograde_vehicle_score_description')}
        portalIdPrefix="autograde-vehicle-score-tooltip"
        showTooltip={showTooltip}
        title={t('autograde_vehicle_score')}
      />
    </>
  );
});

export const EBlockScoreBadge = memo(
  ({ className, range = rangeCA, score, showLabel, showTooltip }: EBlockScoreBadgeProps) => {
    const id = useId();
    const { rangeItem } = useScoreRangeValue({ range, score });

    return (
      <>
        <OutlinedBadge
          className={className}
          data-testid="eblock-score-badge"
          id={id}
          style={{ color: rangeItem?.color, borderColor: rangeItem?.color }}
        >
          {showLabel && `${t('score')} `}
          {score || '-'}
        </OutlinedBadge>
        <BadgeTooltip
          anchorId={id}
          description={t('eblock_vehicle_score_description')}
          portalIdPrefix="eblock-vehicle-score-tooltip"
          showTooltip={showTooltip}
          title={t('eblock_vehicle_score')}
        />
      </>
    );
  }
);

export const BuyerShieldBadge = memo(
  ({ className, glyph, glyphClassName, showTooltip }: BadgeWithGlyphOverwriteProps) => {
    const id = useId();

    return (
      <>
        <div
          className={classnames(style.badge, style.buyerShield, className)}
          data-testid="buyer-shield-badge"
          data-tooltip-id={id}
        >
          <Sprite className={glyphClassName} glyph={glyph || BuyerShieldGlyph} />
        </div>
        <BadgeTooltip
          anchorId={id}
          description={t('buyer_shield_icon_description')}
          portalIdPrefix="buyer-shield-us-only-tooltip"
          showTooltip={showTooltip}
          title={t('buyer_shield_us_only')}
        />
      </>
    );
  }
);

export const NoteBadge = memo(({ className, glyph, glyphClassName, showTooltip }: BadgeWithGlyphOverwriteProps) => {
  const id = useId();

  return (
    <>
      <OutlinedBadge className={classnames(style.notesOutline, className)} data-testid="note-badge">
        <Sprite className={classnames(style.note, glyphClassName)} glyph={glyph || FileGlyph} />
      </OutlinedBadge>
      <BadgeTooltip
        anchorId={id}
        description={t('note_icon_description')}
        portalIdPrefix="note-tooltip"
        showTooltip={showTooltip}
        title={t('note')}
      />
    </>
  );
});

export const HoldbackBadge = memo(({ className, showTooltip }: BadgeProps) => (
  <OutlinedBadge className={classnames(style.holdback, className)} data-testid="holdback-badge">
    <Sprite glyph={HoldbackGlyph} />
  </OutlinedBadge>
));

export const ReranBadge = memo(({ className, showTooltip, text, tooltipDescription }: TextBadgeProps) => {
  const id = useId();

  return (
    <>
      <OutlinedBadge
        className={classnames(style.reranOutlined, className)}
        data-testid="reran-badge"
        data-tooltip-id={id}
      >
        <Sprite className={style.reranGlyph} glyph={RefreshGlyph} />
        <span>{text}</span>
      </OutlinedBadge>
      <BadgeTooltip
        anchorId={id}
        description={tooltipDescription}
        portalIdPrefix="reran-tooltip"
        showTooltip={showTooltip}
        title={t('ran_before')}
      />
    </>
  );
});

export const ReserveBadge = memo(({ className, showTooltip, text, tooltipDescription }: TextBadgeProps) => (
  <OutlinedBadge className={classnames(style.reserve, className)} data-testid="reserve-badge">
    R {text}
  </OutlinedBadge>
));

export const ReserveMetBadge = memo(({ className, showTooltip, text, tooltipDescription }: TextBadgeProps) => {
  const id = useId();

  return (
    <>
      <OutlinedBadge className={classnames(style.reserveMet, className)} data-testid="reserve-met-badge">
        RM {text}
      </OutlinedBadge>
      <BadgeTooltip
        anchorId={id}
        description={t('reserve_met_icon_description')}
        portalIdPrefix="reserve-met-tooltip"
        showTooltip={showTooltip}
        title={t('reserve_met')}
      />
    </>
  );
});

export const RunNumberBadge = memo(({ className, showTooltip, text, tooltipDescription }: TextBadgeProps) => {
  return (
    <OutlinedBadge className={classnames(style.runNumber, className)} data-testid="run-number-badge">
      {text}
    </OutlinedBadge>
  );
});

export const UnreservedBadge = memo(({ className, showTooltip }: UnreservedProps) => {
  const id = useId();

  return (
    <>
      <OutlinedBadge
        className={classnames(style.unreserved, className)}
        data-testid="unreserved-badge"
        data-tooltip-id={id}
      >
        {t('unreserved')}
      </OutlinedBadge>
      <BadgeTooltip
        anchorId={id}
        description={t('unreserved_icon_description')}
        portalIdPrefix="unreserved-tooltip"
        showTooltip={showTooltip}
        title={t('unreserved')}
      />
    </>
  );
});

export const VerifiedBadge = memo(({ className, glyph, glyphClassName, showTooltip }: BadgeWithGlyphOverwriteProps) => {
  const id = useId();

  return (
    <>
      <div
        className={classnames(style.badge, style.verified, className)}
        data-testid="verified-badge"
        data-tooltip-id={id}
      >
        <Sprite className={glyphClassName} glyph={glyph || VerifiedGlyph} />
      </div>
      <BadgeTooltip
        anchorId={id}
        description={t('verified_icon_description')}
        portalIdPrefix="verified-tooltip"
        showTooltip={showTooltip}
        title={t('verified')}
      />
    </>
  );
});

export const WatchedBadge = memo(
  ({
    className,
    showTooltip,
    text,
    tooltipDescription,
  }: TextBadgeProps & Pick<BadgeWithGlyphOverwriteProps, 'glyph'>) => {
    const id = useId();

    return (
      <>
        <OutlinedBadge
          className={classnames(style.watchingOutlined, className)}
          data-testid="watched-badge"
          data-tooltip-id={id}
        >
          <Sprite className={style.watchingGlyph} glyph={StarGlyph} />
          <span>{text}</span>
        </OutlinedBadge>
        <BadgeTooltip
          anchorId={id}
          description={tooltipDescription ?? t('watchlist_icon_description')}
          portalIdPrefix="watchlist-tooltip"
          showTooltip={showTooltip}
          title={t('watchlist')}
        />
      </>
    );
  }
);

export const CarfaxBadge = memo(({ className, claimsExist, showTooltip }: CarfaxBadgeProps) => {
  return (
    <>
      <OutlinedBadge
        className={classnames(style.carfax, className, {
          [style.claimsExist]: claimsExist,
        })}
        data-testid="carfax-badge"
      >
        <Sprite className={style.carfax} glyph={CarfaxCaGlyph} />
      </OutlinedBadge>
    </>
  );
});

interface OutlinedBadgeProps extends HTMLAttributes<HTMLElement> {
  children: ReactNode;
  className?: string;
}
export const OutlinedBadge = ({ children, className, ...props }: OutlinedBadgeProps) => {
  return (
    <div className={classnames(style.outlinedBadge, className)} {...props}>
      {children}
    </div>
  );
};
