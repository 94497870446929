import { useCallback } from 'react';

import Checkbox from 'forms/shared/checkbox';
import { BasicFilter, FilterSection, FilterSelection } from 'store/shared/api/graph/interfaces/types';
import { FilterSectionHeading } from 'components/ui/lists/filters/filterSections/filterSectionHelpers';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { getUrlParams, paramsToQueryString } from 'utils/urlUtils';

import style from './checkboxFilter.scss';

// TODO: Lets make these url param names consistent
export const BROWSE_FILTER_SHOW_APPRAISALS_ID = 'filter.show.appraisal';
export const BROWSE_FILTER_SHOW_GROUNDED_ID = 'filter.show.grounded';
export const BROWSE_FILTER_SHOW_RUN_LIST_ID = 'filter.show.runList';
export const BROWSE_FILTER_SHOW_TIMED_OFFER_ID = 'filter.show.timedOffer';

const SHOW_FILTERS = [
  BROWSE_FILTER_SHOW_APPRAISALS_ID,
  BROWSE_FILTER_SHOW_GROUNDED_ID,
  BROWSE_FILTER_SHOW_RUN_LIST_ID,
  BROWSE_FILTER_SHOW_TIMED_OFFER_ID,
];

interface Props extends RouterProps {
  filterSection: FilterSection;
}

const CheckboxFilter = ({ filterSection, location, router }: Props) => {
  /**
   * onToggleCheckbox
   */
  const onToggleCheckbox = useCallback(
    (selection: FilterSelection) => (e) => {
      const params = getUrlParams();

      if (e.target.checked !== false) {
        delete params[selection.id];
      } else {
        params[selection.id] = false;
      }

      const paramKeys = Object.keys(params);
      if (SHOW_FILTERS.every((id) => paramKeys.includes(id))) {
        // All show filters can't be deselected; reset all
        SHOW_FILTERS.forEach((id) => {
          delete params[id];
        });
      }

      delete params.before;
      delete params.after;

      router.push(`${location.pathname}?${paramsToQueryString(params)}`);
    },
    [location, router]
  );

  return (
    <div>
      <FilterSectionHeading>{filterSection.filters?.[0]?.localizedName}</FilterSectionHeading>
      <div>
        {(filterSection.filters?.[0] as BasicFilter)?.selections?.map((selection) => (
          <Checkbox
            key={selection.id}
            className={style.checkbox}
            hasMarginTop={false}
            id={selection.id}
            labelClassName={style.label}
            onChange={onToggleCheckbox(selection)}
            setChecked={selection.selected}
            text={selection?.localizedName}
            theme="check"
          />
        ))}
      </div>
    </div>
  );
};

export default withRouter(CheckboxFilter);
