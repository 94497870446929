import { ReactNode } from 'react';
import classnames from 'classnames';

import ArrowGlyph from 'glyphs/pagination-arrow.svg';

import Button, { ButtonProps } from 'components/ui/shared/button';
import Sprite from 'components/ui/shared/sprite';
import { FacetedPageInfo } from 'store/shared/api/graph/interfaces/types';
import { formatNumber } from 'utils/stringUtils';
import { t } from 'utils/intlUtils';

import style from 'components/ui/lists/pagination/paginationHelpers.scss';

interface PaginationButtonContainerProps {
  children: ReactNode;
}

export const PaginationButtonContainer = ({ children }: PaginationButtonContainerProps) => {
  return <div className={style.container}>{children}</div>;
};

interface Props extends ButtonProps {
  isNextVariant?: boolean;
}

export const PaginationButton = ({ children, isNextVariant = false, ...props }: Props) => {
  return (
    <Button
      {...props}
      className={classnames(style.button, style[isNextVariant ? 'next' : 'previous'])}
      theme="gray-outline"
    >
      <Sprite className={style.arrow} glyph={ArrowGlyph} />
      {children}
    </Button>
  );
};

interface PaginationSummaryProps {
  /** Faceted pagination information. */
  pageInfo: FacetedPageInfo | undefined;
}

export const PaginationSummary = ({ pageInfo }: PaginationSummaryProps) => {
  if (!pageInfo) {
    return null;
  }

  const { startCursor, endCursor, totalEdges } = pageInfo;
  const start = startCursor && formatNumber(startCursor.toString());
  const end = endCursor && formatNumber(endCursor.toString());
  const total = formatNumber(totalEdges.toString());

  return <span>{t('showing_x_to_y_out_of_z_results', [start, end, total])}</span>;
};
