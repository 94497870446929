import { useCallback, useEffect, useMemo, useState } from 'react';

import { AuctionItemConnection } from 'store/shared/api/graph/interfaces/types';
import { Pagination } from 'constants/enums/pagination';
import {
  PaginationButton,
  PaginationButtonContainer,
  PaginationSummary,
} from 'components/ui/lists/pagination/paginationHelpers';
import { parseQueryParams } from 'utils/apiUtils';
import { t } from 'utils/intlUtils';
import { usePrevious } from 'hooks/usePrevious';

interface PaginationState {
  first?: number;
  last?: number;
  before?: string;
  after?: string;
}

// The default pagination state
const initialPaginationState = { first: Pagination.LIST_LENGTH } as PaginationState;

interface Props {
  /**
   * Callback function that invokes the connection query
   *
   * TODO: Provide ability to pass query type for `options`
   **/
  getItems: (options) => any;
  /** True when the slideout is open and the list should be loaded */
  isOpen: boolean;
  /** Callback function when query errors are returned */
  onError?: (error: Error) => void;
  /** Callback function when the slideout is opened */
  onOpen?: () => void /** Callback function when list is opened */;
}

const usePaginatedConnectionQuery = ({ getItems, isOpen, onError, onOpen }: Props) => {
  // TODO: Provide dynamic typing option for queryResponse
  const [queryResponse, setQueryResponse] = useState<AuctionItemConnection | undefined>(undefined);
  const [pagination, setPagination] = useState<PaginationState>(initialPaginationState);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isOpenPrev = usePrevious(isOpen);
  const paginationPrev = usePrevious(pagination);
  const pageInfo = queryResponse?.pageInfo;

  /**
   * Queries for the list items
   */
  const onFetchItems = useCallback(() => {
    setIsLoading(true);
    return getItems(parseQueryParams(pagination as any))
      ?.then((response) => {
        setQueryResponse(response?.data?.data?.auctionItemConnection);
      })
      ?.catch((error: Error) => onError?.(error))
      ?.finally(() => setIsLoading(false));
  }, [getItems, onError, pagination]);

  /**
   * onPaginate
   */
  const onPaginate = useCallback(
    (isNext: boolean) => () => {
      const paginationNext = isNext ? { after: pageInfo?.endCursor } : { before: pageInfo?.startCursor };
      setPagination(paginationNext as PaginationState);
    },
    [pageInfo]
  );

  /**
   * onPageInfoUpdate
   */
  useEffect(() => {
    if (pageInfo && paginationPrev && pagination !== paginationPrev) {
      onFetchItems();
    }
  }, [onFetchItems, pageInfo, pagination, paginationPrev]);

  /**
   * onOpenSlideOut
   */
  useEffect(() => {
    if (!isOpenPrev && isOpen) {
      onOpen?.();
      onFetchItems();
    } else if (isOpenPrev && !isOpen) {
      setQueryResponse(undefined);
      setPagination({ first: Pagination.LIST_LENGTH });
    }
  }, [isOpen, isOpenPrev, onFetchItems, onOpen]);

  // The pagination components to render
  const renderPagination = useMemo(() => {
    return (
      <PaginationButtonContainer>
        <PaginationButton disabled={!pageInfo?.hasPreviousPage} onClick={onPaginate(false)}>
          {t('prev')}
        </PaginationButton>
        <PaginationSummary pageInfo={pageInfo} />
        <PaginationButton disabled={!pageInfo?.hasNextPage} isNextVariant onClick={onPaginate(true)}>
          {t('next')}
        </PaginationButton>
      </PaginationButtonContainer>
    );
  }, [onPaginate, pageInfo]);

  return {
    isLoading,
    paginationUI: renderPagination,
    queryResponse,
  };
};

export default usePaginatedConnectionQuery;
