export const auctionTimeSlotLaneSchema = `
  id
  active
  assignedItemsCount
  auctionLaneId
  auctionTimeSlotId
  bidIncrements {
    defaultSelected
    increment
  }
  itemsRanCount
  itemsRemainingCount
  liveItem {
    id
    status
  }
  name
  status
  type
`;
