export const filterSections = `
filterSections {
  sectionName
  filters {
    ...on BasicFilter {
      id
      localizedName
      multiselect
      selections {
        id
        localizedName
        selected
      }
    }
  }
}
`;
