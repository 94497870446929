import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AuctionItem from 'constants/auctionItem';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import InputText from 'forms/shared/inputText';
import SelectCompany, { SelectCompanyOption, SelectCompanyProps } from 'forms/shared/selectCompany';
import SelectUser, { SelectUserProps } from 'forms/shared/selectUser';
import { AppDispatch, AppState } from 'store/configureStore';
import { AuctionRelationshipPermission } from 'constants/enums/auctionRelationshipPermission';
import { CompanyAuctionRelationshipStatus } from 'store/shared/api/graph/interfaces/types';
import { ErrorMessages } from 'constants/errors';
import { FormErrors } from 'layouts/formLayouts/formDialogLayouts';
import {
  MakeOfferVariant,
  getUserTrackingAction,
} from 'components/sections/auctionItem/operations/makeOffer/makeOffer';
import { SelectOption } from 'utils/interfaces/SelectOption';
import { UserProps } from 'store/user/userModels';
import { getBiddingSelectionByAuctionId, getEnabledCompanyRelationships, isAuctionStaff } from 'utils/userUtils';
import { onApiError } from 'utils/apiUtils';
import { processMakeOffer } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { t } from 'utils/intlUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';

import style from './dialog.scss';

interface Props {
  /** The auction item details. */
  auctionItem: AuctionItem | undefined;
  /** Function used to close dialog */
  onClose: () => void;
  /** The variant options for the Make Offer button */
  variant: MakeOfferVariant;
}

const Dialog = ({ auctionItem, onClose, variant }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector<AppState, UserProps>((state) => state.app.user);
  const [offeredAmount, setOfferedAmount] = useState<number>(0);
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [offeringCompany, setOfferingCompany] = useState<SelectCompanyOption | undefined>(() => {
    const biddingAsCompany = getBiddingSelectionByAuctionId(auctionItem?.auction.id)?.company;
    return biddingAsCompany ? { label: biddingAsCompany?.name, value: biddingAsCompany?.id } : undefined;
  });
  const [offeringUser, setOfferingUser] = useState<SelectOption | undefined>(() => {
    const biddingAsUser = getBiddingSelectionByAuctionId(auctionItem?.auction.id)?.user;
    return biddingAsUser ? { label: biddingAsUser?.name, value: biddingAsUser?.id } : undefined;
  });
  const [hasMultipleCompanyRelationships, setHasMultipleCompanyRelationships] = useState<boolean>(false);

  const userTrackingAction = useMemo(() => getUserTrackingAction(variant), [variant]);
  const isStaffUser = useMemo(() => isAuctionStaff(user, auctionItem?.auction.id), [auctionItem?.auction.id, user]);

  // When auction item format is `TIMED_OFFER` nextBidAmount represents the minimum offer
  const minimumOffer = auctionItem?.nextBidAmount;
  const minimumOfferInputPlaceholder = useMemo(
    () => t('x_or_more', [minimumOffer?.formattedAmountRounded]),
    [minimumOffer]
  );

  const isActionable = useMemo(() => {
    if (!offeringCompany || offeredAmount < Number(minimumOffer?.amount)) {
      return false;
    }

    if (isStaffUser && !offeringUser) {
      return false;
    }

    return true;
  }, [isStaffUser, minimumOffer, offeredAmount, offeringCompany, offeringUser]);

  /**
   * If only one company is allowed to make an offer, then set the default offering company.
   * The company selection will be hidden from the user (non staff).
   */
  useEffect(() => {
    const userCompanyRelationships = getEnabledCompanyRelationships(user);

    if (userCompanyRelationships?.length === 1) {
      setHasMultipleCompanyRelationships(false);
      setOfferingCompany({
        label: userCompanyRelationships[0].company.name,
        value: userCompanyRelationships[0].company.id,
      });
    } else {
      setHasMultipleCompanyRelationships(true);
    }
  }, [user]);

  /**
   * Handles make offer confirmation button
   */
  const handleOnConfirm = useCallback(
    (shouldSubmit: boolean) => {
      if (shouldSubmit && auctionItem?.id) {
        setIsSubmitting(true);

        processMakeOffer(
          {
            input: {
              amount: offeredAmount,
              auctionItemId: auctionItem.id,
              companyId: offeringCompany?.value,
              userId: offeringUser?.value,
            },
          },
          dispatch
        )
          .then(() => {
            trackUserActionWithAuctionItemAttributes(userTrackingAction.confirm, auctionItem);
            setErrorMessages([]);
            onClose();
          })
          .catch((error) => onApiError(error, setErrorMessages))
          .finally(() => setIsSubmitting(false));
      } else {
        setErrorMessages([]);
        onClose();
      }
    },
    [
      auctionItem,
      dispatch,
      offeredAmount,
      offeringCompany?.value,
      offeringUser?.value,
      onClose,
      userTrackingAction.confirm,
    ]
  );

  /**
   * Handles company change events
   */
  const handleCompanyOnChange = useCallback<NonNullable<SelectCompanyProps['onChange']>>((option) => {
    const company = Array.isArray(option) ? option[0] : option;
    setOfferingCompany(company);
    setOfferingUser(undefined);
  }, []);

  /**
   * Handles user change events
   */
  const handleUserOnChange = useCallback<NonNullable<SelectUserProps['onChange']>>((option) => {
    if (option) {
      setOfferingUser(option);
    }
  }, []);

  /**
   * Handles offer amount change
   */
  const handleOfferAmountOnChange = useCallback((value: string) => {
    setOfferedAmount(Number(value));
  }, []);

  return (
    <ConfirmDialog
      actionable={isActionable}
      actionLabel={t('confirm_offer')}
      actionProgress={isSubmitting}
      isOpen
      isPreventingDefaultOnClick
      onClose={onClose}
      onConfirm={handleOnConfirm}
      theme="green"
      title={t('make_offer')}
    >
      <FormErrors errorMessages={errorMessages} isSmallDialog />
      <p className={style.instructions}>{t('set_make_offer_dialog_message')}</p>
      {(isStaffUser || hasMultipleCompanyRelationships) && (
        <SelectCompany
          connectionVariables={{
            auctionId: auctionItem?.auction?.id,
            auctionRelationshipPermission: [AuctionRelationshipPermission.CAN_BUY],
            auctionRelationshipStatus: CompanyAuctionRelationshipStatus.ENABLED,
          }}
          onChange={handleCompanyOnChange}
          placeholder={t('select_company')}
          value={offeringCompany}
        />
      )}
      {isStaffUser && (
        <SelectUser
          companyId={offeringCompany?.value}
          isDisabled={!offeringCompany?.value}
          onChange={handleUserOnChange}
          placeholder={t('select_user')}
          value={offeringUser}
        />
      )}
      <InputText
        className={style.amount}
        onChange={handleOfferAmountOnChange}
        placeholder={minimumOfferInputPlaceholder}
        type="currency"
      />
    </ConfirmDialog>
  );
};

export default Dialog;
