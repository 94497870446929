import { useMemo } from 'react';

import carfaxCaGlyph from 'glyphs/carfax-ca.svg';

import AuctionItem from 'constants/auctionItem';
import Dialog from 'components/ui/shared/dialogs/dialog';
import Sprite from 'components/ui/shared/sprite';
import Tag from 'components/ui/tag/tag';
import { DetailsSection } from 'layouts/list/listLayout';
import { DialogHeader } from 'layouts/dialogLayouts/dialogLayouts';
import { t } from 'utils/intlUtils';

import style from './detailsDialog.scss';

interface Props {
  /** The comparable auction item to be shown on the details' dialog. */
  auctionItem: AuctionItem;

  /** Whether the details' dialog is open or not. */
  isOpen?: boolean;

  /** Callback to handle when the details' dialog is closed. */
  onClose?: () => void;
}

const DetailsDialog = ({ auctionItem, isOpen, onClose }: Props) => {
  const { inventoryItem } = auctionItem;
  const conditionalReport = inventoryItem?.conditionReport;
  const options = inventoryItem?.options;
  const declarations = conditionalReport?.declarations;
  const hasDeclarations = !!declarations?.length;
  const hasSelectedOptions = !!options?.length;

  const carfaxSection = useMemo(() => {
    const carfaxClaimAmount = conditionalReport?.carfaxCanadaClaimAmount;
    const carfaxClaimAmountFormatted = carfaxClaimAmount?.formattedAmountRounded;
    const carfaxUrl = conditionalReport?.carfaxCanadaReportUrl;
    const countryCode = inventoryItem?.location?.countryCode;
    const isCA = countryCode === 'CA';

    if (isCA && carfaxClaimAmountFormatted) {
      return (
        <DetailsSection title={t('carfax')}>
          <a data-testid="carfaxLink" href={carfaxUrl || undefined} rel="noopener noreferrer" target="_blank">
            <div className={style.carfaxContainer}>
              <Sprite className={style.carfaxImage} glyph={carfaxCaGlyph} />
              <span className={style.carfaxClaimAmount}>{carfaxClaimAmountFormatted}</span>
            </div>
          </a>
        </DetailsSection>
      );
    }
    return undefined;
  }, [
    conditionalReport?.carfaxCanadaClaimAmount,
    conditionalReport?.carfaxCanadaReportUrl,
    inventoryItem?.location?.countryCode,
  ]);

  return (
    <Dialog
      className={style.dialog}
      contentClassName={style.container}
      contentInnerClassName={style.containerInner}
      isOpen={!!isOpen}
      requestClose={onClose}
    >
      <DialogHeader onClose={onClose} title={t('vehicle_details')} />
      <div className={style.content}>
        <DetailsSection
          innerContainerClassName={style.sectionInner}
          isEmpty={!hasSelectedOptions}
          title={t(hasSelectedOptions ? 'options' : 'no_options')}
        >
          {options?.filter(Boolean).map((item) => (
            <Tag key={item.name} theme="green">
              {item.name}
            </Tag>
          ))}
        </DetailsSection>
        <DetailsSection
          innerContainerClassName={style.sectionInner}
          isEmpty={!hasDeclarations}
          title={t(hasDeclarations ? 'declarations' : 'no_declarations')}
        >
          {hasDeclarations && (
            <ul className={style.tagList}>
              {hasDeclarations &&
                declarations.filter(Boolean).map((item) => (
                  <Tag key={item.name} theme="red">
                    {item.name}
                  </Tag>
                ))}
            </ul>
          )}
        </DetailsSection>
        {carfaxSection}
      </div>
    </Dialog>
  );
};

export default DetailsDialog;
