import ApiRequest from 'store/shared/api/apiRequest';
import {
  MyBlockSellerResponse,
  QueryauctionItemConnectionArgs,
  QuerymyBlockBuyerStatisticsArgs,
  QuerymyBlockSellerStatisticsArgs,
} from 'store/shared/api/graph/interfaces/types';
import { auctionItemConnection } from 'store/shared/api/graph/queries/auctionItemConnection';
import { getFacetGroupsByUrl, pageInfo } from 'store/shared/api/graph/schema/connection';
import { inventoryItemValues } from 'store/shared/api/graph/schema/inventoryItem';
import { listItemPhotoFragment } from 'store/shared/api/graph/fragments/photos';
import { parseQueryParams } from 'utils/apiUtils';

export const getMyBlockList = (options: QueryauctionItemConnectionArgs = {}) =>
  auctionItemConnection(
    parseQueryParams(options),
    `
      edges {
        node {
          id
          asIs
          auction {
            id
            title
            type
          }
          auctionTimeSlot {
            startTime
            status
          }
          bidTimeline {
            count
            winning
            outbid
            list {
              company {
                id
                name
              }
              amount {
                amount
                formattedAmount
              }
              created
            }
          }
          colorScheme
          format
          ifBidClaimedByUserId
          ifBidClaimedByUserName
          ifBidTimeline {
            ending
            amountApart {
              formattedAmount
            }
            awaitingMyResponse
            list {
              amount {
                formattedAmount
              }
              company {
                id
              }
            }
          }
          ifBidTimeline {
            count
            ending
            awaitingMyResponse
            inIfBidWithCompany {
              id
              name
            }
            amountApart {
              formattedAmountRounded
            }
            list {
              id
              type
              company {
                id
                name
              }
              amount {
                formattedAmount
                formattedAmountRounded
              }
              comment
              created
              createdBy {
                id
                cellPhone
                email
                firstName
                lastName
              }
            }
          }
          inventoryItem {
            id
            asIsCause
            captureType
            company {
              id
              name
            }
            location {
              regionCode
            }
            make
            mileage { formattedAmount }
            model
            photos { ...listItemPhotoFragment }
            trim
            vehicleBuyerShield
            vin
            year
            ${inventoryItemValues}
          }
          isAssured
          isMyItem
          net {
            amount
            formattedAmount
          }
          ranOn
          reserve {
            amount
            formattedAmount
          }
          reserveType
          displayRunNumber
          startingBid {
            amount
          }
          status
          timerText
          topOffer {
            amount {
              amount
              formattedAmount
            }
          }
          transactionDetails {
            buyBillOfSale
            buyFeesTotal { 
              amount
              formattedAmount
            }
            buyTotal { 
              amount
              formattedAmount
            }
            sellBillOfSale
            sellFeeCredit {
              amount
              formattedAmount
            }
            sellFeesTotal {
              amount
              formattedAmount
            }
            sellTotal {
              amount
              formattedAmount
            }
          }
          transactionType
          watchers {
            isWatched
          }
          workflowProcesses {
            created
            id
            name
            status
            statusColorScheme
            updated
          }
        }
      }
      ${getFacetGroupsByUrl()}
      ${pageInfo}
      sort {
        name
        ascending
        selected
      }
    `,
    [listItemPhotoFragment]
  );

export const getMyBlockSellerStats = ({ input }: QuerymyBlockSellerStatisticsArgs) =>
  ApiRequest<{ myBlockSellerStatistics: MyBlockSellerResponse }>({
    data: {
      operationName: 'getMyBlockSellerStats',
      query: `
        query ($input: MyBlockStatisticsInput!) {
          myBlockSellerStatistics(
            input: $input
          ) {
            inIfBid
            missingCost
            ran
            sold
            totalSoldAmount {
              formattedAmountRounded
            }
            totalSoldNet {
              formattedAmountRounded
            }
          }
        }
      `,
      variables: {
        input,
      },
    },
  });

export const getMyBlockBuyerStats = ({ input }: QuerymyBlockBuyerStatisticsArgs) =>
  ApiRequest({
    data: {
      operationName: 'getMyBlockBuyerStats',
      query: `
        query ($input: MyBlockStatisticsInput!) {
          myBlockBuyerStatistics(
            input: $input
          ) {
            hasBid
            inIfBid
            purchased
            totalSpentAmount {
              formattedAmountRounded
            }
          }
        }
      `,
      variables: {
        input,
      },
    },
  });
