import BaseClass from 'components/ui/shared/base';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import OperationButton from '../operationButton';
import { IncompleteFieldMessage } from 'store/shared/api/graph/interfaces/types';
import { t, tPlural } from 'utils/intlUtils';

import style from './missingItems.scss';

interface Props {
  /** The number of missing items. */
  count: number;
  /** Collection of incomplete field messages. */
  incompleteMessages: IncompleteFieldMessage[];
}

interface State {
  /** True when the dialog is open. */
  isOpen: boolean;
}

class MissingItems extends BaseClass<Props, State> {
  state = {
    isOpen: false,
  };

  render() {
    const { isOpen } = this.state;
    const { count, incompleteMessages } = this.props;
    const missingItemsLabel = tPlural('x_items_missing', count, [count]);

    return [
      <OperationButton
        key="button"
        className={style.buttonLabel}
        dataTestId="missingItems-submitToAuctionButton"
        onClick={() => this.setState({ isOpen: true })}
        theme="gray-outline"
      >
        {t('submit_to_auction')}
        <br />
        <small>{missingItemsLabel}</small>
      </OperationButton>,

      <ConfirmDialog
        key="dialog"
        actionLabel={t('ok')}
        isOpen={isOpen}
        onConfirm={() => this.setState({ isOpen: false })}
        theme="red"
        title={t('incomplete_fields')}
      >
        <ul className={style.dialogContent} data-testid="incomplete-field-messages">
          <div className={style.completeMessage}>{t('message_complete_to_continue')}</div>
          {incompleteMessages.map((message, index) => (
            <li key={`${message.field}-${index}`}>{message.message}</li>
          ))}
        </ul>
      </ConfirmDialog>,
    ];
  }
}

export default MissingItems;
