import { useCallback, useState } from 'react';

import starGlyph from 'glyphs/star.svg';

import Button from 'components/ui/shared/button';
import Sprite from 'components/ui/shared/sprite';
import WatchlistSlideOut from 'components/sections/watchlist/watchlistSlideOut';

import style from './watchlistButton.scss';

const WatchlistButton = () => {
  const [isSlideOutOpen, setIsSlideOutOpen] = useState<boolean>(false);

  /**
   * Open or close the slideOut
   */
  const onOpenCloseSlideOut = useCallback((isOpen: boolean) => {
    setIsSlideOutOpen(isOpen);
  }, []);

  return (
    <>
      <Button
        className={style.button}
        dataTestId="watch-list-button"
        onClick={() => onOpenCloseSlideOut(true)}
        theme="none"
      >
        <Sprite className={style.sprite} glyph={starGlyph} />
      </Button>
      <WatchlistSlideOut isOpen={isSlideOutOpen} onClose={() => onOpenCloseSlideOut(false)} />
    </>
  );
};

export default WatchlistButton;
