import ApiRequest from 'store/shared/api/apiRequest';
import ReservedLaneSlot from 'constants/reservedLaneSlot';
import {
  AuctionItemConnection,
  AuctionTimeSlot,
  QueryauctionItemConnectionArgs,
  QueryauctionTimeSlotArgs,
} from 'store/shared/api/graph/interfaces/types';
import { getFacetGroupsByUrl, pageInfo } from 'store/shared/api/graph/schema/connection';
import { listItemPhotoFragment } from 'store/shared/api/graph/fragments/photos';

export const getTimeSlot = (options: QueryauctionTimeSlotArgs) =>
  ApiRequest<{ auctionTimeSlot: AuctionTimeSlot }>({
    data: {
      operationName: 'getTimeSlot',
      query: `
        query($auctionTimeSlotId: String!) {
          auctionTimeSlot(auctionTimeSlotId: $auctionTimeSlotId) {
            id
            auction {
              title
            }
            auctionTimeSlotLanes(active: true) {
              id
              name
            }
            startTime
          }
        }
    `,
      variables: options,
    },
  });

export const getLane = (options: QueryauctionItemConnectionArgs) =>
  ApiRequest<{
    auctionItemConnection: AuctionItemConnection;
    reservedLaneSlots: ReservedLaneSlot[];
  }>({
    data: {
      operationName: 'getLane',
      query: `
        query(
          $assigned: Boolean,
          $after: String,
          $auctionItemEndTime: [String],
          $auctionLaneType: AuctionLaneType,
          $auctionLocationName: [String],
          $auctionStartTime: [String],
          $auctionTimeSlotIds: [String],
          $auctionTimeSlotLane: [String],
          $auctionTimeSlotLaneIds: [String],
          $auctionTitle: [String],
          $before: String,
          $bodyType: [String],
          $consigner: [String],
          $driveTrain: [String],
          $exteriorColor: [String],
          $filterBy: String,
          $first: Int,
          $formats: [AuctionItemFormat],
          $fuelType: [String],
          $keyword: String,
          $last: Int,
          $makes: [String],
          $mileageGTE: Int,
          $mileageLTE: Int,
          $models: [String],
          $option: [String],
          $region: [String],
          $reservePriceGTE: BigDecimal,
          $reservePriceLTE: BigDecimal,
          $saleLights: [SaleLight],
          $salesRepId: String,
          $sort: [Sort],
          $startingBidGTE: BigDecimal,
          $startingBidLTE: BigDecimal,
          $status: [AuctionItemStatus],
          $subModels: [String],
          $transmission: [String],
          $vehicleScoreGTE: BigDecimal,
          $vehicleScoreLTE: BigDecimal,
          $yearGTE: Int,
          $yearLTE: Int
        ) {
          auctionItemConnection(
            assigned: $assigned,
            after: $after,
            auctionItemEndTime: $auctionItemEndTime,
            auctionLaneType: $auctionLaneType,
            auctionLocationName: $auctionLocationName,
            auctionStartTime: $auctionStartTime,
            auctionTimeSlotIds: $auctionTimeSlotIds,
            auctionTimeSlotLane: $auctionTimeSlotLane,
            auctionTimeSlotLaneIds: $auctionTimeSlotLaneIds,
            auctionTitle: $auctionTitle,
            before: $before,
            bodyType: $bodyType,
            consigner: $consigner,
            driveTrain: $driveTrain,
            exteriorColor: $exteriorColor,
            filterBy: $filterBy,
            first: $first,
            formats: $formats,
            fuelType: $fuelType,
            keyword: $keyword,
            last: $last,
            makes: $makes,
            mileageGTE: $mileageGTE,
            mileageLTE: $mileageLTE,
            models: $models,
            option: $option,
            region: $region,
            reservePriceGTE: $reservePriceGTE,
            reservePriceLTE: $reservePriceLTE,
            saleLights: $saleLights,
            salesRepId: $salesRepId,
            sort: $sort,
            startingBidGTE: $startingBidGTE,
            startingBidLTE: $startingBidLTE,
            status: $status,
            subModels: $subModels,
            transmission: $transmission,
            vehicleScoreGTE: $vehicleScoreGTE,
            vehicleScoreLTE: $vehicleScoreLTE,
            yearGTE: $yearGTE,
            yearLTE: $yearLTE,
          ) {
            edges {
              node {
                __typename
                asIs
                auction {
                  settings {
                    saleLightsOverride
                  }
                }
                autoBids {
                  count
                }
                displayRunNumber
                history {
                  count
                }
                id
                inventoryItem {
                  autoGradeScore
                  captureType
                  company {
                    name
                  }
                  conditionReport {
                    carfaxCanadaClaimAmount {
                      amount
                    }
                    declarations {
                      id
                    }
                    overallConditionRating
                  }
                  extensiveVehicleConditionScore
                  location {
                    countryCode
                    regionCode
                  }
                  make
                  mileage {
                    formattedAmount
                  }
                  model
                  notes {
                    id
                  }
                  photos (first: 1) { ...listItemPhotoFragment }
                  trim
                  values {
                    id
                    type {
                      id
                    }
                    value {
                       formattedAmountRounded
                    }
                  }
                  year
                }
                isAssured
                reserve {
                  formattedAmountRounded
                }
                reserveMet
                reserveType
                runNumber
                saleLights
                startingBid {
                  formattedAmountRounded
                }
                watchers {
                  count
                  isWatched
                }
              }
            }
            ${getFacetGroupsByUrl()}
            ${pageInfo}
          }
          ${
            options?.auctionTimeSlotLaneIds?.length === 1
              ? `
              reservedLaneSlots(auctionTimeSlotLaneId: "${options?.auctionTimeSlotLaneIds?.[0]}") {
                __typename
                displayRunNumber
                laneName
                runNumber
                seller {
                  name
                }
                type
                vehicle: inventoryItem {
                  id
                  location {
                    regionCode
                  }
                  make
                  mileage {
                    formattedAmount
                  }
                  model
                  photos (first: 1) { ...listItemPhotoFragment }
                  trim
                  values {
                      id
                      type {
                        id
                      }
                      value {
                         formattedAmountRounded
                      }
                  }
                  year
                }
              }`
              : ''
          }
        }
        ${listItemPhotoFragment}
      `,
      variables: {
        ...options,
        mileageGTE: options?.mileageGTE ? Number(options?.mileageGTE) : null,
        mileageLTE: options?.mileageLTE ? Number(options?.mileageLTE) : null,
        yearGTE: options?.yearGTE ? Number(options?.yearGTE) : null,
        yearLTE: options?.yearLTE ? Number(options?.yearLTE) : null,
      },
    },
  });
