export const auctionItemConnectionSchema = `
  edges {
    node {
      id
      status
      format
      isAssured
      isMyItem
      colorScheme
      reserveMet
      bidTimeline {
        count
        list {
          createdBy {
            id
            firstName
            lastName
          }
          amount {
            amount
            formattedAmountRounded
          }
          created
        }
      }
      archived
      watchers {
        count
        isWatched
      }
      autoBids {
        count
        myAutoBid {
          maxBid {
            formattedAmountRounded
          }
        }
      }
      listPrice {
        amount
        formattedAmountRounded
      }
      auctionTimeSlot {
        startTime
      }
      holdbackActive
      history {
        count
      }
      sellerTier
      inventoryItem {
        captureType
        id
        year
        make
        model
        trim
        mileage {
          formattedAmount
        }
        notes {
          id
        }
        transmission
        displacement
        cylinders
        fuelType
        photos (first: 1) {
          ...thumbnailListFrag
        }
        company {
          id
          name
        }
        location {
          name
          city
          regionCode
          distanceAway
        }
        vehicleBuyerShield
      }
    }
  }
  pageInfo {
    totalEdges
  }
`;

export const inventoryItemConnectionSchema = `
  edges {
    node {
      id
      location {
        name
        city
        regionCode
        distanceAway
      }
      stockNumber
      description
      company {
        id
        name
      }
      complete
      completionPercentage
      incompleteFieldMessages {
        field
        message
      }
      archived
      notes {
        id
      }
      captureType
      year
      make
      model
      trim
      mileage {
        formattedAmount
      }
      transmission
      displacement
      cylinders
      fuelType
      photos (first: 1) {
        ...thumbnailListFrag
      }
      vin
      conditionReport {
        declarations: declarations(selected: true) {
          name
        }
      }
    }
  }
  pageInfo {
    totalEdges
  }
`;
