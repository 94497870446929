import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import { Company, FacetedPageInfo, FacetGroup } from 'store/shared/api/graph/interfaces/types';

interface CompaniesModel {
  /** Companies facet groups. */
  facetGroups: FacetGroup[];
  /** Faceted page info. */
  pageInfo: FacetedPageInfo;
  /** Companies list. */
  resultList: Company[];
}

export type CompaniesProps = ServerRecordOf<CompaniesModel>;

export const InitialState = ServerRecord<CompaniesModel>({
  resultList: [],
  facetGroups: [],
  pageInfo: null,
});
