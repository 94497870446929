import moment, { Moment } from 'moment';
import { connect, ConnectedProps } from 'react-redux';

import BaseClass from 'components/ui/shared/base';
import Button from 'components/ui/shared/button';
import FormDialog from 'components/ui/shared/dialogs/formDialog';
import InputGroup from 'forms/shared/inputGroup';
import InventoryItem from 'constants/inventoryItem';
import RowButtonWithArrow from 'components/ui/shared/buttons/rowButtonWithArrow';
import { AppDispatch } from 'store/configureStore';
import { ClearableFieldInput } from 'constants/enums/forms';
import { ErrorMessages } from 'constants/errors';
import { FormDialogBody, FormDialogFooter, FormErrors, FormSection } from 'layouts/formLayouts/formDialogLayouts';
import {
  InventoryItemValue,
  InventoryItemValueType,
  MutationinventoryItemFeeUpdate2Args,
  MutationinventoryItemValueCreateArgs,
  MutationinventoryItemValueUpdateArgs,
} from 'store/shared/api/graph/interfaces/types';
import { Spinner } from 'components/ui/loading/loading';
import { UserAction } from 'logging/analytics/events/userActions';
import { getFormattedDate } from 'utils/dateUtils';
import {
  processSaveInventoryItemFees,
  processSaveInventoryItemValues,
} from 'store/inventoryItemDetails/inventoryItemDetailsActions';
import { getUrlParams } from 'utils/urlUtils';
import { myBlockUpdateAuctionItemValues } from 'store/myBlock/list/myBlockActions';
import { t } from 'utils/intlUtils';

import style from './addModifyFeeValue.scss';

export enum FieldTypes {
  FEE = 'fee',
  VALUE = 'value',
}

export enum TrackingAction {
  ADD = 'ADD',
  EDIT = 'EDIT',
}

export enum TrackingClickType {
  CLICK = 'CLICK',
  SAVE_CLICK = 'SAVE_CLICK',
}

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Dispatch function to update inventory item fees. */
  saveFees: (options: MutationinventoryItemFeeUpdate2Args) => processSaveInventoryItemFees(options, dispatch),
  /** Dispatch function to create/update inventory item values. */
  saveValues: (options: MutationinventoryItemValueCreateArgs | MutationinventoryItemValueUpdateArgs) =>
    processSaveInventoryItemValues(options, dispatch),
  /** Dispatch function used to update my block items in state. */
  updateMyBlockListItemValues: (options: { auctionItemId?: string; values?: InventoryItemValue[] }) =>
    dispatch(myBlockUpdateAuctionItemValues(options)),
});

const connector = connect(undefined, dispatchConnect);

export interface FeeField {
  /** The id of the field. */
  id: string;
  /** The name of the field. */
  name: string;
}

interface Props extends ConnectedProps<typeof connector> {
  /** The list of fields to display in dialog. */
  availableFields: FeeField[] | InventoryItemValueType[] | undefined;
  /** Denotes whether this component is used for inventory item fees or values. */
  fieldType: FieldTypes;
  /** The inventory item details. */
  inventoryItem: InventoryItem;
  /** True when editing inventory item. */
  isInventoryItem?: boolean;
  /** True when dialog is open. */
  isOpen: boolean;
  /** Function invoked on dialog close. */
  onClose?: () => void;
  /** The selected field. */
  selectedField?: Partial<InventoryItemValue> & { name?: string };
  /** The title of the dialog. */
  title?: string;
  /** Callback function to track user actions. */
  trackUserAction?: (action: UserAction) => void;
}

interface State {
  /** The inputted fee or value amount. */
  amount: number;
  /** Validation Errors. */
  errorMessages: ErrorMessages;
  /** The selected expiry date. */
  expiryDate: Moment | null;
  /** True when the expiry date picker has focus. */
  expiryDateFocused: false;
  /** The current value of the notes input. */
  note: string | null;
  /** True when the form is submitting. */
  isSubmitting: boolean;
  /** The selected field. */
  selectedField: FeeField | InventoryItemValueType | undefined;
}

/**
 * Get the user action for the given field type
 */
export const getUserAction = (
  fieldType: FieldTypes,
  trackingAction: TrackingAction,
  trackingClickType: TrackingClickType,
  fieldId: string | undefined
): UserAction | undefined => {
  if (!fieldId) {
    return undefined;
  }

  const actionFieldType = fieldType === FieldTypes.FEE ? 'FEES' : 'VALUES';
  return UserAction[`VDP_${actionFieldType}_${trackingAction}_${fieldId.toUpperCase()}_${trackingClickType}`];
};

class AddModifyFeeValue extends BaseClass<Props, State> {
  private amountRef: HTMLInputElement;
  private notesRef: HTMLTextAreaElement;

  static defaultProps = {
    isInventoryItem: true,
    onClose: () => {},
  };

  constructor(props) {
    super(props);
    const { fieldType, inventoryItem, selectedField } = props;

    this.state = {
      amount:
        fieldType === FieldTypes.FEE
          ? inventoryItem?.inventoryItemFee?.[selectedField?.id]?.amount
          : inventoryItem?.values?.find((value) => value?.id === selectedField?.id)?.value?.amount,
      errorMessages: [],
      expiryDate: selectedField?.expiryDate ? moment(selectedField?.expiryDate) : null,
      expiryDateFocused: false,
      note:
        fieldType === FieldTypes.FEE
          ? inventoryItem?.inventoryItemFee?.[`${selectedField?.id}Text`]
          : selectedField?.notes,
      isSubmitting: false,
      selectedField: undefined,
    };
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = prevProps;
    const { isOpen: isOpenNext, fieldType, inventoryItem, selectedField } = this.props;

    if (!isOpen && isOpenNext) {
      this.setState({
        amount:
          fieldType === FieldTypes.FEE
            ? selectedField?.id && inventoryItem?.inventoryItemFee?.[selectedField?.id]?.amount
            : inventoryItem?.values?.find((value) => value?.id === selectedField?.id)?.value?.amount,
        expiryDate: selectedField?.expiryDate ? moment(selectedField?.expiryDate) : null,
        note:
          fieldType === FieldTypes.FEE
            ? inventoryItem?.inventoryItemFee?.[`${selectedField?.id}Text`]
            : selectedField?.notes,
      });
    }
  }

  onClose = () => {
    this.setState({
      errorMessages: [],
      expiryDate: null,
      expiryDateFocused: false,
      isSubmitting: false,
      selectedField: undefined,
    });
    this.props.onClose?.();
  };

  onSubmit = () => {
    const {
      fieldType,
      inventoryItem,
      isInventoryItem,
      saveFees,
      saveValues,
      selectedField,
      trackUserAction,
      updateMyBlockListItemValues,
    } = this.props;
    const { expiryDate, selectedField: selectedFieldState } = this.state;
    const isAdding = !selectedField;
    const fieldId = selectedField?.id || selectedFieldState?.id;
    if (!fieldId) {
      return;
    }

    if ((isAdding && !this.amountRef?.value) || Number(this.amountRef?.value) < 0) {
      this.setState({ errorMessages: [{ name: 'amount', message: t('please_enter_a_valid_amount') }] });
      return;
    }

    const saveFunc = fieldType === FieldTypes.FEE ? saveFees : saveValues;
    const userAction = getUserAction(
      fieldType,
      isAdding ? TrackingAction.ADD : TrackingAction.EDIT,
      TrackingClickType.SAVE_CLICK,
      selectedField?.type?.id || selectedFieldState?.id
    );
    userAction && trackUserAction?.(userAction);

    const options = {
      inventoryItemId: inventoryItem?.id,
      isAdding,
      isInventoryItem: !!isInventoryItem,

      // Fees specific
      [fieldId]: Number(this.amountRef?.value?.trim()) || ClearableFieldInput.NUMBER,
      [`${fieldId}Text`]: this.notesRef?.value?.trim(),

      // Values specific
      valueId: fieldId,
      valueInput: {
        ...(isAdding && { valueType: fieldId }),
        amount: Number(this.amountRef?.value?.trim()) || ClearableFieldInput.NUMBER,
        notes: this.notesRef?.value?.trim(),
        expiryDate: getFormattedDate(expiryDate) || (isAdding ? undefined : ClearableFieldInput.DATE),
      },
    };

    this.setState({ isSubmitting: true });
    saveFunc(options)
      .then((response) => {
        this.onClose();

        if (window?.location?.pathname?.includes('/my-block')) {
          // Update My Block list item if applicable
          updateMyBlockListItemValues({
            auctionItemId: getUrlParams()?.id,
            values: response?.values,
          });
        }
      })
      .catch(this.onApiError)
      .finally(() => this.setState({ isSubmitting: false }));
  };

  onFieldSelect = (selectedField: FeeField | InventoryItemValueType) => {
    const { fieldType, trackUserAction } = this.props;
    const userAction = getUserAction(fieldType, TrackingAction.ADD, TrackingClickType.CLICK, selectedField.id);
    userAction && trackUserAction?.(userAction);
    this.setState({ selectedField });
  };

  onDeleteValue = () => {
    this.amountRef.value = '0';
    this.onSubmit();
  };

  render() {
    const { isOpen, selectedField, availableFields, fieldType } = this.props;
    const {
      amount,
      errorMessages,
      expiryDate,
      expiryDateFocused,
      note,
      isSubmitting,
      selectedField: selectedFieldState,
    } = this.state;

    const title = `${t(selectedField ? 'edit' : 'add')} ${
      t((selectedField || selectedFieldState)?.name) || selectedField?.type?.name
    }`;

    // Form view
    if (selectedField || selectedFieldState) {
      return (
        <FormDialog isOpen={isOpen} onClose={this.onClose} title={title}>
          <FormDialogBody>
            <FormErrors errorMessages={errorMessages} />
            <FormSection sectionNumber={t('x_of_y', [1, 2])}>
              <InputGroup
                dataTestId="amount-input"
                defaultValue={amount}
                label={t('amount')}
                name="amount"
                placeholder={t('enter_amount')}
                reference={(input) => {
                  this.amountRef = input;
                }}
                type="currency"
              />

              {fieldType === FieldTypes.VALUE && (
                <div className={style.expiryContainer}>
                  <InputGroup
                    date={expiryDate}
                    daySize={25}
                    displayFormat="YYYY/MM/DD"
                    focused={expiryDateFocused}
                    groupType="calendar"
                    hasMargin={false}
                    hideKeyboardShortcutsPanel
                    id="expiryDate"
                    label={t('expiry_date')}
                    name="expiryDate"
                    numberOfMonths={1}
                    onDateChange={(value) => this.setState({ expiryDate: value })}
                    onFocusChange={({ focused }) => this.setState({ expiryDateFocused: focused })}
                    placeholder="YYYY/MM/DD"
                  />
                </div>
              )}
            </FormSection>

            <FormSection sectionNumber={t('x_of_y', [2, 2])} title={t('note')}>
              <InputGroup
                defaultValue={note}
                groupType="textarea"
                name="note"
                placeholder={t('enter_note')}
                reference={(textarea) => {
                  this.notesRef = textarea;
                }}
              />
            </FormSection>
          </FormDialogBody>
          <FormDialogFooter>
            {selectedField?.id && (
              <Button onClick={this.onDeleteValue} theme="red">
                {t('delete')}
              </Button>
            )}
            <Button
              dataTestId="saveFeeValuesButton"
              disabled={isSubmitting}
              onClick={() => this.onSubmit()}
              theme="blue"
            >
              {isSubmitting ? <Spinner /> : t('save')}
            </Button>
          </FormDialogFooter>
        </FormDialog>
      );
    }

    // List selection view
    return (
      <FormDialog isOpen={isOpen} onClose={this.onClose} title={t('add_x', [t(fieldType)])}>
        <FormDialogBody>
          <FormSection flexDirection="column">
            <p className={style.chooseFieldHeader}>{t(`choose_${fieldType}_type`)}</p>
            <div className={style.chooseFieldRows}>
              {availableFields?.map((field) => (
                <RowButtonWithArrow
                  key={field?.id}
                  className={style.chooseFieldRow}
                  dataTestId={`${field?.id}-button`}
                  label={t(field?.name)}
                  onClick={() => this.onFieldSelect(field)}
                />
              ))}
            </div>
          </FormSection>
        </FormDialogBody>
      </FormDialog>
    );
  }
}

export default connector(AddModifyFeeValue);
