import classnames from 'classnames';
import { ChangeEvent } from 'react';
import { Link } from 'react-router';
import { isEqual } from 'lodash-es';

import closeGlyph from 'glyphs/close.svg';

import BaseClass from 'components/ui/shared/base';
import Button from 'components/ui/shared/button';
import ConfirmDialog from 'components/sections/inventoryItem/addModify/confirmDialog';
import InputSwitch from 'forms/shared/inputSwitch';
import InputText from 'forms/shared/inputText';
import NotificationBanner from 'components/ui/shared/notifications/notificationBanner';
import Slider from 'forms/shared/slider';
import Sprite from 'components/ui/shared/sprite';
import TextArea from 'forms/shared/textArea';
import { AccountSection } from 'layouts/accountLayouts/accountLayouts';
import { AddModifyVehicleProps, InventoryItemPropsJs } from 'store/inventoryItem/addModify/addModifyModels';
import { ErrorMessages } from 'constants/errors';
import {
  CarfaxReportStatus,
  MutationinventoryItemCarfaxCanadaAttachReportArgs,
} from 'store/shared/api/graph/interfaces/types';
import { Overlay } from 'constants/enums/addModifyInventoryItem';
import { Spinner } from 'components/ui/loading/loading';
import { isVerifiedExtensiveVehicleCondition } from 'utils/auctionItemUtils';
import { t } from 'utils/intlUtils';
import InventoryItem from 'constants/inventoryItem';

import style from './description.scss';

interface Props {
  /** Callback function to change overlay. */
  changeOverlay: (option: Overlay | null) => void;
  /** Callback function to clear error messages. */
  clearError: () => void;
  /** Error messages. */
  errorMessages: ErrorMessages;
  /** Inventory item information. */
  inventoryItem: InventoryItemPropsJs['results'];
  /** Whether is updating or not. */
  isUpdating: boolean;
  /** Overlay type. */
  overlay: Overlay | null;
  /** Callback function to scroll the modal by value. */
  scrollModalByValue: (scrollOffset: number) => void;
  /** Section ref name. */
  sectionRef?: string;
  /** Callback function to set vehicle information. */
  setVehicle: (option: AddModifyVehicleProps) => void;
  /** Callback function to attach Carfax. */
  submitAttachCarfax: (option: MutationinventoryItemCarfaxCanadaAttachReportArgs) => void;
}

interface State {
  /** Whether there is Carfax attached or not. */
  hasAttachedCarfax: boolean;
  /** Whether there is Carfax claim or not. */
  hasCarfaxClaim: boolean;
  /** Whether is loading Carfax or not. */
  loadingCarfax: boolean;
}

class Description extends BaseClass<Props, State> {
  private carfax: HTMLDivElement | null;
  private container: HTMLDivElement | null;
  private description: HTMLDivElement | null;
  private score: HTMLDivElement | null;

  constructor(props: Props) {
    super(props);
    const { carfaxCanadaClaimAmount, carfaxCanadaReportUrl } = props.inventoryItem.vehicle.conditionReport || {};

    this.state = {
      hasAttachedCarfax: !!carfaxCanadaReportUrl,
      hasCarfaxClaim: !!(carfaxCanadaClaimAmount && carfaxCanadaClaimAmount.amount),
      loadingCarfax: false,
    };

    this.carfax = null;
    this.container = null;
    this.description = null;
    this.score = null;
  }

  componentDidMount() {
    super.componentDidMount();

    const { scrollModalByValue, sectionRef } = this.props;

    if (sectionRef && this[sectionRef]) {
      const offsetValue = this[sectionRef].getBoundingClientRect().top;
      scrollModalByValue(offsetValue);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { errorMessages, isUpdating } = this.props;
    const { errorMessages: errorMessagesPrev, isUpdating: isUpdatingPrev } = prevProps;
    const hasVinError = errorMessages.some((o) => o.name === 'vin');

    if (!isEqual(errorMessages, errorMessagesPrev) && hasVinError) {
      this.setState({ hasAttachedCarfax: false });
    }

    if (isUpdatingPrev && !isUpdating) {
      this.setState({ loadingCarfax: false });
    }
  }

  getFormattedText = () => {
    const { overallConditionRating: value } = this.props.inventoryItem.vehicle.conditionReport || {};

    if (Number(value) > 85) {
      return t('condition_very_clean');
    }
    if (Number(value) > 70) {
      return t('condition_clean');
    }
    if (Number(value) > 55) {
      return t('condition_average');
    }
    if (Number(value) > 40) {
      return t('condition_rough');
    }
    if (Number(value) > 20) {
      return t('condition_very_rough');
    }

    return t('condition_salvage');
  };

  getSliderTheme = () => {
    const {
      inventoryItem: {
        vehicle: { conditionReport, extensiveVehicleConditionScore },
      },
    } = this.props;
    const overallConditionRating = conditionReport?.overallConditionRating;

    if (extensiveVehicleConditionScore) {
      if (extensiveVehicleConditionScore > 4) {
        return 'green';
      }
      if (extensiveVehicleConditionScore > 2) {
        return 'orange';
      }
      return 'red';
    }

    if (Number(overallConditionRating) > 70) {
      return 'green';
    }
    if (Number(overallConditionRating) > 40) {
      return 'orange';
    }
    return 'red';
  };

  getSegmentClasses = (refName: string) => {
    const { sectionRef } = this.props;
    return classnames(style.segment, sectionRef && sectionRef === refName && style.highlighted);
  };

  handleClearError = () => {
    if (this.props.errorMessages.length) {
      this.props.clearError();
    }
  };

  handleOnBlur = (key: keyof InventoryItem, originalValue: string, value: string | undefined) => {
    const { setVehicle } = this.props;
    if (originalValue !== value) {
      setVehicle({ [key]: value });
    }
  };

  handleOnConfirm = (shouldSubmit: boolean) => {
    const {
      changeOverlay,
      inventoryItem: {
        vehicle: { id: inventoryItemId },
      },
      submitAttachCarfax,
    } = this.props;

    changeOverlay(null);
    if (shouldSubmit) {
      this.setState({ loadingCarfax: true });
      submitAttachCarfax({ inventoryItemId });
    } else {
      this.setState({ hasAttachedCarfax: false });
    }
  };

  /**
   * Handles attach carfax report input switch click.
   */
  handleAttachCarfax = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      inventoryItem: {
        vehicle: { id: inventoryItemId, conditionReport: conditionReportObj },
      },
      setVehicle,
      submitAttachCarfax,
    } = this.props;
    const isChecked = e.target.checked;

    this.handleClearError();
    this.setState({ hasAttachedCarfax: isChecked });
    if (isChecked) {
      submitAttachCarfax({ inventoryItemId });
    } else {
      const conditionReport = { ...conditionReportObj, carfaxCanadaReportUrl: '' };
      setVehicle({ conditionReport });
    }
  };

  /**
   * Handles additional claims input switch click.
   */
  handleAdditionalClaimsClick = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const {
      inventoryItem: {
        vehicle: { conditionReport: conditionReportObj },
      },
      setVehicle,
    } = this.props;

    this.handleClearError();
    this.setState({ hasCarfaxClaim: isChecked });
    if (!isChecked) {
      const conditionReport = { ...conditionReportObj, carfaxCanadaClaimAmount: { amount: 0 } };
      setVehicle({ conditionReport });
    }
  };

  renderScore = () => {
    const {
      clearError,
      errorMessages,
      inventoryItem: {
        vehicle: { captureType, conditionReport: conditionReportObj, extensiveVehicleConditionScore },
      },
      setVehicle,
    } = this.props;
    const overallConditionRating = conditionReportObj?.overallConditionRating;
    const hasError = !!errorMessages.length;
    const verifiedExtensiveVehicleCondition = isVerifiedExtensiveVehicleCondition(captureType);

    return (
      <div className={this.getSegmentClasses('score')}>
        {hasError && (
          <ul className={style.errorMessageContainer}>
            {errorMessages.map((errorItem) => (
              <li key={errorItem.name}>{errorItem.message}</li>
            ))}
            <Button className={style.closeErrors} onClick={clearError} theme="none">
              <Sprite className={style.sprite} glyph={closeGlyph} />
            </Button>
          </ul>
        )}
        <div
          ref={(div) => {
            this.score = div;
          }}
        />
        <AccountSection className={style.section} title={t('overall_vehicle_condition')} titleClass={style.title}>
          <div className={style.overallConditionRatingContainer}>
            {!verifiedExtensiveVehicleCondition && (
              <div className={style.sliderContainer}>
                <Slider
                  max={100}
                  min={0}
                  onChange={(value) => {
                    const conditionReport = { ...conditionReportObj, overallConditionRating: value || 0 };
                    setVehicle({ conditionReport });
                    this.handleClearError();
                  }}
                  theme={this.getSliderTheme()}
                  value={overallConditionRating || undefined}
                />
              </div>
            )}
            {verifiedExtensiveVehicleCondition ? (
              <>
                <span className={style.overallConditionText}>
                  {extensiveVehicleConditionScore !== null ? (
                    <>
                      {t('your_overall_vehicle_condition')}
                      <span className={classnames(style.tag, style[this.getSliderTheme()])}>
                        {extensiveVehicleConditionScore}
                      </span>
                    </>
                  ) : (
                    t('calculate_x_overall_score_instruction', [6])
                  )}
                </span>
              </>
            ) : null}
            {!verifiedExtensiveVehicleCondition && overallConditionRating ? (
              <>
                <span className={style.overallCondition}>{overallConditionRating}%</span>
                <span className={style.overallConditionText}>
                  - {t('vehicle_condition_description_web')}
                  <span className={classnames(style.tag, style[this.getSliderTheme()])}>{this.getFormattedText()}</span>
                </span>
              </>
            ) : null}
            {!verifiedExtensiveVehicleCondition && !overallConditionRating ? (
              <span className={classnames(style.overallConditionText, style.redText)}>
                {t('condition_slider_description')}
              </span>
            ) : null}
          </div>
        </AccountSection>
      </div>
    );
  };

  renderCarfax = () => {
    const { hasAttachedCarfax, hasCarfaxClaim, loadingCarfax } = this.state;
    const {
      inventoryItem: {
        vehicle: { conditionReport: conditionReportObj },
      },
      isUpdating,
      setVehicle,
    } = this.props;
    const {
      carfaxCanadaClaimAmount: carfaxClaim,
      carfaxCanadaReportStatus,
      carfaxCanadaReportUrl,
    } = conditionReportObj || {};
    const amount = carfaxClaim && carfaxClaim.amount;
    const carfaxCanadaClaimAmount = amount ? amount.toString() : '';

    return (
      <div className={this.getSegmentClasses('carfax')}>
        <div
          ref={(div) => {
            this.carfax = div;
          }}
        />
        <AccountSection
          className={style.section}
          contentClass={style.sectionContent}
          title={t('carfax')}
          titleClass={style.title}
        >
          <div className={style.sectionContainer}>
            {carfaxCanadaReportStatus === CarfaxReportStatus.PENDING && (
              <NotificationBanner className={style.banner} primaryGlyphType="warning">
                {t('carfax_report_pending_message')}
              </NotificationBanner>
            )}
            <div className={classnames(style.carfaxRow, style.bottomGap)}>
              <label className={style.label}>{t('attach_carfax_canada_report')}</label>
              <InputSwitch
                className={style.inputSwitch}
                dataTestId="attach-carfax-switch"
                id="attachCarfax"
                onChange={this.handleAttachCarfax}
                value={hasAttachedCarfax}
              />
              {carfaxCanadaReportUrl && (
                <Link className={style.viewReportLink} target="_blank" to={carfaxCanadaReportUrl}>
                  {t('view_carfax_canada_report')}
                </Link>
              )}
              {isUpdating && (
                <div className={style.loading}>
                  <Spinner spinnerStyleClassName={style.spinnerStyle} theme="gray" />
                  <span>{loadingCarfax ? t('loading_carfax') : t('checking_carfax')}</span>
                </div>
              )}
            </div>
            {hasAttachedCarfax && (
              <div className={style.carfaxRow}>
                <label className={style.label}>{t('additional_claims_on_the_vehicle')}</label>
                <InputSwitch
                  className={style.inputSwitch}
                  dataTestId="additional-claims-switch"
                  id="hasCarfaxClaim"
                  onChange={this.handleAdditionalClaimsClick}
                  value={!!carfaxCanadaClaimAmount}
                />
                <div className={style.additionClaimContainer}>
                  <InputText
                    className={style.additionalClaimInput}
                    defaultValue={carfaxCanadaClaimAmount}
                    disabled={!hasCarfaxClaim}
                    onBlur={(e) => {
                      const value = e?.target.value || null;
                      if (carfaxCanadaClaimAmount !== value) {
                        const conditionReport = {
                          ...conditionReportObj,
                          carfaxCanadaClaimAmount: { amount: Number(value) || 0 },
                        };
                        setVehicle({ conditionReport });
                      }
                    }}
                    onChange={this.handleClearError}
                    placeholder={t('claim_amount')}
                    theme="minimalSmallCurrency"
                    type="currency"
                  />
                </div>
              </div>
            )}
          </div>
        </AccountSection>
      </div>
    );
  };

  renderDescription = () => {
    const { sellerNotes } = this.props.inventoryItem.vehicle;
    return (
      <div className={this.getSegmentClasses('description')}>
        <div
          ref={(div) => {
            this.description = div;
          }}
        />
        <AccountSection
          className={style.section}
          contentClass={style.sectionContent}
          title={t('seller_notes')}
          titleClass={style.title}
        >
          <TextArea
            className={style.message}
            defaultValue={sellerNotes || undefined}
            onBlur={(e) => this.handleOnBlur('sellerNotes', sellerNotes || '', e?.target.value)}
            onChange={this.handleClearError}
            placeholder={t('enter_seller_notes')}
            theme="small"
          />
        </AccountSection>
      </div>
    );
  };

  render() {
    const { overlay } = this.props;

    return (
      <>
        <div
          ref={(div) => {
            this.container = div;
          }}
          className={style.description}
          data-testid="add-vehicle-description"
        >
          {this.renderScore()}
          {this.renderCarfax()}
          {this.renderDescription()}
        </div>
        {overlay === Overlay.CARFAX_CONFIRM_DIALOG && (
          <ConfirmDialog isConfirmButtons onConfirm={this.handleOnConfirm} title={t('are_you_sure')}>
            <p>{t('carfax_report_purchase_confirm_message')}</p>
          </ConfirmDialog>
        )}
      </>
    );
  }
}

export default Description;
