import classnames from 'classnames';
import { MouseEventHandler, useMemo, useCallback } from 'react';

import AuctionItem from 'constants/auctionItem';
import AuctionLocationRow from 'components/sections/auctionItem/details/status/statusRows/auctionLocationRow';
import AuditLogRow from 'components/sections/auctionItem/details/status/statusRows/auditLogRow';
import BidsRow from 'components/sections/auctionItem/details/status/statusRows/bidsRow';
import RanBeforeRow from 'components/sections/auctionItem/details/status/statusRows/ranBeforeRow';
import StaffUserRows from 'components/sections/auctionItem/details/status/statusRows/staffUserRows/staffUserRows';
import User from 'constants/user';
import { AuctionItemStatus } from 'store/shared/api/graph/interfaces/types';
import { Row, Rows } from 'components/sections/auctionItem/details/status/statusRows/statusRows';
import { formatCalendarDate } from 'utils/dateUtils';
import { getAuctionNameByDetails } from 'utils/formatting/auctionItemFormatUtils';
import { isAuctionStaff } from 'utils/userUtils';
import { t } from 'utils/intlUtils';

import style from './liveAppraisal.scss';

interface Props {
  /** The auction item details. */
  details: AuctionItem;
  /** Click handler for show audit log. */
  onShowAuditLog: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show auto-bids. */
  onShowAutobids: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show bids. */
  onShowBids: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show ran before. */
  onShowRanBefore: MouseEventHandler<HTMLButtonElement>;
  /** Click handler on transport estimate row click. */
  onShowTransportEstimate: (auctionItem: AuctionItem) => void;
  /** The current user. */
  user: User;
}

const LiveAppraisal = ({
  details,
  details: {
    auction,
    autoBids,
    bidTimeline,
    deliveryDate,
    guaranteedAuctionPrice,
    inventoryItem,
    isMyItem,
    startingBid,
    status,
    topOffer,
  },
  onShowAuditLog,
  onShowAutobids,
  onShowBids,
  onShowRanBefore,
  onShowTransportEstimate,
  user,
}: Props) => {
  const auctionTitle = getAuctionNameByDetails(details);
  const isWinning = !!bidTimeline?.winning;
  const isLosing = !!bidTimeline?.outbid;
  const autoBidAmount = autoBids?.myAutoBid?.maxBid?.formattedAmountRounded;
  const estimatedDelivery = formatCalendarDate(deliveryDate, false);
  const isStaffUser = useMemo(() => isAuctionStaff(user, auction.id), [auction.id, user]);

  /**
   * Handles Transport Estimate onClick event
   */
  const handleTransportEstimateOnClick = useCallback(
    () => onShowTransportEstimate(details),
    [details, onShowTransportEstimate]
  );

  return (
    <div className={style.liveAppraisal} data-testid="live-appraisal">
      <Rows>
        <Row name={t('auction')}>{auctionTitle}</Row>
        {guaranteedAuctionPrice && (
          <Row dataTestId="status-row-gap" name={t('guaranteed_auction_price')}>
            {guaranteedAuctionPrice?.amount === -1 ? '-' : guaranteedAuctionPrice?.formattedAmount}
          </Row>
        )}
        <AuctionLocationRow inventoryItem={inventoryItem} />
        <Row name={t('est_vehicle_delivery')}>{estimatedDelivery}</Row>
        {topOffer && (
          <Row
            className={classnames(isWinning && style.isWinning, isLosing && style.isLosing)}
            dataTestId="status-row-current-bid"
            name={t('current_bid')}
          >
            {topOffer.amount.formattedAmountRounded}
          </Row>
        )}
        {startingBid && (isStaffUser || !topOffer || status === AuctionItemStatus.UPCOMING) && (
          <Row name={t('starting_bid')}>{startingBid?.formattedAmountRounded}</Row>
        )}
        {(isStaffUser || isMyItem) && <BidsRow details={details} isStaffUser={isStaffUser} onClick={onShowBids} />}
        {autoBidAmount && (
          <Row className={style.autobidAmount} name={t('my_autobid')}>
            {autoBidAmount}
          </Row>
        )}
        <StaffUserRows auctionItem={details} onShowAutobids={onShowAutobids} />
        <Row
          dataTestId="status-row-transport-estimate"
          name={t('transport_estimate')}
          onClick={handleTransportEstimateOnClick}
        />
        <RanBeforeRow details={details} onClick={onShowRanBefore} />
        <AuditLogRow auctionId={auction?.id} onClick={onShowAuditLog} user={user} />
      </Rows>
    </div>
  );
};

export default LiveAppraisal;
