import moment from 'moment';

import AuctionItem from 'constants/auctionItem';
import FooterProgressBar from 'components/ui/lists/listItem/footerProgressBar';
import InventoryItem from 'constants/inventoryItem';
import User from 'constants/user';
import { AuctionItemStatus } from 'store/shared/api/graph/interfaces/types';
import { isAuctionStaff, isGroupManagerRole } from 'utils/userUtils';
import { t, tPlural } from 'utils/intlUtils';

interface Props {
  /** The auction item.  */
  auctionItem?: AuctionItem;
  /** The inventory item. */
  inventoryItem?: InventoryItem;
  /** Function invoked when item's auction ends. */
  onItemEnded?: (auctionItems: AuctionItem[]) => void;
  /** The global system time offset. */
  timeOffset?: number;
  /** The current user. */
  user: User;
}

const InventoryItemFooterProgressBar = ({ auctionItem, inventoryItem, onItemEnded, timeOffset, user }: Props) => {
  const footerLabels: (string | undefined)[] = [];
  let theme = 'green';
  let timeStart: moment.Moment | undefined;
  let timeEnd: moment.Moment | undefined;
  let percentage: number | undefined;
  let onEnded: (() => void) | undefined;
  let showFooterClock = false;
  let showFooterTimer = false;

  if (auctionItem) {
    const {
      _ended,
      bidTimeline,
      colorScheme,
      ifBidTimeline,
      isMyItem,
      status,
      timerEnd,
      timerText,
      topOffer,
      watchers,
    } = auctionItem;
    const isWatched = !!watchers?.isWatched;

    if (status === AuctionItemStatus.UPCOMING) {
      return null;
    }

    if (['AWAITING_CHECKOUT', 'IN_IF_BID'].includes(status)) {
      onEnded = () => onItemEnded?.([auctionItem]);
    }

    if (timerText) {
      const endTime = status === AuctionItemStatus.IN_IF_BID && ifBidTimeline ? ifBidTimeline.ending : timerEnd;
      const noTimerStatuses = ['PENDING_DELIVERY', 'SOLD', 'PURCHASED', 'NO_SALE', 'SALE_CANCELLED'];
      if (!noTimerStatuses.includes(status) && endTime && moment(endTime).isAfter(moment())) {
        showFooterTimer = true;
        showFooterClock = true;
        timeEnd = moment(endTime).add(timeOffset, 'ms');

        if (status === AuctionItemStatus.IN_IF_BID) {
          // Ensure timer bar is displayed for items with an endTime
          timeStart = timeEnd ? timeEnd.clone().add(-1, 'minute') : undefined;
        }
      }
      if (status === AuctionItemStatus.IN_IF_BID && _ended) {
        footerLabels.push(t('times_up'));
        showFooterClock = false;
        showFooterTimer = false;
      } else {
        footerLabels.push(timerText);
      }
    } else if (timerEnd || auctionItem?.checkoutExpiry) {
      let endTime = status === AuctionItemStatus.IN_IF_BID && ifBidTimeline ? ifBidTimeline.ending : timerEnd;

      if (status === AuctionItemStatus.AWAITING_CHECKOUT) {
        endTime = auctionItem?.checkoutExpiry;
      }

      const numBids = (bidTimeline && bidTimeline.list?.length) || 0;
      const isWinning = bidTimeline ? bidTimeline.winning : false;
      const isLosing = bidTimeline ? bidTimeline.outbid : false;
      const winningCompany = topOffer?.company;

      timeEnd = endTime ? moment(endTime).add(timeOffset, 'ms') : undefined;
      timeStart = timeEnd ? timeEnd.clone().add(-1, 'minute') : undefined;
      theme = isLosing ? 'red' : 'green';

      showFooterClock = true;
      if (_ended) {
        footerLabels.push(t('times_up'));
        if (!['AWAITING_CHECKOUT', 'IN_IF_BID'].includes(status)) {
          if (user && isAuctionStaff(user) && winningCompany?.name) {
            footerLabels.push(t('x_is_the_highest_bidder', [winningCompany?.name]));
          } else if (isMyItem || numBids > 0) {
            footerLabels.push(numBids === 0 ? t('no_bids') : tPlural('x_bids', numBids, [numBids]));
          }
        }
      } else {
        showFooterTimer = true;
        if (status !== AuctionItemStatus.AWAITING_CHECKOUT) {
          if (numBids > 0) {
            footerLabels.push(tPlural('x_bids', numBids, [numBids]));
          }
          if (isAuctionStaff(user) && winningCompany?.name) {
            footerLabels.push(t('x_is_winning', [winningCompany?.name]));
          } else if (isWinning) {
            footerLabels.push(isGroupManagerRole(user) ? winningCompany?.name : t('you_are_winning'));
          } else if (isLosing) {
            footerLabels.push(t('you_are_losing'));
          }
        } else {
          footerLabels.push(t('time_left_to_complete_checkout'));
        }
      }

      if (!isMyItem && isWatched && numBids > 0 && !isWinning) {
        theme = 'red';
      }
    } else {
      return null;
    }

    if (status === AuctionItemStatus.AWAITING_CHECKOUT) {
      theme = 'blue';
    }

    if (colorScheme) {
      theme = colorScheme.toLowerCase();
    }
  } else {
    const { completionPercentage } = inventoryItem || {};
    percentage = completionPercentage ? Math.round(completionPercentage * 100) : NaN;

    if (completionPercentage === 1) {
      footerLabels.push(t('inventory_item_complete'));
    } else {
      footerLabels.push(t('inventory_item_progress', [percentage]));
    }

    if (percentage < 40) {
      theme = 'red';
    } else if (percentage < 60) {
      theme = 'yellow';
    } else {
      theme = 'green';
    }
  }

  return (
    <FooterProgressBar
      label={footerLabels.filter(Boolean)}
      onEnd={onEnded}
      percentage={percentage}
      showClock={showFooterClock}
      showTimer={showFooterTimer}
      theme={theme}
      timeEnd={timeEnd}
      timeStart={timeStart}
    />
  );
};

export default InventoryItemFooterProgressBar;
