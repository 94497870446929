import classnames from 'classnames';
import { ComponentType, useEffect } from 'react';

import Button from 'components/ui/shared/button';
import Dialog from 'components/ui/shared/dialogs/dialog';
import { StepConfig } from 'components/ui/productTours/productTour';
import { t } from 'utils/intlUtils';
import { useElementQuerySelector } from 'hooks/useElementQuerySelector';

import style from './cards.scss';

export interface CardProps {
  onClose: () => void;
  setCurrentStep: (step: number) => void;
}

interface Props {
  currentStep: number;
  onClose: () => void;
  setCurrentStep: (step: number) => void;
  showSkipButton?: boolean;
  showSteps?: boolean;
  steps: StepConfig[];
}

const Cards = ({ currentStep, onClose, setCurrentStep, showSkipButton = false, showSteps = false, steps }: Props) => {
  const Card = steps[currentStep].card as ComponentType<CardProps>;
  const elementRef = useElementQuerySelector('[data-testid="dialog-content"] > div');

  useEffect(() => {
    if (elementRef) {
      // Note: Default to 200px from the top, centered
      elementRef.setAttribute('style', steps[currentStep]?.cssText ?? 'position:absolute; top: 200px;');
    }
  }, [currentStep, steps, elementRef]);

  return (
    <Dialog
      className={style.dialog}
      contentInnerClassName={style.dialogContentInner}
      isOpen
      overlayClassName={style.dialogOverlay}
      requestClose={onClose}
    >
      <Card onClose={onClose} setCurrentStep={setCurrentStep} />

      {showSteps && currentStep !== 0 && currentStep !== steps.length - 1 && (
        <div className={style.stepDots}>
          {steps?.map((step, index) => (
            <Button
              key={index}
              className={classnames(style.stepDot, currentStep === index && style.active)}
              onClick={() => setCurrentStep(index)}
              theme="none"
            />
          ))}
        </div>
      )}

      {showSkipButton && currentStep !== steps.length - 1 && (
        <Button className={style.skipButton} onClick={onClose} theme="none">
          {t('skip')}
        </Button>
      )}
      <div className={style.pixel} data-testid="pixel-distraction" />
    </Dialog>
  );
};

export default Cards;
