import InventoryItemType from 'constants/inventoryItem';
import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import { Location, LogEntry } from 'store/shared/api/graph/interfaces/types';

export interface LogEntries {
  /** The title for the collection of log entries. */
  title: string;
  /** A collection of logs. */
  logs: LogEntry[];
}

interface LogEntriesModel {
  /** List of log entries. */
  results: LogEntries[] | null;
}

interface InventoryItemModel {
  /** List of consigner locations. */
  consignerLocations: Location[];
  /** Inventory item details. */
  details: InventoryItemType | null;
  /** List of log entries. */
  logEntries: ServerRecordOf<LogEntriesModel>;
}

const LogEntriesInitialState = ServerRecord<LogEntriesModel>({
  results: null,
});

export type InventoryItemProps = ServerRecordOf<InventoryItemModel>;

export const InventoryItem = ServerRecord<InventoryItemModel>({
  details: null,
  logEntries: new LogEntriesInitialState(),
  consignerLocations: [],
});
