import ApiRequest from 'store/shared/api/apiRequest';
import { AuctionItemConnection, Query, SavedFilter, User } from 'store/shared/api/graph/interfaces/types';
import { QueryAuctionItemListProps } from 'store/auctionItemsList/auctionItemsActions';
import { auctionItemListSchema } from 'store/auctionItemsList/auctionItemsSchemas';
import { filterSections } from 'store/shared/api/graph/schema/filters';
import { getFacetGroupsByUrl, pageInfo } from 'store/shared/api/graph/schema/connection';
import { listItemPhotoFragment } from 'store/shared/api/graph/fragments/photos';
import { locationFragment } from 'store/shared/api/graph/fragments/common';
import { toBooleanValue } from '../inventoryItemsList/inventoryItemsListApi';

export const getAuctionItemsList = ({
  transactionType = null,
  region = null,
  formats = null,
  status = null,
  first = null,
  last = null,
  after = null,
  before = null,
  sort = null,
  filterBy = null,
  consigner = null,
  consignerId = null,
  keyword = null,
  auctionIds = null,
  auctionItemIds = null,
  auctionLocationName = null,
  auctionTitle = null,
  auctionStartTime = null,
  auctionTimeSlotLane = null,
  auctionLaneType = null,
  auctionTimeSlotStatus = null,
  auctionItemEndTime = null,
  dateRanGTE = null,
  dateRanLTE = null,
  latitude = null,
  longitude = null,
  distance = null,
  ifBidClaim = null,
  mileageGTE = null,
  mileageLTE = null,
  yearGTE = null,
  yearLTE = null,
  makes = null,
  models = null,
  reservePriceGTE = null,
  reservePriceLTE = null,
  saleLights = null,
  salesRepId = null,
  startingBidGTE = null,
  startingBidLTE = null,
  subModels = null,
  vehicleScoreGTE = null,
  vehicleScoreLTE = null,
  option = null,
  bodyType = null,
  driveTrain = null,
  exteriorColor = null,
  fuelType = null,
  transmission = null,
  archived = false,
  viewName = null,
}: QueryAuctionItemListProps) => {
  return ApiRequest<Pick<Query, 'auctionItemConnection'>>({
    data: {
      operationName: 'getAuctionItemsList',
      query: `
        query(
            $after: String
            $archived: Boolean
            $auctionIds: [String]
            $auctionItemEndTime: [String]
            $auctionItemIds: [String]
            $auctionLaneType: AuctionLaneType
            $auctionLocationName: [String]
            $auctionStartTime: [String]
            $auctionTimeSlotLane: [String]
            $auctionTimeSlotStatus: [AuctionTimeSlotStatus]
            $auctionTitle: [String]
            $before: String
            $bodyType: [String]
            $consigner: [String]
            $consignerId: String
            $dateRanGTE: DateTime
            $dateRanLTE: DateTime
            $distance: Int
            $driveTrain: [String]
            $exteriorColor: [String]
            $filterBy: String
            $first: Int
            $formats: [AuctionItemFormat]
            $fuelType: [String]
            $ifBidClaim: IfBidClaim
            $keyword: String
            $last: Int
            $latitude: Float
            $longitude: Float
            $makes: [String]
            $mileageGTE: Int
            $mileageLTE: Int
            $models: [String]
            $option: [String]
            $region: [String]
            $reservePriceGTE: BigDecimal
            $reservePriceLTE: BigDecimal
            $saleLights: [SaleLight]
            $salesRepId: String
            $sort: [Sort]
            $startingBidGTE: BigDecimal
            $startingBidLTE: BigDecimal
            $status: [AuctionItemStatus]
            $subModels: [String]
            $transactionType: TransactionType
            $transmission: [String]
            $vehicleScoreGTE: BigDecimal
            $vehicleScoreLTE: BigDecimal
            $viewName: AuctionItemViewName
            $yearGTE: Int
            $yearLTE: Int
          ) {
          auctionItemConnection(
            after: $after
            archived: $archived
            auctionIds: $auctionIds
            auctionItemEndTime: $auctionItemEndTime
            auctionItemIds: $auctionItemIds
            auctionLaneType: $auctionLaneType
            auctionLocationName: $auctionLocationName
            auctionStartTime: $auctionStartTime
            auctionTimeSlotLane: $auctionTimeSlotLane
            auctionTimeSlotStatus: $auctionTimeSlotStatus
            auctionTitle: $auctionTitle
            before: $before
            bodyType: $bodyType
            consigner: $consigner
            consignerId: $consignerId
            dateRanGTE: $dateRanGTE
            dateRanLTE: $dateRanLTE
            distance: $distance
            driveTrain: $driveTrain
            exteriorColor: $exteriorColor
            filterBy: $filterBy
            first: $first
            formats: $formats
            fuelType: $fuelType
            ifBidClaim: $ifBidClaim
            keyword: $keyword
            last: $last
            latitude: $latitude
            longitude: $longitude
            makes: $makes
            mileageGTE: $mileageGTE
            mileageLTE: $mileageLTE
            models: $models
            option: $option
            region: $region
            reservePriceGTE: $reservePriceGTE
            reservePriceLTE: $reservePriceLTE
            saleLights: $saleLights
            salesRepId: $salesRepId
            sort: $sort
            startingBidGTE: $startingBidGTE
            startingBidLTE: $startingBidLTE
            status: $status
            subModels: $subModels
            transactionType: $transactionType
            transmission: $transmission
            vehicleScoreGTE: $vehicleScoreGTE
            vehicleScoreLTE: $vehicleScoreLTE
            viewName: $viewName
            yearGTE: $yearGTE
            yearLTE: $yearLTE
          ) {
            sort {
              name
              ascending
              selected
            }
            edges {
              cursor
              node {
                ${auctionItemListSchema}
              }
            }
            ${getFacetGroupsByUrl()}
            ${filterSections}
            ${pageInfo}
          }
        }
        ${listItemPhotoFragment}
        ${locationFragment}
      `,
      variables: {
        transactionType,
        region,
        formats,
        status,
        first,
        after,
        last,
        before,
        sort,
        filterBy,
        consignerId,
        consigner,
        keyword,
        auctionIds,
        auctionItemIds,
        auctionLocationName,
        auctionTitle,
        auctionStartTime,
        auctionTimeSlotLane,
        auctionLaneType,
        auctionTimeSlotStatus,
        auctionItemEndTime,
        dateRanGTE,
        dateRanLTE,
        latitude,
        longitude,
        distance: distance ? Number(distance) : null,
        ifBidClaim,
        mileageGTE: mileageGTE ? Number(mileageGTE) : null,
        mileageLTE: mileageLTE ? Number(mileageLTE) : null,
        yearGTE: yearGTE ? Number(yearGTE) : null,
        yearLTE: yearLTE ? Number(yearLTE) : null,
        makes,
        models,
        reservePriceGTE,
        reservePriceLTE,
        saleLights,
        salesRepId,
        startingBidGTE,
        startingBidLTE,
        subModels,
        vehicleScoreGTE,
        vehicleScoreLTE,
        option,
        bodyType,
        driveTrain,
        exteriorColor,
        fuelType,
        transmission,
        viewName,
        archived: toBooleanValue(archived),
      },
    },
    seqKey: 'auctionItemsList',
  });
};

const toArray = (val) => (val instanceof Array || !val ? val : [val]);

export const savedFilterCreate = (name, defaultFilter, params) => {
  const auctionLocationName = toArray(params.auctionLocationName);
  const auctionTitle = toArray(params.auctionTitle);
  const bodyType = toArray(params.bodyType);
  const driveTrain = toArray(params.driveTrain);
  const exteriorColor = toArray(params.exteriorColor);
  const formats = toArray(params.formats);
  const fuelType = toArray(params.fuelType);
  const makes = toArray(params.makes);
  const models = toArray(params.models);
  const options = toArray(params.options);
  const region = toArray(params.region);
  const subModels = toArray(params.subModels);
  const transmission = toArray(params.transmission);

  const {
    distance,
    mileageGTE,
    mileageLTE,
    reservePriceGTE,
    reservePriceLTE,
    startingBidGTE,
    startingBidLTE,
    vehicleScoreGTE,
    vehicleScoreLTE,
    yearGTE,
    yearLTE,
  } = params;

  return ApiRequest<{ savedFilterCreate: SavedFilter }>({
    data: {
      operationName: 'savedFilterCreate',
      query: `
        mutation(
          $auctionLocationName: [String],
          $auctionTitle: [String],
          $bodyType: [String],
          $dateRanGTE: DateTime,
          $dateRanLTE: DateTime,
          $defaultFilter: Boolean,
          $distance: Int,
          $driveTrain: [String],
          $exteriorColor: [String],
          $formats: [String],
          $fuelType: [String],
          $makes: [String],
          $mileageGTE: Int,
          $mileageLTE: Int,
          $models: [String],
          $name: String,
          $options: [String],
          $region: [String],
          $reservePriceGTE: BigDecimal,
          $reservePriceLTE: BigDecimal,
          $startingBidGTE: BigDecimal,
          $startingBidLTE: BigDecimal,
          $subModels: [String],
          $transmission: [String],
          $vehicleScoreGTE: BigDecimal,
          $vehicleScoreLTE: BigDecimal,
          $yearGTE: Int,
          $yearLTE: Int
        ) {
          savedFilterCreate(auctionItemSavedFilter: {
            auctionLocationName: $auctionLocationName,
            auctionTitle: $auctionTitle,
            bodyType: $bodyType,
            dateRanGTE: $dateRanGTE,
            dateRanLTE: $dateRanLTE,
            defaultFilter: $defaultFilter,
            distance: $distance,
            driveTrain: $driveTrain,
            exteriorColor: $exteriorColor,
            formats: $formats,
            fuelType: $fuelType,
            makes: $makes,
            mileageGTE: $mileageGTE,
            mileageLTE: $mileageLTE,
            models: $models,
            name: $name,
            options: $options,
            region: $region,
            reservePriceGTE: $reservePriceGTE,
            reservePriceLTE: $reservePriceLTE,
            startingBidGTE: $startingBidGTE,
            startingBidLTE: $startingBidLTE,
            subModels: $subModels,
            transmission: $transmission,
            vehicleScoreGTE: $vehicleScoreGTE,
            vehicleScoreLTE: $vehicleScoreLTE,
            yearGTE: $yearGTE,
            yearLTE: $yearLTE
          }) {
            id
          }
        }
      `,
      variables: {
        auctionLocationName,
        auctionTitle,
        bodyType,
        defaultFilter,
        distance: distance ? Number(distance) : null,
        driveTrain,
        exteriorColor,
        formats,
        fuelType,
        makes,
        mileageGTE: mileageGTE ? Number(mileageGTE) : null,
        mileageLTE: mileageLTE ? Number(mileageLTE) : null,
        reservePriceGTE,
        reservePriceLTE,
        startingBidGTE,
        startingBidLTE,
        vehicleScoreGTE,
        vehicleScoreLTE,
        models,
        name,
        options,
        region,
        subModels,
        transmission,
        yearGTE: yearGTE ? Number(yearGTE) : null,
        yearLTE: yearLTE ? Number(yearLTE) : null,
      },
    },
  });
};

export const getSavedFilters = () =>
  ApiRequest<{ user: Pick<User, 'savedFilters'> }>({
    data: {
      operationName: 'getSavedFilters',
      query: `
      {
        user {
          savedFilters(type: ["AuctionItemSavedFilter"]) {
            id
            ... on AuctionItemSavedFilter {
              id
              auctionLocationName,
              auctionTitle,
              bodyType,
              defaultFilter,
              distance,
              driveTrain,
              exteriorColor,
              formats,
              fuelType,
              makes,
              mileageGTE,
              mileageLTE,
              reservePriceGTE,
              reservePriceLTE,
              startingBidGTE,
              startingBidLTE,
              vehicleScoreGTE,
              vehicleScoreLTE,
              models,
              name,
              options,
              region,
              subModels,
              transmission,
              yearGTE,
              yearLTE,
            }
          }
        }
      }
    `,
    },
  });

export const savedFilterDelete = (id) =>
  ApiRequest<{ savedFilterDelete: Pick<User, 'id'> }>({
    data: {
      query: `
      mutation($id: String!) {
        savedFilterDelete(id: $id) {
          id
        }
      }
    `,
      variables: {
        id,
      },
    },
  });

export const savedFilterUpdate = (id, name, defaultFilter, params) => {
  const auctionLocationName = toArray(params.auctionLocationName);
  const auctionTitle = toArray(params.auctionTitle);
  const bodyType = toArray(params.bodyType);
  const driveTrain = toArray(params.driveTrain);
  const exteriorColor = toArray(params.exteriorColor);
  const formats = toArray(params.formats);
  const fuelType = toArray(params.fuelType);
  const makes = toArray(params.makes);
  const models = toArray(params.models);
  const options = toArray(params.options);
  const region = toArray(params.region);
  const subModels = toArray(params.subModels);
  const transmission = toArray(params.transmission);

  const {
    distance,
    mileageGTE,
    mileageLTE,
    reservePriceGTE,
    reservePriceLTE,
    startingBidGTE,
    startingBidLTE,
    vehicleScoreGTE,
    vehicleScoreLTE,
    yearGTE,
    yearLTE,
  } = params;

  return ApiRequest<{ savedFilterCreate: SavedFilter }>({
    data: {
      operationName: 'savedFilterUpdate',
      query: `
        mutation(
          $id: String!,
          $auctionLocationName: [String],
          $auctionTitle: [String],
          $bodyType: [String],
          $dateRanGTE: DateTime,
          $dateRanLTE: DateTime,
          $defaultFilter: Boolean,
          $distance: Int,
          $driveTrain: [String],
          $exteriorColor: [String],
          $formats: [String],
          $fuelType: [String],
          $makes: [String],
          $mileageGTE: Int,
          $mileageLTE: Int,
          $models: [String],
          $name: String,
          $options: [String],
          $region: [String],
          $reservePriceGTE: BigDecimal,
          $reservePriceLTE: BigDecimal,
          $startingBidGTE: BigDecimal,
          $startingBidLTE: BigDecimal,
          $subModels: [String],
          $transmission: [String],
          $vehicleScoreGTE: BigDecimal,
          $vehicleScoreLTE: BigDecimal,
          $yearGTE: Int,
          $yearLTE: Int
        ) {
          savedFilterCreate(id: $id, auctionItemSavedFilter: {
            auctionLocationName: $auctionLocationName,
            auctionTitle: $auctionTitle,
            bodyType: $bodyType,
            dateRanGTE: $dateRanGTE,
            dateRanLTE: $dateRanLTE,
            defaultFilter: $defaultFilter,
            distance: $distance,
            driveTrain: $driveTrain,
            exteriorColor: $exteriorColor,
            formats: $formats,
            fuelType: $fuelType,
            makes: $makes,
            mileageGTE: $mileageGTE,
            mileageLTE: $mileageLTE,
            models: $models,
            name: $name,
            options: $options,
            region: $region,
            reservePriceGTE: $reservePriceGTE,
            reservePriceLTE: $reservePriceLTE,
            startingBidGTE: $startingBidGTE,
            startingBidLTE: $startingBidLTE,
            subModels: $subModels,
            transmission: $transmission,
            vehicleScoreGTE: $vehicleScoreGTE,
            vehicleScoreLTE: $vehicleScoreLTE,
            yearGTE: $yearGTE,
            yearLTE: $yearLTE
          }) {
            id
          }
        }
      `,
      variables: {
        id,
        auctionLocationName,
        auctionTitle,
        bodyType,
        defaultFilter,
        distance: distance ? Number(distance) : null,
        driveTrain,
        exteriorColor,
        formats,
        fuelType,
        makes,
        mileageGTE: mileageGTE ? Number(mileageGTE) : null,
        mileageLTE: mileageLTE ? Number(mileageLTE) : null,
        reservePriceGTE,
        reservePriceLTE,
        startingBidGTE,
        startingBidLTE,
        vehicleScoreGTE,
        vehicleScoreLTE,
        models,
        name,
        options,
        region,
        subModels,
        transmission,
        yearGTE: yearGTE ? Number(yearGTE) : null,
        yearLTE: yearLTE ? Number(yearLTE) : null,
      },
    },
  });
};

export const getAuctionItemsEndTimes = () =>
  ApiRequest<{ auctionItemConnection: Pick<AuctionItemConnection, 'facetGroups'> }>({
    data: {
      query: `
      {
        auctionItemConnection(first: 1 status:LIVE formats: [GROUNDED, APPRAISAL]) {
          facetGroups(name: ["auctionItemEndTime"]) {
            name
            allowMultiple
            facets {
              count
              localizedName
              name
              selected
            }
          }
        }
      }
    `,
      variables: {},
    },
  });
