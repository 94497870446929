import { InjectedRouter } from 'react-router';
import { debounce, omit } from 'lodash-es';

import AuctionItemDetails from 'containers/lists/auctionItemsList/auctionItemDetailsContainer';
import AuthService from 'store/shared/services/authService';
import BaseClass from 'components/ui/shared/base';
import FacetGroups from 'components/ui/lists/filters/facetGroups/facetGroups';
import Loading from 'components/ui/loading/loading';
import Logger from 'logging/Logger';
import MyBlockContent from 'components/sections/myBlock/myBlockContent';
import NoResultsList from 'components/ui/noResults/noResultsList';
import SearchInput, { SearchInputRef } from 'forms/shared/searchInput';
import { Details } from 'layouts/list/listLayout';
import { FacetGroupConfig } from 'components/ui/lists/filters/facetGroups/interfaces/facetGroupTypes';
import { Flex, FlexColumn } from 'layouts/shared/flexbox';
import { Location } from 'constants/reactRouter';
import { MyBlockProps } from 'store/myBlock/list/myBlockModels';
import { Overlay } from 'components/ui/shared/overlays';
import { UserAction } from 'logging/analytics/events/userActions';
import { UserProps } from 'store/user/userModels';
import { getUrlParams, paramsToQueryString } from 'utils/urlUtils';
import { haveParamsChanged } from 'utils/listUtils';
import { t } from 'utils/intlUtils';

import style from './myBlock.scss';

export enum MyBlockVariant {
  BUYER = 'Buyer',
  SELLER = 'Seller',
}

export interface LocationQuery {
  id: string;
  showDetails: `${boolean}`;
}

interface Props {
  /** My block list. */
  list: MyBlockProps;
  /** The current url information. */
  location: Location<LocationQuery>;
  /** Callback function called to refresh my block list. */
  onRefresh: () => void;
  /** The current router information. */
  router: InjectedRouter;
  /** The logged-in user */
  user: UserProps;
  /** The variation of my block view */
  variant: MyBlockVariant;
}

class MyBlock extends BaseClass<Props> {
  private list: HTMLDivElement;
  private searchInput: SearchInputRef | null;

  componentDidUpdate(prevProps) {
    if (haveParamsChanged(prevProps?.location, this.props?.location, ['id', 'showDetails'])) {
      if (this.list) {
        this.list.scrollTop = 0;
      }
    }
  }

  getFacetGroupsConfig = (): FacetGroupConfig => {
    return [
      { type: 'heading', label: t('status') },
      { name: 'status', type: 'multi' },
      { type: 'heading', label: t('auction') },
      { name: 'auctionTitle', label: t('auction'), type: 'multi' },
      { name: 'auctionLocationName', label: t('auction_location'), type: 'multi' },
      { name: 'formats', type: 'multi' },
      { name: 'consigner', type: 'multi', label: t('consigner') },
      { name: 'region', type: 'multi', label: t('region') },
      { name: 'dateRan', label: t('date_ran'), type: 'dateRange', isUTC: false },
    ];
  };

  /**
   * Clears all active filters, including the keyword search value.
   *
   * @param {array} omittedFilters
   */
  onClearFilters = (omittedFilters = []) => {
    const { location } = this.props;
    const ignorableKeys = ['id', ...omittedFilters];
    const queryParams = Object.keys(location.query)
      .filter((key) => ignorableKeys.includes(key))
      .reduce((obj, key) => {
        const query = { ...obj };
        query[key] = location.query[key];
        return query;
      }, {});

    this.searchInput?.setValue('');
    this.props.router.push({
      ...location,
      query: queryParams,
    });
  };

  /**
   * Similar to `setKeyword`, but with calls throttled by 250ms
   */
  onSearch = debounce((keyword) => {
    this.setKeyword(keyword);
    Logger.trackUserAction(UserAction.SEARCH, {
      is_auction_staff: !!AuthService.getOwnedAuctionIds()?.length,
    });
  }, 250);

  /**
   * Applies keyword search to query params. Pagination is reset
   * after each call. If the keyword value is empty, the 'keyword'
   * query param is removed from the location.
   *
   * @param keyword
   */
  setKeyword = (keyword) => {
    const { router, location } = this.props;

    if (keyword === '') {
      const query = omit(location.query, 'keyword');
      router.push({ ...location, query });
    } else {
      // Reset to first page of results
      const query = omit(location.query, ['before', 'after']);
      router.push({ ...location, query: { ...query, keyword } });
    }
  };

  render() {
    const { list, location, onRefresh, user, variant } = this.props;
    const { id, ...activeFilters } = location.query;
    const isUserLoaded = !!user.loadedDate;
    const isListLoaded = !!list.loadedDate;
    const isPageInfoLoaded = !!list.pageInfo;
    const isFacetGroupsLoaded = !!list.facetGroups.length;
    const isEmptyLoadedList = isListLoaded && list.resultList.length === 0 && !list.isLoading;
    const isFilteredList = !!Object.keys(activeFilters).length;

    if ((!isEmptyLoadedList && (!isUserLoaded || !isListLoaded)) || !isPageInfoLoaded || !isFacetGroupsLoaded) {
      return <Loading hasFullWidth isLoading isModalTransparent />;
    }

    if (isEmptyLoadedList && !isFilteredList && !id) {
      return <NoResultsList onRefresh={onRefresh} />;
    }

    return (
      <>
        <Flex className={style.container}>
          <FlexColumn className={style.filters}>
            <div className={style.search}>
              <SearchInput
                ref={(input) => {
                  this.searchInput = input;
                }}
                defaultValue={getUrlParams()?.keyword}
                onChange={(e) => this.onSearch(e?.target?.value)}
              />
            </div>
            <div className={style.facetGroups}>
              <FacetGroups config={this.getFacetGroupsConfig()} facetGroups={list?.facetGroups} />
            </div>
          </FlexColumn>
          <Details
            className={style.listContainer}
            reference={(div) => {
              this.list = div;
            }}
          >
            <MyBlockContent list={list} onClearFilters={this.onClearFilters} user={user} variant={variant} />
            <Loading hasFullWidth isLoading={list.isLoading || list.isUpdating} />
          </Details>
          {location?.query?.showDetails === 'true' && (
            <div className={style.auctionItem}>
              <Overlay
                data-testid="auction-item-overlay"
                onClick={() => {
                  const params = getUrlParams();
                  const paramsNext = paramsToQueryString({ ...params, showDetails: false });
                  this.props?.router?.push(`${this.props?.location?.pathname}?${paramsNext}`);
                }}
              />
              <div className={style.auctionItemInner}>
                <AuctionItemDetails auctionItemId={id || undefined} location={location} />
              </div>
            </div>
          )}
        </Flex>
      </>
    );
  }
}

export default MyBlock;
