import { useCallback, useMemo, useState } from 'react';

import AuthService from 'store/shared/services/authService';
import ProductTour, {
  getProductTourStorage,
  ProductTourConfig,
  ProductTourName,
  setProductTourStorage,
} from './productTour';
import { t } from 'utils/intlUtils';
import { useElementQuerySelector } from 'hooks/useElementQuerySelector';
import { useMountEffect } from 'hooks/useMountEffect';

const BrowseCard = () => {
  return <div>{t('browse_feature_description')}</div>;
};

const BrowseProductTour = () => {
  const [isProductTourEnabled, setIsProductTourEnabled] = useState<boolean>();
  const elementRef = useElementQuerySelector('[data-testid="navigation-link-browse"]');

  /**
   * onClose
   */
  const onClose = useCallback(() => {
    setProductTourStorage(String(AuthService.user.id), ProductTourName.BROWSE);
    setIsProductTourEnabled(false);
  }, []);

  /**
   * Generate config

   * We need calculate the position of the highlighted area
   * insert it into the config, because there could be SystemBanners
   * visible altering the position at any given time.
   */
  const config = useMemo((): ProductTourConfig => {
    let top = 45;
    if (isProductTourEnabled) {
      top = elementRef?.getBoundingClientRect()?.bottom ?? top;
    }

    return {
      steps: [
        {
          card: BrowseCard,
          cssText: `position: absolute; top:${top + 15}px; left: 55px`,
          elementQuerySelector: '[data-testid="navigation-primary"]',
          elementPadding: 0,
        },
      ],
    };
  }, [elementRef, isProductTourEnabled]);

  /**
   * onMount
   *
   * Open product tour if the current device hasn't already displayed the tour in the past.
   */
  useMountEffect(() => {
    if (!getProductTourStorage(String(AuthService.user.id), ProductTourName.BROWSE)) {
      setTimeout(() => setIsProductTourEnabled(true), 2000);
    }
  });

  if (!isProductTourEnabled) {
    return null;
  }

  return <ProductTour config={config} onClose={onClose} />;
};

export default BrowseProductTour;
