import { PartialDeep } from 'type-fest';

import InventoryItem from 'constants/inventoryItem';
import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import { ErrorMessages } from 'constants/errors';
import {
  InventoryItemOption,
  Mileage as MileageType,
  PhotoType,
  VehicleBuilder,
  VehicleInput,
  VehicleMetaData,
} from 'store/shared/api/graph/interfaces/types';
import { Modal, Overlay, Page } from 'constants/enums/addModifyInventoryItem';

export interface InventoryItemVehicleModel
  extends Omit<InventoryItem, 'auctionLocation' | 'conditionReport' | 'mileage' | 'options' | 'tireCondition'>,
    Pick<VehicleInput, 'auctionLocationId' | 'locationId' | 'pickupLocationId' | 'tireCondition'> {
  conditionReport?: InventoryItem['conditionReport'] & {
    declarations: string[];
  };
  /** Consigner id. */
  consignerId?: string;
  /** Remaining uploads count. */
  ignoreHasStateChanged: boolean;
  /** Mileage */
  mileage: MileageType;
  /** Vehicle options. */
  options?: (string | InventoryItemOption)[];
  /** Number of photos remaining */
  remainingUploads: number;
  /** Current uploading photo type. */
  uploadPhotoType: PhotoType;
  /** Whether validation is complete or not. */
  validateComplete: boolean;
}

export type AddModifyVehicleProps = PartialDeep<InventoryItemVehicleModel, { recurseIntoArrays: true }>;

export interface InventoryItemModel {
  /** Country code. */
  countryCode: string;

  /** Vehicle meta data. */
  declarationsMeta: VehicleMetaData;

  /** Duplicated inventory item. */
  duplicates: {
    data: InventoryItem & { id: string; created: string };
    section: string;
  }[];

  /** Error messages. */
  errorMessages: ErrorMessages;

  /** Whether has continued with capture or not. */
  hasContinuedWithCapture: boolean;

  /** Whether has photos changed or not. */
  hasPhotosChanged: boolean;

  /** Whether has state changed or not. */
  hasStateChanged: boolean;

  /** Modal type. */
  modal: Modal;

  /** Overlay type. */
  overlay: Overlay | null;

  /** Page type. */
  page: Page;

  /** Inventory item results. */
  results: InventoryItemResultModel;
}

export type InventoryItemProps = ServerRecordOf<InventoryItemModel>;
export type InventoryItemPropsJs = ReturnType<ServerRecordOf<InventoryItemModel>['toJS']>;

export interface InventoryItemResultModel extends Omit<VehicleBuilder, 'vehicle'> {
  vehicle: InventoryItemVehicleModel;
}

export const InventoryItemInitialState = ServerRecord<InventoryItemModel>({
  countryCode: 'CA',
  declarationsMeta: null,
  duplicates: [],
  errorMessages: [],
  hasContinuedWithCapture: false,
  hasPhotosChanged: false,
  hasStateChanged: false,
  modal: null,
  overlay: null,
  page: Page.VIN_OVERLAY,
  results: { vehicle: { conditionReport: {} } } as InventoryItemResultModel,
});
