import ApiRequest from 'store/shared/api/apiRequest';
import AuctionItem from 'constants/auctionItem';
import {
  AuctionItemConnection,
  AuctionTimeSlotLane,
  QueryauctionItemConnectionArgs,
} from 'store/shared/api/graph/interfaces/types';
import { auctionTimeSlotLaneSchema } from 'store/admin/liveLanes/shared/liveLaneSchemas';
import { inventoryItemValues } from 'store/shared/api/graph/schema/inventoryItem';
import { listItemPhotoFragment } from 'store/shared/api/graph/fragments/photos';
import { pageInfo } from 'store/shared/api/graph/schema/connection';
import { topOffer } from 'store/shared/api/graph/schema/auctionItem';

export const getAuctionItem = ({ auctionItemId }: { auctionItemId: string }) => {
  return ApiRequest<{ auctionItem: AuctionItem }>({
    data: {
      query: `
        query($auctionItemId: String!) {
          auctionItem(auctionItemId: $auctionItemId)  {
            id
            asIs
            auction {
              currencyCode
              title
              videoStreamConfig {
                accountId
                subscriptionToken
              }
            }
            auctionTimeSlot {
              id
            }
            auctionTimeSlotLane {
              id
              name
            }
            autoBids {
              count
            }
            bidIncrement {
              amount
              formattedAmountRounded
            }
            bidTimeline {
              count
              list {
                amount {
                  formattedAmount
                }
                company {
                  name
                }
                createdBy {
                  id
                }
                type
              }
            }
            format
            history {
              count
              list {
                ranOn
              }
            }
            inventoryItem {
              notes {
                id
              }
              id
              autoGradeScore
              captureType
              company {
                id
                name
              }
              comparableValuations {
                price
                soldDate
              }
              conditionReport {
                carfaxCanadaClaimAmount {
                  amount
                  formattedAmountRounded
                }
                carfaxCanadaDisclosuresExist
                carfaxCanadaReportUrl
                declarations(selected:true) {
                  id
                  name
                  type
                }
                overallConditionRating
              }
              extensiveVehicleConditionScore
              exteriorColor
              lastKnownMileage {
                amount
                unit
              }
              lastKnownMileageDate
              location {
                countryCode
              }
              make
              mileage {
                formattedAmount
              }
              model
              pickupLocation {
                countryCode
              }
              photos (first: 1) { ...listItemPhotoFragment }
              options(selected: true) {
                name
              }
              sellerNotes
              subModel
              trim
              vehicleBuyerShield
              vin
              year
              ${inventoryItemValues}
            }
            isAssured
            nextBidAmount {
              amount
              formattedAmountRounded
            }
            saleLights
            sellerIntent {
              status
              createdBy {
                id
                firstName
                lastName
              }
            }
            reserve {
              amount
              currencyCode
              formattedAmount
              formattedAmountRounded
            }
            reserveMet
            reserveType
            displayRunNumber
            startingBid {
              amount
              formattedAmountRounded
            }
            status
            ${topOffer}
            watchers {
              count
              isWatched
            }
          }
        }
        ${listItemPhotoFragment}
      `,
      variables: {
        auctionItemId,
      },
    },
  });
};

export const getLiveLane = ({
  auctionTimeSlotLaneIds = undefined,
  first = undefined,
  status = undefined,
  sort = undefined,
}: QueryauctionItemConnectionArgs) =>
  ApiRequest<{
    auctionItemConnection: AuctionItemConnection;
    auctionTimeSlotLane: AuctionTimeSlotLane;
  }>({
    data: {
      operationName: 'getLiveLane',
      query: `
        query(
          $auctionTimeSlotLaneIds: [String],
          $auctionTimeSlotLane: String!,
          $first: Int,
          $sort: [Sort]
          $status: [AuctionItemStatus]
        ) {
          auctionItemConnection(
            auctionTimeSlotLaneIds: $auctionTimeSlotLaneIds,
            first: $first,
            sort: $sort,
            status: $status
          ) {
            edges {
              node {
                id
                auctionTimeSlotLane {
                  name
                }
                inventoryItem {
                  id
                  captureType
                  company {
                    id
                    name
                  }
                  conditionReport {
                    carfaxCanadaClaimAmount {
                      amount
                      formattedAmountRounded
                    }
                    declarations(selected:true) {
                      id
                      name
                    }
                  }
                  make
                  mileage {
                    formattedAmount
                  }
                  model
                  photos (first: 1) { ...listItemPhotoFragment }
                  trim
                  vehicleBuyerShield
                  vin
                  year
                }
                isAssured
                displayRunNumber
                status
                watchers {
                  isWatched
                }
              }
            }
            ${pageInfo}
          }
          auctionTimeSlotLane(auctionTimeSlotLaneId: $auctionTimeSlotLane) {
           ${auctionTimeSlotLaneSchema}
          }
        }
        ${listItemPhotoFragment}
      `,
      variables: {
        auctionTimeSlotLaneIds,
        auctionTimeSlotLane: auctionTimeSlotLaneIds?.[0],
        first,
        sort,
        status,
      },
    },
  });
