import { useCallback, useMemo, MouseEventHandler } from 'react';
import { useSelector } from 'react-redux';

import AuctionItem from 'constants/auctionItem';
import AuditLogRow from 'components/sections/auctionItem/details/status/statusRows/auditLogRow';
import BuyNowPriceRow from 'components/sections/auctionItem/details/status/statusRows/buyNowPriceRow';
import OfferRow from 'components/sections/auctionItem/details/status/statusRows/offerRow/offerRow';
import RanBeforeRow from 'components/sections/auctionItem/details/status/statusRows/ranBeforeRow';
import StaffUserRows from 'components/sections/auctionItem/details/status/statusRows/staffUserRows/staffUserRows';
import YourOfferRow from 'components/sections/auctionItem/details/status/statusRows/offerRow/yourOfferRow';
import { AppState } from 'store/configureStore';
import { Row, Rows } from 'components/sections/auctionItem/details/status/statusRows/statusRows';
import { UserProps } from 'store/user/userModels';
import { getAuctionNameByDetails } from 'utils/formatting/auctionItemFormatUtils';
import { isAuctionStaff } from 'utils/userUtils';
import { t } from 'utils/intlUtils';

import style from './liveGrounded.scss';

interface Props {
  /** The auction item details. */
  details: AuctionItem;
  /** Click handler for show audit log. */
  onShowAuditLog: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show ran before. */
  onShowRanBefore: MouseEventHandler<HTMLButtonElement>;
  /** Click handler on transport estimate row click. */
  onShowTransportEstimate: (auctionItem: AuctionItem) => void;
}

const LiveTimedOffer = ({
  details,
  details: { auction, buyNowPrice, timedOfferTimeline },
  onShowAuditLog,
  onShowRanBefore,
  onShowTransportEstimate,
}: Props) => {
  const user = useSelector<AppState, UserProps>((state) => state.app.user);
  const isStaffUser = useMemo(() => isAuctionStaff(user, auction.id), [auction.id, user]);
  const auctionTitle = getAuctionNameByDetails(details);

  /**
   * Handles Transport Estimate onClick event
   */
  const handleTransportEstimateOnClick = useCallback(
    () => onShowTransportEstimate(details),
    [details, onShowTransportEstimate]
  );

  return (
    <div className={style.liveBuyNow} data-testid="live-timed-offer">
      <Rows>
        <Row name={t('auction')}>{auctionTitle}</Row>
        <BuyNowPriceRow auctionItem={details} user={user} />
        {details.isMyItem || isStaffUser ? (
          <OfferRow offers={timedOfferTimeline?.offerThreads || null} />
        ) : (
          <YourOfferRow
            offers={timedOfferTimeline?.offerThreads || null}
            waitingOnThem={!!timedOfferTimeline?.waitingOnThem}
            waitingOnYou={!!timedOfferTimeline?.waitingOnYou}
          />
        )}
        <StaffUserRows auctionItem={details} />
        <Row name={t('transport_estimate')} onClick={handleTransportEstimateOnClick} />
        <RanBeforeRow details={details} onClick={onShowRanBefore} />
        <AuditLogRow auctionId={auction.id} onClick={onShowAuditLog} user={user} />
      </Rows>
    </div>
  );
};

export default LiveTimedOffer;
