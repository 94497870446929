import AuctionItem from 'constants/auctionItem';
import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import { AuctionEventTimelineEntry } from 'store/shared/api/graph/interfaces/types';

interface AuctionItemDetailsModel {
  /** The timeline of events for an AuctionItem's TimeSlotLane */
  auctionEventTimeline: AuctionEventTimelineEntry[];
  /** Auction item details. */
  details: AuctionItem;
}

export type AuctionItemDetailsProps = ServerRecordOf<AuctionItemDetailsModel>;

/**
 * Initial state of model
 */
export const InitialState = ServerRecord<AuctionItemDetailsModel>({
  auctionEventTimeline: null,
  details: null,
});
