import { MouseEventHandler, useMemo } from 'react';

import AuctionItem from 'constants/auctionItem';
import AuctionLocationRow from 'components/sections/auctionItem/details/status/statusRows/auctionLocationRow';
import AuditLogRow from 'components/sections/auctionItem/details/status/statusRows/auditLogRow';
import BidsRow from 'components/sections/auctionItem/details/status/statusRows/bidsRow';
import BuyNowPriceRow from 'components/sections/auctionItem/details/status/statusRows/buyNowPriceRow';
import IfBidsRow from 'components/sections/auctionItem/details/status/statusRows/ifBidsRow';
import RanBeforeRow from 'components/sections/auctionItem/details/status/statusRows/ranBeforeRow';
import ReservePriceRow from 'components/sections/auctionItem/details/status/statusRows/reservePriceRow';
import StaffUserRows from 'components/sections/auctionItem/details/status/statusRows/staffUserRows/staffUserRows';
import User from 'constants/user';
import { Row, Rows } from 'components/sections/auctionItem/details/status/statusRows/statusRows';
import { formatCalendarDate, formatCalendarTime } from 'utils/dateUtils';
import { getAuctionNameByDetails } from 'utils/formatting/auctionItemFormatUtils';
import { isAuctionFormat } from 'utils/auctionItemUtils';
import { isAuctionStaff } from 'utils/userUtils';
import { t } from 'utils/intlUtils';

import style from './noSale.scss';

interface Props {
  /** The auction item details. */
  details: AuctionItem;
  /** Click handler for show audit log. */
  onShowAuditLog: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show auto-bids. */
  onShowAutobids: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show bids. */
  onShowBids: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show highest bidder. */
  onShowHighestBidder: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show if bids. */
  onShowIfBids: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show ran before. */
  onShowRanBefore: MouseEventHandler<HTMLButtonElement>;
  /** The current user. */
  user: User;
}

const NoSale = ({
  details,
  details: { auction, buyer, deliveryDate, format, guaranteedAuctionPrice, inventoryItem, isMyItem, ranOn, topOffer },
  onShowAuditLog,
  onShowAutobids,
  onShowBids,
  onShowHighestBidder,
  onShowIfBids,
  onShowRanBefore,
  user,
}: Props) => {
  const auctionName = getAuctionNameByDetails(details);
  const dateRan = formatCalendarTime(ranOn, 'MMMM DD, YYYY [at] h:mm A');
  const estimatedDelivery = deliveryDate && formatCalendarDate(deliveryDate, false);
  const isAuction = isAuctionFormat(format);
  const isStaffUser = useMemo(() => isAuctionStaff(user, auction.id), [auction.id, user]);

  if (!isStaffUser && !isMyItem) {
    return null;
  }

  return (
    <div className={style.noSale} data-testid="no-sale-rows">
      <Rows>
        {buyer?.company && isMyItem && (
          <Row name={t('highest_bidder')} onClick={onShowHighestBidder}>
            {buyer.company.name}
          </Row>
        )}
        <Row className={style.highestOffer} dataTestId="highest-bid-row" name={t('highest_bid')}>
          {topOffer ? topOffer.amount.formattedAmountRounded : t('none')}
        </Row>
        <IfBidsRow details={details} onClick={onShowIfBids} theme="red" />
        {(isStaffUser || isMyItem) && <BidsRow details={details} onClick={isAuction ? onShowBids : undefined} />}
        <StaffUserRows auctionItem={details} onShowAutobids={onShowAutobids} />
        <Row name={t('auction')}>{auctionName}</Row>
        {guaranteedAuctionPrice && (
          <Row dataTestId="status-row-gap" name={t('guaranteed_auction_price')}>
            {guaranteedAuctionPrice?.amount === -1 ? '-' : guaranteedAuctionPrice?.formattedAmount}
          </Row>
        )}
        <AuctionLocationRow inventoryItem={inventoryItem} />
        <Row name={t('date_ran')}>{dateRan}</Row>
        {estimatedDelivery && <Row name={t('est_vehicle_delivery')}>{estimatedDelivery}</Row>}
        <ReservePriceRow auctionItem={details} user={user} />
        <BuyNowPriceRow auctionItem={details} user={user} />
        <RanBeforeRow details={details} onClick={onShowRanBefore} />
        <AuditLogRow auctionId={auction?.id} onClick={onShowAuditLog} user={user} />
      </Rows>
    </div>
  );
};

export default NoSale;
