import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import { Company, CompanyCreditLog } from 'store/shared/api/graph/interfaces/types';

export type CompanyVehicleAuctionStatusAmounts = {
  /** Number of vehicles in awaiting checkout. */
  awaitingCheckout: number;
  /** Number of cancelled vehicles. */
  cancelled: number;
  /** Number of vehicles in if bid. */
  inIfBid: number;
  /** Number of live lanes vehicles. */
  liveLanes?: number;
  /** Number of no sale vehicles. */
  noSale: number;
  /** Number of parked vehicles. */
  parked: number;
  /** Number of pending delivery vehicles. */
  pendingDelivery: number;
  /** Number of purchased vehicles. */
  purchased: number;
  /** Number of sold vehicles. */
  sold: number;
  /** Number of timed auction vehicles. */
  timedAuctions: number;
  /** Number of upcoming vehicles. */
  upcoming: number;
};

interface CompanyDetailsModel {
  /** Company credits logs. */
  creditsLog: CompanyCreditLog | null;
  /** Company details. */
  details: Company;
  /** Company vehicle auction amounts by status. */
  vehicles: CompanyVehicleAuctionStatusAmounts;
}

export type CompanyDetailsProps = ServerRecordOf<CompanyDetailsModel>;

export const InitialState = ServerRecord<CompanyDetailsModel>({
  creditsLog: null,
  details: null,
  vehicles: {
    liveLanes: 0,
    timedAuctions: 0,
    upcoming: 0,
    parked: 0,
    inIfBid: 0,
    pendingDelivery: 0,
    awaitingCheckout: 0,
    purchased: 0,
    sold: 0,
    noSale: 0,
    cancelled: 0,
  },
});
