/* eslint-disable */

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** An arbitrary precision signed decimal */
  BigDecimal: { input: number; output: number };
  /** Date in the format 'uuuu-MM-dd' */
  Date: { input: string; output: string };
  /** Date time in the format 'uuuu-MM-dd'T'HH:mm:ss.SSS'. */
  DateTime: { input: string; output: string };
  /** A 64-bit signed integer */
  Long: { input: number; output: number };
};

export enum AccountEnum {
  BUY = 'BUY',
  LOYALTY = 'LOYALTY',
  SELL = 'SELL',
}

export type AccountingSystem = {
  __typename?: 'AccountingSystem';
  accountStatusUrl?: Maybe<Scalars['String']['output']>;
  businessUnits: Array<AccountingSystemBusinessUnit>;
  countryCode?: Maybe<Scalars['String']['output']>;
  currencyCode: CurrencyCode;
  id: Scalars['String']['output'];
  localPrimary: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export enum AccountingSystemBusinessUnit {
  EB_ATLANTIC = 'EB_ATLANTIC',
  EB_BC = 'EB_BC',
  EB_DIGITAL = 'EB_DIGITAL',
  EB_ONTARIO = 'EB_ONTARIO',
  EB_PRAIRIES = 'EB_PRAIRIES',
  EB_QUEBEC = 'EB_QUEBEC',
  EB_US_DIGITAL = 'EB_US_DIGITAL',
  ESP = 'ESP',
}

export enum AccountsPayableDeduction {
  DEDUCT_ALL_AR = 'DEDUCT_ALL_AR',
  DEDUCT_VIN_AR = 'DEDUCT_VIN_AR',
  NO_DEDUCTION = 'NO_DEDUCTION',
}

export type Address = {
  __typename?: 'Address';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  regionCode?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export enum AmountType {
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
}

export enum ArbitrationCompensationType {
  GOODWILL = 'GOODWILL',
  POLICY_LOSS = 'POLICY_LOSS',
}

export type ArbitrationMetaData = {
  __typename?: 'ArbitrationMetaData';
  arbitrationTags: Array<ArbitrationTag>;
};

export type ArbitrationRequest = {
  __typename?: 'ArbitrationRequest';
  actionBy?: Maybe<User>;
  arbitrationAgent?: Maybe<User>;
  cancelled?: Maybe<Scalars['Boolean']['output']>;
  company?: Maybe<Company>;
  compensationComment?: Maybe<Scalars['String']['output']>;
  compensationPaidByCarrier?: Maybe<MonetaryAmount>;
  compensationPaidByEBlock?: Maybe<MonetaryAmount>;
  compensationPaidByEBlockType?: Maybe<ArbitrationCompensationType>;
  compensationPaidBySeller?: Maybe<MonetaryAmount>;
  compensationToBuyer?: Maybe<MonetaryAmount>;
  content: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  onTime?: Maybe<Scalars['Boolean']['output']>;
  responsibleComment?: Maybe<Scalars['String']['output']>;
  responsibleParty?: Maybe<ArbitrationResponsibleParty>;
  status: AuctionItemArbitrationStatus;
  subStatus?: Maybe<AuctionItemArbitrationSubStatus>;
  tags: Array<ArbitrationTag>;
  transactionType: TransactionType;
  updated: Scalars['DateTime']['output'];
};

export type ArbitrationRequesttagsArgs = {
  selected?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ArbitrationResponsibleParty {
  BUYER = 'BUYER',
  CARRIER = 'CARRIER',
  EBLOCK_CAPTURE_REP = 'EBLOCK_CAPTURE_REP',
  EBLOCK_OTHER = 'EBLOCK_OTHER',
  EBLOCK_SALES_REP = 'EBLOCK_SALES_REP',
  NO_FAULT = 'NO_FAULT',
  PSI = 'PSI',
  SELLER = 'SELLER',
}

export type ArbitrationTag = {
  __typename?: 'ArbitrationTag';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  selected: Scalars['Boolean']['output'];
};

export enum AsIsEligibility {
  ALL = 'ALL',
  EBLOCK = 'EBLOCK',
}

export type Attachment = {
  __typename?: 'Attachment';
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  fileExtension?: Maybe<Scalars['String']['output']>;
  fileName: Scalars['String']['output'];
  fileSizeInBytes?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  publicId?: Maybe<Scalars['String']['output']>;
  putUrl?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  timeline?: Maybe<TimelineAction>;
  url: Scalars['String']['output'];
};

export type Auction = {
  __typename?: 'Auction';
  accountingSystem?: Maybe<AccountingSystem>;
  appraisalMessage?: Maybe<Scalars['String']['output']>;
  auctionAccessId?: Maybe<Scalars['Int']['output']>;
  auctionEdgeShortCode?: Maybe<Scalars['String']['output']>;
  auctionMessage?: Maybe<Scalars['String']['output']>;
  buyNowMessage?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  currencyCode?: Maybe<CurrencyCode>;
  description?: Maybe<Scalars['String']['output']>;
  featureFlagKeys: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lanes: Array<AuctionLane>;
  location?: Maybe<Location>;
  managingCompanies: Array<Company>;
  regionCodes: Array<Scalars['String']['output']>;
  representativeTypes: Array<AuctionRepresentativeType>;
  settings: AuctionSettings;
  status: AuctionStatus;
  timeZone?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: AuctionType;
  /**
   * Returns the upcoming time slots.
   * If no parameter is provided the default query looks for time slots where the cut off time is greater than or equals the current time.
   */
  upcomingTimeSlots: Array<AuctionTimeSlot>;
  updated: Scalars['DateTime']['output'];
  videoStreamConfig: VideoStreamConfig;
};

export type AuctionupcomingTimeSlotsArgs = {
  cutOffTimeGTE?: InputMaybe<Scalars['DateTime']['input']>;
  cutOffTimeLTE?: InputMaybe<Scalars['DateTime']['input']>;
  format?: InputMaybe<AuctionTimeSlotFormat>;
  startTimeGTE?: InputMaybe<Scalars['DateTime']['input']>;
  startTimeLTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AuctionAccessCompany = {
  __typename?: 'AuctionAccessCompany';
  companyAuctionAccessId: Scalars['Int']['output'];
  legalName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export enum AuctionAccessEntityType {
  COMPANY = 'COMPANY',
  USER = 'USER',
}

export type AuctionAccessIssue = {
  __typename?: 'AuctionAccessIssue';
  effectiveDate?: Maybe<Scalars['DateTime']['output']>;
  type: Scalars['String']['output'];
};

export type AuctionAccessIssueLog = {
  __typename?: 'AuctionAccessIssueLog';
  auctionAccessId?: Maybe<Scalars['Int']['output']>;
  auctionId?: Maybe<Scalars['String']['output']>;
  effectiveDate?: Maybe<Scalars['DateTime']['output']>;
  entity?: Maybe<AuctionAccessEntityType>;
  id?: Maybe<Scalars['String']['output']>;
  issueName?: Maybe<Scalars['String']['output']>;
  resolvedDate?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<AuctionAccessIssueStatus>;
  visibility?: Maybe<Scalars['String']['output']>;
};

export enum AuctionAccessIssueStatus {
  OPEN = 'OPEN',
  RESOLVED = 'RESOLVED',
}

export enum AuctionAccessStatus {
  APPROVED = 'APPROVED',
  BLOCKED = 'BLOCKED',
}

export type AuctionAccessValidateUserResponse = {
  __typename?: 'AuctionAccessValidateUserResponse';
  auctionAccessId: Scalars['Int']['output'];
  companies?: Maybe<Array<Maybe<AuctionAccessCompany>>>;
  firstName?: Maybe<Scalars['String']['output']>;
  issues?: Maybe<Array<Maybe<AuctionAccessValidationIssue>>>;
  lastName?: Maybe<Scalars['String']['output']>;
  validationStatus: ValidationStatus;
};

export type AuctionAccessValidationIssue = {
  __typename?: 'AuctionAccessValidationIssue';
  blockingRegistration: Scalars['Boolean']['output'];
  entityType?: Maybe<Scalars['String']['output']>;
  issueId: Scalars['Int']['output'];
  issueName?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type AuctionConnection = {
  __typename?: 'AuctionConnection';
  edges: Array<AuctionEdge>;
  facetGroups: Array<FacetGroup>;
  filterSections: Array<FilterSection>;
  pageInfo: FacetedPageInfo;
  sort: Array<SortOption>;
};

/** A connection to a list of items. */
export type AuctionConnectionfacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type AuctionCreateInput = {
  auctionOwnerIds: Array<Scalars['String']['input']>;
  currency: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  region: Scalars['String']['input'];
  status: AuctionStatus;
  timezone: Scalars['String']['input'];
  type: AuctionType;
};

export type AuctionEdge = {
  __typename?: 'AuctionEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: Auction;
};

export type AuctionEventTimelineEntry = {
  __typename?: 'AuctionEventTimelineEntry';
  auctionItemId?: Maybe<Scalars['String']['output']>;
  auctionTimeSlotLaneId: Scalars['String']['output'];
  audience: AuctionEventTimelineEntryAudience;
  created: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  message: Scalars['String']['output'];
  style: AuctionEventTimelineEntryStyle;
};

export enum AuctionEventTimelineEntryAudience {
  ALL = 'ALL',
  CUSTOMER = 'CUSTOMER',
  STAFF = 'STAFF',
}

export enum AuctionEventTimelineEntryStyle {
  NORMAL = 'NORMAL',
  STRONG = 'STRONG',
}

export enum AuctionFeeMode {
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
}

export type AuctionItem = {
  __typename?: 'AuctionItem';
  arbitrationRequest?: Maybe<ArbitrationRequest>;
  archived: Scalars['Boolean']['output'];
  asIs: Scalars['Boolean']['output'];
  auction: Auction;
  auctionServices: Array<AuctionService>;
  auctionTimeSlot?: Maybe<AuctionTimeSlot>;
  auctionTimeSlotLane?: Maybe<AuctionTimeSlotLane>;
  autoBids?: Maybe<AutoBids>;
  /** The time the timer should begin counting down and the reset amount when anti-snipe. */
  bidExtension: Scalars['Int']['output'];
  bidIncrement?: Maybe<MonetaryAmount>;
  /** Accessible by the buyer, seller and sales representatives. */
  bidTimeline?: Maybe<BidTimeline>;
  buyNowPrice?: Maybe<MonetaryAmount>;
  buyReleaseForm?: Maybe<Scalars['String']['output']>;
  buyer?: Maybe<Buyer>;
  buyerBidBadge?: Maybe<BidBadge>;
  buyerReps: Array<AuctionRepresentative>;
  canReverseInvoicesOnCancel: Scalars['Boolean']['output'];
  cancelled?: Maybe<Scalars['DateTime']['output']>;
  checkoutExpiry?: Maybe<Scalars['DateTime']['output']>;
  colorScheme?: Maybe<ColorSchemeEnum>;
  created: Scalars['DateTime']['output'];
  createdBy: User;
  current: Scalars['Boolean']['output'];
  deliveryDate?: Maybe<Scalars['Date']['output']>;
  displayRunNumber?: Maybe<Scalars['String']['output']>;
  dmvReg398Url?: Maybe<Scalars['String']['output']>;
  format: AuctionItemFormat;
  furtherBidIncrement?: Maybe<MonetaryAmount>;
  guaranteedAuctionPrice?: Maybe<MonetaryAmount>;
  hideSellerNameEnabled: Scalars['Boolean']['output'];
  history?: Maybe<AuctionItemHistory>;
  holdReason?: Maybe<HoldReason>;
  holdback?: Maybe<DifferenceAmount>;
  holdbackActive: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  ifBidClaimedByUserId?: Maybe<Scalars['String']['output']>;
  ifBidClaimedByUserName?: Maybe<Scalars['String']['output']>;
  /** Only accessible by the buyer, seller and sales representatives. */
  ifBidTimeline?: Maybe<IfBidTimeline>;
  inventoryItem: InventoryItem;
  invoices: Array<Invoice>;
  isAssured: Scalars['Boolean']['output'];
  isMyItem: Scalars['Boolean']['output'];
  listPrice?: Maybe<MonetaryAmount>;
  net?: Maybe<MonetaryAmount>;
  nextBidAmount?: Maybe<MonetaryAmount>;
  noSaleReason?: Maybe<NoSaleReason>;
  notes: Array<Note>;
  order?: Maybe<AuctionItemOrder>;
  potentialBuyers?: Maybe<Array<User>>;
  ranOn: Scalars['DateTime']['output'];
  redirectPath: Scalars['String']['output'];
  releaseBlockers: Array<ReleaseBlocker>;
  releaseStatus: ReleaseStatus;
  /** Only accessible by the seller and sales representatives. */
  reserve?: Maybe<MonetaryAmount>;
  reserveMet: Scalars['Boolean']['output'];
  reserveType: ReserveType;
  runNumber?: Maybe<Scalars['String']['output']>;
  saleLights: Array<SaleLight>;
  sellerIntent?: Maybe<SellerIntent>;
  /** Representatives of the seller where the item is listed */
  sellerReps: Array<AuctionRepresentative>;
  sellerTier?: Maybe<CompanyTier>;
  startingBid?: Maybe<MonetaryAmount>;
  status: AuctionItemStatus;
  /** The set of tasks on the item */
  tasks: Array<Task>;
  timedOfferTimeline?: Maybe<TimedOfferTimeline>;
  /** The set of timeline actions on the item */
  timelineActions: Array<TimelineAction>;
  timerEnd?: Maybe<Scalars['DateTime']['output']>;
  timerStart?: Maybe<Scalars['DateTime']['output']>;
  timerText?: Maybe<Scalars['String']['output']>;
  topOffer?: Maybe<TopOffer>;
  /** Only accessible by the buyer, seller and sales representatives. */
  transactionDetails?: Maybe<TransactionDetails>;
  transactionType: TransactionType;
  transportReleaseForm?: Maybe<Scalars['String']['output']>;
  updated: Scalars['DateTime']['output'];
  vehicleReleaseForm?: Maybe<Scalars['String']['output']>;
  viewers?: Maybe<Array<User>>;
  vinHistory?: Maybe<AuctionItemHistory>;
  /** Task related warnings highlighted for users attention */
  warnings: Array<Scalars['String']['output']>;
  watchers?: Maybe<Watchers>;
  workflowProcesses: Array<WorkflowProcess>;
};

export enum AuctionItemArbitrationStatus {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
}

export enum AuctionItemArbitrationSubStatus {
  INVALID_ARBITRATION = 'INVALID_ARBITRATION',
  RESOLVED_WITH_COMPENSATION = 'RESOLVED_WITH_COMPENSATION',
  SALE_CANCELLED = 'SALE_CANCELLED',
  SALE_STANDS = 'SALE_STANDS',
}

export type AuctionItemBulkCreateGroundedInput = {
  auctionId: Scalars['String']['input'];
};

export type AuctionItemBulkCreateInput = {
  /** Otherwise as is will be false or inherit previous value on reruns */
  asIsIfCause?: InputMaybe<Scalars['Boolean']['input']>;
  auctionTimeSlotId: Scalars['String']['input'];
  /** Will be added to reserve to create buy now price if format requires buy now price, if null and buy now price is optional, no buy now will be set */
  buyNowExtraAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Temporary hack until grounded uses timeslots, if not null, items will go to grounded */
  groundedInput?: InputMaybe<AuctionItemBulkCreateGroundedInput>;
  inventoryItemIds: Array<Scalars['String']['input']>;
  reserveType?: InputMaybe<ReserveType>;
  /** If the seller has made a counter offer that is lower then reserve price, use it instead */
  useSellerLowestCounterAsReserve?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AuctionItemBuyNowInput = {
  auctionItemId: Scalars['String']['input'];
  companyId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type AuctionItemComparableValuation = {
  __typename?: 'AuctionItemComparableValuation';
  price?: Maybe<Scalars['Float']['output']>;
  soldDate: Scalars['Date']['output'];
};

export type AuctionItemComparables = {
  __typename?: 'AuctionItemComparables';
  count: Scalars['Int']['output'];
  list: Array<AuctionItem>;
};

/** A connection to a list of items. */
export type AuctionItemConnection = {
  __typename?: 'AuctionItemConnection';
  edges: Array<AuctionItemEdge>;
  facetGroups: Array<FacetGroup>;
  filterSections: Array<FilterSection>;
  pageInfo: FacetedPageInfo;
  sort: Array<SortOption>;
};

/** A connection to a list of items. */
export type AuctionItemConnectionfacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type AuctionItemCreateAppraisalInput = {
  asIs: Scalars['Boolean']['input'];
  auctionId: Scalars['String']['input'];
  auctionLocationId?: InputMaybe<Scalars['String']['input']>;
  auctionTimeSlotId: Scalars['String']['input'];
  deliveryDate: Scalars['Date']['input'];
  saleLights?: InputMaybe<Array<SaleLight>>;
  startingAmount: Scalars['Float']['input'];
};

export type AuctionItemCreateAuctionInput = {
  asIs: Scalars['Boolean']['input'];
  auctionLocationId?: InputMaybe<Scalars['String']['input']>;
  auctionTimeSlotId: Scalars['String']['input'];
  reserveAmount?: InputMaybe<Scalars['Float']['input']>;
  reserveType?: InputMaybe<ReserveType>;
  saleLights?: InputMaybe<Array<SaleLight>>;
};

export type AuctionItemCreateGroundedInput = {
  asIs: Scalars['Boolean']['input'];
  auctionId: Scalars['String']['input'];
  auctionLocationId?: InputMaybe<Scalars['String']['input']>;
  auctionTimeSlotId: Scalars['String']['input'];
  buyNowAmount?: InputMaybe<Scalars['Float']['input']>;
  reserveAmount?: InputMaybe<Scalars['Float']['input']>;
  reserveType?: InputMaybe<ReserveType>;
  saleLights?: InputMaybe<Array<SaleLight>>;
};

export type AuctionItemCreateInput = {
  appraisalInput?: InputMaybe<AuctionItemCreateAppraisalInput>;
  auctionInput?: InputMaybe<AuctionItemCreateAuctionInput>;
  groundedInput?: InputMaybe<AuctionItemCreateGroundedInput>;
  physicalInput?: InputMaybe<AuctionItemCreatePhysicalInput>;
  timedOfferInput?: InputMaybe<AuctionItemCreateTimedOfferInput>;
};

export type AuctionItemCreatePhysicalInput = {
  asIs: Scalars['Boolean']['input'];
  auctionLocationId?: InputMaybe<Scalars['String']['input']>;
  auctionTimeSlotLaneId: Scalars['String']['input'];
  reserveAmount?: InputMaybe<Scalars['Float']['input']>;
  runNumber: Scalars['Int']['input'];
  saleLights?: InputMaybe<Array<InputMaybe<SaleLight>>>;
};

export type AuctionItemCreateTimedOfferInput = {
  asIs: Scalars['Boolean']['input'];
  auctionLocationId?: InputMaybe<Scalars['String']['input']>;
  auctionTimeSlotId: Scalars['String']['input'];
  buyNowAmount: Scalars['Float']['input'];
  saleLights?: InputMaybe<Array<SaleLight>>;
};

export type AuctionItemEdge = {
  __typename?: 'AuctionItemEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: AuctionItem;
};

export type AuctionItemFeesInput = {
  amount: Scalars['Float']['input'];
  feeMetadataExternalCode?: InputMaybe<Scalars['String']['input']>;
  feeMetadataId?: InputMaybe<Scalars['String']['input']>;
  party: AuctionServiceMetadataParty;
};

export enum AuctionItemFormat {
  APPRAISAL = 'APPRAISAL',
  AUCTION = 'AUCTION',
  AUCTION_PHYSICAL = 'AUCTION_PHYSICAL',
  GROUNDED = 'GROUNDED',
  TIMED_OFFER = 'TIMED_OFFER',
}

export type AuctionItemHistory = {
  __typename?: 'AuctionItemHistory';
  count: Scalars['Int']['output'];
  list: Array<AuctionItemRun>;
  topOffers?: Maybe<Array<TopOffer>>;
};

export type AuctionItemOrder = {
  __typename?: 'AuctionItemOrder';
  autoCheckedOut: Scalars['Boolean']['output'];
  buyerPickupLocation?: Maybe<Location>;
  checkedOut?: Maybe<Scalars['DateTime']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lineItems: Array<AuctionItemOrderLineItem>;
  paymentType: PaymentType;
  subtotal?: Maybe<MonetaryAmount>;
  taxes?: Maybe<MonetaryAmount>;
  total?: Maybe<MonetaryAmount>;
  totalBuyCredit?: Maybe<MonetaryAmount>;
};

export type AuctionItemOrderlineItemsArgs = {
  includeNotSelected?: InputMaybe<Scalars['Boolean']['input']>;
  party?: InputMaybe<AuctionServiceMetadataParty>;
};

export type AuctionItemOrderLineItem = {
  __typename?: 'AuctionItemOrderLineItem';
  amount?: Maybe<MonetaryAmount>;
  auctionService?: Maybe<AuctionService>;
  buyerTaxAmount?: Maybe<MonetaryAmount>;
  buyerTaxRate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  inventoryService?: Maybe<InventoryService>;
  /** True if the user may select/unselect the service on checkout */
  optional: Scalars['Boolean']['output'];
  selected: Scalars['Boolean']['output'];
  taxable: Scalars['Boolean']['output'];
};

export type AuctionItemOrderUpdateInput = {
  auctionItemOrderId: Scalars['ID']['input'];
  buyerPickupLocationId?: InputMaybe<Scalars['ID']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  paymentTypeId?: InputMaybe<Scalars['ID']['input']>;
};

export type AuctionItemOrdersPickupLocationUpdateInput = {
  auctionItemOrderIds: Array<Scalars['ID']['input']>;
  buyerPickupLocationId?: InputMaybe<Scalars['ID']['input']>;
};

export type AuctionItemOrdersResponse = {
  __typename?: 'AuctionItemOrdersResponse';
  auctionItems: Array<AuctionItem>;
  credit?: Maybe<MonetaryAmount>;
  subtotal?: Maybe<MonetaryAmount>;
  taxes?: Maybe<MonetaryAmount>;
  total?: Maybe<MonetaryAmount>;
  totalFeeTax?: Maybe<MonetaryAmount>;
  totalFees?: Maybe<MonetaryAmount>;
};

export enum AuctionItemPaymentStatus {
  NOT_RECEIVED = 'NOT_RECEIVED',
  NOT_SENT = 'NOT_SENT',
  RECEIVED = 'RECEIVED',
  SENT = 'SENT',
  SENT_NOT_RECEIVED = 'SENT_NOT_RECEIVED',
  SENT_RECEIVED = 'SENT_RECEIVED',
}

export type AuctionItemPhysicalEndInput = {
  auctionItemId: Scalars['String']['input'];
  bidBadgeId?: InputMaybe<Scalars['String']['input']>;
  overrideCreditLimit?: InputMaybe<Scalars['Boolean']['input']>;
  status: AuctionItemStatus;
};

export type AuctionItemPositionInput = {
  auctionTimeSlotLaneId?: InputMaybe<Scalars['String']['input']>;
  runNumber?: InputMaybe<Scalars['Int']['input']>;
};

export type AuctionItemPriceInput = {
  buyNowAmount?: InputMaybe<Scalars['Int']['input']>;
  reserveAmount?: InputMaybe<Scalars['Int']['input']>;
  reserveType?: InputMaybe<ReserveType>;
  startingAmount?: InputMaybe<Scalars['Int']['input']>;
};

export type AuctionItemPublic = {
  __typename?: 'AuctionItemPublic';
  asIs?: Maybe<Scalars['Boolean']['output']>;
  assured?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  soldAmount?: Maybe<MonetaryAmount>;
  soldDate?: Maybe<Scalars['DateTime']['output']>;
  vehicle: VehiclePublic;
};

/** A connection to a list of items. */
export type AuctionItemPublicConnection = {
  __typename?: 'AuctionItemPublicConnection';
  edges: Array<AuctionItemPublicEdge>;
  facetGroups: Array<FacetGroup>;
  filterSections: Array<FilterSection>;
  metadata: MarketGuideMetadata;
  pageInfo: FacetedPageInfo;
  sort: Array<SortOption>;
};

/** A connection to a list of items. */
export type AuctionItemPublicConnectionfacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type AuctionItemPublicEdge = {
  __typename?: 'AuctionItemPublicEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: AuctionItemPublic;
};

export type AuctionItemRun = {
  __typename?: 'AuctionItemRun';
  auction: Auction;
  buyNowPrice?: Maybe<MonetaryAmount>;
  format: AuctionItemFormat;
  id: Scalars['String']['output'];
  ranOn: Scalars['DateTime']['output'];
  reserve?: Maybe<MonetaryAmount>;
  reserveMet: Scalars['Boolean']['output'];
  status: AuctionItemStatus;
  topOffer?: Maybe<TopOffer>;
  totalBids: Scalars['Int']['output'];
  totalOffers?: Maybe<Scalars['Int']['output']>;
  transactionNumber?: Maybe<Scalars['String']['output']>;
};

export type AuctionItemSaleLightsInput = {
  clearAutoBids?: InputMaybe<Scalars['Boolean']['input']>;
  saleLights: Array<SaleLight>;
};

export type AuctionItemSavedFilter = SavedFilter & {
  __typename?: 'AuctionItemSavedFilter';
  auctionLocationName?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  auctionTitle?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  bodyType?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  chargingCable?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  created: Scalars['DateTime']['output'];
  defaultFilter: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  distance?: Maybe<Scalars['Int']['output']>;
  driveTrain?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  exteriorColor?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  formats?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fuelType?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id: Scalars['String']['output'];
  makes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  mileageGTE?: Maybe<Scalars['Int']['output']>;
  mileageLTE?: Maybe<Scalars['Int']['output']>;
  models?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name: Scalars['String']['output'];
  options?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  region?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  reservePriceGTE?: Maybe<Scalars['BigDecimal']['output']>;
  reservePriceLTE?: Maybe<Scalars['BigDecimal']['output']>;
  startingBidGTE?: Maybe<Scalars['BigDecimal']['output']>;
  startingBidLTE?: Maybe<Scalars['BigDecimal']['output']>;
  subModels?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  transmission?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  updated: Scalars['DateTime']['output'];
  vehicleScoreGTE?: Maybe<Scalars['BigDecimal']['output']>;
  vehicleScoreLTE?: Maybe<Scalars['BigDecimal']['output']>;
  yearGTE?: Maybe<Scalars['Int']['output']>;
  yearLTE?: Maybe<Scalars['Int']['output']>;
};

export type AuctionItemSavedFilterInput = {
  auctionLocationName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  auctionTitle?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bodyType?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  chargingCable?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateRanGTE?: InputMaybe<Scalars['DateTime']['input']>;
  dateRanLTE?: InputMaybe<Scalars['DateTime']['input']>;
  defaultFilter?: InputMaybe<Scalars['Boolean']['input']>;
  distance?: InputMaybe<Scalars['Int']['input']>;
  driveTrain?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  exteriorColor?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formats?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fuelType?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  makes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mileageGTE?: InputMaybe<Scalars['Int']['input']>;
  mileageLTE?: InputMaybe<Scalars['Int']['input']>;
  models?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  region?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reservePriceGTE?: InputMaybe<Scalars['BigDecimal']['input']>;
  reservePriceLTE?: InputMaybe<Scalars['BigDecimal']['input']>;
  startingBidGTE?: InputMaybe<Scalars['BigDecimal']['input']>;
  startingBidLTE?: InputMaybe<Scalars['BigDecimal']['input']>;
  subModels?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  transmission?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vehicleScoreGTE?: InputMaybe<Scalars['BigDecimal']['input']>;
  vehicleScoreLTE?: InputMaybe<Scalars['BigDecimal']['input']>;
  yearGTE?: InputMaybe<Scalars['Int']['input']>;
  yearLTE?: InputMaybe<Scalars['Int']['input']>;
};

export enum AuctionItemStatus {
  AWAITING_CHECKOUT = 'AWAITING_CHECKOUT',
  FAILED = 'FAILED',
  INIT = 'INIT',
  IN_IF_BID = 'IN_IF_BID',
  LIVE = 'LIVE',
  NO_SALE = 'NO_SALE',
  PAUSED = 'PAUSED',
  PENDING_DELIVERY = 'PENDING_DELIVERY',
  SALE_CANCELLED = 'SALE_CANCELLED',
  SOLD = 'SOLD',
  UPCOMING = 'UPCOMING',
}

export type AuctionItemUpdateHoldInput = {
  auctionItemId: Scalars['String']['input'];
  hold: Scalars['Boolean']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  /** required if hold is true */
  reason?: InputMaybe<HoldReason>;
};

export type AuctionItemUpdateInput = {
  auctionItemId: Scalars['String']['input'];
  fees?: InputMaybe<Array<AuctionItemFeesInput>>;
  position?: InputMaybe<AuctionItemPositionInput>;
  /** One of prices or saleLights or both required */
  prices?: InputMaybe<AuctionItemPriceInput>;
  saleLightsInput?: InputMaybe<AuctionItemSaleLightsInput>;
};

export enum AuctionItemViewName {
  BROWSE = 'BROWSE',
}

export type AuctionLane = {
  __typename?: 'AuctionLane';
  archived: Scalars['Boolean']['output'];
  auctionId: Scalars['String']['output'];
  bidIncrements: Array<AuctionLaneBidIncrement>;
  controlType: AuctionLaneControlType;
  defaultActivated: Scalars['Boolean']['output'];
  defaultDescription: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: AuctionLaneType;
};

export type AuctionLaneBidIncrement = {
  __typename?: 'AuctionLaneBidIncrement';
  defaultSelected: Scalars['Boolean']['output'];
  increment: Scalars['Float']['output'];
};

export type AuctionLaneBidIncrementInput = {
  defaultSelected?: Scalars['Boolean']['input'];
  increment: Scalars['Float']['input'];
};

export enum AuctionLaneControlType {
  AUTOMATED = 'AUTOMATED',
  MANUAL = 'MANUAL',
}

export type AuctionLaneReservation = {
  __typename?: 'AuctionLaneReservation';
  active: Scalars['Boolean']['output'];
  auctionLane?: Maybe<AuctionLane>;
  auctionTimeSlotLane?: Maybe<AuctionTimeSlotLane>;
  company: Company;
  global: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  runNumberEnd: Scalars['Int']['output'];
  runNumberStart: Scalars['Int']['output'];
};

export type AuctionLaneReservationCreateInput = {
  auctionTimeSlotLaneId: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  global: Scalars['Boolean']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  runNumberEnd: Scalars['Int']['input'];
  runNumberStart: Scalars['Int']['input'];
};

export type AuctionLaneReservationUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  auctionLaneReservationId: Scalars['ID']['input'];
  auctionTimeSlotLaneId?: InputMaybe<Scalars['String']['input']>;
  global?: InputMaybe<Scalars['Boolean']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  runNumberEnd?: InputMaybe<Scalars['Int']['input']>;
  runNumberStart?: InputMaybe<Scalars['Int']['input']>;
};

export enum AuctionLaneType {
  DIGITAL = 'DIGITAL',
  PHYSICAL = 'PHYSICAL',
}

export type AuctionRepresentative = {
  __typename?: 'AuctionRepresentative';
  auction?: Maybe<Auction>;
  company: Company;
  representativeType?: Maybe<AuctionRepresentativeType>;
  user: User;
};

export type AuctionRepresentativeType = {
  __typename?: 'AuctionRepresentativeType';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type AuctionService = Fee & {
  __typename?: 'AuctionService';
  active: Scalars['Boolean']['output'];
  buyerShield?: Maybe<AuctionServiceTriState>;
  companySubTypes?: Maybe<Array<Maybe<CompanySubType>>>;
  created: Scalars['DateTime']['output'];
  feeFixedMode?: Maybe<AuctionFeeMode>;
  feeFixedValue?: Maybe<Scalars['BigDecimal']['output']>;
  feeTiers?: Maybe<Array<Maybe<AuctionServiceFeeTier>>>;
  feeType: AuctionServiceType;
  flagged: Scalars['Boolean']['output'];
  format?: Maybe<AuctionItemFormat>;
  id: Scalars['String']['output'];
  paidBy?: Maybe<AuctionServiceMetadataParty>;
  serviceMetadata: AuctionServiceMetadata;
  updated: Scalars['DateTime']['output'];
  verified?: Maybe<AuctionServiceTriState>;
};

/** A connection to a list of items. */
export type AuctionServiceConnection = {
  __typename?: 'AuctionServiceConnection';
  edges: Array<AuctionServiceEdge>;
  facetGroups: Array<FacetGroup>;
  filterSections: Array<FilterSection>;
  pageInfo: FacetedPageInfo;
  sort: Array<SortOption>;
};

/** A connection to a list of items. */
export type AuctionServiceConnectionfacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type AuctionServiceCreateInput = {
  auctionServiceMetadataId: Scalars['String']['input'];
  buyerShield?: InputMaybe<AuctionServiceTriState>;
  /** Non-null if auction service is created for a particular company. */
  companyId?: InputMaybe<Scalars['String']['input']>;
  companySubTypes?: InputMaybe<Array<CompanySubType>>;
  /** Cannot be null or less than zero when creating a fee of FIXED type */
  feeFixedMode?: InputMaybe<AuctionFeeMode>;
  /** Cannot be null when creating a fee of FIXED type */
  feeFixedValue?: InputMaybe<Scalars['BigDecimal']['input']>;
  /** Cannot be null when creating a fee of TIERED type */
  feeTiers?: InputMaybe<Array<AuctionServiceTierInput>>;
  feeType: AuctionServiceType;
  format: AuctionItemFormat;
  paidBy?: InputMaybe<AuctionServiceMetadataParty>;
  verified?: InputMaybe<AuctionServiceTriState>;
};

export type AuctionServiceDefault = {
  __typename?: 'AuctionServiceDefault';
  auctionServiceMetadata: AuctionServiceMetadata;
  defaultSelected: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
};

export type AuctionServiceDefaultSetInput = {
  auctionServiceMetadataId: Scalars['ID']['input'];
  defaultSelected: Scalars['Boolean']['input'];
};

export type AuctionServiceEdge = {
  __typename?: 'AuctionServiceEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: AuctionService;
};

export type AuctionServiceFeeTier = {
  __typename?: 'AuctionServiceFeeTier';
  /** Last tier must always end in null endPrice */
  endPrice?: Maybe<Scalars['BigDecimal']['output']>;
  id: Scalars['String']['output'];
  mode: AuctionFeeMode;
  /** First tier must always have 0.00 as startPrice */
  startPrice?: Maybe<Scalars['BigDecimal']['output']>;
  /** Cannot be null or negative */
  value: Scalars['BigDecimal']['output'];
};

/** Service Metadata used for inventory and auction fees */
export type AuctionServiceMetadata = {
  __typename?: 'AuctionServiceMetadata';
  accountingSystemId?: Maybe<Scalars['String']['output']>;
  active: Scalars['Boolean']['output'];
  auction?: Maybe<Auction>;
  createsTask: Scalars['Boolean']['output'];
  defaultSelected: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  feeName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  logicalName?: Maybe<AuctionServiceMetadataLogicalName>;
  optional: Scalars['Boolean']['output'];
  ordering: Scalars['Int']['output'];
  party?: Maybe<AuctionServiceMetadataParty>;
  serviceName: Scalars['String']['output'];
  /** True if the user must specify pickup location for vehicle */
  transportService: Scalars['Boolean']['output'];
};

export enum AuctionServiceMetadataDefaultType {
  BOTH = 'BOTH',
  MANDATORY = 'MANDATORY',
  OPTIONAL = 'OPTIONAL',
}

export enum AuctionServiceMetadataLogicalName {
  ABS_FLOORING = 'ABS_FLOORING',
  ABS_INSPECTION_GUARANTEE = 'ABS_INSPECTION_GUARANTEE',
  AS_IS_SELL = 'AS_IS_SELL',
  BUYER_PROTECTION = 'BUYER_PROTECTION',
  DATA_CAPTURE = 'DATA_CAPTURE',
  OTHER = 'OTHER',
  PSI = 'PSI',
  PSI_ONLINE = 'PSI_ONLINE',
  REGISTRATION_TRANSFER = 'REGISTRATION_TRANSFER',
  REGISTRATION_TRANSFER_BC = 'REGISTRATION_TRANSFER_BC',
  SALE_ASSURANCE = 'SALE_ASSURANCE',
  SELL = 'SELL',
  TRANSPORT = 'TRANSPORT',
  TRANSPORT_CITY_DISTANCE = 'TRANSPORT_CITY_DISTANCE',
  TRANSPORT_COMPOUND = 'TRANSPORT_COMPOUND',
  VHR = 'VHR',
  VHR_BC = 'VHR_BC',
}

export enum AuctionServiceMetadataParty {
  BUYER = 'BUYER',
  SELLER = 'SELLER',
}

export type AuctionServiceTierInput = {
  endPrice?: InputMaybe<Scalars['BigDecimal']['input']>;
  mode: AuctionFeeMode;
  startPrice?: InputMaybe<Scalars['BigDecimal']['input']>;
  value?: InputMaybe<Scalars['BigDecimal']['input']>;
};

export enum AuctionServiceTriState {
  BOTH = 'BOTH',
  FALSE = 'FALSE',
  TRUE = 'TRUE',
}

export enum AuctionServiceType {
  FIXED = 'FIXED',
  TIERED = 'TIERED',
}

export type AuctionSettings = {
  __typename?: 'AuctionSettings';
  appraisalAuctionSettings: TimedAuctionSettings;
  arbitrationEmail?: Maybe<Scalars['String']['output']>;
  asIsEligibility: AsIsEligibility;
  assuranceEnabled: Scalars['Boolean']['output'];
  billOfSaleTemplate: BillOfSaleTemplate;
  blackBookEnabled: Scalars['Boolean']['output'];
  checkoutEnabled: Scalars['Boolean']['output'];
  dealerArbitrationEnabled: Scalars['Boolean']['output'];
  groundedAuctionSettings: TimedAuctionSettings;
  internalSaleEnabled: Scalars['Boolean']['output'];
  liveAuctionSettings: LiveAuctionSettings;
  mmrEnabled: Scalars['Boolean']['output'];
  remitRvTaxEnabled: Scalars['Boolean']['output'];
  requireAuctionLocation?: Maybe<Scalars['Boolean']['output']>;
  requireExtensiveVehicleCondition?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Sale lights available for an auction,
   * NULL indicates use EBlock RED,YELLOW,GREEN automation
   * Empty list indicates sale lights disabled
   * Non empty list indicates the lights available for manual selection
   */
  saleLightsOverride?: Maybe<Array<SaleLight>>;
  supportsReg398: Scalars['Boolean']['output'];
  timedOfferAuctionSettings: TimedOfferAuctionSettings;
};

export type AuctionSettingsUpdateInput = {
  appraisalAuctionSettings?: InputMaybe<TimedAuctionSettingsInput>;
  arbitrationEmail?: InputMaybe<Scalars['String']['input']>;
  asIsEligibility?: InputMaybe<AsIsEligibility>;
  assuranceEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  auctionId: Scalars['ID']['input'];
  billOfSaleTemplate?: InputMaybe<BillOfSaleTemplate>;
  blackBookEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  checkoutEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  dealerArbitrationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  groundedAuctionSettings?: InputMaybe<TimedAuctionSettingsInput>;
  internalSaleEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  liveAuctionSettings?: InputMaybe<LiveAuctionSettingsInput>;
  mmrEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  remitRvTaxEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  supportsReg398?: InputMaybe<Scalars['Boolean']['input']>;
  timedOfferAuctionSettings?: InputMaybe<TimedOfferAuctionSettingsInput>;
};

export enum AuctionStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type AuctionTimeSlot = {
  __typename?: 'AuctionTimeSlot';
  auction?: Maybe<Auction>;
  auctionTimeSlotLanes: Array<AuctionTimeSlotLane>;
  auctionTimeSlotStats?: Maybe<AuctionTimeSlotStats>;
  cutOffTime?: Maybe<Scalars['DateTime']['output']>;
  eventType: Scalars['String']['output'];
  finishTime?: Maybe<Scalars['DateTime']['output']>;
  format: AuctionTimeSlotFormat;
  hasUnassignedItems: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  itemCount?: Maybe<Scalars['Int']['output']>;
  notes: Array<Note>;
  startTime: Scalars['DateTime']['output'];
  status: AuctionTimeSlotStatus;
  uniqueCompaniesListing?: Maybe<Array<Company>>;
};

export type AuctionTimeSlotauctionTimeSlotLanesArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AuctionTimeSlotauctionTimeSlotStatsArgs = {
  dateGTE?: InputMaybe<Scalars['DateTime']['input']>;
  dateLTE?: InputMaybe<Scalars['DateTime']['input']>;
};

/** A connection to a list of items. */
export type AuctionTimeSlotConnection = {
  __typename?: 'AuctionTimeSlotConnection';
  edges: Array<AuctionTimeSlotEdge>;
  facetGroups: Array<FacetGroup>;
  filterSections: Array<FilterSection>;
  pageInfo: FacetedPageInfo;
  sort: Array<SortOption>;
};

/** A connection to a list of items. */
export type AuctionTimeSlotConnectionfacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type AuctionTimeSlotEdge = {
  __typename?: 'AuctionTimeSlotEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: AuctionTimeSlot;
};

export enum AuctionTimeSlotFormat {
  APPRAISAL = 'APPRAISAL',
  AUCTION = 'AUCTION',
  GROUNDED = 'GROUNDED',
  TIMED_OFFER = 'TIMED_OFFER',
}

export type AuctionTimeSlotLane = {
  __typename?: 'AuctionTimeSlotLane';
  active: Scalars['Boolean']['output'];
  assignedItemsCount?: Maybe<Scalars['Int']['output']>;
  auctionClerk?: Maybe<User>;
  auctionLaneId?: Maybe<Scalars['String']['output']>;
  auctionTimeSlot?: Maybe<AuctionTimeSlot>;
  auctionTimeSlotId?: Maybe<Scalars['String']['output']>;
  bidIncrements: Array<AuctionLaneBidIncrement>;
  controlType: AuctionLaneControlType;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  itemsRanCount?: Maybe<Scalars['Int']['output']>;
  itemsRemainingCount?: Maybe<Scalars['Int']['output']>;
  liveItem?: Maybe<AuctionItem>;
  name?: Maybe<Scalars['String']['output']>;
  openRunNumbers: Array<RunNumber>;
  startRunNumber: Scalars['Int']['output'];
  status: LiveLaneStatus;
  type: AuctionLaneType;
  unassignedItemsCount?: Maybe<Scalars['Int']['output']>;
  /** AuctionItems which are upcoming. */
  upcoming: Array<AuctionItem>;
};

export type AuctionTimeSlotLaneopenRunNumbersArgs = {
  companyId?: InputMaybe<Scalars['String']['input']>;
};

export type AuctionTimeSlotLaneCreateInput = {
  active?: Scalars['Boolean']['input'];
  auctionTimeSlotId: Scalars['String']['input'];
  controlType: AuctionLaneControlType;
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  startingRunNumber?: Scalars['Int']['input'];
  type: AuctionLaneType;
};

export type AuctionTimeSlotStats = {
  __typename?: 'AuctionTimeSlotStats';
  numOfListedItems?: Maybe<Scalars['Int']['output']>;
  numOfSoldItems?: Maybe<Scalars['Int']['output']>;
  percentageOfItemsSold?: Maybe<Scalars['Float']['output']>;
};

export enum AuctionTimeSlotStatus {
  END = 'END',
  INIT = 'INIT',
  RUNNING = 'RUNNING',
  WAITING = 'WAITING',
}

export enum AuctionType {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export type AuctionUpdateInput = {
  auctionId: Scalars['ID']['input'];
  auctionOwnerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  currency?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AuctionStatus>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AuctionType>;
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  /** Will be null if verificationCodeRequired is true */
  token?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use userInfo instead */
  user: User;
  userInfo: AuthUserResponse;
  verificationCodeRequired: Scalars['Boolean']['output'];
};

export type AuthUserResponse = {
  __typename?: 'AuthUserResponse';
  cellPhoneVerified: Scalars['Boolean']['output'];
  emailVerified: Scalars['Boolean']['output'];
  locale?: Maybe<Scalars['String']['output']>;
  maskedCellphone?: Maybe<Scalars['String']['output']>;
  maskedEmail: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type AuthVerificationResponse = {
  __typename?: 'AuthVerificationResponse';
  token: Scalars['String']['output'];
  user: User;
};

export type AutoBid = {
  __typename?: 'AutoBid';
  actionBy?: Maybe<User>;
  active: Scalars['Boolean']['output'];
  company: Company;
  created: Scalars['DateTime']['output'];
  createdBy: User;
  id: Scalars['String']['output'];
  maxBid?: Maybe<MonetaryAmount>;
};

export type AutoBids = {
  __typename?: 'AutoBids';
  /** Amount apart based on the auto-bids alone. Only be accessible by sales representatives. */
  amountApart?: Maybe<MonetaryAmount>;
  /** Only be accessible by sales representatives. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Only be accessible by sales representatives. */
  list?: Maybe<Array<AutoBid>>;
  /** Only be accessible by the buyer. */
  myAutoBid?: Maybe<AutoBid>;
  /** Only be accessible by sales representatives. */
  reserveMet?: Maybe<Scalars['Boolean']['output']>;
  /** Only be accessible by sales representatives. */
  startingBid?: Maybe<MonetaryAmount>;
  /** Only be accessible by sales representatives. */
  topAutoBid?: Maybe<AutoBid>;
};

export type AutoBidslistArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type AutoGradeDamageCodeRecord = {
  __typename?: 'AutoGradeDamageCodeRecord';
  damageCode: Scalars['Int']['output'];
  damageName: Scalars['String']['output'];
  disabled: Scalars['Boolean']['output'];
  frame: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  itemCode: Scalars['Int']['output'];
  itemName: Scalars['String']['output'];
  sectionName: Scalars['String']['output'];
  sectionNumber: Scalars['Int']['output'];
  severityCode: Scalars['Int']['output'];
  severityName: Scalars['String']['output'];
  sortingNumber: Scalars['Int']['output'];
};

export type AutoGradePhotoInputObject = {
  /** Special field for reusing an existing photo. */
  existingCDNId?: InputMaybe<Scalars['String']['input']>;
  /** For reusing an existing photo */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Base64 encoded photo data. */
  photo?: InputMaybe<Scalars['String']['input']>;
  /** Special field for reusing an existing photo. */
  photoSource?: InputMaybe<Scalars['String']['input']>;
  /** Photo number */
  shotCode?: InputMaybe<Scalars['Int']['input']>;
};

export type AutoGradeScoreData = {
  __typename?: 'AutoGradeScoreData';
  autoGradeVehicleDamage?: Maybe<Array<AutoGradeVehicleDamage>>;
  autoGradeVehicleDamageCount: Scalars['Int']['output'];
};

export type AutoGradeSection = {
  __typename?: 'AutoGradeSection';
  items: Array<AutoGradeSectionItem>;
  name: Scalars['String']['output'];
  number: Scalars['Int']['output'];
};

export type AutoGradeSectionitemsArgs = {
  codes?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type AutoGradeSectionItem = {
  __typename?: 'AutoGradeSectionItem';
  code: Scalars['Int']['output'];
  damages: Array<AutoGradeSectionItemDamage>;
  name: Scalars['String']['output'];
};

export type AutoGradeSectionItemdamagesArgs = {
  codes?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type AutoGradeSectionItemDamage = {
  __typename?: 'AutoGradeSectionItemDamage';
  code: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  severities: Array<AutoGradeSectionItemDamageSeverity>;
};

export type AutoGradeSectionItemDamageseveritiesArgs = {
  codes?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type AutoGradeSectionItemDamageSeverity = {
  __typename?: 'AutoGradeSectionItemDamageSeverity';
  code: Scalars['Int']['output'];
  frame: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type AutoGradeVehicleDamage = {
  __typename?: 'AutoGradeVehicleDamage';
  damageCodeRecord: AutoGradeDamageCodeRecord;
  photos?: Maybe<Array<Photo>>;
  vehicleRepairItem?: Maybe<VehicleRepairItem>;
};

export type AutoGradeVehicleDamageInput = {
  damageCodeId: Scalars['Int']['input'];
  photos?: InputMaybe<Array<AutoGradePhotoInputObject>>;
};

export type AutocheckException = {
  __typename?: 'AutocheckException';
  date?: Maybe<Scalars['Date']['output']>;
  description: Scalars['String']['output'];
  stateCode?: Maybe<Scalars['String']['output']>;
};

export type BankDetailsDto = {
  __typename?: 'BankDetailsDto';
  accountNumber: Scalars['String']['output'];
  bankNumber: Scalars['String']['output'];
  branchTransitNumber: Scalars['String']['output'];
};

export type BankDetailsInput = {
  accountNumber: Scalars['String']['input'];
  bankNumber: Scalars['String']['input'];
  branchTransitNumber: Scalars['String']['input'];
};

export type BasicFilter = Filter & {
  __typename?: 'BasicFilter';
  id: Scalars['String']['output'];
  localizedName: Scalars['String']['output'];
  multiselect: Scalars['Boolean']['output'];
  selections: Array<FilterSelection>;
};

export type Bid = {
  __typename?: 'Bid';
  actionBy?: Maybe<User>;
  amount: MonetaryAmount;
  company?: Maybe<Company>;
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  id: Scalars['String']['output'];
  retracted: Scalars['Boolean']['output'];
  type: BidType;
};

export type BidBadge = {
  __typename?: 'BidBadge';
  auctionTimeSlot: AuctionTimeSlot;
  bidBadgeNumber: Scalars['String']['output'];
  id: Scalars['String']['output'];
  issueDate: Scalars['DateTime']['output'];
  userCompanyRelationship: UserCompanyRelationship;
};

export type BidBadgeDeleteInput = {
  auctionTimeSlotId: Scalars['String']['input'];
  bidBadgeNumber: Scalars['String']['input'];
};

export type BidTimeline = {
  __typename?: 'BidTimeline';
  /** Only accessible by the seller and sales representatives. */
  amountApart?: Maybe<MonetaryAmount>;
  count: Scalars['Int']['output'];
  list: Array<Bid>;
  /** True if you are outbid. */
  outbid: Scalars['Boolean']['output'];
  /** Was the reserve met during live bidding. Only accessible by the seller and sales representatives. */
  reserveMet: Scalars['Boolean']['output'];
  uniqueBidders: Scalars['Int']['output'];
  /** True if you are winning the item. */
  winning: Scalars['Boolean']['output'];
};

export type BidTimelinelistArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
};

export enum BidType {
  AUTO_BID = 'AUTO_BID',
  BUY_NOW = 'BUY_NOW',
  FLOOR = 'FLOOR',
  ONLINE = 'ONLINE',
}

export enum BillOfSaleTemplate {
  AAA365 = 'AAA365',
  EBLOCK_CA = 'EBLOCK_CA',
  EBLOCK_US = 'EBLOCK_US',
  MAG_NOW = 'MAG_NOW',
}

export type BlackBookValueUpdateInput = {
  auctionId?: InputMaybe<Scalars['ID']['input']>;
  inventoryItemId: Scalars['ID']['input'];
};

export type BlueDriverCode = {
  __typename?: 'BlueDriverCode';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  system?: Maybe<Scalars['String']['output']>;
  type?: Maybe<BlueDriverCodeScanEventType>;
};

export enum BlueDriverCodeScanEventType {
  Confirmed = 'Confirmed',
  Enhanced = 'Enhanced',
  Pending = 'Pending',
  Permanent = 'Permanent',
}

export type BlueDriverScanData = {
  __typename?: 'BlueDriverScanData';
  codeCount?: Maybe<Scalars['Int']['output']>;
  codeScanRequestStatus: BlueDriverScanRequestStatus;
  codes?: Maybe<Array<BlueDriverCode>>;
  smogScan?: Maybe<BlueDriverSmogScan>;
  smogScanRequestStatus: BlueDriverScanRequestStatus;
};

export enum BlueDriverScanRequestStatus {
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
  NOT_REQUESTED = 'NOT_REQUESTED',
  PENDING = 'PENDING',
}

export enum BlueDriverScanType {
  CODE = 'CODE',
  SMOG = 'SMOG',
}

export type BlueDriverSmogScan = {
  __typename?: 'BlueDriverSmogScan';
  checkEngineLights?: Maybe<Scalars['Boolean']['output']>;
  completeMonitors?: Maybe<Array<Scalars['String']['output']>>;
  confirmedCodesNumber?: Maybe<Scalars['Int']['output']>;
  incompleteMonitors?: Maybe<Array<Scalars['String']['output']>>;
  pendingCodesNumber?: Maybe<Scalars['Int']['output']>;
  result: BlueDriverSmogScanResult;
};

export enum BlueDriverSmogScanResult {
  Fail = 'Fail',
  Pass = 'Pass',
  Warning = 'Warning',
}

export type Broadcast = {
  __typename?: 'Broadcast';
  auction?: Maybe<Auction>;
  end: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  regionCodes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  start: Scalars['DateTime']['output'];
  status: BroadcastStatus;
  type: BroadcastType;
};

/** A connection to a list of items. */
export type BroadcastConnection = {
  __typename?: 'BroadcastConnection';
  edges: Array<BroadcastEdge>;
  facetGroups: Array<FacetGroup>;
  filterSections: Array<FilterSection>;
  pageInfo: FacetedPageInfo;
  sort: Array<SortOption>;
};

/** A connection to a list of items. */
export type BroadcastConnectionfacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type BroadcastCreateInput = {
  auctionId?: InputMaybe<Scalars['ID']['input']>;
  end: Scalars['DateTime']['input'];
  message: Scalars['String']['input'];
  regionCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  start: Scalars['DateTime']['input'];
  type: BroadcastType;
};

export type BroadcastEdge = {
  __typename?: 'BroadcastEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: Broadcast;
};

export enum BroadcastStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  ENDED = 'ENDED',
  UPCOMING = 'UPCOMING',
}

export enum BroadcastType {
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARN = 'WARN',
}

export type BroadcastUpdateInput = {
  broadcastId: Scalars['ID']['input'];
  end?: InputMaybe<Scalars['DateTime']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  regionCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<BroadcastType>;
};

export type BuilderOption = {
  __typename?: 'BuilderOption';
  extraInfo?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  selected: Scalars['Boolean']['output'];
  value: Scalars['String']['output'];
};

export type BuilderOptionInt = {
  __typename?: 'BuilderOptionInt';
  extraInfo?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  selected: Scalars['Boolean']['output'];
  value: Scalars['String']['output'];
};

export type BuilderOptionLocation = {
  __typename?: 'BuilderOptionLocation';
  id: Scalars['String']['output'];
  location: Location;
  selected: Scalars['Boolean']['output'];
};

export type BuilderType = VehicleBuilder;

export type BuilderValue = {
  __typename?: 'BuilderValue';
  id: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Buyer = {
  __typename?: 'Buyer';
  company?: Maybe<Company>;
  user?: Maybe<User>;
};

export type CarfaxAnnouncement = {
  __typename?: 'CarfaxAnnouncement';
  detail?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  type: CarfaxAnnouncementType;
};

export enum CarfaxAnnouncementType {
  ADDITIONAL_DISCLOSURE = 'ADDITIONAL_DISCLOSURE',
  DAMAGE_CLAIM = 'DAMAGE_CLAIM',
  JURISDICTION = 'JURISDICTION',
}

export type CarfaxAnnouncements = {
  __typename?: 'CarfaxAnnouncements';
  announcements?: Maybe<Array<CarfaxAnnouncement>>;
  reportStatus: CarfaxReportStatus;
  reportUrl?: Maybe<Scalars['String']['output']>;
};

export enum CarfaxReportStatus {
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  NO_MVDA_REPORT = 'NO_MVDA_REPORT',
  PENDING = 'PENDING',
  VALID = 'VALID',
}

export type CarfaxUSAReportResponse = {
  __typename?: 'CarfaxUSAReportResponse';
  reportUrl?: Maybe<Scalars['String']['output']>;
  requiresAuthentication: Scalars['Boolean']['output'];
};

export type Carrier = {
  __typename?: 'Carrier';
  archived: Scalars['Boolean']['output'];
  auctionRelationships?: Maybe<CompanyAuctionRelationshipList>;
  /** Could be deprecated in favor of Company.contacts as mutations are on company so this isn't all that useful */
  contacts: Array<Contact>;
  created: Scalars['DateTime']['output'];
  deliveryIsLocalOnly: Scalars['Boolean']['output'];
  deliveryNotes?: Maybe<Scalars['String']['output']>;
  deliveryWhitelistedRegions: Array<OperatingRegion>;
  id: Scalars['String']['output'];
  insuranceExpiry?: Maybe<Scalars['DateTime']['output']>;
  locations: Array<Location>;
  name: Scalars['String']['output'];
  notes: Array<Note>;
  primaryLocation?: Maybe<Location>;
  proofOfInsurance?: Maybe<Attachment>;
  sdCarrierGuid?: Maybe<Scalars['ID']['output']>;
  shipmentModes: Array<CarrierShipmentMode>;
  updated: Scalars['DateTime']['output'];
};

export type CarriercontactsArgs = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  primary?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<Array<ContactRole>>;
};

/** A connection to a list of items. */
export type CarrierConnection = {
  __typename?: 'CarrierConnection';
  edges: Array<CarrierEdge>;
  facetGroups: Array<FacetGroup>;
  filterSections: Array<FilterSection>;
  pageInfo: FacetedPageInfo;
  sort: Array<SortOption>;
};

/** A connection to a list of items. */
export type CarrierConnectionfacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CarrierEdge = {
  __typename?: 'CarrierEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: Carrier;
};

export enum CarrierShipmentMode {
  DRIVE_AWAY = 'DRIVE_AWAY',
  FLAT_BED = 'FLAT_BED',
  HAULER = 'HAULER',
  RAIL = 'RAIL',
  TOW = 'TOW',
}

export enum ChargeCode {
  ARBITRATION_POLICY_LOSS = 'ARBITRATION_POLICY_LOSS',
  ASSURANCE_POLICY_LOSS = 'ASSURANCE_POLICY_LOSS',
  BOS = 'BOS',
  BUYER = 'BUYER',
  DRY_RUN_BUYER = 'DRY_RUN_BUYER',
  DRY_RUN_SELLER = 'DRY_RUN_SELLER',
  FUEL_BUYER = 'FUEL_BUYER',
  FUEL_SELLER = 'FUEL_SELLER',
  GOODWILL = 'GOODWILL',
  INHERITED_POLICY_LOSS = 'INHERITED_POLICY_LOSS',
  OPERATIONS = 'OPERATIONS',
  SALES_PROMO = 'SALES_PROMO',
  SELLER = 'SELLER',
  TRANSPORT = 'TRANSPORT',
}

export type ChargeInstruction = {
  __typename?: 'ChargeInstruction';
  amount?: Maybe<MonetaryAmount>;
  code?: Maybe<ChargeCode>;
  comment: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  createdBy: User;
  id: Scalars['String']['output'];
  transportRequest?: Maybe<TransportRequest>;
  updated: Scalars['DateTime']['output'];
};

export type ChargeInstructionCreateInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  code: ChargeCode;
  comment?: InputMaybe<Scalars['String']['input']>;
  transportRequestIds: Array<Scalars['String']['input']>;
};

export type ChargeInstructionUpdateInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  code: ChargeCode;
  comment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type CheckoutResponse = {
  __typename?: 'CheckoutResponse';
  excludedFromCheckout?: Maybe<Scalars['Int']['output']>;
  expired?: Maybe<Scalars['Int']['output']>;
  successfullyCheckedOut: Scalars['Int']['output'];
};

export type Cheque = {
  __typename?: 'Cheque';
  chequeNumber: Scalars['String']['output'];
  id: Scalars['String']['output'];
  referenceUrl: Scalars['String']['output'];
  status: ChequeStatus;
};

export enum ChequeStatus {
  CANCELLED = 'CANCELLED',
  DRAFT = 'DRAFT',
  PRINTED = 'PRINTED',
  PRINTED_IN_NETSUITE = 'PRINTED_IN_NETSUITE',
}

export type ClearableDateTimeInput = {
  /** The new datetime */
  datetime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Reset datetime to none */
  reset?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ColorOption = {
  __typename?: 'ColorOption';
  name: Scalars['String']['output'];
  rgbHex: Scalars['String']['output'];
};

export enum ColorSchemeEnum {
  BLACK = 'BLACK',
  GREEN = 'GREEN',
  RED = 'RED',
  YELLOW = 'YELLOW',
}

export type Company = {
  __typename?: 'Company';
  accountingSystemRelationships: Array<CompanyAccountingSystemRelationship>;
  americanLegalInfo?: Maybe<CompanyAmericanLegalInfo>;
  archived: Scalars['Boolean']['output'];
  auctionAccessInfo?: Maybe<CompanyAuctionAccessInfo>;
  auctionRelationships?: Maybe<CompanyAuctionRelationshipList>;
  buyLienCheck?: Maybe<Scalars['Boolean']['output']>;
  canadianLegalInfo?: Maybe<CompanyCanadianLegalInfo>;
  checkReleasePreference?: Maybe<Scalars['String']['output']>;
  contacts?: Maybe<Array<Contact>>;
  created: Scalars['DateTime']['output'];
  creditsBuy?: Maybe<MonetaryAmount>;
  creditsLoyalty?: Maybe<MonetaryAmount>;
  creditsSell?: Maybe<MonetaryAmount>;
  defaultSellerNotes?: Maybe<Scalars['String']['output']>;
  /** Only available for Carrier Type. */
  deliveryIsLocalOnly?: Maybe<Scalars['Boolean']['output']>;
  /** Only available for Carrier Type. */
  deliveryNotes?: Maybe<Scalars['String']['output']>;
  /** Only available for Carrier Type. */
  deliveryWhitelistedRegions: Array<OperatingRegion>;
  denylisted?: Maybe<Array<Company>>;
  denylistedBy?: Maybe<Array<Company>>;
  description?: Maybe<Scalars['String']['output']>;
  holdback?: Maybe<DifferenceAmount>;
  id: Scalars['String']['output'];
  integrations?: Maybe<Array<CompanyIntegration>>;
  invoiceSignature?: Maybe<InvoiceSignature>;
  legalName?: Maybe<Scalars['String']['output']>;
  locations: Array<Location>;
  name: Scalars['String']['output'];
  notes?: Maybe<Array<Maybe<Note>>>;
  numericId?: Maybe<Scalars['Int']['output']>;
  parentCompany?: Maybe<Company>;
  pipelineId?: Maybe<Scalars['String']['output']>;
  primaryLocation?: Maybe<Location>;
  remainingBuyerCreditTotal?: Maybe<MonetaryAmount>;
  reportGroups: Array<ReportGroup>;
  restrictCarfaxCanadaOrdering: Scalars['Boolean']['output'];
  rolePermissionsEnabled: Scalars['Boolean']['output'];
  sellLienCheck?: Maybe<Scalars['Boolean']['output']>;
  /** Only available for Carrier Type. */
  shipmentModes: Array<CarrierShipmentMode>;
  source?: Maybe<Scalars['String']['output']>;
  stats?: Maybe<CompanyStatistics>;
  status: CompanyStatus;
  subType?: Maybe<CompanySubType>;
  /** The set of tasks on the item */
  tasks: Array<Task>;
  /** The set of timeline actions on the item */
  timelineActions: Array<TimelineAction>;
  titleReleasePreference?: Maybe<Scalars['String']['output']>;
  type: CompanyType;
  updated: Scalars['DateTime']['output'];
  userRelationships?: Maybe<UserCompanyRelationshipList>;
  visibleRegions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type CompanycontactsArgs = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  primary?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<Array<InputMaybe<ContactRole>>>;
};

export type CompanyremainingBuyerCreditTotalArgs = {
  auctionTimeSlotId: Scalars['String']['input'];
};

export type CompanyAccountingSystemRelationship = {
  __typename?: 'CompanyAccountingSystemRelationship';
  accountingSystem: AccountingSystem;
  accountsPayableDeduction: AccountsPayableDeduction;
  bankDetails?: Maybe<BankDetailsDto>;
  businessUnit?: Maybe<AccountingSystemBusinessUnit>;
  company: Company;
  created: Scalars['DateTime']['output'];
  defaultPaymentType?: Maybe<PaymentType>;
  id: Scalars['String']['output'];
  padPaymentAggregationEnabled: Scalars['Boolean']['output'];
  paymentTypes: Array<PaymentType>;
  updated: Scalars['DateTime']['output'];
};

export type CompanyAmericanLegalInfo = {
  __typename?: 'CompanyAmericanLegalInfo';
  auctionAccessIssues?: Maybe<Array<Maybe<AuctionAccessIssue>>>;
  bondAmount?: Maybe<MonetaryAmount>;
  bondCompany?: Maybe<Scalars['String']['output']>;
  bondExpiry?: Maybe<Scalars['Date']['output']>;
  bondNumber?: Maybe<Scalars['String']['output']>;
  dealerLicenseExpiry?: Maybe<Scalars['Date']['output']>;
  dealerLicenseNumber?: Maybe<Scalars['String']['output']>;
  dealerLicenseState?: Maybe<Scalars['String']['output']>;
  ein?: Maybe<Scalars['String']['output']>;
  modifiable: Scalars['Boolean']['output'];
  sellerPermitNumber?: Maybe<Scalars['String']['output']>;
};

export type CompanyAuctionAccessInfo = {
  __typename?: 'CompanyAuctionAccessInfo';
  auctionAccessId?: Maybe<Scalars['Int']['output']>;
  auctionAccessLast4GovId?: Maybe<Scalars['String']['output']>;
  auctionAccessLastVerified?: Maybe<Scalars['DateTime']['output']>;
};

export type CompanyAuctionPermission = {
  __typename?: 'CompanyAuctionPermission';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CompanyAuctionRelationship = {
  __typename?: 'CompanyAuctionRelationship';
  auction: Auction;
  auctionAccessIssueLogs?: Maybe<Array<AuctionAccessIssueLog>>;
  auctionAccessStatus?: Maybe<AuctionAccessStatus>;
  auctionServiceDefaults: Array<Maybe<AuctionServiceDefault>>;
  buyerCreditLimit?: Maybe<MonetaryAmount>;
  buyerLienCheck: Scalars['Boolean']['output'];
  buyerShieldEnabled?: Maybe<Scalars['Boolean']['output']>;
  buyerTransportCompoundLocation?: Maybe<Location>;
  company: Company;
  created: Scalars['DateTime']['output'];
  defaultPaymentType?: Maybe<PaymentType>;
  defaultSellerNotes?: Maybe<Scalars['String']['output']>;
  defaultTransportLocation?: Maybe<Location>;
  eftEnabled: Scalars['Boolean']['output'];
  hideSellerNameEnabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  paymentTypes: Array<PaymentType>;
  permissions: Array<Maybe<CompanyAuctionPermission>>;
  reps: Array<Maybe<AuctionRepresentative>>;
  sellerAssuranceEnabled?: Maybe<Scalars['Boolean']['output']>;
  sellerLienCheck: Scalars['Boolean']['output'];
  sellerTransportCompoundLocation?: Maybe<Location>;
  sendTitlePaymentOverride?: Maybe<Scalars['Boolean']['output']>;
  status: CompanyAuctionRelationshipStatus;
  tier?: Maybe<CompanyTier>;
  vehicleReleasePaymentOverride?: Maybe<Scalars['Boolean']['output']>;
};

export type CompanyAuctionRelationshipauctionServiceDefaultsArgs = {
  defaultSelected?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A connection to a list of items. */
export type CompanyAuctionRelationshipConnection = {
  __typename?: 'CompanyAuctionRelationshipConnection';
  edges: Array<CompanyAuctionRelationshipEdge>;
  facetGroups: Array<FacetGroup>;
  filterSections: Array<FilterSection>;
  pageInfo: FacetedPageInfo;
  sort: Array<SortOption>;
};

/** A connection to a list of items. */
export type CompanyAuctionRelationshipConnectionfacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyAuctionRelationshipEdge = {
  __typename?: 'CompanyAuctionRelationshipEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: CompanyAuctionRelationship;
};

export type CompanyAuctionRelationshipList = {
  __typename?: 'CompanyAuctionRelationshipList';
  count: Scalars['Int']['output'];
  list: Array<CompanyAuctionRelationship>;
};

export enum CompanyAuctionRelationshipStatus {
  DELETED = 'DELETED',
  ENABLED = 'ENABLED',
  PENDING = 'PENDING',
  SUSPENDED = 'SUSPENDED',
}

export type CompanyCanadianLegalInfo = {
  __typename?: 'CompanyCanadianLegalInfo';
  bcPstNumber?: Maybe<Scalars['String']['output']>;
  craHstGstNumber?: Maybe<Scalars['String']['output']>;
  dealerLicenseExpiry?: Maybe<Scalars['Date']['output']>;
  dealerLicenseNumber?: Maybe<Scalars['String']['output']>;
  modifiable: Scalars['Boolean']['output'];
  numeroDeDossier?: Maybe<Scalars['String']['output']>;
  ontarioRin?: Maybe<Scalars['String']['output']>;
  skPstNumber?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  edges: Array<CompanyEdge>;
  facetGroups: Array<FacetGroup>;
  filterSections: Array<FilterSection>;
  pageInfo: FacetedPageInfo;
  sort: Array<SortOption>;
};

/** A connection to a list of items. */
export type CompanyConnectionfacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyCreditLog = {
  __typename?: 'CompanyCreditLog';
  accountingSystem: AccountingSystem;
  amount: Scalars['Float']['output'];
  auctionItem?: Maybe<AuctionItem>;
  comment?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  createdByUser: User;
  id: Scalars['String']['output'];
  reason?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type CompanyCreditLogConnection = {
  __typename?: 'CompanyCreditLogConnection';
  edges: Array<CompanyCreditLogEdge>;
  metadata: CompanyCreditLogMetadata;
  pageInfo: FacetedPageInfo;
  sort: Array<SortOption>;
};

export type CompanyCreditLogEdge = {
  __typename?: 'CompanyCreditLogEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: CompanyCreditLog;
};

export type CompanyCreditLogInput = {
  accountingSystem: Scalars['String']['input'];
  amount: Scalars['BigDecimal']['input'];
  auctionItemId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['String']['input'];
  type: AccountEnum;
};

export type CompanyCreditLogMetadata = {
  __typename?: 'CompanyCreditLogMetadata';
  balance: MonetaryAmount;
};

export type CompanyEdge = {
  __typename?: 'CompanyEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: Company;
};

export type CompanyIntegration = {
  __typename?: 'CompanyIntegration';
  created: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  values: Array<Maybe<CompanyIntegrationValue>>;
};

export type CompanyIntegrationMetadata = {
  __typename?: 'CompanyIntegrationMetadata';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  values: Array<Maybe<CompanyIntegrationValue>>;
};

export type CompanyIntegrationValue = {
  __typename?: 'CompanyIntegrationValue';
  description?: Maybe<Scalars['String']['output']>;
  encrypted?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  required: Scalars['Boolean']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type CompanySearchResult = {
  __typename?: 'CompanySearchResult';
  address?: Maybe<Address>;
  auctionAccessId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CompanyService = Fee & {
  __typename?: 'CompanyService';
  active: Scalars['Boolean']['output'];
  amount: Scalars['BigDecimal']['output'];
  comment: Scalars['String']['output'];
  company: Company;
  created: Scalars['DateTime']['output'];
  flagged: Scalars['Boolean']['output'];
  formattedAmount: Scalars['String']['output'];
  id: Scalars['String']['output'];
  invoiced: Scalars['Boolean']['output'];
  serviceMetadata: AuctionServiceMetadata;
  updated: Scalars['DateTime']['output'];
};

export type CompanyServiceUpdateInput = {
  amount: Scalars['Float']['input'];
  companyFeeId: Scalars['String']['input'];
  flagged?: Scalars['Boolean']['input'];
};

export type CompanyStatistics = {
  __typename?: 'CompanyStatistics';
  purchased?: Maybe<Scalars['Int']['output']>;
  purchasedArbitrated?: Maybe<Scalars['Int']['output']>;
  sold?: Maybe<Scalars['Int']['output']>;
  soldArbitrated?: Maybe<Scalars['Int']['output']>;
};

export enum CompanyStatus {
  DELETED = 'DELETED',
  ENABLED = 'ENABLED',
  PENDING = 'PENDING',
  SUSPENDED = 'SUSPENDED',
}

export enum CompanySubType {
  /** Only for CONSIGNER */
  CAPTIVE_FINANCE = 'CAPTIVE_FINANCE',
  /** Only for CARRIER */
  COMPANY = 'COMPANY',
  /** Only for CONSIGNER */
  DEALER_FRANCHISE = 'DEALER_FRANCHISE',
  /** Only for CONSIGNER */
  DEALER_INDEPENDENT = 'DEALER_INDEPENDENT',
  /** Only for CONSIGNER */
  FLEET_LEASE_FINANCE = 'FLEET_LEASE_FINANCE',
  /** Only for CONSIGNER */
  FLEET_LEASE_OTHER = 'FLEET_LEASE_OTHER',
  /** Only for CONSIGNER */
  FLEET_LEASE_RENTAL = 'FLEET_LEASE_RENTAL',
  /** Only for AUCTION */
  GOVERNMENT = 'GOVERNMENT',
  /** Only for CONSIGNER */
  OEM = 'OEM',
  /** Only for AUCTION */
  ONLINE = 'ONLINE',
  /** Only for CARRIER */
  OWNER_OPERATOR = 'OWNER_OPERATOR',
  /** Only for AUCTION */
  PHYSICAL = 'PHYSICAL',
  /** Only for AUCTION */
  PRIVATE = 'PRIVATE',
  /** Only for AUCTION */
  SALVAGE = 'SALVAGE',
  /** Test type */
  TEST = 'TEST',
}

export enum CompanyTier {
  ELITE = 'ELITE',
  NEW = 'NEW',
  NONE = 'NONE',
  POWER = 'POWER',
}

export enum CompanyType {
  AUCTION = 'AUCTION',
  CARRIER = 'CARRIER',
  CONSIGNER = 'CONSIGNER',
  HOLDING = 'HOLDING',
}

export type CompoundHistoryEntry = {
  __typename?: 'CompoundHistoryEntry';
  action: InventoryCheckedInOutStatus;
  actionByUser: User;
  actionTime: Scalars['DateTime']['output'];
  checkedOutReason?: Maybe<InventoryCheckedOutReason>;
};

export type ConsignerSavedFilter = SavedFilter & {
  __typename?: 'ConsignerSavedFilter';
  city?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  country?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  created: Scalars['DateTime']['output'];
  defaultFilter: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  privilege?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  region?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  salesRepName?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  updated: Scalars['DateTime']['output'];
};

export type ConsignerSavedFilterInput = {
  city?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  defaultFilter?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  privilege?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  region?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  salesRepName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Contact = {
  __typename?: 'Contact';
  archived: Scalars['Boolean']['output'];
  created: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  locale?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  primary: Scalars['Boolean']['output'];
  roles?: Maybe<Array<Maybe<ContactRole>>>;
  updated: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export enum ContactRole {
  BILLING = 'BILLING',
  CLAIMS = 'CLAIMS',
  DISPATCH = 'DISPATCH',
  OFFER = 'OFFER',
  OTHER = 'OTHER',
  PICKUP = 'PICKUP',
  RELEASES = 'RELEASES',
  TITLES = 'TITLES',
}

export type Conversation = {
  __typename?: 'Conversation';
  customer?: Maybe<User>;
  id: Scalars['ID']['output'];
  messages: Array<Maybe<ConversationMessage>>;
  staff: User;
  type: ConversationType;
};

export type ConversationMessage = {
  __typename?: 'ConversationMessage';
  created: Scalars['DateTime']['output'];
  createdById: Scalars['String']['output'];
  id: Scalars['String']['output'];
  message: Scalars['String']['output'];
  type: ConversationMessageType;
};

export enum ConversationMessageType {
  LIVE_LANE = 'LIVE_LANE',
  LIVE_LANE_BROADCAST = 'LIVE_LANE_BROADCAST',
}

export enum ConversationType {
  LIVE_LANE = 'LIVE_LANE',
  LIVE_LANE_BROADCAST = 'LIVE_LANE_BROADCAST',
}

export enum CountryCode {
  CA = 'CA',
  US = 'US',
}

export enum CropType {
  FILL = 'FILL',
  LIMIT = 'LIMIT',
}

export enum CurrencyCode {
  CAD = 'CAD',
  LPT = 'LPT',
  USD = 'USD',
}

export type Declaration = {
  __typename?: 'Declaration';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  selected: Scalars['Boolean']['output'];
  type: DeclarationType;
};

export enum DeclarationType {
  NEGATIVE = 'NEGATIVE',
  POSITIVE = 'POSITIVE',
}

export type DeclarationsInput = {
  /** clears all autobids on auction item if true */
  clearAutoBids?: InputMaybe<Scalars['Boolean']['input']>;
  declarations: Array<Scalars['String']['input']>;
  lastKnownMileage?: InputMaybe<MileageInput>;
  lastKnownMileageDate?: InputMaybe<Scalars['Date']['input']>;
};

export enum DeviceType {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
}

export type DifferenceAmount = {
  __typename?: 'DifferenceAmount';
  amount: Scalars['BigDecimal']['output'];
  type: AmountType;
};

export enum DistanceUnit {
  KM = 'KM',
  MI = 'MI',
}

export type EmailContent = {
  __typename?: 'EmailContent';
  attachmentURLs?: Maybe<Array<Scalars['String']['output']>>;
  emailTo: Array<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  subject: Scalars['String']['output'];
};

export type EmailContentInput = {
  emailTo: Array<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};

export type EmailFailure = {
  __typename?: 'EmailFailure';
  date: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  responded?: Maybe<Scalars['DateTime']['output']>;
  type: EmailHistoryFailureType;
};

export type EmailHistory = {
  __typename?: 'EmailHistory';
  bounced: Array<EmailFailure>;
  complaints: Array<EmailFailure>;
  created: Scalars['DateTime']['output'];
  delivered: Array<Scalars['String']['output']>;
  entityId?: Maybe<Scalars['String']['output']>;
  entityType?: Maybe<EntityType>;
  failures: Array<EmailFailure>;
  fromAddress: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  recipients: Array<Scalars['String']['output']>;
  resendable: Scalars['Boolean']['output'];
  status: EmailHistoryStatus;
  subject: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type EmailHistoryConnection = {
  __typename?: 'EmailHistoryConnection';
  edges: Array<EmailHistoryEdge>;
  facetGroups: Array<FacetGroup>;
  filterSections: Array<FilterSection>;
  pageInfo: FacetedPageInfo;
  sort: Array<SortOption>;
};

/** A connection to a list of items. */
export type EmailHistoryConnectionfacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type EmailHistoryEdge = {
  __typename?: 'EmailHistoryEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: EmailHistory;
};

export enum EmailHistoryFailureType {
  BOUNCE_PERMANENT = 'BOUNCE_PERMANENT',
  BOUNCE_TRANSIENT = 'BOUNCE_TRANSIENT',
  BOUNCE_UNKNOWN = 'BOUNCE_UNKNOWN',
  COMPLAINT = 'COMPLAINT',
}

export enum EmailHistoryStatus {
  DELIVERED = 'DELIVERED',
  FAILED = 'FAILED',
  IN_TRANSIT = 'IN_TRANSIT',
  PARTIALLY_DELIVERED = 'PARTIALLY_DELIVERED',
}

export enum EmailMessageType {
  BOS_BUYER = 'BOS_BUYER',
  BOS_SELLER = 'BOS_SELLER',
  BOS_SUMMARY_BUYER = 'BOS_SUMMARY_BUYER',
  INVOICE = 'INVOICE',
}

export enum EntityType {
  AUCTION = 'AUCTION',
  AUCTION_ITEM = 'AUCTION_ITEM',
  COMPANY = 'COMPANY',
  INVENTORY_ITEM = 'INVENTORY_ITEM',
  LOCATION = 'LOCATION',
  TRANSPORT_JOB = 'TRANSPORT_JOB',
  TRANSPORT_REQUEST = 'TRANSPORT_REQUEST',
  USER = 'USER',
  VEHICLE_REPAIR_ITEM = 'VEHICLE_REPAIR_ITEM',
}

export type ExternalId = {
  __typename?: 'ExternalId';
  created: Scalars['DateTime']['output'];
  entityId: Scalars['String']['output'];
  entityType: EntityType;
  externalId: Scalars['String']['output'];
  formattedName: Scalars['String']['output'];
  integrationName: IntegrationName;
  lastReceived?: Maybe<Scalars['DateTime']['output']>;
  lastSent?: Maybe<Scalars['DateTime']['output']>;
};

export type ExternalIdSetInput = {
  entityId: Scalars['String']['input'];
  entityType: EntityType;
  externalId: Scalars['String']['input'];
  integrationName: IntegrationName;
};

export type Facet = {
  __typename?: 'Facet';
  count: Scalars['Long']['output'];
  localizedName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  selected: Scalars['Boolean']['output'];
  sum?: Maybe<Scalars['Float']['output']>;
};

export type FacetGroup = {
  __typename?: 'FacetGroup';
  allowMultiple: Scalars['Boolean']['output'];
  facets: Array<Facet>;
  name: Scalars['String']['output'];
};

/** Information about pagination in a connection. */
export type FacetedPageInfo = {
  __typename?: 'FacetedPageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
  /** The total number of edges. */
  totalEdges: Scalars['Long']['output'];
};

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  auctions?: Maybe<Array<Auction>>;
  defaultValue: Scalars['Boolean']['output'];
  featureKey: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type FeatureFlagSetInput = {
  /** Modify one or more auction's feature flag */
  auctionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The feature key */
  featureKey: Scalars['String']['input'];
  /** Default value if auctionId not specified or on/off on auction otherwise */
  value: Scalars['Boolean']['input'];
};

export type Fee = {
  active: Scalars['Boolean']['output'];
  created: Scalars['DateTime']['output'];
  flagged: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  serviceMetadata: AuctionServiceMetadata;
  updated: Scalars['DateTime']['output'];
};

export enum FeeInvoicingIndicator {
  AGED_30_DAYS = 'AGED_30_DAYS',
  AGED_45_DAYS = 'AGED_45_DAYS',
  AGED_60_DAYS = 'AGED_60_DAYS',
  AGED_90_DAYS = 'AGED_90_DAYS',
  FLAGGED = 'FLAGGED',
  VEHICLE_NOT_SOLD_ASSIGNED_TO_AUCTION = 'VEHICLE_NOT_SOLD_ASSIGNED_TO_AUCTION',
  VEHICLE_SOLD_AT_COMPOUND = 'VEHICLE_SOLD_AT_COMPOUND',
}

export type FileAndSize = {
  name: Scalars['String']['input'];
  size: Scalars['Int']['input'];
};

export type FileUpload = {
  name: Scalars['String']['input'];
  size: Scalars['Int']['input'];
};

export type Filter = {
  id: Scalars['String']['output'];
  localizedName: Scalars['String']['output'];
};

export type FilterSection = {
  __typename?: 'FilterSection';
  filters: Array<Filter>;
  id: Scalars['String']['output'];
  sectionName?: Maybe<Scalars['String']['output']>;
};

export type FilterSelection = {
  __typename?: 'FilterSelection';
  id: Scalars['String']['output'];
  localizedName: Scalars['String']['output'];
  selected: Scalars['Boolean']['output'];
};

export type FindUser = {
  __typename?: 'FindUser';
  auctionAccessId?: Maybe<Scalars['String']['output']>;
  companyNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  mainPhotoUrl?: Maybe<Scalars['String']['output']>;
  photoUrl?: Maybe<Scalars['String']['output']>;
};

export type FindUsermainPhotoUrlArgs = {
  height?: InputMaybe<Scalars['Int']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type FindUserphotoUrlArgs = {
  height?: InputMaybe<Scalars['Int']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type ForceFeeInvoice = {
  auctionItemId?: InputMaybe<Scalars['String']['input']>;
  /** send paymentTypeId, terms, auctionItemId, for auto-invoicing, if permission allows */
  paymentTypeId?: InputMaybe<Scalars['String']['input']>;
  terms?: InputMaybe<Terms>;
};

export type ForgotPasswordMessage = {
  __typename?: 'ForgotPasswordMessage';
  message: Scalars['String']['output'];
};

export enum HoldReason {
  FINANCING_DECLINED = 'FINANCING_DECLINED',
  OTHER = 'OTHER',
  PAYMENT_DELAYED = 'PAYMENT_DELAYED',
  PAYMENT_RETURNED = 'PAYMENT_RETURNED',
  WAITING_FOR_SELLER_REFUND = 'WAITING_FOR_SELLER_REFUND',
}

export type IfBid = {
  __typename?: 'IfBid';
  actionBy?: Maybe<User>;
  amount?: Maybe<MonetaryAmount>;
  comment?: Maybe<Scalars['String']['output']>;
  company: Company;
  created: Scalars['DateTime']['output'];
  createdBy: User;
  id: Scalars['String']['output'];
  type: IfBidType;
};

export enum IfBidClaim {
  CLAIMED = 'CLAIMED',
  CLAIMED_BY_USER = 'CLAIMED_BY_USER',
  UNCLAIMED = 'UNCLAIMED',
}

export enum IfBidLastOfferBy {
  BUYER = 'BUYER',
  SELLER = 'SELLER',
}

export enum IfBidStatus {
  ACCEPTED = 'ACCEPTED',
  ACTIVE = 'ACTIVE',
  DECLINED = 'DECLINED',
  EXPIRED = 'EXPIRED',
  INACTIVE = 'INACTIVE',
}

export type IfBidTimeline = {
  __typename?: 'IfBidTimeline';
  amountApart?: Maybe<MonetaryAmount>;
  awaitingMyResponse: Scalars['Boolean']['output'];
  bidderHighestOffer?: Maybe<MonetaryAmount>;
  consignerLowestOffer?: Maybe<MonetaryAmount>;
  count: Scalars['Int']['output'];
  ending?: Maybe<Scalars['DateTime']['output']>;
  inIfBidWithCompany: Company;
  list: Array<IfBid>;
};

export enum IfBidType {
  ACCEPTED = 'ACCEPTED',
  BUYER_OFFER = 'BUYER_OFFER',
  CANCELLED = 'CANCELLED',
  /** Seller action. */
  COUNTERED = 'COUNTERED',
  /** Buyer action. */
  OFFERED = 'OFFERED',
  SELLER_OFFER = 'SELLER_OFFER',
}

export type IncompleteFieldMessage = {
  __typename?: 'IncompleteFieldMessage';
  field: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type Inspection = {
  __typename?: 'Inspection';
  acceptedInspectionProposal?: Maybe<InspectionProposal>;
  assignedBy?: Maybe<User>;
  cancellationReason?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  id: Scalars['String']['output'];
  /** Used in a list view to be faster than inspectedInventoryItems. */
  inspectedInventoryItemCount: Scalars['Int']['output'];
  inspectedInventoryItems?: Maybe<Array<Maybe<InventoryItem>>>;
  /** Company which conducts the inspection */
  inspectionCompany: Company;
  inspectionDate?: Maybe<Scalars['DateTime']['output']>;
  inspectionNumber: Scalars['Long']['output'];
  inspectionProposals?: Maybe<Array<Maybe<InspectionProposal>>>;
  location: Location;
  notes?: Maybe<Array<Maybe<Note>>>;
  numberOfItemsRequested: Scalars['Int']['output'];
  requestedInspectionDate: Scalars['Date']['output'];
  requestedInspectionTime: InspectionTime;
  /** Company which owns the units and request the capture */
  requestingCompany: Company;
  status: InspectionStatus;
  updated: Scalars['DateTime']['output'];
};

/** A connection to a list of items. */
export type InspectionConnection = {
  __typename?: 'InspectionConnection';
  edges: Array<InspectionEdge>;
  facetGroups: Array<FacetGroup>;
  filterSections: Array<FilterSection>;
  pageInfo: FacetedPageInfo;
  sort: Array<SortOption>;
};

/** A connection to a list of items. */
export type InspectionConnectionfacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type InspectionEdge = {
  __typename?: 'InspectionEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: Inspection;
};

export type InspectionProposal = {
  __typename?: 'InspectionProposal';
  created: Scalars['DateTime']['output'];
  createdBy: User;
  id: Scalars['String']['output'];
  proposedInspectionTime: Scalars['DateTime']['output'];
};

export enum InspectionStatus {
  CANCELLED = 'CANCELLED',
  CLAIMED = 'CLAIMED',
  COMPLETE = 'COMPLETE',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
}

export enum InspectionTime {
  AFTERNOON = 'AFTERNOON',
  ANYTIME = 'ANYTIME',
  ASAP = 'ASAP',
  EVENING = 'EVENING',
  MORNING = 'MORNING',
}

export type IntegrationInputValue = {
  id: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export enum IntegrationName {
  ABS = 'ABS',
  AIM = 'AIM',
  AUCTION_ACCESS = 'AUCTION_ACCESS',
  AUTOVIN = 'AUTOVIN',
  AUTO_IMS = 'AUTO_IMS',
  CARFAX_CANADA = 'CARFAX_CANADA',
  CRSIMPLIFIED = 'CRSIMPLIFIED',
  EDGE_PIPELINE = 'EDGE_PIPELINE',
  NETSUITE = 'NETSUITE',
  SUPER_DISPATCH = 'SUPER_DISPATCH',
  SUPER_DISPATCH_OFFER = 'SUPER_DISPATCH_OFFER',
  XCIRA = 'XCIRA',
  ZOHO = 'ZOHO',
}

export enum InventoryCheckedInOutStatus {
  IN = 'IN',
  NONE = 'NONE',
  OUT = 'OUT',
}

export enum InventoryCheckedOutReason {
  BUYER_PICKUP = 'BUYER_PICKUP',
  OTHER_SERVICE = 'OTHER_SERVICE',
  OVERRIDE = 'OVERRIDE',
  PSI = 'PSI',
  REPAIR = 'REPAIR',
  SELLER_PICKUP = 'SELLER_PICKUP',
  TRANSPORT = 'TRANSPORT',
}

export type InventoryItem = {
  __typename?: 'InventoryItem';
  archived: Scalars['Boolean']['output'];
  /** When non-null, display disclaimer when submitting to auction and mark as is. */
  asIsCause?: Maybe<Scalars['String']['output']>;
  assignment?: Maybe<InventoryItemAssignment>;
  auctionLocation?: Maybe<Location>;
  /** The intended auction time slot lane */
  auctionTimeSlotLane?: Maybe<AuctionTimeSlotLane>;
  /** This returns the repaired score */
  autoGradeScore?: Maybe<Scalars['Float']['output']>;
  autoGradeScoreData?: Maybe<AutoGradeScoreData>;
  autocheckExceptions?: Maybe<Array<Maybe<AutocheckException>>>;
  autocheckExceptionsReceived?: Maybe<Scalars['Boolean']['output']>;
  availableAuctions: Array<Maybe<Auction>>;
  blueDriverScanData?: Maybe<BlueDriverScanData>;
  bodyType?: Maybe<Scalars['String']['output']>;
  canBeListed: Scalars['Boolean']['output'];
  captureType: VehicleCaptureType;
  captured?: Maybe<Scalars['DateTime']['output']>;
  capturedBy?: Maybe<User>;
  chargingCable?: Maybe<Scalars['String']['output']>;
  /** Non-null if checked into current location */
  checkedIn?: Maybe<Scalars['DateTime']['output']>;
  checkedInBy?: Maybe<User>;
  /** Non-null if checked out of current location (e.g. was pickedUp) */
  checkedOut?: Maybe<Scalars['DateTime']['output']>;
  checkedOutBy?: Maybe<User>;
  checkedOutReason?: Maybe<InventoryCheckedOutReason>;
  chromeTirePressureMonitoringSystem?: Maybe<Scalars['Boolean']['output']>;
  company: Company;
  comparableValuations: Array<AuctionItemComparableValuation>;
  comparables?: Maybe<AuctionItemComparables>;
  complete: Scalars['Boolean']['output'];
  completionPercentage: Scalars['Float']['output'];
  compoundHistory: Array<CompoundHistoryEntry>;
  conditionReport?: Maybe<VehicleConditionReport>;
  created?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  /** The latest/current auction item for the inventory item */
  currentAuctionItem?: Maybe<AuctionItem>;
  cylinders?: Maybe<Scalars['Int']['output']>;
  daysOnLot?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** Non-null if checked out reason is transport to compound */
  destination?: Maybe<Location>;
  displacement?: Maybe<Scalars['Float']['output']>;
  driveTrain?: Maybe<Scalars['String']['output']>;
  drivetrainBuyerShield?: Maybe<Scalars['Boolean']['output']>;
  engineBuyerShield?: Maybe<Scalars['Boolean']['output']>;
  extensiveVehicleConditionScore?: Maybe<Scalars['BigDecimal']['output']>;
  extensiveVehicleConditionScoreOverride?: Maybe<Scalars['Boolean']['output']>;
  exteriorColor?: Maybe<Scalars['String']['output']>;
  exteriorNote?: Maybe<Scalars['String']['output']>;
  exteriorRating?: Maybe<Scalars['Float']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  fuelType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  incompleteFieldMessages?: Maybe<Array<Maybe<IncompleteFieldMessage>>>;
  interiorColor?: Maybe<Scalars['String']['output']>;
  interiorNote?: Maybe<Scalars['String']['output']>;
  interiorRating?: Maybe<Scalars['Float']['output']>;
  inventoryItemFee?: Maybe<InventoryItemFee>;
  inventoryServices?: Maybe<Array<InventoryService>>;
  isUpdatable: Scalars['Boolean']['output'];
  keyTag?: Maybe<Scalars['String']['output']>;
  /** The most recent auction item for the inventory item */
  lastAuctionItem?: Maybe<AuctionItem>;
  lastKnownMileage?: Maybe<Mileage>;
  lastKnownMileageDate?: Maybe<Scalars['Date']['output']>;
  legalSaleDate?: Maybe<Scalars['DateTime']['output']>;
  location?: Maybe<Location>;
  logEntries?: Maybe<Array<Maybe<LogEntry>>>;
  lotLocation?: Maybe<Scalars['String']['output']>;
  make?: Maybe<Scalars['String']['output']>;
  makeId?: Maybe<Scalars['String']['output']>;
  mechanicalDrivetrainNote?: Maybe<Scalars['String']['output']>;
  mechanicalDrivetrainRating?: Maybe<Scalars['Float']['output']>;
  mechanicalEngineNote?: Maybe<Scalars['String']['output']>;
  mechanicalEngineRating?: Maybe<Scalars['Float']['output']>;
  mechanicalNote?: Maybe<Scalars['String']['output']>;
  mechanicalRating?: Maybe<Scalars['Float']['output']>;
  mechanicalTransmissionNote?: Maybe<Scalars['String']['output']>;
  mechanicalTransmissionRating?: Maybe<Scalars['Float']['output']>;
  mileage?: Maybe<Mileage>;
  minimalCR: Scalars['Boolean']['output'];
  model?: Maybe<Scalars['String']['output']>;
  modelId?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Array<Maybe<Note>>>;
  numberOfDoors?: Maybe<Scalars['Int']['output']>;
  numberOfPassengers?: Maybe<Scalars['Int']['output']>;
  operable: Scalars['Boolean']['output'];
  options?: Maybe<Array<Maybe<InventoryItemOption>>>;
  /** This always returns the unrepaired original score */
  originalAutoGradeScore?: Maybe<Scalars['Float']['output']>;
  paintCondition?: Maybe<VehiclePaintCondition>;
  /** Photos of the inventory item. The first photo is always the primary photo. Photos are sorted by the shot code. */
  photos?: Maybe<Array<Maybe<Photo>>>;
  pickupLocation?: Maybe<Location>;
  printableUrl: Scalars['String']['output'];
  readyForPickup?: Maybe<Scalars['Boolean']['output']>;
  redeemed?: Maybe<Scalars['Date']['output']>;
  redemptionRequested?: Maybe<Scalars['Date']['output']>;
  redirectPath: Scalars['String']['output'];
  releaseStatus: ReleaseStatus;
  /** The intended run number */
  runNumber?: Maybe<Scalars['Int']['output']>;
  sellHoldStatus: SellHoldStatus;
  sellHoldUntil?: Maybe<Scalars['DateTime']['output']>;
  sellReleaseForm?: Maybe<Scalars['String']['output']>;
  sellerNotes?: Maybe<Scalars['String']['output']>;
  sellerReleaseBlockers?: Maybe<Array<ReleaseBlocker>>;
  /** Sales reps of all the auctions the item can be submit to */
  sellerReps?: Maybe<Array<Maybe<AuctionRepresentative>>>;
  source?: Maybe<Scalars['String']['output']>;
  status?: Maybe<VehicleStatus>;
  stickerUrl: Scalars['String']['output'];
  stockNumber?: Maybe<Scalars['String']['output']>;
  subModel?: Maybe<Scalars['String']['output']>;
  subModelId?: Maybe<Scalars['String']['output']>;
  /** The set of tasks on the item */
  tasks: Array<Task>;
  /** The set of timeline actions on the item */
  timelineActions: Array<TimelineAction>;
  tireCondition?: Maybe<VehicleTireCondition>;
  tiresNote?: Maybe<Scalars['String']['output']>;
  tiresRating?: Maybe<Scalars['Float']['output']>;
  transmission?: Maybe<Scalars['String']['output']>;
  transmissionBuyerShield?: Maybe<Scalars['Boolean']['output']>;
  transportDetails: Array<TransportDetail>;
  transportRequest?: Maybe<TransportRequest>;
  transportRequests?: Maybe<Array<Maybe<TransportRequest>>>;
  trim?: Maybe<Scalars['String']['output']>;
  trimId?: Maybe<Scalars['String']['output']>;
  uniqueStockNumber: Scalars['Long']['output'];
  updated?: Maybe<Scalars['DateTime']['output']>;
  values?: Maybe<Array<Maybe<InventoryItemValue>>>;
  vehicleBuyerShield?: Maybe<Scalars['Boolean']['output']>;
  vehicleTitle?: Maybe<VehicleTitle>;
  verifiedBy?: Maybe<Scalars['String']['output']>;
  vin?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type InventoryItemautoGradeScoreDataArgs = {
  includeRepaired?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InventoryItemcomparablesArgs = {
  soldDateDesc?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InventoryItemoptionsArgs = {
  selected?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InventoryItemphotosArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Array<PhotoStatus>>;
  type?: InputMaybe<PhotoType>;
};

/** Commercial inventory assignment */
export type InventoryItemAssignment = {
  __typename?: 'InventoryItemAssignment';
  /** Only supported by source AutoIMS vehicles, to document assignment acceptance date */
  assignmentAccepted?: Maybe<Scalars['DateTime']['output']>;
  availableForPickupDate?: Maybe<Scalars['DateTime']['output']>;
  dropOffDate?: Maybe<Scalars['DateTime']['output']>;
  pickUpOrDropOffNote?: Maybe<Scalars['String']['output']>;
};

export type InventoryItemAssignmentInput = {
  assignmentAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  availableForPickupDate?: InputMaybe<Scalars['DateTime']['input']>;
  dropOffDate?: InputMaybe<Scalars['DateTime']['input']>;
  pickupOrDropOffNote?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of items. */
export type InventoryItemConnection = {
  __typename?: 'InventoryItemConnection';
  edges: Array<InventoryItemEdge>;
  facetGroups: Array<FacetGroup>;
  filterSections: Array<FilterSection>;
  pageInfo: FacetedPageInfo;
  sort: Array<SortOption>;
};

/** A connection to a list of items. */
export type InventoryItemConnectionfacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type InventoryItemEdge = {
  __typename?: 'InventoryItemEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: InventoryItem;
};

export type InventoryItemFee = {
  __typename?: 'InventoryItemFee';
  fuelFee?: Maybe<MonetaryAmount>;
  fuelFeeText?: Maybe<Scalars['String']['output']>;
  marshallingFee?: Maybe<MonetaryAmount>;
  marshallingFeeText?: Maybe<Scalars['String']['output']>;
  mechanicalFee?: Maybe<MonetaryAmount>;
  mechanicalFeeText?: Maybe<Scalars['String']['output']>;
  miscFee?: Maybe<MonetaryAmount>;
  miscFeeText?: Maybe<Scalars['String']['output']>;
  reconditioningFee?: Maybe<MonetaryAmount>;
  reconditioningFeeText?: Maybe<Scalars['String']['output']>;
  redemptionFee?: Maybe<MonetaryAmount>;
  redemptionFeeText?: Maybe<Scalars['String']['output']>;
  registrationFee?: Maybe<MonetaryAmount>;
  registrationFeeText?: Maybe<Scalars['String']['output']>;
  storageFee?: Maybe<MonetaryAmount>;
  storageFeeText?: Maybe<Scalars['String']['output']>;
  transportFee?: Maybe<MonetaryAmount>;
  transportFeeText?: Maybe<Scalars['String']['output']>;
};

export type InventoryItemMake = {
  __typename?: 'InventoryItemMake';
  id: Scalars['String']['output'];
  models?: Maybe<Array<Maybe<InventoryItemModel>>>;
  name: Scalars['String']['output'];
};

export type InventoryItemMakemodelsArgs = {
  modelId?: InputMaybe<Scalars['String']['input']>;
};

export type InventoryItemMetaData = {
  __typename?: 'InventoryItemMetaData';
  vehicleMetaData?: Maybe<VehicleMetaData>;
};

export type InventoryItemModel = {
  __typename?: 'InventoryItemModel';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  subModels?: Maybe<Array<Maybe<InventoryItemSubModel>>>;
};

export type InventoryItemModelsubModelsArgs = {
  subModelId?: InputMaybe<Scalars['String']['input']>;
};

export type InventoryItemOption = {
  __typename?: 'InventoryItemOption';
  id?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  selected: Scalars['Boolean']['output'];
};

export type InventoryItemPhotoCreateInput = {
  contentType: Scalars['String']['input'];
  damageLocation?: InputMaybe<Scalars['String']['input']>;
  inventoryItemId: Scalars['String']['input'];
  photoType: PhotoType;
  shotCode?: InputMaybe<Scalars['Int']['input']>;
  thumbnails?: InputMaybe<Scalars['String']['input']>;
};

export type InventoryItemPhotoInputObject = {
  damageLocation?: InputMaybe<Scalars['String']['input']>;
  /** Special field for reusing an existing photo. */
  existingCDNId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  /**
   * Base64 encoded photo data.
   * @deprecated Not used as photos are uploaded via a different mechanism
   */
  photo?: InputMaybe<Scalars['String']['input']>;
  /** Special field for reusing an existing photo. */
  photoSource?: InputMaybe<Scalars['String']['input']>;
  shotCode?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<PhotoType>;
};

export enum InventoryItemSource {
  AIM = 'AIM',
  AUTOIMS = 'AUTOIMS',
  AUTOVIN = 'AUTOVIN',
  CRSIMPLIFIED = 'CRSIMPLIFIED',
  EBLOCK = 'EBLOCK',
  EBLOCK_API = 'EBLOCK_API',
  EDGE = 'EDGE',
  MAGNET_DMS = 'MAGNET_DMS',
}

export type InventoryItemSubModel = {
  __typename?: 'InventoryItemSubModel';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  trims?: Maybe<Array<Maybe<InventoryItemTrim>>>;
};

export type InventoryItemTrim = {
  __typename?: 'InventoryItemTrim';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  year: Scalars['Int']['output'];
};

export type InventoryItemValue = {
  __typename?: 'InventoryItemValue';
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  expiryDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  type: InventoryItemValueType;
  updated?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  value: MonetaryAmount;
};

export type InventoryItemValueInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  expiryDate?: InputMaybe<Scalars['Date']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  valueType?: InputMaybe<InventoryItemValueTypeEnum>;
};

export type InventoryItemValueType = {
  __typename?: 'InventoryItemValueType';
  canAdd: Scalars['Boolean']['output'];
  canDelete: Scalars['Boolean']['output'];
  canUpdate: Scalars['Boolean']['output'];
  id: InventoryItemValueTypeEnum;
  name: Scalars['String']['output'];
};

export enum InventoryItemValueTypeEnum {
  BLACK_BOOK_AVG_COMBINED = 'BLACK_BOOK_AVG_COMBINED',
  BLACK_BOOK_AVG_WHOLE = 'BLACK_BOOK_AVG_WHOLE',
  BLACK_BOOK_CLEAN_COMBINED = 'BLACK_BOOK_CLEAN_COMBINED',
  BLACK_BOOK_CLEAN_MILEAGE_ADJ = 'BLACK_BOOK_CLEAN_MILEAGE_ADJ',
  BLACK_BOOK_CLEAN_WHOLE = 'BLACK_BOOK_CLEAN_WHOLE',
  BLACK_BOOK_MILEAGE_ADJ = 'BLACK_BOOK_MILEAGE_ADJ',
  BLACK_BOOK_ROUGH_COMBINED = 'BLACK_BOOK_ROUGH_COMBINED',
  BLACK_BOOK_ROUGH_MILEAGE_ADJ = 'BLACK_BOOK_ROUGH_MILEAGE_ADJ',
  BLACK_BOOK_ROUGH_WHOLE = 'BLACK_BOOK_ROUGH_WHOLE',
  COST = 'COST',
  KBB_MILEAGE_ADJUSTMENT = 'KBB_MILEAGE_ADJUSTMENT',
  KBB_NO_MILEAGE = 'KBB_NO_MILEAGE',
  KBB_TOTAL_LENDING = 'KBB_TOTAL_LENDING',
  MARKET_GUIDE_AVERAGE_SOLD_PRICE = 'MARKET_GUIDE_AVERAGE_SOLD_PRICE',
  MARKET_GUIDE_HIGHEST_SOLD_PRICE = 'MARKET_GUIDE_HIGHEST_SOLD_PRICE',
  MARKET_GUIDE_LOWEST_SOLD_PRICE = 'MARKET_GUIDE_LOWEST_SOLD_PRICE',
  MMR_ADJUSTED = 'MMR_ADJUSTED',
  SUGGESTED_RESERVE = 'SUGGESTED_RESERVE',
}

export type InventoryItemValueUpdateInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  /** Please set '0000-01-01' to nullify the value */
  expiryDate?: InputMaybe<Scalars['Date']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type InventoryService = Fee & {
  __typename?: 'InventoryService';
  active: Scalars['Boolean']['output'];
  amount: Scalars['BigDecimal']['output'];
  created: Scalars['DateTime']['output'];
  flagged: Scalars['Boolean']['output'];
  formattedAmount: Scalars['String']['output'];
  id: Scalars['String']['output'];
  inventoryItem: InventoryItem;
  invoiced: Scalars['Boolean']['output'];
  serviceMetadata: AuctionServiceMetadata;
  updated: Scalars['DateTime']['output'];
};

export type InventoryServiceCreateInput = {
  amount: Scalars['Float']['input'];
  /** send paymentTypeId, terms, auctionItemId, for auto-invoicing, if permission allows */
  feeAndInvoice?: InputMaybe<ForceFeeInvoice>;
  flagged?: Scalars['Boolean']['input'];
  inventoryItemId: Scalars['String']['input'];
  serviceMetadataId: Scalars['String']['input'];
  /** @deprecated no longer needed */
  transportRequestId?: InputMaybe<Scalars['String']['input']>;
  /** at most one of reference ids can be passed */
  vehicleRepairItemId?: InputMaybe<Scalars['String']['input']>;
};

export type InventoryServiceUpdateInput = {
  amount: Scalars['Float']['input'];
  flagged?: Scalars['Boolean']['input'];
  inventoryFeeId: Scalars['String']['input'];
};

export type Invoice = {
  __typename?: 'Invoice';
  accountingSystemLocalPrimary: Scalars['Boolean']['output'];
  /** references to invoice adjusted by this one */
  adjustedByInvoices?: Maybe<Array<Invoice>>;
  /** reference to original adjusted invoice */
  adjustedInvoice?: Maybe<Invoice>;
  amount: MonetaryAmount;
  auction: Auction;
  auctionItem?: Maybe<AuctionItem>;
  canChangePaymentType: Scalars['Boolean']['output'];
  company: Company;
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  credit: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  draft: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  inventoryItemDescription?: Maybe<Scalars['String']['output']>;
  inventoryItemId?: Maybe<Scalars['String']['output']>;
  /** Human readable id */
  invoiceDisplayId: Scalars['String']['output'];
  /** URL of the downloadable invoice receipt (PDF) */
  invoiceReceiptUrl: Scalars['String']['output'];
  invoiceType: InvoiceType;
  paymentBlockers: Array<PaymentBlocker>;
  paymentStatus?: Maybe<InvoicePaymentStatus>;
  /** Default payment type */
  paymentType?: Maybe<PaymentType>;
  payments: Array<Payment>;
  reason?: Maybe<InvoiceReason>;
  /** Id in external finance system */
  referenceId?: Maybe<Scalars['String']['output']>;
  /** Tracking number set on creation */
  referenceNumber?: Maybe<Scalars['String']['output']>;
  /** Status in external finance system */
  referenceStatus?: Maybe<Scalars['String']['output']>;
  /** Link to record in external finance system */
  referenceUrl?: Maybe<Scalars['String']['output']>;
  subtotals: Array<InvoiceSubtotal>;
  terms?: Maybe<Terms>;
  total: MonetaryAmount;
  /** Sale transaction or standalone transaction this invoice is for */
  transactionNumber: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
  vin?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type InvoiceConnection = {
  __typename?: 'InvoiceConnection';
  edges: Array<InvoiceEdge>;
  facetGroups: Array<FacetGroup>;
  filterSections: Array<FilterSection>;
  pageInfo: FacetedPageInfo;
  sort: Array<SortOption>;
};

/** A connection to a list of items. */
export type InvoiceConnectionfacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type InvoiceEdge = {
  __typename?: 'InvoiceEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: Invoice;
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  /** Positive amount = Payable, Negative amount = Receivable */
  amount: MonetaryAmount;
  creditable: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export enum InvoicePaymentStatus {
  /** Transaction was cancelled...needs reversing */
  CANCELLED = 'CANCELLED',
  DRAFT = 'DRAFT',
  FULLY_PAID = 'FULLY_PAID',
  OUTSTANDING = 'OUTSTANDING',
  PARTIAL = 'PARTIAL',
}

export enum InvoiceReason {
  ARBITRATION = 'ARBITRATION',
  GOODWILL = 'GOODWILL',
  SALE_CANCELLED = 'SALE_CANCELLED',
  SERVICE_ADDED = 'SERVICE_ADDED',
  SERVICE_CANCELLED = 'SERVICE_CANCELLED',
  WRITE_OFF = 'WRITE_OFF',
}

export type InvoiceSignature = {
  __typename?: 'InvoiceSignature';
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
  signatureUrl: Scalars['String']['output'];
};

export type InvoiceSubtotal = {
  __typename?: 'InvoiceSubtotal';
  formattedTaxRate: Scalars['String']['output'];
  lineItems: Array<InvoiceItem>;
  regionCode?: Maybe<Scalars['String']['output']>;
  tax: MonetaryAmount;
  taxRate: Scalars['Float']['output'];
  total: MonetaryAmount;
};

export enum InvoiceType {
  BUYER_CREDIT = 'BUYER_CREDIT',
  BUYER_INVOICE = 'BUYER_INVOICE',
  BUYER_INVOICE_ADJUSTMENT = 'BUYER_INVOICE_ADJUSTMENT',
  OVERPAYMENT = 'OVERPAYMENT',
  SELLER_BILL = 'SELLER_BILL',
  SELLER_CREDIT = 'SELLER_CREDIT',
  SELLER_INVOICE = 'SELLER_INVOICE',
  SELLER_INVOICE_ADJUSTMENT = 'SELLER_INVOICE_ADJUSTMENT',
  SERVICE_CREDIT = 'SERVICE_CREDIT',
  SERVICE_INVOICE = 'SERVICE_INVOICE',
}

export type JobQuoteInput = {
  carrierId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated not used */
  notes?: InputMaybe<Scalars['String']['input']>;
  shipmentMode?: InputMaybe<CarrierShipmentMode>;
  transportJobId: Scalars['String']['input'];
  transportRequestQuotes: Array<TransportRequestQuoteInput>;
};

export type JournalEntry = {
  __typename?: 'JournalEntry';
  accountingSystemTransactionNumber?: Maybe<Scalars['String']['output']>;
  attachments?: Maybe<Array<Attachment>>;
  created: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  journalDate: Scalars['DateTime']['output'];
  memo?: Maybe<Scalars['String']['output']>;
  netsuiteId?: Maybe<Scalars['String']['output']>;
  payment: Payment;
  reason: JournalEntryReason;
  /** Link to record in external finance system */
  referenceUrl?: Maybe<Scalars['String']['output']>;
  status: JournalEntryStatus;
  updated: Scalars['DateTime']['output'];
};

export enum JournalEntryReason {
  ACCOUNT_CLOSED = 'ACCOUNT_CLOSED',
  CANCELLED_SALE = 'CANCELLED_SALE',
  DUPLICATE_AFC_PAYMENT = 'DUPLICATE_AFC_PAYMENT',
  DUPLICATE_CHEQUE_PAYMENT = 'DUPLICATE_CHEQUE_PAYMENT',
  DUPLICATE_DEPOSIT_PAYMENT = 'DUPLICATE_DEPOSIT_PAYMENT',
  DUPLICATE_ESP_CAPITAL_INC_PAYMENT = 'DUPLICATE_ESP_CAPITAL_INC_PAYMENT',
  DUPLICATE_ETRANSFER_PAYMENT = 'DUPLICATE_ETRANSFER_PAYMENT',
  DUPLICATE_PAD_PAYMENT = 'DUPLICATE_PAD_PAYMENT',
  DUPLICATE_TD_FINANCE_PAYMENT = 'DUPLICATE_TD_FINANCE_PAYMENT',
  DUPLICATE_WIRE_PAYMENT = 'DUPLICATE_WIRE_PAYMENT',
  FAILED_PAYMENT = 'FAILED_PAYMENT',
  INCORRECT_BANK_DETAILS = 'INCORRECT_BANK_DETAILS',
  INVOICE_NEEDS_TO_BE_CREDITED = 'INVOICE_NEEDS_TO_BE_CREDITED',
  NOT_SUFFICIENT_FUNDS = 'NOT_SUFFICIENT_FUNDS',
  PAD_HOLD_APPROVED_BY_ARBITRATION = 'PAD_HOLD_APPROVED_BY_ARBITRATION',
  PAD_HOLD_APPROVED_BY_MANAGEMENT = 'PAD_HOLD_APPROVED_BY_MANAGEMENT',
  PROCESSED_BY_MISTAKE = 'PROCESSED_BY_MISTAKE',
  REQUESTED_PAYMENT_VIA_FLOORING = 'REQUESTED_PAYMENT_VIA_FLOORING',
  RETURN_PAYMENT_TO_CUSTOMER = 'RETURN_PAYMENT_TO_CUSTOMER',
  STOPPED_RECALLED_PAYMENT = 'STOPPED_RECALLED_PAYMENT',
  VOID_CUSTOMER_REFUND = 'VOID_CUSTOMER_REFUND',
}

export enum JournalEntryStatus {
  APPROVED = 'APPROVED',
  CREATED = 'CREATED',
}

export type LaneInput = {
  auctionLaneId?: InputMaybe<Scalars['String']['input']>;
  controlType?: InputMaybe<AuctionLaneControlType>;
  description: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  type: AuctionLaneType;
};

export enum LaneSlotType {
  /** There is a vehicle intended for a lane slot */
  INTENDED = 'INTENDED',
  /** This slot is reserved by a seller */
  RESERVED = 'RESERVED',
}

export type LineItemAdjustInput = {
  amount: Scalars['Float']['input'];
  /** fee id for new adjusted invoice, should provide one of [feeId|lineItemId] */
  feeId?: InputMaybe<Scalars['String']['input']>;
  /** line item id from existing invoice that this is adjusting, should provide one of [feeId|lineItemId] */
  lineItemId?: InputMaybe<Scalars['String']['input']>;
  taxExempt?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LiveAuctionItem = {
  __typename?: 'LiveAuctionItem';
  asIs: Scalars['Boolean']['output'];
  atAmount?: Maybe<Scalars['Float']['output']>;
  autoGradeScore?: Maybe<Scalars['Float']['output']>;
  captureType?: Maybe<VehicleCaptureType>;
  conditionScore?: Maybe<Scalars['Float']['output']>;
  consignerId: Scalars['String']['output'];
  currencyCode?: Maybe<Scalars['String']['output']>;
  displayRunNumber?: Maybe<Scalars['String']['output']>;
  furtherBidIncrement?: Maybe<Scalars['Float']['output']>;
  hasBuyerShield?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  isAssured?: Maybe<Scalars['Boolean']['output']>;
  isReserveMet: Scalars['Boolean']['output'];
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  mileage: Scalars['Float']['output'];
  name1: Scalars['String']['output'];
  name2: Scalars['String']['output'];
  nextAmount?: Maybe<Scalars['Float']['output']>;
  odometer?: Maybe<Mileage>;
  photoFull?: Maybe<Scalars['String']['output']>;
  photoThumb?: Maybe<Scalars['String']['output']>;
  regionCode: Scalars['String']['output'];
  runNumber?: Maybe<Scalars['Int']['output']>;
  saleLights: Array<SaleLight>;
  sellerIntent?: Maybe<AuctionItemStatus>;
  state: LiveAuctionItemState;
  tier?: Maybe<Scalars['String']['output']>;
  totalBids: Scalars['Int']['output'];
  uniqueBidders: Array<Scalars['String']['output']>;
  userIdsWatching?: Maybe<Array<Scalars['String']['output']>>;
  winningBid?: Maybe<LiveBid>;
};

export enum LiveAuctionItemState {
  BID = 'BID',
  END = 'END',
  INIT = 'INIT',
  PAUSED = 'PAUSED',
}

export type LiveAuctionSettings = {
  __typename?: 'LiveAuctionSettings';
  auctionCutOff: Scalars['Int']['output'];
  digitalCheckoutExpiration: Scalars['Int']['output'];
  digitalIfBidExpiration: Scalars['Int']['output'];
  digitalLaneAutoAssignment: Scalars['Boolean']['output'];
  laneInitTimeInSeconds: Scalars['Int']['output'];
  numberOfFutureEvents: Scalars['Int']['output'];
  physicalCheckoutExpiration: Scalars['Int']['output'];
  physicalIfBidExpiration: Scalars['Int']['output'];
  schedules: Array<Scalars['String']['output']>;
  showStartPriceUpcoming: Scalars['Boolean']['output'];
};

export type LiveAuctionSettingsInput = {
  auctionCutOff?: InputMaybe<Scalars['Int']['input']>;
  digitalCheckoutExpiration?: InputMaybe<Scalars['Int']['input']>;
  digitalIfBidExpiration?: InputMaybe<Scalars['Int']['input']>;
  digitalLaneAutoAssignment?: InputMaybe<Scalars['Boolean']['input']>;
  laneInitTimeInSeconds?: InputMaybe<Scalars['Int']['input']>;
  lanes?: InputMaybe<Array<LaneInput>>;
  numberOfFutureEvents?: InputMaybe<Scalars['Int']['input']>;
  physicalCheckoutExpiration?: InputMaybe<Scalars['Int']['input']>;
  physicalIfBidExpiration?: InputMaybe<Scalars['Int']['input']>;
  schedules?: InputMaybe<Array<Scalars['String']['input']>>;
  showStartPriceUpcoming?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LiveBid = {
  __typename?: 'LiveBid';
  amount: Scalars['Float']['output'];
  consigner: Scalars['String']['output'];
  consignerId?: Maybe<Scalars['String']['output']>;
  type: BidType;
};

export type LiveLane = {
  __typename?: 'LiveLane';
  auctionId: Scalars['String']['output'];
  auctionItemIdsWatching: Array<Scalars['String']['output']>;
  auctionItemIdsWithAutoBids: Array<Scalars['String']['output']>;
  auctionName: Scalars['String']['output'];
  controlType: AuctionLaneControlType;
  id: Scalars['String']['output'];
  itemsRemaining: Scalars['Int']['output'];
  liveItem?: Maybe<LiveAuctionItem>;
  name: Scalars['String']['output'];
  nextItemId?: Maybe<Scalars['String']['output']>;
  sequence: Scalars['Long']['output'];
  startTime: Scalars['DateTime']['output'];
  status: LiveLaneStatus;
  timeSlotId: Scalars['String']['output'];
  timerEnd?: Maybe<Scalars['DateTime']['output']>;
  timerStart?: Maybe<Scalars['DateTime']['output']>;
  type: LiveLaneType;
};

export type LiveLaneConversationGroup = {
  __typename?: 'LiveLaneConversationGroup';
  liveLaneBroadcastConversation: Conversation;
  liveLaneConversation?: Maybe<Conversation>;
};

export enum LiveLaneStatus {
  END = 'END',
  INIT = 'INIT',
  RUNNING = 'RUNNING',
  WAITING = 'WAITING',
}

export enum LiveLaneType {
  DIGITAL = 'DIGITAL',
  PHYSICAL = 'PHYSICAL',
}

export type Location = {
  __typename?: 'Location';
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  /** The country code of the location. (2 letters) */
  countryCode: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  distanceAway?: Maybe<Scalars['Float']['output']>;
  dropOffInstructions?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fax?: Maybe<Scalars['String']['output']>;
  formattedTaxRateRegionCode: Scalars['String']['output'];
  id: Scalars['String']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  pickupInstructions?: Maybe<Scalars['String']['output']>;
  public: Scalars['Boolean']['output'];
  /** The region of the location (state/province). */
  region: Scalars['String']['output'];
  /** The region code of the location. (2 letters) */
  regionCode: Scalars['String']['output'];
  taxRateForRegionCode: Scalars['Float']['output'];
  updated: Scalars['DateTime']['output'];
  zipCode: Scalars['String']['output'];
};

export type LocationdistanceAwayArgs = {
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
};

/** A connection to a list of items. */
export type LocationConnection = {
  __typename?: 'LocationConnection';
  edges: Array<LocationEdge>;
  facetGroups: Array<FacetGroup>;
  filterSections: Array<FilterSection>;
  pageInfo: FacetedPageInfo;
  sort: Array<SortOption>;
};

/** A connection to a list of items. */
export type LocationConnectionfacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum LocationConnectionMode {
  COMPOUNDS_ONLY = 'COMPOUNDS_ONLY',
  OWN_AND_COMPOUNDS = 'OWN_AND_COMPOUNDS',
  OWN_ONLY = 'OWN_ONLY',
}

export type LocationEdge = {
  __typename?: 'LocationEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: Location;
};

export type LocationInventoryService = {
  __typename?: 'LocationInventoryService';
  active: Scalars['Boolean']['output'];
  amount: Scalars['BigDecimal']['output'];
  created: Scalars['DateTime']['output'];
  formattedAmount: Scalars['String']['output'];
  id: Scalars['String']['output'];
  location: Location;
  serviceMetadata: AuctionServiceMetadata;
  updated: Scalars['DateTime']['output'];
};

export type LocationPublic = {
  __typename?: 'LocationPublic';
  country: Scalars['String']['output'];
  /** The country code of the location. (2 letters) */
  countryCode: Scalars['String']['output'];
  distanceAway?: Maybe<Scalars['Float']['output']>;
  /** The region of the location (state/province). */
  region: Scalars['String']['output'];
  /** The region code of the location. (2 letters) */
  regionCode: Scalars['String']['output'];
};

export type LogEntry = {
  __typename?: 'LogEntry';
  color: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  message: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type MaintenanceTask = {
  __typename?: 'MaintenanceTask';
  attempt: Scalars['Int']['output'];
  created: Scalars['DateTime']['output'];
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  processed: Scalars['Int']['output'];
  startedAt: Scalars['DateTime']['output'];
  stoppedByUser?: Maybe<User>;
  taskName: Scalars['String']['output'];
  total: Scalars['Int']['output'];
  updated: Scalars['DateTime']['output'];
};

export type Make = {
  __typename?: 'Make';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type MakeModelFilter = {
  makeId: Scalars['String']['input'];
  maxMileage?: InputMaybe<Scalars['Int']['input']>;
  minMileage?: InputMaybe<Scalars['Int']['input']>;
  modelId?: InputMaybe<Scalars['String']['input']>;
  yearFrom?: InputMaybe<Scalars['Int']['input']>;
  yearTo?: InputMaybe<Scalars['Int']['input']>;
};

export type MakeOfferInput = {
  amount: Scalars['Float']['input'];
  appliedToBuyNow?: InputMaybe<Scalars['Boolean']['input']>;
  auctionItemId: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  offerId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type MarketGuideMetadata = {
  __typename?: 'MarketGuideMetadata';
  averagePrice: MonetaryAmount;
  highestPrice: MonetaryAmount;
  lowestPrice: MonetaryAmount;
};

export type Mileage = {
  __typename?: 'Mileage';
  amount?: Maybe<Scalars['Int']['output']>;
  formattedAmount?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
};

export type MileageInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type MinimumSupportedApplicationVersions = {
  __typename?: 'MinimumSupportedApplicationVersions';
  android: Scalars['String']['output'];
  ios: Scalars['String']['output'];
  truffleIos: Scalars['String']['output'];
};

export type Model = {
  __typename?: 'Model';
  id: Scalars['String']['output'];
  makeId: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type MonetaryAmount = {
  __typename?: 'MonetaryAmount';
  amount: Scalars['Float']['output'];
  currencyCode: Scalars['String']['output'];
  formattedAmount: Scalars['String']['output'];
  /** Removes any 'cents' from the formatted value. */
  formattedAmountRounded: Scalars['String']['output'];
};

export enum MoveMode {
  MOVE = 'MOVE',
  SWAP = 'SWAP',
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Starts the automated (digital) new lane launching and ending lane shutdown tasks */
  auctionAutomatedLaneManagerStartTasks: Scalars['Boolean']['output'];
  /** Stops the automated (digital) new lane launching and ending lane shutdown tasks */
  auctionAutomatedLaneManagerStopTasks: Scalars['Boolean']['output'];
  auctionClone: Auction;
  auctionCreate: Auction;
  auctionItemAcceptOffer: AuctionItem;
  /** @deprecated no longer used */
  auctionItemArbitrationUpdate: AuctionItem;
  auctionItemArchive: AuctionItem;
  auctionItemArrived: AuctionItem;
  auctionItemAssignTimeSlotLane: AuctionItem;
  /** Creates fee of type FIXED|TIERED associated with an auction item */
  auctionItemAuctionServiceCreate?: Maybe<AuctionService>;
  auctionItemBulkCreate: Array<AuctionItem>;
  auctionItemBuyNow: AuctionItem;
  auctionItemCancelPendingDelivery: AuctionItem;
  auctionItemCancelSale: AuctionItem;
  auctionItemChangeBuyer: AuctionItem;
  auctionItemCheckoutAndPay?: Maybe<Array<Payment>>;
  /** Claims multiple If Bids. When userId present, then ifBids assigned to that user. */
  auctionItemClaimIfBids: Array<AuctionItem>;
  auctionItemCompleteCheckout: CheckoutResponse;
  auctionItemCreate: AuctionItem;
  auctionItemDeclarationsUpdate: AuctionItem;
  auctionItemDeclineOffer: AuctionItem;
  auctionItemIfBidAccept: AuctionItem;
  auctionItemIfBidCancel: AuctionItem;
  auctionItemIfBidCounter: AuctionItem;
  auctionItemMakeOffer: AuctionItem;
  /** Moves auction items from one lane to another. Shifts existing auction items in the lane and places incoming auction items at runNumber position */
  auctionItemMove: Array<AuctionItem>;
  auctionItemOrderServiceAdd: AuctionItemOrder;
  auctionItemOrderServiceRemove: AuctionItemOrder;
  auctionItemOrderUpdate: AuctionItemOrder;
  auctionItemOrdersPickupLocationUpdate: Array<AuctionItemOrder>;
  auctionItemOrdersServicesAdd: Array<AuctionItemOrder>;
  /** Override top Online Bid by a Floor Bid of equal amount */
  auctionItemOverrideOnlineBid: AuctionItem;
  auctionItemPhysicalClearBids: AuctionItem;
  auctionItemPhysicalEnd: AuctionItem;
  auctionItemPhysicalPause: AuctionItem;
  auctionItemPhysicalRerun: AuctionItem;
  auctionItemPhysicalResume: AuctionItem;
  auctionItemPhysicalRetractBid: AuctionItem;
  auctionItemPhysicalStart: AuctionItem;
  auctionItemPullFromAuction: AuctionItem;
  auctionItemRearrangeUpcoming: Array<AuctionItem>;
  auctionItemReleaseForBuyerPickup?: Maybe<AuctionItem>;
  auctionItemReleaseForTransport?: Maybe<AuctionItem>;
  auctionItemRemoveLastBid: AuctionItem;
  auctionItemRequestArbitration: AuctionItem;
  auctionItemRequestTitle: AuctionItem;
  auctionItemReserveMet: AuctionItem;
  auctionItemSellDirect: AuctionItem;
  auctionItemSellInternal: AuctionItem;
  auctionItemSetAsIs: AuctionItem;
  auctionItemSetAutoBid: AuctionItem;
  auctionItemSetBidIncrement: AuctionItem;
  auctionItemSetHoldback?: Maybe<AuctionItem>;
  auctionItemSetSellerIntent: AuctionItem;
  auctionItemSetStartPrice: AuctionItem;
  auctionItemSetWatching: AuctionItem;
  auctionItemSubmitBid: AuctionItem;
  auctionItemSubmitFloorBid: AuctionItem;
  auctionItemSwapUpcoming: Array<AuctionItem>;
  auctionItemSwapUpcomingByIds: Array<AuctionItem>;
  auctionItemTransferNoSale: InventoryItem;
  auctionItemTransferRunning: AuctionItem;
  auctionItemTransferSale: AuctionItem;
  auctionItemUpdate?: Maybe<AuctionItem>;
  auctionItemUpdateHold: AuctionItem;
  auctionItemUpdatePaymentType: AuctionItem;
  auctionLaneBidIncrementUpdate?: Maybe<Array<Maybe<AuctionLaneBidIncrement>>>;
  auctionLaneReservationCreate: AuctionLaneReservation;
  auctionLaneReservationUpdate: AuctionLaneReservation;
  auctionLaneUpdate?: Maybe<AuctionLane>;
  auctionPhysicalLaneAssignClerk?: Maybe<AuctionTimeSlotLane>;
  auctionPhysicalLaneEnd?: Maybe<AuctionTimeSlotLane>;
  auctionPhysicalLaneStart?: Maybe<AuctionTimeSlotLane>;
  /**
   * Creates fee of type FIXED|TIERED associated with a particular auction.
   * If companyId is provided - creates fee associated with auction and company.
   */
  auctionServiceCreate?: Maybe<AuctionService>;
  auctionServiceDefaultSet?: Maybe<CompanyAuctionRelationship>;
  auctionServiceRemove?: Maybe<Scalars['Boolean']['output']>;
  auctionServicesCreate: Array<AuctionService>;
  auctionSettingsUpdate?: Maybe<Auction>;
  auctionTimeSlotChangeCutoff?: Maybe<AuctionTimeSlot>;
  auctionTimeSlotLaneCreate: AuctionTimeSlotLane;
  /** Restarts (stops/starts) the lane runner */
  auctionTimeSlotLaneRestart: Scalars['Boolean']['output'];
  /** Stops the lane runner */
  auctionTimeSlotLaneStop: Scalars['Boolean']['output'];
  auctionTimeSlotLaneUpdate?: Maybe<AuctionTimeSlotLane>;
  auctionUpdate: Auction;
  authenticatePipeline?: Maybe<User>;
  /**
   * Creates, edits or deletes vehicle's AutoGrade damage records with optional photos attached to each damage record.
   * The vehicle can have only one damage record per specific AutoGradeDamageCodeRecord.
   * The number of damages per vehicle is limited only by the total number of AutoGrade damage codes.
   */
  autoGradeVehicleDamageUpdate?: Maybe<Array<AutoGradeVehicleDamage>>;
  autoVinImportVehicle?: Maybe<Array<InventoryItem>>;
  bidBadgeDelete: Scalars['String']['output'];
  bidBadgesCreate: Array<BidBadge>;
  broadcastCreate: Broadcast;
  broadcastUpdate: Broadcast;
  carrierCreate?: Maybe<Carrier>;
  carrierUpdate?: Maybe<Carrier>;
  chargeInstructionCreate?: Maybe<Array<ChargeInstruction>>;
  chargeInstructionRemove: Scalars['Boolean']['output'];
  chargeInstructionUpdate: ChargeInstruction;
  companyAccountingSystemRelationshipUpdate?: Maybe<CompanyAccountingSystemRelationship>;
  companyAuctionRelationshipCreate?: Maybe<CompanyAuctionRelationship>;
  companyAuctionRelationshipUpdate?: Maybe<CompanyAuctionRelationship>;
  companyAuctionRelationshipUpdateSellerNotes?: Maybe<CompanyAuctionRelationship>;
  companyAuctionRelationshipsCreate?: Maybe<Company>;
  companyAuctionRepresentativeAssign?: Maybe<Scalars['Boolean']['output']>;
  companyAuctionRepsSet?: Maybe<CompanyAuctionRelationship>;
  companyContactCreate?: Maybe<Company>;
  companyContactUpdate?: Maybe<Company>;
  companyCreate?: Maybe<Company>;
  companyCreateIntegration?: Maybe<CompanyIntegration>;
  companyDenylist?: Maybe<Company>;
  companyFeeCreateFromFile: Array<CompanyService>;
  companyFeeUpload: Attachment;
  companyInvoiceSignatureSet?: Maybe<Company>;
  companySendBuyerSummary?: Maybe<Scalars['Boolean']['output']>;
  companyServiceCreate: CompanyService;
  companyServiceRemove?: Maybe<Scalars['Boolean']['output']>;
  companyServiceUpdate: CompanyService;
  companyUpdateAmericanLegalInfo?: Maybe<Company>;
  companyUpdateCanadianLegalInfo?: Maybe<Company>;
  companyUpdateCredits?: Maybe<Company>;
  companyUpdateHoldback?: Maybe<Company>;
  companyUpdateInfo?: Maybe<Company>;
  companyUpdateIntegration?: Maybe<CompanyIntegration>;
  companyUpdateRestrictCarfaxOrdering: Company;
  companyUpdateVisibleRegions?: Maybe<Company>;
  emailContentReleaseForBuyerPickup?: Maybe<EmailContent>;
  emailContentReleaseForSellerPickup?: Maybe<EmailContent>;
  emailContentReleaseForTransport?: Maybe<EmailContent>;
  /** Resends an email from history to a list of emails */
  emailResend?: Maybe<Scalars['Boolean']['output']>;
  employeeAuthenticate?: Maybe<User>;
  entityCacheClear: Scalars['Boolean']['output'];
  entityTriggerIndex: Scalars['Boolean']['output'];
  externalIdSet: ExternalId;
  /** deletes feature flag */
  featureFlagRemove: FeatureFlag;
  /** Creates or updates feature flag */
  featureFlagSet: FeatureFlag;
  feeMetadataUpdateFromFile: Scalars['Boolean']['output'];
  feeMetadataUpload: Attachment;
  importBankingInformation: Scalars['String']['output'];
  /** Accepting a proposal when one has already been accepted will replace the previous. */
  inspectionAcceptProposal?: Maybe<Inspection>;
  inspectionAddInventoryItem?: Maybe<Inspection>;
  inspectionAssign?: Maybe<Inspection>;
  inspectionCancel?: Maybe<Inspection>;
  inspectionComplete?: Maybe<Inspection>;
  inspectionCreate?: Maybe<Inspection>;
  inspectionProposalCreate?: Maybe<Inspection>;
  inventoryFeeCreateFromFile: Array<InventoryService>;
  inventoryFeeUpload: Attachment;
  inventoryItemArchive?: Maybe<InventoryItem>;
  inventoryItemAssignmentAccept: Array<InventoryItem>;
  inventoryItemAssignmentUpdate?: Maybe<InventoryItem>;
  inventoryItemCarfaxCanadaAttachReport?: Maybe<InventoryItem>;
  inventoryItemChangeLocation?: Maybe<InventoryItem>;
  inventoryItemCheckIn?: Maybe<InventoryItem>;
  inventoryItemCheckOut?: Maybe<InventoryItem>;
  inventoryItemCreate?: Maybe<BuilderType>;
  inventoryItemFeeUpdate2?: Maybe<InventoryItem>;
  inventoryItemPhotoCreate?: Maybe<PhotosDTO>;
  inventoryItemReleaseForSellerPickup?: Maybe<InventoryItem>;
  inventoryItemShare?: Maybe<Scalars['String']['output']>;
  inventoryItemTransfer?: Maybe<InventoryItem>;
  inventoryItemUpdate?: Maybe<BuilderType>;
  inventoryItemUpdateBlueDriverScanData: InventoryItem;
  inventoryItemValueCreate?: Maybe<InventoryItem>;
  inventoryItemValueUpdate?: Maybe<InventoryItem>;
  inventoryItemValuesFetchBlackBookValues: Array<InventoryItemValue>;
  /** @deprecated use inventoryServiceCreate2 */
  inventoryServiceCreate: InventoryService;
  inventoryServiceCreate2: InventoryService;
  inventoryServiceRemove?: Maybe<Scalars['Boolean']['output']>;
  inventoryServiceUpdate: InventoryService;
  /** Creates new adjustment invoice (credit or invoice) from an invoice's (invoice or credit) subset of line items */
  invoiceAdjust?: Maybe<Invoice>;
  /** Cancels/reverses and auto applies all invoices for a sale (currently only after sale canceled) */
  invoiceCancel: Array<Invoice>;
  /** Deletes the invoice. Only draft invoices allowed to be deleted currently. */
  invoiceDelete: Array<Scalars['String']['output']>;
  /** Creates new payment, of a given type, that is combined with any specified credits and applied to specified debits */
  invoiceProcessPayment: Array<Payment>;
  /** Creates new refund of specified credits minus any specified debits */
  invoiceRefund: Array<Payment>;
  /** Sends an email to the invoiced company billing contacts with the current invoice */
  invoiceSendEmail?: Maybe<Scalars['Boolean']['output']>;
  /** updates payment type (and terms) on invoices */
  invoicesUpdatePaymentType: Array<Invoice>;
  liveLaneConversationRegister: LiveLaneConversationGroup;
  locationCreate?: Maybe<Location>;
  locationInventoryServiceCreate: LocationInventoryService;
  locationInventoryServiceRemove: Scalars['String']['output'];
  locationUpdate?: Maybe<Location>;
  logoutCarfaxUSASession: User;
  netsuiteRecordResync: Scalars['Boolean']['output'];
  noteCreate?: Maybe<Note>;
  orderCarfaxUSAReport: CarfaxUSAReportResponse;
  /** Cancel the payment, reverse the invoices, create Journal Entry, re-sync everything */
  paymentCancel: Payment;
  /** mark payments as deposited */
  paymentMarkDeposited: PaymentDeposit;
  /**
   * The final step in completing a lead. Perform once the user has been added to a
   * consigner. Any admin may change the lead status. Client should perform validation on
   * lead checkout.
   */
  requestedInviteChangeStatus?: Maybe<RequestedInvite>;
  requestedInviteCreate?: Maybe<RequestedInviteSuccess>;
  resendNotification: Scalars['Boolean']['output'];
  savedFilterCreate?: Maybe<SavedFilter>;
  savedFilterDelete?: Maybe<User>;
  savedFilterUpdate?: Maybe<SavedFilter>;
  serviceInvoicesCreate: Array<Invoice>;
  /** stops execution of maintenance task for the next processed page */
  stopMaintenanceTask?: Maybe<MaintenanceTask>;
  taskCreate: Task;
  taskDelete: Scalars['String']['output'];
  taskUpdate: Array<Task>;
  timelineActionAdd2: Array<TimelineAction>;
  timelineActionDelete: Scalars['String']['output'];
  titleAttachmentDelete?: Maybe<VehicleTitle>;
  titleUpdate?: Maybe<VehicleTitle>;
  transportFeeCacheClear: Scalars['Long']['output'];
  transportJobCancelAll?: Maybe<TransportJob>;
  transportJobCreate?: Maybe<TransportJob>;
  transportJobQuoteAccept?: Maybe<TransportJob>;
  transportJobQuoteCreate?: Maybe<TransportJob>;
  transportJobQuoteInvite?: Maybe<TransportJob>;
  transportJobQuoteUpdate?: Maybe<TransportJob>;
  transportJobUpdate?: Maybe<TransportJob>;
  transportRateUpdate: Scalars['Boolean']['output'];
  transportRateUpload: Attachment;
  transportRequestBulkUpdate: Array<TransportRequest>;
  transportRequestCancel?: Maybe<TransportRequest>;
  transportRequestCreate?: Maybe<TransportRequest>;
  transportRequestDelivered?: Maybe<TransportRequest>;
  transportRequestUpdate?: Maybe<TransportRequest>;
  triggerReindex?: Maybe<Scalars['String']['output']>;
  updateCarrierSuperDispatch?: Maybe<Carrier>;
  userAccountSendVerificationCode: Scalars['Boolean']['output'];
  userAccountVerify?: Maybe<User>;
  userActAsUser?: Maybe<User>;
  userAuthenticate?: Maybe<AuthResponse>;
  userClearActingUser?: Maybe<User>;
  userCompanyRelationshipCreate?: Maybe<UserCompanyRelationship>;
  userCompanyRelationshipUpdate?: Maybe<UserCompanyRelationship>;
  userCreate?: Maybe<User>;
  userCreateAuctionAccess?: Maybe<User>;
  userDelete: Scalars['Boolean']['output'];
  userForgotPassword?: Maybe<ForgotPasswordMessage>;
  userLogoutSession?: Maybe<User>;
  userNotificationDeviceSubscribe?: Maybe<User>;
  userNotificationSettingsUpdate?: Maybe<User>;
  userResetPassword?: Maybe<User>;
  userUpdateAuctionAccessId?: Maybe<User>;
  userUpdateDefaultClientRoute?: Maybe<User>;
  userUpdateInfo?: Maybe<User>;
  userUpdateInfoAsAdmin?: Maybe<User>;
  userUpdateLocaleTimeZone?: Maybe<User>;
  userUpdateOmvic?: Maybe<User>;
  userUpdatePassword?: Maybe<User>;
  userUpdatePhotoAuctionAccess?: Maybe<User>;
  userUpdatePreferredLocationDistanceUnit?: Maybe<User>;
  userVerifyAuthentication?: Maybe<AuthVerificationResponse>;
  vehicleMakeAdd?: Maybe<Make>;
  vehicleModelAdd?: Maybe<Model>;
  /**
   * Creates, edits or deletes vehicle's VehicleRepairItems
   * The vehicle can have only one repair record per specific AutoGradeDamageCodeRecord.
   */
  vehicleRepairItemUpdate: VehicleRepairItem;
  vehicleSubModelAdd?: Maybe<SubModel>;
  vehicleTrimAdd?: Maybe<Trim>;
  workflowProcessUpdate?: Maybe<Array<Scalars['String']['output']>>;
};

export type MutationauctionCloneArgs = {
  cloneAuctionLanes: Scalars['Boolean']['input'];
  cloneFees: Scalars['Boolean']['input'];
  fromAuctionId: Scalars['String']['input'];
  toAuctionId: Scalars['String']['input'];
};

export type MutationauctionCreateArgs = {
  input: AuctionCreateInput;
};

export type MutationauctionItemAcceptOfferArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  offerId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationauctionItemArbitrationUpdateArgs = {
  arbiterId?: InputMaybe<Scalars['String']['input']>;
  auctionItemId: Scalars['String']['input'];
  cancelled?: InputMaybe<Scalars['Boolean']['input']>;
  compensationComment?: InputMaybe<Scalars['String']['input']>;
  compensationPaidByCarrier?: InputMaybe<Scalars['Float']['input']>;
  compensationPaidByEBlock?: InputMaybe<Scalars['Float']['input']>;
  compensationPaidByEBlockType?: InputMaybe<ArbitrationCompensationType>;
  compensationPaidBySeller?: InputMaybe<Scalars['Float']['input']>;
  compensationToBuyer?: InputMaybe<Scalars['Float']['input']>;
  onTime?: InputMaybe<Scalars['Boolean']['input']>;
  responsibleComment?: InputMaybe<Scalars['String']['input']>;
  responsibleParty?: InputMaybe<ArbitrationResponsibleParty>;
  status?: InputMaybe<AuctionItemArbitrationStatus>;
  subStatus?: InputMaybe<AuctionItemArbitrationSubStatus>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MutationauctionItemArchiveArgs = {
  archive: Scalars['Boolean']['input'];
  auctionItemId: Scalars['String']['input'];
};

export type MutationauctionItemArrivedArgs = {
  auctionItemId: Scalars['String']['input'];
};

export type MutationauctionItemAssignTimeSlotLaneArgs = {
  auctionItemId: Scalars['ID']['input'];
  auctionTimeSlotLaneId: Scalars['ID']['input'];
  runNumber?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationauctionItemAuctionServiceCreateArgs = {
  auctionItemId: Scalars['String']['input'];
  auctionServiceMetadataId: Scalars['String']['input'];
  feeFixedMode?: InputMaybe<AuctionFeeMode>;
  feeFixedValue?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeTiers?: InputMaybe<Array<InputMaybe<AuctionServiceTierInput>>>;
  feeType: AuctionServiceType;
};

export type MutationauctionItemBulkCreateArgs = {
  input?: InputMaybe<AuctionItemBulkCreateInput>;
};

export type MutationauctionItemBuyNowArgs = {
  input?: InputMaybe<AuctionItemBuyNowInput>;
};

export type MutationauctionItemCancelPendingDeliveryArgs = {
  auctionItemId: Scalars['String']['input'];
};

export type MutationauctionItemCancelSaleArgs = {
  auctionItemId: Scalars['String']['input'];
  invoiceFees?: InputMaybe<Scalars['Boolean']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type MutationauctionItemChangeBuyerArgs = {
  auctionItemId: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type MutationauctionItemCheckoutAndPayArgs = {
  amount?: InputMaybe<Scalars['BigDecimal']['input']>;
  auctionItemIds: Array<Scalars['String']['input']>;
  creditCardSurchargeOverride?: Scalars['Boolean']['input'];
  paymentTypeId?: InputMaybe<Scalars['String']['input']>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  upload?: InputMaybe<FileUpload>;
};

export type MutationauctionItemClaimIfBidsArgs = {
  auctionItemIds: Array<Scalars['ID']['input']>;
  markClaimed: Scalars['Boolean']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationauctionItemCompleteCheckoutArgs = {
  auctionItemIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationauctionItemCreateArgs = {
  auctionItemCreateInput?: InputMaybe<AuctionItemCreateInput>;
  format: AuctionItemFormat;
  inventoryItemId: Scalars['String']['input'];
};

export type MutationauctionItemDeclarationsUpdateArgs = {
  auctionItemId: Scalars['String']['input'];
  declarationsInput: DeclarationsInput;
};

export type MutationauctionItemDeclineOfferArgs = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  offerId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationauctionItemIfBidAcceptArgs = {
  auctionItemId: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  consignerId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationauctionItemIfBidCancelArgs = {
  auctionItemId: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  consignerId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationauctionItemIfBidCounterArgs = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  auctionItemId: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  consignerId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationauctionItemMakeOfferArgs = {
  input: MakeOfferInput;
};

export type MutationauctionItemMoveArgs = {
  auctionItemIds: Array<Scalars['String']['input']>;
  auctionTimeSlotLaneId: Scalars['String']['input'];
  mode?: InputMaybe<MoveMode>;
  runNumber: Scalars['Int']['input'];
};

export type MutationauctionItemOrderServiceAddArgs = {
  auctionItemOrderId: Scalars['ID']['input'];
  auctionServiceId: Scalars['ID']['input'];
};

export type MutationauctionItemOrderServiceRemoveArgs = {
  auctionItemOrderId: Scalars['ID']['input'];
  auctionServiceId: Scalars['ID']['input'];
};

export type MutationauctionItemOrderUpdateArgs = {
  input: AuctionItemOrderUpdateInput;
};

export type MutationauctionItemOrdersPickupLocationUpdateArgs = {
  input: AuctionItemOrdersPickupLocationUpdateInput;
};

export type MutationauctionItemOrdersServicesAddArgs = {
  auctionItemOrderIds: Array<Scalars['ID']['input']>;
  auctionServiceIds: Array<Scalars['ID']['input']>;
};

export type MutationauctionItemOverrideOnlineBidArgs = {
  auctionItemId: Scalars['String']['input'];
  onlineBidId: Scalars['String']['input'];
};

export type MutationauctionItemPhysicalClearBidsArgs = {
  auctionItemId: Scalars['String']['input'];
};

export type MutationauctionItemPhysicalEndArgs = {
  input: AuctionItemPhysicalEndInput;
};

export type MutationauctionItemPhysicalPauseArgs = {
  addTimelineEntry?: InputMaybe<Scalars['Boolean']['input']>;
  auctionItemId: Scalars['String']['input'];
};

export type MutationauctionItemPhysicalRerunArgs = {
  auctionItemId: Scalars['String']['input'];
  auctionTimeSlotLaneId: Scalars['String']['input'];
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
};

export type MutationauctionItemPhysicalResumeArgs = {
  addTimelineEntry?: InputMaybe<Scalars['Boolean']['input']>;
  auctionItemId: Scalars['String']['input'];
};

export type MutationauctionItemPhysicalRetractBidArgs = {
  auctionItemId: Scalars['String']['input'];
};

export type MutationauctionItemPhysicalStartArgs = {
  auctionItemId: Scalars['String']['input'];
  auctionTimeSlotLaneId: Scalars['String']['input'];
};

export type MutationauctionItemPullFromAuctionArgs = {
  auctionItemId: Scalars['String']['input'];
};

export type MutationauctionItemRearrangeUpcomingArgs = {
  append: Scalars['Boolean']['input'];
  auctionItemIds: Array<InputMaybe<Scalars['String']['input']>>;
  auctionTimeSlotLaneId: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationauctionItemReleaseForBuyerPickupArgs = {
  input?: InputMaybe<ReleaseForBuyerPickupInput>;
};

export type MutationauctionItemReleaseForTransportArgs = {
  input?: InputMaybe<ReleaseForTransportInput>;
};

export type MutationauctionItemRemoveLastBidArgs = {
  bidId: Scalars['String']['input'];
};

export type MutationauctionItemRequestArbitrationArgs = {
  auctionItemId: Scalars['String']['input'];
  consignerId?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationauctionItemRequestTitleArgs = {
  input: RequestTitleInput;
};

export type MutationauctionItemReserveMetArgs = {
  auctionItemId: Scalars['String']['input'];
};

export type MutationauctionItemSellDirectArgs = {
  asIs: Scalars['Boolean']['input'];
  auctionId: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  inventoryItemId: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  userId: Scalars['String']['input'];
};

export type MutationauctionItemSellInternalArgs = {
  auctionId: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  inventoryItemId: Scalars['String']['input'];
  price: Scalars['BigDecimal']['input'];
};

export type MutationauctionItemSetAsIsArgs = {
  asIs: Scalars['Boolean']['input'];
  auctionItemId: Scalars['String']['input'];
};

export type MutationauctionItemSetAutoBidArgs = {
  auctionItemId: Scalars['String']['input'];
  consignerId?: InputMaybe<Scalars['String']['input']>;
  maxBidPrice?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationauctionItemSetBidIncrementArgs = {
  auctionItemId: Scalars['String']['input'];
  bidIncrement: Scalars['Float']['input'];
};

export type MutationauctionItemSetHoldbackArgs = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  auctionItemId: Scalars['String']['input'];
  holdbackActive?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<AmountType>;
};

export type MutationauctionItemSetSellerIntentArgs = {
  auctionItemId: Scalars['String']['input'];
  status?: InputMaybe<AuctionItemStatus>;
};

export type MutationauctionItemSetStartPriceArgs = {
  auctionItemId: Scalars['String']['input'];
  auctionTimeSlotLaneId: Scalars['String']['input'];
  startPrice: Scalars['Float']['input'];
};

export type MutationauctionItemSetWatchingArgs = {
  auctionItemId: Scalars['String']['input'];
  watching?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationauctionItemSubmitBidArgs = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  auctionItemId: Scalars['String']['input'];
  consignerId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationauctionItemSubmitFloorBidArgs = {
  amount: Scalars['Float']['input'];
  auctionItemId: Scalars['String']['input'];
};

export type MutationauctionItemSwapUpcomingArgs = {
  auctionTimeSlotId?: InputMaybe<Scalars['String']['input']>;
  swapList1?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  swapList2?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MutationauctionItemSwapUpcomingByIdsArgs = {
  auctionTimeSlotId?: InputMaybe<Scalars['String']['input']>;
  swapList1?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  swapList2?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MutationauctionItemTransferNoSaleArgs = {
  auctionItemId: Scalars['String']['input'];
  consignerId?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  pickupLocationId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationauctionItemTransferRunningArgs = {
  auctionItemId: Scalars['String']['input'];
  consignerId?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  pickupLocationId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationauctionItemTransferSaleArgs = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  asIs?: InputMaybe<Scalars['Boolean']['input']>;
  auctionItemId: Scalars['String']['input'];
  consignerId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AuctionItemStatus>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationauctionItemUpdateArgs = {
  input: AuctionItemUpdateInput;
};

export type MutationauctionItemUpdateHoldArgs = {
  input: AuctionItemUpdateHoldInput;
};

export type MutationauctionItemUpdatePaymentTypeArgs = {
  auctionItemId: Scalars['String']['input'];
  paymentTypeId: Scalars['String']['input'];
};

export type MutationauctionLaneBidIncrementUpdateArgs = {
  auctionLaneId: Scalars['String']['input'];
  bidIncrements: Array<InputMaybe<AuctionLaneBidIncrementInput>>;
};

export type MutationauctionLaneReservationCreateArgs = {
  input: AuctionLaneReservationCreateInput;
};

export type MutationauctionLaneReservationUpdateArgs = {
  input: AuctionLaneReservationUpdateInput;
};

export type MutationauctionLaneUpdateArgs = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  auctionLaneId: Scalars['String']['input'];
  defaultActivated?: InputMaybe<Scalars['Boolean']['input']>;
  defaultDescription?: InputMaybe<Scalars['String']['input']>;
};

export type MutationauctionPhysicalLaneAssignClerkArgs = {
  auctionTimeSlotLaneId: Scalars['String']['input'];
};

export type MutationauctionPhysicalLaneEndArgs = {
  auctionTimeSlotLaneId: Scalars['String']['input'];
};

export type MutationauctionPhysicalLaneStartArgs = {
  auctionTimeSlotLaneId: Scalars['String']['input'];
};

export type MutationauctionServiceCreateArgs = {
  auctionId: Scalars['String']['input'];
  auctionServiceMetadataId: Scalars['String']['input'];
  buyerShield?: InputMaybe<AuctionServiceTriState>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  companySubTypes?: InputMaybe<Array<InputMaybe<CompanySubType>>>;
  feeFixedMode?: InputMaybe<AuctionFeeMode>;
  feeFixedValue?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeTiers?: InputMaybe<Array<InputMaybe<AuctionServiceTierInput>>>;
  feeType: AuctionServiceType;
  format: AuctionItemFormat;
  paidBy?: InputMaybe<AuctionServiceMetadataParty>;
  verified?: InputMaybe<AuctionServiceTriState>;
};

export type MutationauctionServiceDefaultSetArgs = {
  companyAuctionRelationshipId: Scalars['ID']['input'];
  defaultType?: AuctionServiceMetadataDefaultType;
  input: Array<InputMaybe<AuctionServiceDefaultSetInput>>;
};

export type MutationauctionServiceRemoveArgs = {
  auctionServiceId: Scalars['String']['input'];
};

export type MutationauctionServicesCreateArgs = {
  auctionId: Scalars['String']['input'];
  auctionServiceCreateInputs: Array<AuctionServiceCreateInput>;
};

export type MutationauctionSettingsUpdateArgs = {
  input: AuctionSettingsUpdateInput;
};

export type MutationauctionTimeSlotChangeCutoffArgs = {
  auctionTimeSlotId: Scalars['String']['input'];
};

export type MutationauctionTimeSlotLaneCreateArgs = {
  input: AuctionTimeSlotLaneCreateInput;
};

export type MutationauctionTimeSlotLaneRestartArgs = {
  auctionTimeSlotLaneId: Scalars['String']['input'];
};

export type MutationauctionTimeSlotLaneStopArgs = {
  auctionTimeSlotLaneId: Scalars['String']['input'];
};

export type MutationauctionTimeSlotLaneUpdateArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  auctionTimeSlotLaneId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  startRunNumber?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationauctionUpdateArgs = {
  input: AuctionUpdateInput;
};

export type MutationauthenticatePipelineArgs = {
  code: Scalars['String']['input'];
};

export type MutationautoGradeVehicleDamageUpdateArgs = {
  damage: Array<AutoGradeVehicleDamageInput>;
  vehicleId: Scalars['String']['input'];
};

export type MutationautoVinImportVehicleArgs = {
  consignerId?: InputMaybe<Scalars['String']['input']>;
  inspectionIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type MutationbidBadgeDeleteArgs = {
  bidBadgeDeleteInput?: InputMaybe<BidBadgeDeleteInput>;
  bidBadgeId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationbidBadgesCreateArgs = {
  auctionTimeSlotId: Scalars['String']['input'];
  companyIds: Array<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type MutationbroadcastCreateArgs = {
  broadcastCreateInput: BroadcastCreateInput;
};

export type MutationbroadcastUpdateArgs = {
  broadcastUpdateInput: BroadcastUpdateInput;
};

export type MutationcarrierCreateArgs = {
  deliveryIsLocalOnly?: InputMaybe<Scalars['Boolean']['input']>;
  deliveryNotes?: InputMaybe<Scalars['String']['input']>;
  deliveryWhitelistedRegionCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  shipmentModes?: InputMaybe<Array<CarrierShipmentMode>>;
};

export type MutationcarrierUpdateArgs = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  carrierId: Scalars['String']['input'];
  deliveryIsLocalOnly?: InputMaybe<Scalars['Boolean']['input']>;
  deliveryNotes?: InputMaybe<Scalars['String']['input']>;
  deliveryWhitelistedRegionCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  insuranceExpiry?: InputMaybe<ClearableDateTimeInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  primaryLocationId?: InputMaybe<Scalars['String']['input']>;
  proofOfInsurance?: InputMaybe<FileUpload>;
  shipmentModes?: InputMaybe<Array<CarrierShipmentMode>>;
};

export type MutationchargeInstructionCreateArgs = {
  input: ChargeInstructionCreateInput;
};

export type MutationchargeInstructionRemoveArgs = {
  id: Scalars['String']['input'];
};

export type MutationchargeInstructionUpdateArgs = {
  input: ChargeInstructionUpdateInput;
};

export type MutationcompanyAccountingSystemRelationshipUpdateArgs = {
  accountingSystemId: Scalars['String']['input'];
  accountsPayableDeduction?: InputMaybe<AccountsPayableDeduction>;
  bankDetailsInput?: InputMaybe<BankDetailsInput>;
  businessUnit?: InputMaybe<AccountingSystemBusinessUnit>;
  companyId: Scalars['String']['input'];
  padPaymentAggregationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationcompanyAuctionRelationshipCreateArgs = {
  auctionId: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  defaultPaymentTypeId?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status?: InputMaybe<CompanyAuctionRelationshipStatus>;
  tier?: InputMaybe<CompanyTier>;
};

export type MutationcompanyAuctionRelationshipUpdateArgs = {
  activePaymentTypeIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  buyerCreditLimit?: InputMaybe<Scalars['Int']['input']>;
  buyerLienCheck?: InputMaybe<Scalars['Boolean']['input']>;
  buyerShieldEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  buyerTransportCompoundLocationId?: InputMaybe<Scalars['String']['input']>;
  companyAuctionRelationshipId: Scalars['String']['input'];
  defaultPaymentTypeId?: InputMaybe<Scalars['String']['input']>;
  defaultTransportLocationId?: InputMaybe<Scalars['String']['input']>;
  eftEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  hideSellerNameEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sellerAssuranceEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  sellerLienCheck?: InputMaybe<Scalars['Boolean']['input']>;
  sellerTransportCompoundLocationId?: InputMaybe<Scalars['String']['input']>;
  sendTitlePaymentOverride?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<CompanyAuctionRelationshipStatus>;
  tier?: InputMaybe<CompanyTier>;
  vehicleReleasePaymentOverride?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationcompanyAuctionRelationshipUpdateSellerNotesArgs = {
  companyAuctionRelationshipId: Scalars['String']['input'];
  defaultSellerNotes: Scalars['String']['input'];
};

export type MutationcompanyAuctionRelationshipsCreateArgs = {
  auctionIds: Array<InputMaybe<Scalars['String']['input']>>;
  companyId: Scalars['String']['input'];
};

export type MutationcompanyAuctionRepresentativeAssignArgs = {
  relationshipIds: Array<InputMaybe<Scalars['String']['input']>>;
  representative: RepInput;
};

export type MutationcompanyAuctionRepsSetArgs = {
  companyAuctionRelationshipId: Scalars['String']['input'];
  reps: Array<InputMaybe<RepInput>>;
};

export type MutationcompanyContactCreateArgs = {
  companyId: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  primary?: InputMaybe<Scalars['Boolean']['input']>;
  roles?: InputMaybe<Array<InputMaybe<ContactRole>>>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationcompanyContactUpdateArgs = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  contactId: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  primary?: InputMaybe<Scalars['Boolean']['input']>;
  roles?: InputMaybe<Array<InputMaybe<ContactRole>>>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationcompanyCreateArgs = {
  auctionIds: Array<InputMaybe<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  legalName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentCompanyId?: InputMaybe<Scalars['String']['input']>;
  subType?: InputMaybe<CompanySubType>;
  type?: InputMaybe<CompanyType>;
};

export type MutationcompanyCreateIntegrationArgs = {
  companyId: Scalars['String']['input'];
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  type: Scalars['String']['input'];
  values?: InputMaybe<Array<InputMaybe<IntegrationInputValue>>>;
};

export type MutationcompanyDenylistArgs = {
  companyId: Scalars['String']['input'];
  denylist: Scalars['Boolean']['input'];
  denylistedCompanyId: Scalars['String']['input'];
};

export type MutationcompanyFeeCreateFromFileArgs = {
  attachmentId: Scalars['String']['input'];
  auctionId: Scalars['String']['input'];
};

export type MutationcompanyFeeUploadArgs = {
  auctionId: Scalars['String']['input'];
  upload: FileUpload;
};

export type MutationcompanyInvoiceSignatureSetArgs = {
  companyId: Scalars['String']['input'];
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  signature?: InputMaybe<Scalars['String']['input']>;
};

export type MutationcompanySendBuyerSummaryArgs = {
  auctionId: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
};

export type MutationcompanyServiceCreateArgs = {
  amount: Scalars['Float']['input'];
  comment: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  flagged?: Scalars['Boolean']['input'];
  serviceMetadataId: Scalars['String']['input'];
};

export type MutationcompanyServiceRemoveArgs = {
  companyServiceId: Scalars['String']['input'];
};

export type MutationcompanyServiceUpdateArgs = {
  input: CompanyServiceUpdateInput;
};

export type MutationcompanyUpdateAmericanLegalInfoArgs = {
  auctionAccessId?: InputMaybe<Scalars['Int']['input']>;
  bondAmount?: InputMaybe<Scalars['Float']['input']>;
  bondCompany?: InputMaybe<Scalars['String']['input']>;
  bondExpiry?: InputMaybe<Scalars['Date']['input']>;
  bondNumber?: InputMaybe<Scalars['String']['input']>;
  dealerLicenseExpiry?: InputMaybe<Scalars['Date']['input']>;
  dealerLicenseNumber?: InputMaybe<Scalars['String']['input']>;
  dealerLicenseState?: InputMaybe<Scalars['String']['input']>;
  ein?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  sellerPermitNumber?: InputMaybe<Scalars['String']['input']>;
};

export type MutationcompanyUpdateCanadianLegalInfoArgs = {
  auctionAccessId?: InputMaybe<Scalars['Int']['input']>;
  bcPstNumber?: InputMaybe<Scalars['String']['input']>;
  craHstGstNumber?: InputMaybe<Scalars['String']['input']>;
  dealerLicenseExpiry?: InputMaybe<Scalars['Date']['input']>;
  dealerLicenseNumber?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  numeroDeDossier?: InputMaybe<Scalars['String']['input']>;
  ontarioRin?: InputMaybe<Scalars['String']['input']>;
  skPstNumber?: InputMaybe<Scalars['String']['input']>;
};

export type MutationcompanyUpdateCreditsArgs = {
  input?: InputMaybe<CompanyCreditLogInput>;
};

export type MutationcompanyUpdateHoldbackArgs = {
  amount?: InputMaybe<Scalars['BigDecimal']['input']>;
  id: Scalars['String']['input'];
  type?: InputMaybe<AmountType>;
};

export type MutationcompanyUpdateInfoArgs = {
  checkReleasePreference?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  legalName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentCompanyId?: InputMaybe<Scalars['String']['input']>;
  rolePermissionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<CompanyStatus>;
  subType?: InputMaybe<CompanySubType>;
  titleReleasePreference?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<CompanyType>;
};

export type MutationcompanyUpdateIntegrationArgs = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  values?: InputMaybe<Array<InputMaybe<IntegrationInputValue>>>;
};

export type MutationcompanyUpdateRestrictCarfaxOrderingArgs = {
  id: Scalars['String']['input'];
  restrictCarfaxCanadaOrdering: Scalars['Boolean']['input'];
};

export type MutationcompanyUpdateVisibleRegionsArgs = {
  id: Scalars['String']['input'];
  regions: Array<InputMaybe<Scalars['String']['input']>>;
};

export type MutationemailContentReleaseForBuyerPickupArgs = {
  auctionItemId: Scalars['String']['input'];
  releaseForTransport?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationemailContentReleaseForSellerPickupArgs = {
  inventoryItemId: Scalars['String']['input'];
};

export type MutationemailContentReleaseForTransportArgs = {
  auctionItemId: Scalars['String']['input'];
};

export type MutationemailResendArgs = {
  customMessage?: InputMaybe<Scalars['String']['input']>;
  emailHistoryId: Scalars['String']['input'];
  emails: Array<Scalars['String']['input']>;
};

export type MutationemployeeAuthenticateArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
};

export type MutationentityCacheClearArgs = {
  cacheKey: Scalars['String']['input'];
};

export type MutationentityTriggerIndexArgs = {
  ids: Array<Scalars['String']['input']>;
  schemaName: Scalars['String']['input'];
};

export type MutationexternalIdSetArgs = {
  externalIdSetInput: ExternalIdSetInput;
};

export type MutationfeatureFlagRemoveArgs = {
  featureKey: Scalars['String']['input'];
};

export type MutationfeatureFlagSetArgs = {
  featureFlagSetInput: FeatureFlagSetInput;
};

export type MutationfeeMetadataUpdateFromFileArgs = {
  attachmentId: Scalars['String']['input'];
  auctionId: Scalars['String']['input'];
};

export type MutationfeeMetadataUploadArgs = {
  auctionId: Scalars['String']['input'];
  upload: FileUpload;
};

export type MutationimportBankingInformationArgs = {
  accountingSystemId: Scalars['String']['input'];
  importContent: Scalars['String']['input'];
};

export type MutationinspectionAcceptProposalArgs = {
  inspectionId: Scalars['String']['input'];
  proposalId: Scalars['String']['input'];
  proposedDateTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MutationinspectionAddInventoryItemArgs = {
  inspectionId: Scalars['String']['input'];
  inventoryItemIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MutationinspectionAssignArgs = {
  inspectionId: Scalars['String']['input'];
  inspectorId?: InputMaybe<Scalars['String']['input']>;
  proposedDateTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MutationinspectionCancelArgs = {
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  inspectionId: Scalars['String']['input'];
};

export type MutationinspectionCompleteArgs = {
  inspectionId: Scalars['String']['input'];
};

export type MutationinspectionCreateArgs = {
  consignerId?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  numberOfItemsToInspect?: InputMaybe<Scalars['Int']['input']>;
  requestedDate?: InputMaybe<Scalars['Date']['input']>;
  requestedTime?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationinspectionProposalCreateArgs = {
  inspectionId: Scalars['String']['input'];
  inspectorId?: InputMaybe<Scalars['String']['input']>;
  proposedDateTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MutationinventoryFeeCreateFromFileArgs = {
  attachmentId: Scalars['String']['input'];
  auctionId: Scalars['String']['input'];
};

export type MutationinventoryFeeUploadArgs = {
  auctionId: Scalars['String']['input'];
  upload: FileUpload;
};

export type MutationinventoryItemArchiveArgs = {
  archive: Scalars['Boolean']['input'];
  inventoryItemId: Scalars['String']['input'];
};

export type MutationinventoryItemAssignmentAcceptArgs = {
  assignmentAccepted: Scalars['Boolean']['input'];
  inventoryItemIds: Array<Scalars['String']['input']>;
};

export type MutationinventoryItemAssignmentUpdateArgs = {
  input: InventoryItemAssignmentInput;
  inventoryItemId: Scalars['String']['input'];
};

export type MutationinventoryItemCarfaxCanadaAttachReportArgs = {
  inventoryItemId: Scalars['String']['input'];
};

export type MutationinventoryItemChangeLocationArgs = {
  inventoryItemId: Scalars['String']['input'];
  locationId?: InputMaybe<Scalars['String']['input']>;
  pickupLocationId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationinventoryItemCheckInArgs = {
  inventoryItemId: Scalars['String']['input'];
  keyTag?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  lotLocation?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  override?: InputMaybe<Scalars['Boolean']['input']>;
  pickupLocationId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationinventoryItemCheckOutArgs = {
  destinationId?: InputMaybe<Scalars['String']['input']>;
  identification?: InputMaybe<Scalars['String']['input']>;
  inventoryItemId: Scalars['String']['input'];
  invoiceFees?: InputMaybe<Scalars['Boolean']['input']>;
  isIDVerified?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  reason: InventoryCheckedOutReason;
};

export type MutationinventoryItemCreateArgs = {
  consignerId?: InputMaybe<Scalars['String']['input']>;
  vehicle?: InputMaybe<VehicleInput>;
};

export type MutationinventoryItemFeeUpdate2Args = {
  fuelFee?: InputMaybe<Scalars['Float']['input']>;
  fuelFeeText?: InputMaybe<Scalars['String']['input']>;
  inventoryItemId: Scalars['String']['input'];
  marshallingFee?: InputMaybe<Scalars['Float']['input']>;
  marshallingFeeText?: InputMaybe<Scalars['String']['input']>;
  mechanicalFee?: InputMaybe<Scalars['Float']['input']>;
  mechanicalFeeText?: InputMaybe<Scalars['String']['input']>;
  miscFee?: InputMaybe<Scalars['Float']['input']>;
  miscFeeText?: InputMaybe<Scalars['String']['input']>;
  reconditioningFee?: InputMaybe<Scalars['Float']['input']>;
  reconditioningFeeText?: InputMaybe<Scalars['String']['input']>;
  redemptionFee?: InputMaybe<Scalars['Float']['input']>;
  redemptionFeeText?: InputMaybe<Scalars['String']['input']>;
  registrationFee?: InputMaybe<Scalars['Float']['input']>;
  registrationFeeText?: InputMaybe<Scalars['String']['input']>;
  storageFee?: InputMaybe<Scalars['Float']['input']>;
  storageFeeText?: InputMaybe<Scalars['String']['input']>;
  transportFee?: InputMaybe<Scalars['Float']['input']>;
  transportFeeText?: InputMaybe<Scalars['String']['input']>;
};

export type MutationinventoryItemPhotoCreateArgs = {
  photoInput: InventoryItemPhotoCreateInput;
};

export type MutationinventoryItemReleaseForSellerPickupArgs = {
  input: ReleaseForSellerPickupInput;
};

export type MutationinventoryItemShareArgs = {
  auctionItemId?: InputMaybe<Scalars['String']['input']>;
  inventoryItemId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationinventoryItemTransferArgs = {
  consignerId?: InputMaybe<Scalars['String']['input']>;
  inventoryItemId: Scalars['String']['input'];
};

export type MutationinventoryItemUpdateArgs = {
  inventoryItemId: Scalars['String']['input'];
  vehicle?: InputMaybe<VehicleInput>;
};

export type MutationinventoryItemUpdateBlueDriverScanDataArgs = {
  clearExistingScan?: InputMaybe<Scalars['Boolean']['input']>;
  inventoryItemId: Scalars['String']['input'];
  scanType: BlueDriverScanType;
};

export type MutationinventoryItemValueCreateArgs = {
  inventoryItemId: Scalars['String']['input'];
  valueInput: InventoryItemValueInput;
};

export type MutationinventoryItemValueUpdateArgs = {
  valueId: Scalars['String']['input'];
  valueInput: InventoryItemValueUpdateInput;
};

export type MutationinventoryItemValuesFetchBlackBookValuesArgs = {
  input: BlackBookValueUpdateInput;
};

export type MutationinventoryServiceCreateArgs = {
  amount: Scalars['Float']['input'];
  feeAndInvoice?: InputMaybe<ForceFeeInvoice>;
  flagged?: Scalars['Boolean']['input'];
  inventoryItemId: Scalars['String']['input'];
  serviceMetadataId: Scalars['String']['input'];
  vehicleRepairItemId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationinventoryServiceCreate2Args = {
  input: InventoryServiceCreateInput;
};

export type MutationinventoryServiceRemoveArgs = {
  inventoryServiceId: Scalars['String']['input'];
};

export type MutationinventoryServiceUpdateArgs = {
  input: InventoryServiceUpdateInput;
};

export type MutationinvoiceAdjustArgs = {
  applyImmediately?: InputMaybe<Scalars['Boolean']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  inputs: Array<LineItemAdjustInput>;
  invoiceId: Scalars['String']['input'];
  paymentTypeId?: InputMaybe<Scalars['String']['input']>;
  reason: InvoiceReason;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  sendEmail?: InputMaybe<Scalars['Boolean']['input']>;
  terms?: InputMaybe<Terms>;
};

export type MutationinvoiceCancelArgs = {
  auctionItemId: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
};

export type MutationinvoiceDeleteArgs = {
  invoiceIds: Array<Scalars['String']['input']>;
};

export type MutationinvoiceProcessPaymentArgs = {
  amount?: InputMaybe<Scalars['BigDecimal']['input']>;
  auctionId: Scalars['String']['input'];
  bankFileDateToProcess?: InputMaybe<Scalars['DateTime']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  creditCardSurchargeOverride?: Scalars['Boolean']['input'];
  invoiceIds: Array<Scalars['String']['input']>;
  paymentTypeId?: InputMaybe<Scalars['String']['input']>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  upload?: InputMaybe<FileUpload>;
};

export type MutationinvoiceRefundArgs = {
  amount?: InputMaybe<Scalars['BigDecimal']['input']>;
  auctionId: Scalars['String']['input'];
  bankFileDateToProcess?: InputMaybe<Scalars['DateTime']['input']>;
  invoiceIds: Array<Scalars['String']['input']>;
  paymentTypeId?: InputMaybe<Scalars['String']['input']>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
};

export type MutationinvoiceSendEmailArgs = {
  invoiceId: Scalars['String']['input'];
};

export type MutationinvoicesUpdatePaymentTypeArgs = {
  invoiceIds: Array<Scalars['String']['input']>;
  paymentTypeId: Scalars['String']['input'];
  terms: Terms;
};

export type MutationliveLaneConversationRegisterArgs = {
  auctionTimeSlotLaneId: Scalars['String']['input'];
  forceCreate?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationlocationCreateArgs = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  carrierId?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  consignerId?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  dropOffInstructions?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pickupInstructions?: InputMaybe<Scalars['String']['input']>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  regionCode?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type MutationlocationInventoryServiceCreateArgs = {
  amount: Scalars['Float']['input'];
  locationId: Scalars['String']['input'];
  serviceMetadataId: Scalars['String']['input'];
};

export type MutationlocationInventoryServiceRemoveArgs = {
  locationInventoryServiceId: Scalars['String']['input'];
};

export type MutationlocationUpdateArgs = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  archive?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  dropOffInstructions?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pickupInstructions?: InputMaybe<Scalars['String']['input']>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  regionCode?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type MutationnetsuiteRecordResyncArgs = {
  entityId: Scalars['String']['input'];
  recordType: Scalars['String']['input'];
};

export type MutationnoteCreateArgs = {
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  content: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  entityType: NoteEntityType;
};

export type MutationorderCarfaxUSAReportArgs = {
  authorizationCode?: InputMaybe<Scalars['String']['input']>;
  vin: Scalars['String']['input'];
};

export type MutationpaymentCancelArgs = {
  addNsfFee: Scalars['Boolean']['input'];
  creditSurcharge?: InputMaybe<Scalars['Boolean']['input']>;
  journalDate: Scalars['DateTime']['input'];
  memo: Scalars['String']['input'];
  paymentId: Scalars['String']['input'];
  reason: JournalEntryReason;
  supportingDocuments?: InputMaybe<Array<FileUpload>>;
};

export type MutationpaymentMarkDepositedArgs = {
  cashbackAmount: Scalars['BigDecimal']['input'];
  depositDate: Scalars['DateTime']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  paymentIds: Array<Scalars['String']['input']>;
  statementAmount: Scalars['BigDecimal']['input'];
};

export type MutationrequestedInviteChangeStatusArgs = {
  requestInviteId: Scalars['String']['input'];
  status: Scalars['String']['input'];
};

export type MutationrequestedInviteCreateArgs = {
  dealerName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type MutationresendNotificationArgs = {
  notificationId: Scalars['String']['input'];
};

export type MutationsavedFilterCreateArgs = {
  auctionItemSavedFilter?: InputMaybe<AuctionItemSavedFilterInput>;
  consignerSavedFilter?: InputMaybe<ConsignerSavedFilterInput>;
};

export type MutationsavedFilterDeleteArgs = {
  id: Scalars['String']['input'];
};

export type MutationsavedFilterUpdateArgs = {
  auctionItemSavedFilter?: InputMaybe<AuctionItemSavedFilterInput>;
  consignerSavedFilter?: InputMaybe<ConsignerSavedFilterInput>;
  id: Scalars['String']['input'];
};

export type MutationserviceInvoicesCreateArgs = {
  auctionItemId?: InputMaybe<Scalars['String']['input']>;
  paymentTypeId?: InputMaybe<Scalars['String']['input']>;
  serviceIds: Array<Scalars['String']['input']>;
  terms: Terms;
};

export type MutationstopMaintenanceTaskArgs = {
  id: Scalars['String']['input'];
};

export type MutationtaskCreateArgs = {
  input: TaskCreateInput;
};

export type MutationtaskDeleteArgs = {
  id: Scalars['String']['input'];
};

export type MutationtaskUpdateArgs = {
  input: TaskUpdateInput;
};

export type MutationtimelineActionAdd2Args = {
  input: TimelineActionAddInput;
};

export type MutationtimelineActionDeleteArgs = {
  id: Scalars['String']['input'];
};

export type MutationtitleAttachmentDeleteArgs = {
  attachmentId: Scalars['String']['input'];
};

export type MutationtitleUpdateArgs = {
  input: TitleInput;
};

export type MutationtransportFeeCacheClearArgs = {
  auctionId?: InputMaybe<Scalars['String']['input']>;
  dropOffLocationId?: InputMaybe<Scalars['String']['input']>;
  pickupLocationId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<TransportQuoteType>;
};

export type MutationtransportJobCancelAllArgs = {
  cancellationReason: TransportCancellationReason;
  cancellationReasonComment?: InputMaybe<Scalars['String']['input']>;
  cloneRequestIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['String']['input'];
  initiator: TransportCancellationInitiator;
};

export type MutationtransportJobCreateArgs = {
  transportRequestIds: Array<Scalars['String']['input']>;
};

export type MutationtransportJobQuoteAcceptArgs = {
  noteUpdates?: InputMaybe<Array<TransportRequestNoteInput>>;
  syncWithSuperDispatch?: Scalars['Boolean']['input'];
  transportJobId: Scalars['String']['input'];
  transportJobQuoteId: Scalars['String']['input'];
};

export type MutationtransportJobQuoteCreateArgs = {
  input: JobQuoteInput;
};

export type MutationtransportJobQuoteInviteArgs = {
  carrierIds: Array<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  noteUpdates?: InputMaybe<Array<TransportRequestNoteInput>>;
};

export type MutationtransportJobQuoteUpdateArgs = {
  input: JobQuoteInput;
};

export type MutationtransportJobUpdateArgs = {
  id: Scalars['String']['input'];
  transportRequestIds: Array<Scalars['String']['input']>;
};

export type MutationtransportRateUpdateArgs = {
  auctionId: Scalars['String']['input'];
  rateUploadAttachmentId: Scalars['String']['input'];
  type: TransportRateType;
};

export type MutationtransportRateUploadArgs = {
  auctionId: Scalars['String']['input'];
  upload: FileUpload;
};

export type MutationtransportRequestBulkUpdateArgs = {
  input: TransportRequestBulkInput;
};

export type MutationtransportRequestCancelArgs = {
  cancellationReason: TransportCancellationReason;
  cancellationReasonComment?: InputMaybe<Scalars['String']['input']>;
  clone?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  initiator: TransportCancellationInitiator;
};

export type MutationtransportRequestCreateArgs = {
  input: TransportRequestInput;
};

export type MutationtransportRequestDeliveredArgs = {
  chargedAmount?: InputMaybe<Scalars['Float']['input']>;
  deliveryDate?: InputMaybe<Scalars['Date']['input']>;
  finalAmount?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['String']['input'];
};

export type MutationtransportRequestUpdateArgs = {
  input: TransportRequestUpdateInput;
};

export type MutationtriggerReindexArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  schemaName: Scalars['String']['input'];
  since?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type MutationupdateCarrierSuperDispatchArgs = {
  carrierId: Scalars['String']['input'];
  carrierSDGuid?: InputMaybe<Scalars['String']['input']>;
};

export type MutationuserAccountSendVerificationCodeArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
  verificationType: UserAccountVerificationType;
  verifiedField?: InputMaybe<Scalars['String']['input']>;
};

export type MutationuserAccountVerifyArgs = {
  code: Scalars['String']['input'];
  verificationType: UserAccountVerificationType;
  verifiedField: Scalars['String']['input'];
};

export type MutationuserActAsUserArgs = {
  otherUserId: Scalars['String']['input'];
};

export type MutationuserAuthenticateArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationuserCompanyRelationshipCreateArgs = {
  companyId: Scalars['String']['input'];
  department?: InputMaybe<Scalars['String']['input']>;
  roleIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status?: InputMaybe<UserCompanyRelationshipStatus>;
  userId: Scalars['String']['input'];
};

export type MutationuserCompanyRelationshipUpdateArgs = {
  companyId: Scalars['String']['input'];
  department?: InputMaybe<Scalars['String']['input']>;
  roleIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status?: InputMaybe<UserCompanyRelationshipStatus>;
  userId: Scalars['String']['input'];
};

export type MutationuserCreateArgs = {
  cellPhone?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  secondaryPhone?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UserStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type MutationuserCreateAuctionAccessArgs = {
  form: UserCreateAuctionAccessInput;
};

export type MutationuserDeleteArgs = {
  password: Scalars['String']['input'];
};

export type MutationuserForgotPasswordArgs = {
  email: Scalars['String']['input'];
};

export type MutationuserNotificationDeviceSubscribeArgs = {
  firebaseToken?: InputMaybe<Scalars['String']['input']>;
  subscribe: Scalars['Boolean']['input'];
};

export type MutationuserNotificationSettingsUpdateArgs = {
  appraisal?: InputMaybe<Scalars['Boolean']['input']>;
  auction?: InputMaybe<Scalars['Boolean']['input']>;
  distanceFromPreferredLocation?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['Boolean']['input']>;
  grounded?: InputMaybe<Scalars['Boolean']['input']>;
  makeModelFilters?: InputMaybe<Array<InputMaybe<MakeModelFilter>>>;
  notifications?: InputMaybe<Array<InputMaybe<NotificationInput>>>;
  push?: InputMaybe<Scalars['Boolean']['input']>;
  sms?: InputMaybe<Scalars['Boolean']['input']>;
  timedOffer?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationuserResetPasswordArgs = {
  password?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

export type MutationuserUpdateAuctionAccessIdArgs = {
  auctionAccessId: Scalars['Int']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationuserUpdateDefaultClientRouteArgs = {
  defaultClientRoute?: InputMaybe<Scalars['String']['input']>;
};

export type MutationuserUpdateInfoArgs = {
  cellPhone?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  photo?: InputMaybe<Scalars['String']['input']>;
  secondaryPhone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type MutationuserUpdateInfoAsAdminArgs = {
  cellPhone?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  photo?: InputMaybe<Scalars['String']['input']>;
  secondaryPhone?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UserStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type MutationuserUpdateLocaleTimeZoneArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationuserUpdateOmvicArgs = {
  expiryDate?: InputMaybe<Scalars['Date']['input']>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationuserUpdatePasswordArgs = {
  currentPassword: Scalars['String']['input'];
  logoutDevices?: InputMaybe<Scalars['Boolean']['input']>;
  password: Scalars['String']['input'];
};

export type MutationuserUpdatePhotoAuctionAccessArgs = {
  userId: Scalars['String']['input'];
};

export type MutationuserUpdatePreferredLocationDistanceUnitArgs = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  distanceUnit?: InputMaybe<DistanceUnit>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type MutationuserVerifyAuthenticationArgs = {
  userId: Scalars['String']['input'];
  verificationCode: Scalars['String']['input'];
  verificationType: UserAccountVerificationType;
  verifiedField?: InputMaybe<Scalars['String']['input']>;
};

export type MutationvehicleMakeAddArgs = {
  name: Scalars['String']['input'];
};

export type MutationvehicleModelAddArgs = {
  makeId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type MutationvehicleRepairItemUpdateArgs = {
  repairItemInput: RepairItemInput;
};

export type MutationvehicleSubModelAddArgs = {
  modelId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type MutationvehicleTrimAddArgs = {
  name: Scalars['String']['input'];
  subModelId: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type MutationworkflowProcessUpdateArgs = {
  entityId: Scalars['String']['input'];
  entityType: EntityType;
  inputs: Array<ProcessInput>;
};

export type MyBlockBuyerResponse = {
  __typename?: 'MyBlockBuyerResponse';
  hasBid: Scalars['Int']['output'];
  inIfBid: Scalars['Int']['output'];
  purchased: Scalars['Int']['output'];
  totalSpentAmount: MonetaryAmount;
};

export type MyBlockSellerResponse = {
  __typename?: 'MyBlockSellerResponse';
  inIfBid: Scalars['Int']['output'];
  missingCost: Scalars['Boolean']['output'];
  ran: Scalars['Int']['output'];
  sold: Scalars['Int']['output'];
  totalSoldAmount: MonetaryAmount;
  totalSoldNet: MonetaryAmount;
};

export type MyBlockStatisticsInput = {
  auctionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  auctionLocationName?: InputMaybe<Array<Scalars['String']['input']>>;
  auctionTitle?: InputMaybe<Array<Scalars['String']['input']>>;
  consigner?: InputMaybe<Array<Scalars['String']['input']>>;
  consignerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  dateRanGTE?: InputMaybe<Scalars['DateTime']['input']>;
  dateRanLTE?: InputMaybe<Scalars['DateTime']['input']>;
  formats?: InputMaybe<Array<AuctionItemFormat>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Array<Scalars['String']['input']>>;
  totalIncludeIfBid: Scalars['Boolean']['input'];
};

export enum NoSaleReason {
  CANCELLED_IF_BID = 'CANCELLED_IF_BID',
  CANCELLED_IF_BID_SELLER = 'CANCELLED_IF_BID_SELLER',
  CANCELLED_IF_BID_STAFF = 'CANCELLED_IF_BID_STAFF',
  CANCELLED_PENDING_DELIVERY = 'CANCELLED_PENDING_DELIVERY',
  CANCELLED_PENDING_DELIVERY_SELLER = 'CANCELLED_PENDING_DELIVERY_SELLER',
  CANCELLED_PENDING_DELIVERY_STAFF = 'CANCELLED_PENDING_DELIVERY_STAFF',
  DIGITAL_NO_BIDS = 'DIGITAL_NO_BIDS',
  EXPIRED_IF_BID = 'EXPIRED_IF_BID',
  EXPIRED_TIMED_OFFER = 'EXPIRED_TIMED_OFFER',
  LANE_UNASSIGNED = 'LANE_UNASSIGNED',
  PHYSICAL_CLERK = 'PHYSICAL_CLERK',
  PULLED_LIVE = 'PULLED_LIVE',
  PULLED_LIVE_SELLER = 'PULLED_LIVE_SELLER',
  PULLED_LIVE_STAFF = 'PULLED_LIVE_STAFF',
  PULLED_UPCOMING = 'PULLED_UPCOMING',
  PULLED_UPCOMING_SELLER = 'PULLED_UPCOMING_SELLER',
  PULLED_UPCOMING_STAFF = 'PULLED_UPCOMING_STAFF',
}

export type Note = {
  __typename?: 'Note';
  content: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  id: Scalars['String']['output'];
};

export enum NoteEntityType {
  AUCTION_ITEM = 'AUCTION_ITEM',
  AUCTION_TIME_SLOT = 'AUCTION_TIME_SLOT',
  CARRIER = 'CARRIER',
  CONSIGNER = 'CONSIGNER',
  INVENTORY_ITEM = 'INVENTORY_ITEM',
  REQUESTED_CAPTURE = 'REQUESTED_CAPTURE',
  REQUESTED_INVITE = 'REQUESTED_INVITE',
  TRANSPORT_JOB = 'TRANSPORT_JOB',
  TRANSPORT_REQUEST = 'TRANSPORT_REQUEST',
  USER = 'USER',
}

export type Notification = {
  __typename?: 'Notification';
  category: NotificationCategory;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  push?: Maybe<Scalars['Boolean']['output']>;
  sms?: Maybe<Scalars['Boolean']['output']>;
};

export enum NotificationCategory {
  IN_AUCTION = 'IN_AUCTION',
  POST_SALE = 'POST_SALE',
  PRE_SALE = 'PRE_SALE',
  STAFF = 'STAFF',
  VEHICLE_LISTINGS = 'VEHICLE_LISTINGS',
}

export type NotificationDevice = {
  __typename?: 'NotificationDevice';
  active: Scalars['Boolean']['output'];
  /** At time of device notification registration. */
  appVersion: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  deviceName: Scalars['String']['output'];
  deviceType: DeviceType;
  id: Scalars['String']['output'];
  token?: Maybe<Scalars['String']['output']>;
};

export type NotificationInput = {
  email?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  push?: InputMaybe<Scalars['Boolean']['input']>;
  sms?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NotificationMakeModelFilter = {
  __typename?: 'NotificationMakeModelFilter';
  make: Scalars['String']['output'];
  makeId: Scalars['String']['output'];
  maxMileage?: Maybe<Scalars['Int']['output']>;
  minMileage?: Maybe<Scalars['Int']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  modelId?: Maybe<Scalars['String']['output']>;
  yearFrom?: Maybe<Scalars['Int']['output']>;
  yearTo?: Maybe<Scalars['Int']['output']>;
};

export type NotificationSettings = {
  __typename?: 'NotificationSettings';
  appraisal: Scalars['Boolean']['output'];
  auction: Scalars['Boolean']['output'];
  distanceFromPreferredLocation?: Maybe<Scalars['Int']['output']>;
  email: Scalars['Boolean']['output'];
  grounded: Scalars['Boolean']['output'];
  makeModelFilters?: Maybe<Array<Maybe<NotificationMakeModelFilter>>>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  push: Scalars['Boolean']['output'];
  sms: Scalars['Boolean']['output'];
  timedOffer: Scalars['Boolean']['output'];
};

export enum NotificationType {
  EMAIL = 'EMAIL',
  PUSH = 'PUSH',
  SMS = 'SMS',
}

export enum OfferType {
  BID = 'BID',
  IF_BID = 'IF_BID',
}

export type OperatingRegion = {
  __typename?: 'OperatingRegion';
  country: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  region: Scalars['String']['output'];
  regionCode: Scalars['String']['output'];
};

export type Payment = {
  __typename?: 'Payment';
  accountingSystemTransactionNumber?: Maybe<Scalars['String']['output']>;
  /** collected amount of payment, can be null if payment is offset */
  amount: MonetaryAmount;
  attachment?: Maybe<Attachment>;
  cheques?: Maybe<Array<Cheque>>;
  company: Company;
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  journalEntry?: Maybe<JournalEntry>;
  netsuiteId?: Maybe<Scalars['String']['output']>;
  paymentBankFile?: Maybe<PaymentBankFile>;
  paymentDeposit?: Maybe<PaymentDeposit>;
  paymentInvoices: Array<PaymentInvoice>;
  paymentReceiptUrl?: Maybe<Scalars['String']['output']>;
  paymentSubType: PaymentSubType;
  paymentType?: Maybe<PaymentType>;
  referenceNumber?: Maybe<Scalars['String']['output']>;
  /** Link to record in external finance system */
  referenceUrl?: Maybe<Scalars['String']['output']>;
  /** amount not applied in case of overpayment */
  remaining: MonetaryAmount;
  status: PaymentStatus;
  updated: Scalars['DateTime']['output'];
};

export type PaymentBankFile = {
  __typename?: 'PaymentBankFile';
  accountingSystemTransactionNumber?: Maybe<Scalars['String']['output']>;
  comment: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dateToProcess: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  payments: Array<Payment>;
  referenceUrl: Scalars['String']['output'];
  transactionNumberLong: Scalars['Long']['output'];
  type: PaymentBankFileType;
  updated: Scalars['DateTime']['output'];
};

/** A connection to a list of items. */
export type PaymentBankFileConnection = {
  __typename?: 'PaymentBankFileConnection';
  edges: Array<PaymentBankFileEdge>;
  facetGroups: Array<FacetGroup>;
  filterSections: Array<FilterSection>;
  pageInfo: FacetedPageInfo;
  sort: Array<SortOption>;
};

/** A connection to a list of items. */
export type PaymentBankFileConnectionfacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PaymentBankFileEdge = {
  __typename?: 'PaymentBankFileEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: PaymentBankFile;
};

export enum PaymentBankFileType {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
  MIXED = 'MIXED',
}

export enum PaymentBlocker {
  ARBITRATION_IN_PROGRESS = 'ARBITRATION_IN_PROGRESS',
  COLLECT_TITLE_BACK_FROM_BUYER = 'COLLECT_TITLE_BACK_FROM_BUYER',
  CREDIT_INVOICE_NOT_DEPOSITED = 'CREDIT_INVOICE_NOT_DEPOSITED',
  LIEN_CHECK_OPEN = 'LIEN_CHECK_OPEN',
  NOT_SOLD_STATUS = 'NOT_SOLD_STATUS',
  OVERPAYMENT_NOT_DEPOSITED = 'OVERPAYMENT_NOT_DEPOSITED',
  PSI_OPEN = 'PSI_OPEN',
  TERMS = 'TERMS',
  TITLE_INVALID = 'TITLE_INVALID',
}

/** A connection to a list of items. */
export type PaymentConnection = {
  __typename?: 'PaymentConnection';
  edges: Array<PaymentEdge>;
  facetGroups: Array<FacetGroup>;
  filterSections: Array<FilterSection>;
  pageInfo: FacetedPageInfo;
  sort: Array<SortOption>;
};

/** A connection to a list of items. */
export type PaymentConnectionfacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PaymentDeposit = {
  __typename?: 'PaymentDeposit';
  accountingSystemTransactionNumber?: Maybe<Scalars['String']['output']>;
  cashBackAmount: MonetaryAmount;
  created: Scalars['DateTime']['output'];
  depositBy?: Maybe<User>;
  depositDate: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  netsuiteId?: Maybe<Scalars['String']['output']>;
  payments: Array<Payment>;
  paymentsTotalAmount: MonetaryAmount;
  /** Link to record in external finance system */
  referenceUrl?: Maybe<Scalars['String']['output']>;
  totalDepositAmount: MonetaryAmount;
  updated: Scalars['DateTime']['output'];
};

/** A connection to a list of items. */
export type PaymentDepositConnection = {
  __typename?: 'PaymentDepositConnection';
  edges: Array<PaymentDepositEdge>;
  facetGroups: Array<FacetGroup>;
  filterSections: Array<FilterSection>;
  pageInfo: FacetedPageInfo;
  sort: Array<SortOption>;
};

/** A connection to a list of items. */
export type PaymentDepositConnectionfacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PaymentDepositEdge = {
  __typename?: 'PaymentDepositEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: PaymentDeposit;
};

export type PaymentEdge = {
  __typename?: 'PaymentEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: Payment;
};

export type PaymentInvoice = {
  __typename?: 'PaymentInvoice';
  amount: MonetaryAmount;
  balance: MonetaryAmount;
  created: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  invoice: Invoice;
  payment: Payment;
  updated: Scalars['DateTime']['output'];
};

export enum PaymentStatus {
  CANCELLED = 'CANCELLED',
  DEPOSITED = 'DEPOSITED',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  NOT_DEPOSITED = 'NOT_DEPOSITED',
}

export enum PaymentSubType {
  DISBURSEMENT = 'DISBURSEMENT',
  OFFSET = 'OFFSET',
  PAYMENT = 'PAYMENT',
}

export type PaymentType = {
  __typename?: 'PaymentType';
  active: Scalars['Boolean']['output'];
  allowForRv: Scalars['Boolean']['output'];
  allowOverpayment: Scalars['Boolean']['output'];
  defaultType: Scalars['Boolean']['output'];
  floorPlan: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  logicalName?: Maybe<PaymentTypeLogicalName>;
  name: Scalars['String']['output'];
  subType?: Maybe<PaymentSubType>;
  surchargeAmount?: Maybe<Scalars['BigDecimal']['output']>;
  surchargeType: SurchargeType;
};

export enum PaymentTypeLogicalName {
  ABS_AFC_FLOORPLAN = 'ABS_AFC_FLOORPLAN',
  ABS_FINANCE_FLOORPLAN = 'ABS_FINANCE_FLOORPLAN',
  ABS_KINETIC_ADVANTAGE_FLOORPLAN = 'ABS_KINETIC_ADVANTAGE_FLOORPLAN',
  ABS_NEXTGEAR_FLOORPLAN = 'ABS_NEXTGEAR_FLOORPLAN',
  ABS_WESTLAKE_FINANCIAL_FLOORPLAN = 'ABS_WESTLAKE_FINANCIAL_FLOORPLAN',
  ACH = 'ACH',
  ACH_HOLD = 'ACH_HOLD',
  ACH_ON_TITLE = 'ACH_ON_TITLE',
  AFS_FLOORPLAN = 'AFS_FLOORPLAN',
  AXLE_FUNDING_FLOORPLAN = 'AXLE_FUNDING_FLOORPLAN',
  CHECK_ON_TITLE = 'CHECK_ON_TITLE',
  CHEQUE = 'CHEQUE',
  CREDIT_CARD = 'CREDIT_CARD',
  EFT = 'EFT',
  ESP_CAPITAL_INC_FLOORPLAN = 'ESP_CAPITAL_INC_FLOORPLAN',
  FIRST_CHOICE_FINANCIAL_FLOORPLAN = 'FIRST_CHOICE_FINANCIAL_FLOORPLAN',
  LEVER_AUTO_FLOORPLAN = 'LEVER_AUTO_FLOORPLAN',
  PAC_AUTO_FLOORPLAN = 'PAC_AUTO_FLOORPLAN',
  PAD = 'PAD',
  PAD_HOLD = 'PAD_HOLD',
  TD_FINANCE_FLOORPLAN = 'TD_FINANCE_FLOORPLAN',
}

export type Photo = {
  __typename?: 'Photo';
  id: Scalars['String']['output'];
  /** The damage location of the photo. */
  location?: Maybe<Scalars['String']['output']>;
  /** The damage location key of the photo. */
  locationKey?: Maybe<Scalars['String']['output']>;
  /** A user's note about damages shown in the photo. */
  note?: Maybe<Scalars['String']['output']>;
  /** The order of the photo. 1-8 Represent 360 photo positions. */
  shotCode: Scalars['Int']['output'];
  status: PhotoStatus;
  type: PhotoType;
  /** The url of the photo. */
  url: Scalars['String']['output'];
};

export type PhotourlArgs = {
  cropType?: InputMaybe<CropType>;
  height?: InputMaybe<Scalars['Int']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type PhotoDTO = {
  __typename?: 'PhotoDTO';
  damageLocation?: Maybe<Scalars['String']['output']>;
  downloadUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  shotCode?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<PhotoStatus>;
  thumbnails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  type: PhotoType;
  uploadUrl?: Maybe<Scalars['String']['output']>;
};

export enum PhotoStatus {
  FAILED = 'FAILED',
  INIT = 'INIT',
  UPLOADED = 'UPLOADED',
}

export enum PhotoType {
  AUTOGRADE_DAMAGE = 'AUTOGRADE_DAMAGE',
  DAMAGE = 'DAMAGE',
  EXTERIOR = 'EXTERIOR',
  INTERIOR = 'INTERIOR',
  UNDERCARRIAGE = 'UNDERCARRIAGE',
}

export type PhotosDTO = {
  __typename?: 'PhotosDTO';
  createdPhotoDTO: PhotoDTO;
  photoDTOs?: Maybe<Array<Maybe<PhotoDTO>>>;
};

export type PlaceAutocomplete = {
  __typename?: 'PlaceAutocomplete';
  id: Scalars['String']['output'];
  primaryText: Scalars['String']['output'];
  secondaryText: Scalars['String']['output'];
};

export type PlaceDetails = {
  __typename?: 'PlaceDetails';
  address1?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  /** The country code of the location. (2 letters) */
  countryCode: Scalars['String']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  /** The region of the location (state/province). */
  region: Scalars['String']['output'];
  /** The region code of the location. (2 letters) */
  regionCode: Scalars['String']['output'];
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type ProcessInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  processName?: InputMaybe<ProcessName>;
  processStatus: ProcessStatus;
};

export enum ProcessName {
  ADD_ON_TRANSPORT = 'ADD_ON_TRANSPORT',
  ARBITRATION = 'ARBITRATION',
  BUYER_PAYMENT = 'BUYER_PAYMENT',
  COMPOUND_TRANSPORT = 'COMPOUND_TRANSPORT',
  SELLER_PAYMENT = 'SELLER_PAYMENT',
  TITLE = 'TITLE',
}

export enum ProcessStatus {
  ADD_ON_TRANSPORT_CANCELLED = 'ADD_ON_TRANSPORT_CANCELLED',
  ADD_ON_TRANSPORT_DELIVERED = 'ADD_ON_TRANSPORT_DELIVERED',
  ADD_ON_TRANSPORT_IN_TRANSIT = 'ADD_ON_TRANSPORT_IN_TRANSIT',
  ADD_ON_TRANSPORT_ORDERED = 'ADD_ON_TRANSPORT_ORDERED',
  ARBITRATION_OPEN = 'ARBITRATION_OPEN',
  ARBITRATION_RESOLVED = 'ARBITRATION_RESOLVED',
  BUYER_FINANCING_APPROVED = 'BUYER_FINANCING_APPROVED',
  BUYER_PAYMENT_AWAITING = 'BUYER_PAYMENT_AWAITING',
  BUYER_PAYMENT_RECEIVED = 'BUYER_PAYMENT_RECEIVED',
  COMPOUND_TRANSPORT_CANCELLED = 'COMPOUND_TRANSPORT_CANCELLED',
  COMPOUND_TRANSPORT_DELIVERED = 'COMPOUND_TRANSPORT_DELIVERED',
  COMPOUND_TRANSPORT_IN_TRANSIT = 'COMPOUND_TRANSPORT_IN_TRANSIT',
  COMPOUND_TRANSPORT_ORDERED = 'COMPOUND_TRANSPORT_ORDERED',
  SELLER_PAYMENT_PENDING = 'SELLER_PAYMENT_PENDING',
  SELLER_PAYMENT_SENT = 'SELLER_PAYMENT_SENT',
  TITLE_NOT_REQUIRED = 'TITLE_NOT_REQUIRED',
  TITLE_RECEIVED = 'TITLE_RECEIVED',
  TITLE_REQUESTED = 'TITLE_REQUESTED',
  TITLE_SENT = 'TITLE_SENT',
}

export type Query = {
  __typename?: 'Query';
  /** @deprecated no longer used */
  arbitrationMetaData?: Maybe<ArbitrationMetaData>;
  asIsCause?: Maybe<Scalars['String']['output']>;
  /** @deprecated Field no longer supported */
  asIsQualified: Scalars['Boolean']['output'];
  auction?: Maybe<Auction>;
  auctionConnection?: Maybe<AuctionConnection>;
  auctionEventTimeline?: Maybe<Array<Maybe<AuctionEventTimelineEntry>>>;
  auctionItem?: Maybe<AuctionItem>;
  /** @deprecated no longer used */
  auctionItemArbitrationConnection?: Maybe<AuctionItemConnection>;
  /** Returns metadata info on existing fees for given auctionItemId and party */
  auctionItemAuctionServiceMetadata?: Maybe<Array<Maybe<AuctionServiceMetadata>>>;
  auctionItemConnection?: Maybe<AuctionItemConnection>;
  auctionItemOrders?: Maybe<AuctionItemOrdersResponse>;
  auctionItemPublic?: Maybe<AuctionItemPublic>;
  auctionItemSuggestedSaleLights: Array<SaleLight>;
  auctionLane: AuctionLane;
  /**
   * Returns the reservations for a give TimeSlot or TimeSlotLane.
   * Requires at least one of auctionTimeSlotLaneId or auctionTimeSlotId.
   * If both are specified, auctionTimeSlotLaneId takes precedence.
   */
  auctionLaneReservations: Array<AuctionLaneReservation>;
  /** Connection query that is paged and returns facets */
  auctionServiceConnection?: Maybe<AuctionServiceConnection>;
  auctionServiceDefaultsMetadata: Array<Maybe<AuctionServiceMetadata>>;
  /** Returns metadata info on existing fees for given parameters */
  auctionServiceMetadata?: Maybe<Array<Maybe<AuctionServiceMetadata>>>;
  auctionTimeSlot?: Maybe<AuctionTimeSlot>;
  auctionTimeSlotConnection?: Maybe<AuctionTimeSlotConnection>;
  auctionTimeSlotLane?: Maybe<AuctionTimeSlotLane>;
  /** Used for direct access of AutoGrade damage metadata records. */
  autoGradeDamageCodeRecords?: Maybe<Array<Maybe<AutoGradeDamageCodeRecord>>>;
  /** Used for AutoGrade damage metadata tree discovery. The tree structure is section.item.damage.severity. */
  autoGradeDamageMetadata?: Maybe<Array<Maybe<AutoGradeSection>>>;
  bidBadge?: Maybe<BidBadge>;
  broadcast?: Maybe<Broadcast>;
  broadcasts?: Maybe<Array<Maybe<Broadcast>>>;
  broadcastsConnection?: Maybe<BroadcastConnection>;
  carrier?: Maybe<Carrier>;
  carrierConnection?: Maybe<CarrierConnection>;
  clerkLiveLaneConversation: Conversation;
  clerkLiveLaneConversations: Array<Conversation>;
  company?: Maybe<Company>;
  companyAuction?: Maybe<CompanyAuctionRelationship>;
  companyAuctionConnection?: Maybe<CompanyAuctionRelationshipConnection>;
  companyAuctionPermissions?: Maybe<Array<Maybe<CompanyAuctionPermission>>>;
  companyConnection?: Maybe<CompanyConnection>;
  companyCreditLogConnection?: Maybe<CompanyCreditLogConnection>;
  companyIntegrationMetadata?: Maybe<Array<Maybe<CompanyIntegrationMetadata>>>;
  companyServiceMetadata: Array<AuctionServiceMetadata>;
  companyUserConnection?: Maybe<UserCompanyRelationshipConnection>;
  conversationAccessToken: Scalars['String']['output'];
  conversationAuctioneerAccessToken: Scalars['String']['output'];
  eblockId?: Maybe<Scalars['String']['output']>;
  emailContentRequestTitle: EmailContent;
  emailHistoryConnection?: Maybe<EmailHistoryConnection>;
  emailHistoryContent?: Maybe<Scalars['String']['output']>;
  externalIds?: Maybe<Array<Maybe<ExternalId>>>;
  featureFlags: Array<FeatureFlag>;
  /** Returns fees associated with a particular auction, company, and auction item */
  fees: Array<Maybe<Fee>>;
  findCompany?: Maybe<Array<Maybe<CompanySearchResult>>>;
  findUser?: Maybe<Array<Maybe<FindUser>>>;
  inspection?: Maybe<Inspection>;
  inspectionConnection?: Maybe<InspectionConnection>;
  /** Returns a list of companies between which internal sale of the inventory item is possible */
  internalSaleCompanies: Array<Company>;
  inventoryItem?: Maybe<InventoryItem>;
  inventoryItemConnection?: Maybe<InventoryItemConnection>;
  inventoryItemMetaData?: Maybe<InventoryItemMetaData>;
  inventoryItemValues: Array<InventoryItemValue>;
  inventoryItemValuesMetaData?: Maybe<Array<Maybe<InventoryItemValueType>>>;
  /** Returns metadata for available inventory fees */
  inventoryServiceMetadata: Array<AuctionServiceMetadata>;
  /** Returns fees associated with a company */
  inventoryServices: Array<InventoryService>;
  invoice?: Maybe<Invoice>;
  invoicesConnection?: Maybe<InvoiceConnection>;
  liveLanes?: Maybe<Array<Maybe<LiveLane>>>;
  location?: Maybe<Location>;
  locationConnection?: Maybe<LocationConnection>;
  /** Returns fees associated with a location */
  locationInventoryServices: Array<LocationInventoryService>;
  maintenanceTasks: Array<MaintenanceTask>;
  marketGuideConnection?: Maybe<AuctionItemPublicConnection>;
  minimumSupportedApplicationVersions?: Maybe<MinimumSupportedApplicationVersions>;
  myBlockBuyerStatistics: MyBlockBuyerResponse;
  myBlockSellerStatistics: MyBlockSellerResponse;
  notificationConnection?: Maybe<UserNotificationConnection>;
  operatingRegions?: Maybe<Array<Maybe<OperatingRegion>>>;
  payment?: Maybe<Payment>;
  paymentBankFilesConnection?: Maybe<PaymentBankFileConnection>;
  paymentDepositsConnection?: Maybe<PaymentDepositConnection>;
  paymentTypes?: Maybe<Array<PaymentType>>;
  paymentsConnection?: Maybe<PaymentConnection>;
  placesAutocompleteQuery?: Maybe<Array<PlaceAutocomplete>>;
  placesDetailsQuery?: Maybe<PlaceDetails>;
  requestedInvite?: Maybe<RequestedInvite>;
  requestedInviteConnection?: Maybe<RequestedInviteConnection>;
  reservedLaneSlots?: Maybe<Array<ReservedLaneSlot>>;
  serviceFeesConnection?: Maybe<ServiceFeeConnection>;
  superDispatchCarriers: Array<SDCarrierDetail>;
  supportedLocales: Array<Maybe<ValueDTO>>;
  task?: Maybe<Task>;
  taskConnection?: Maybe<TaskConnection>;
  taskTypes: Array<TaskType>;
  transportFeeQuote?: Maybe<TransportFeeQuote>;
  transportJob?: Maybe<TransportJob>;
  transportRequest?: Maybe<TransportRequest>;
  transportRequestConnection?: Maybe<TransportRequestConnection>;
  user?: Maybe<User>;
  userBidBadges?: Maybe<Array<BidBadge>>;
  userCompanyConnection?: Maybe<UserCompanyRelationshipConnection>;
  userConnection?: Maybe<UserConnection>;
  userRoleConnection?: Maybe<UserCompanyRoleConnection>;
  userValidateAuctionAccess?: Maybe<AuctionAccessValidateUserResponse>;
  vehicleCarfaxAnnouncements: CarfaxAnnouncements;
};

export type QueryasIsCauseArgs = {
  auctionFormat: AuctionItemFormat;
  auctionId: Scalars['String']['input'];
  inventoryItemId: Scalars['String']['input'];
  price: Scalars['Float']['input'];
};

export type QueryasIsQualifiedArgs = {
  auctionFormat: AuctionItemFormat;
  auctionId: Scalars['String']['input'];
  inventoryItemId: Scalars['String']['input'];
  price: Scalars['Float']['input'];
};

export type QueryauctionArgs = {
  auctionId: Scalars['String']['input'];
};

export type QueryauctionConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  auctionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  before?: InputMaybe<Scalars['String']['input']>;
  consignerId?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  regionCode?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type QueryauctionEventTimelineArgs = {
  auctionTimeSlotLaneId: Scalars['String']['input'];
};

export type QueryauctionItemArgs = {
  auctionItemId: Scalars['String']['input'];
  holdbackActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryauctionItemArbitrationConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  arbiter?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  arbitrationCancelled?: InputMaybe<Scalars['Boolean']['input']>;
  arbitrationOnTime?: InputMaybe<Scalars['Boolean']['input']>;
  arbitrationResponsibleParty?: InputMaybe<Array<InputMaybe<ArbitrationResponsibleParty>>>;
  arbitrationStatus?: InputMaybe<Array<InputMaybe<AuctionItemArbitrationStatus>>>;
  arbitrationSubStatus?: InputMaybe<Array<InputMaybe<AuctionItemArbitrationSubStatus>>>;
  arbitrationTags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  arbitrationTransactionType?: InputMaybe<Array<InputMaybe<TransactionType>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  buyer?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  compensationPaidByEBlockGTE?: InputMaybe<Scalars['Int']['input']>;
  compensationPaidByEBlockLTE?: InputMaybe<Scalars['Int']['input']>;
  compensationPaidByEBlockType?: InputMaybe<Array<InputMaybe<ArbitrationCompensationType>>>;
  compensationToBuyerGTE?: InputMaybe<Scalars['Int']['input']>;
  compensationToBuyerLTE?: InputMaybe<Scalars['Int']['input']>;
  filterBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  seller?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
};

export type QueryauctionItemAuctionServiceMetadataArgs = {
  auctionItemId: Scalars['String']['input'];
  party: AuctionServiceMetadataParty;
};

export type QueryauctionItemConnectionArgs = {
  accountingSystemIds?: InputMaybe<Array<Scalars['String']['input']>>;
  after?: InputMaybe<Scalars['String']['input']>;
  alreadyViewed?: InputMaybe<Scalars['Boolean']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  assigned?: InputMaybe<Scalars['Boolean']['input']>;
  auctionIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  auctionItemEndTime?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  auctionItemIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  auctionLaneType?: InputMaybe<AuctionLaneType>;
  auctionLocationName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  auctionStartTime?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  auctionTimeSlotIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  auctionTimeSlotLane?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  auctionTimeSlotLaneIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  auctionTimeSlotStatus?: InputMaybe<Array<InputMaybe<AuctionTimeSlotStatus>>>;
  auctionTitle?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyType?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  buyer?: InputMaybe<Array<Scalars['String']['input']>>;
  buyerSalesManager?: InputMaybe<Array<Scalars['String']['input']>>;
  chargingCable?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  consigner?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  consignerId?: InputMaybe<Scalars['String']['input']>;
  currentOnly?: InputMaybe<Scalars['Boolean']['input']>;
  dateCreatedGTE?: InputMaybe<Scalars['DateTime']['input']>;
  dateCreatedLTE?: InputMaybe<Scalars['DateTime']['input']>;
  dateRanGTE?: InputMaybe<Scalars['DateTime']['input']>;
  dateRanLTE?: InputMaybe<Scalars['DateTime']['input']>;
  dateSoldGTE?: InputMaybe<Scalars['DateTime']['input']>;
  dateSoldLTE?: InputMaybe<Scalars['DateTime']['input']>;
  distance?: InputMaybe<Scalars['Int']['input']>;
  driveTrain?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  exteriorColor?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filterBy?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  formats?: InputMaybe<Array<InputMaybe<AuctionItemFormat>>>;
  fuelType?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  holdbackActive?: InputMaybe<Scalars['Boolean']['input']>;
  ifBidClaim?: InputMaybe<IfBidClaim>;
  ifBidClaimedByUserName?: InputMaybe<Array<Scalars['String']['input']>>;
  ifBidLastOfferBy?: InputMaybe<IfBidLastOfferBy>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  locationCountry?: InputMaybe<Array<Scalars['String']['input']>>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  makes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mileageGTE?: InputMaybe<Scalars['Int']['input']>;
  mileageLTE?: InputMaybe<Scalars['Int']['input']>;
  models?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  option?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  paymentSummaryStatus?: InputMaybe<AuctionItemPaymentStatus>;
  region?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reservePriceGTE?: InputMaybe<Scalars['BigDecimal']['input']>;
  reservePriceLTE?: InputMaybe<Scalars['BigDecimal']['input']>;
  saleLights?: InputMaybe<Array<InputMaybe<SaleLight>>>;
  salesManager?: InputMaybe<Array<Scalars['String']['input']>>;
  salesRep?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  salesRepId?: InputMaybe<Scalars['String']['input']>;
  sellerRegion?: InputMaybe<Array<Scalars['String']['input']>>;
  sellerSalesManager?: InputMaybe<Array<Scalars['String']['input']>>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
  startingBidGTE?: InputMaybe<Scalars['BigDecimal']['input']>;
  startingBidLTE?: InputMaybe<Scalars['BigDecimal']['input']>;
  status?: InputMaybe<Array<InputMaybe<AuctionItemStatus>>>;
  subModels?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  transactionType?: InputMaybe<TransactionType>;
  transmission?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  validTitle?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleScoreGTE?: InputMaybe<Scalars['BigDecimal']['input']>;
  vehicleScoreLTE?: InputMaybe<Scalars['BigDecimal']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
  viewName?: InputMaybe<AuctionItemViewName>;
  yearGTE?: InputMaybe<Scalars['Int']['input']>;
  yearLTE?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryauctionItemPublicArgs = {
  auctionItemId: Scalars['String']['input'];
};

export type QueryauctionItemSuggestedSaleLightsArgs = {
  asIs: Scalars['Boolean']['input'];
  inventoryItemId: Scalars['String']['input'];
};

export type QueryauctionLaneArgs = {
  auctionLaneId: Scalars['String']['input'];
};

export type QueryauctionLaneReservationsArgs = {
  auctionTimeSlotId?: InputMaybe<Scalars['String']['input']>;
  auctionTimeSlotLaneId?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryauctionServiceConnectionArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  auctionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  formats?: InputMaybe<Array<AuctionItemFormat>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  party?: InputMaybe<AuctionServiceMetadataParty>;
};

export type QueryauctionServiceDefaultsMetadataArgs = {
  companyAuctionRelationshipId: Scalars['ID']['input'];
  party?: InputMaybe<AuctionServiceMetadataParty>;
  type?: InputMaybe<AuctionServiceMetadataDefaultType>;
};

export type QueryauctionServiceMetadataArgs = {
  auctionId: Scalars['String']['input'];
  buyerShield?: InputMaybe<AuctionServiceTriState>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  companySubTypes?: InputMaybe<Array<InputMaybe<CompanySubType>>>;
  formats?: InputMaybe<Array<AuctionItemFormat>>;
  paidBy?: InputMaybe<AuctionServiceMetadataParty>;
  party: AuctionServiceMetadataParty;
  verified?: InputMaybe<AuctionServiceTriState>;
};

export type QueryauctionTimeSlotArgs = {
  auctionTimeSlotId: Scalars['String']['input'];
};

export type QueryauctionTimeSlotConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  auctionIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  auctionTitles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  cutOffTimeGTE?: InputMaybe<Scalars['DateTime']['input']>;
  cutOffTimeLTE?: InputMaybe<Scalars['DateTime']['input']>;
  events?: InputMaybe<Scalars['String']['input']>;
  finishTimeGTE?: InputMaybe<Scalars['DateTime']['input']>;
  finishTimeLTE?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  formats?: InputMaybe<Array<AuctionTimeSlotFormat>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
  startTimeGTE?: InputMaybe<Scalars['DateTime']['input']>;
  startTimeLTE?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Array<InputMaybe<AuctionTimeSlotStatus>>>;
};

export type QueryauctionTimeSlotLaneArgs = {
  auctionTimeSlotLaneId: Scalars['String']['input'];
};

export type QueryautoGradeDamageCodeRecordsArgs = {
  recordIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryautoGradeDamageMetadataArgs = {
  sectionNumbers?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QuerybidBadgeArgs = {
  auctionTimeSlotId: Scalars['String']['input'];
  bidBadgeNumber: Scalars['String']['input'];
};

export type QuerybroadcastArgs = {
  broadcastId: Scalars['ID']['input'];
};

export type QuerybroadcastsArgs = {
  auctionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type QuerybroadcastsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  auctionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Array<InputMaybe<BroadcastStatus>>>;
  type?: InputMaybe<Array<InputMaybe<BroadcastType>>>;
};

export type QuerycarrierArgs = {
  carrierId: Scalars['String']['input'];
};

export type QuerycarrierConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  auction?: InputMaybe<Array<Scalars['String']['input']>>;
  auctionId?: InputMaybe<Array<Scalars['String']['input']>>;
  before?: InputMaybe<Scalars['String']['input']>;
  carrierIds?: InputMaybe<Array<Scalars['String']['input']>>;
  deliveryRegionCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  deliveryRegions?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  flagged?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  shipmentModes?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type QueryclerkLiveLaneConversationArgs = {
  conversationId: Scalars['String']['input'];
  isAuctioneer?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryclerkLiveLaneConversationsArgs = {
  auctionTimeSlotLaneId: Scalars['String']['input'];
  isAuctioneer?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerycompanyArgs = {
  companyId: Scalars['String']['input'];
};

export type QuerycompanyAuctionArgs = {
  auctionId?: InputMaybe<Scalars['String']['input']>;
  companyAuctionRelationshipId?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
};

export type QuerycompanyAuctionConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  permission?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  region?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status?: InputMaybe<Array<InputMaybe<CompanyAuctionRelationshipStatus>>>;
};

export type QuerycompanyConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  auction?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  auctionId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  auctionRelationshipPermission?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  auctionRelationshipStatus?: InputMaybe<Array<InputMaybe<CompanyAuctionRelationshipStatus>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  companyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filterBy?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  parentCompanyIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  region?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  salesRep?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  salesRepId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status?: InputMaybe<Array<InputMaybe<CompanyStatus>>>;
  subType?: InputMaybe<Array<InputMaybe<CompanySubType>>>;
  type?: InputMaybe<Array<InputMaybe<CompanyType>>>;
  visibleRegion?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QuerycompanyCreditLogConnectionArgs = {
  accountingSystemId: Scalars['String']['input'];
  after: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  first: Scalars['Int']['input'];
  type: AccountEnum;
};

export type QuerycompanyIntegrationMetadataArgs = {
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QuerycompanyServiceMetadataArgs = {
  auctionId?: InputMaybe<Scalars['String']['input']>;
};

export type QuerycompanyUserConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  auctionAccessStatus?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  department?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  photoStatus?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  region?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
  status?: InputMaybe<Array<InputMaybe<UserCompanyRelationshipStatus>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryconversationAuctioneerAccessTokenArgs = {
  auctionTimeSlotLaneId: Scalars['String']['input'];
};

export type QueryeblockIdArgs = {
  entityType: EntityType;
  externalId: Scalars['String']['input'];
  integrationName: IntegrationName;
};

export type QueryemailContentRequestTitleArgs = {
  auctionItemId: Scalars['String']['input'];
};

export type QueryemailHistoryConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  entityId: Scalars['String']['input'];
  entityType: EntityType;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryemailHistoryContentArgs = {
  id: Scalars['String']['input'];
};

export type QueryexternalIdsArgs = {
  entityId: Scalars['String']['input'];
  integrationName?: InputMaybe<IntegrationName>;
};

export type QueryfeatureFlagsArgs = {
  keys?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryfeesArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  auctionId?: InputMaybe<Scalars['String']['input']>;
  auctionItemId?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  format?: InputMaybe<AuctionItemFormat>;
  party: AuctionServiceMetadataParty;
};

export type QueryfindCompanyArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
};

export type QueryfindUserArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  notInCompanyId: Scalars['String']['input'];
};

export type QueryinspectionArgs = {
  inspectionId: Scalars['String']['input'];
};

export type QueryinspectionConnectionArgs = {
  acceptedDataCaptureRepName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  acceptedSalesRepId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  captureDateGTE?: InputMaybe<Scalars['DateTime']['input']>;
  captureDateLTE?: InputMaybe<Scalars['DateTime']['input']>;
  city?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  consignerId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  consignerName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filterBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  region?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  salesRep?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  salesRepId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status?: InputMaybe<Array<InputMaybe<InspectionStatus>>>;
  timeOfDay?: InputMaybe<Array<InputMaybe<InspectionTime>>>;
};

export type QueryinternalSaleCompaniesArgs = {
  auctionId: Scalars['String']['input'];
  inventoryItemId: Scalars['String']['input'];
};

export type QueryinventoryItemArgs = {
  inventoryItemId: Scalars['String']['input'];
};

export type QueryinventoryItemConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  auction?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  auctionLocationName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  auctionTimeSlotLaneDescription?: InputMaybe<Array<Scalars['String']['input']>>;
  auctionTimeSlotStartTime?: InputMaybe<Array<Scalars['String']['input']>>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyType?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  buyerName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  chargingCable?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  checkedInOut?: InputMaybe<InventoryCheckedInOutStatus>;
  checkedOutReason?: InputMaybe<Array<InventoryCheckedOutReason>>;
  consigner?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  consignerId?: InputMaybe<Scalars['String']['input']>;
  createdGTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdLTE?: InputMaybe<Scalars['DateTime']['input']>;
  currentAuctionItemId?: InputMaybe<Scalars['String']['input']>;
  currentAuctionItemStatusName?: InputMaybe<Array<Scalars['String']['input']>>;
  currentCompoundName?: InputMaybe<Array<Scalars['String']['input']>>;
  distance?: InputMaybe<Scalars['Int']['input']>;
  driveTrain?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  exteriorColor?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fuelType?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hasDamages?: InputMaybe<Scalars['Boolean']['input']>;
  inspectorCaptureCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  inventoryItemIds?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  locationCountry?: InputMaybe<Array<Scalars['String']['input']>>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  makes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mileageGTE?: InputMaybe<Scalars['Int']['input']>;
  mileageLTE?: InputMaybe<Scalars['Int']['input']>;
  models?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  option?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  parked?: InputMaybe<Scalars['Boolean']['input']>;
  pickupCompoundName?: InputMaybe<Array<Scalars['String']['input']>>;
  region?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reservePrice?: InputMaybe<Scalars['String']['input']>;
  runNumber?: InputMaybe<Array<Scalars['String']['input']>>;
  salesRepId?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<VehicleStatus>>;
  subModels?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titleStatus?: InputMaybe<Array<TitleStatus>>;
  transmission?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updatedGTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedLTE?: InputMaybe<Scalars['DateTime']['input']>;
  validTitle?: InputMaybe<Scalars['Boolean']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
  yearGTE?: InputMaybe<Scalars['Int']['input']>;
  yearLTE?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryinventoryItemValuesArgs = {
  auctionId: Scalars['String']['input'];
  inventoryItemId: Scalars['String']['input'];
};

export type QueryinventoryItemValuesMetaDataArgs = {
  canAdd?: InputMaybe<Scalars['Boolean']['input']>;
  canDelete?: InputMaybe<Scalars['Boolean']['input']>;
  canUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  inventoryItemId: Scalars['String']['input'];
};

export type QueryinventoryServiceMetadataArgs = {
  auctionId?: InputMaybe<Scalars['String']['input']>;
  party?: InputMaybe<AuctionServiceMetadataParty>;
};

export type QueryinventoryServicesArgs = {
  companyId: Scalars['String']['input'];
  invoiced?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryinvoiceArgs = {
  id: Scalars['String']['input'];
};

export type QueryinvoicesConnectionArgs = {
  accountingSystemId?: InputMaybe<Scalars['String']['input']>;
  after: Scalars['String']['input'];
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  companyNames?: InputMaybe<Array<Scalars['String']['input']>>;
  createdGTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdLTE?: InputMaybe<Scalars['DateTime']['input']>;
  first: Scalars['Int']['input'];
  invoiceTypes?: InputMaybe<Array<InvoiceType>>;
  isCredit?: InputMaybe<Scalars['Boolean']['input']>;
  isPaid?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  paymentBlockers?: InputMaybe<Array<Scalars['String']['input']>>;
  paymentTypeNames?: InputMaybe<Array<Scalars['String']['input']>>;
  sort?: InputMaybe<Array<Sort>>;
};

export type QueryliveLanesArgs = {
  auctionIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  laneId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  watching?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerylocationArgs = {
  locationId: Scalars['String']['input'];
};

export type QuerylocationConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  allowSuspended?: InputMaybe<Scalars['Boolean']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  auctionId?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  carrierId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  consignerId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mode?: InputMaybe<LocationConnectionMode>;
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  region?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regionCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
};

export type QuerylocationInventoryServicesArgs = {
  locationId: Scalars['String']['input'];
};

export type QuerymarketGuideConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  bodyTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  distance?: InputMaybe<Scalars['Int']['input']>;
  driveTrains?: InputMaybe<Array<Scalars['String']['input']>>;
  exteriorColors?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fuelTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  mileageGTE?: InputMaybe<Scalars['Int']['input']>;
  mileageLTE?: InputMaybe<Scalars['Int']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Array<Scalars['String']['input']>>;
  regions?: InputMaybe<Array<Scalars['String']['input']>>;
  soldWithinDays: Scalars['Int']['input'];
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
  subModels?: InputMaybe<Array<Scalars['String']['input']>>;
  transmissions?: InputMaybe<Array<Scalars['String']['input']>>;
  trims?: InputMaybe<Array<Scalars['String']['input']>>;
  vin?: InputMaybe<Scalars['String']['input']>;
  yearGTE?: InputMaybe<Scalars['Int']['input']>;
  yearLTE?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerymyBlockBuyerStatisticsArgs = {
  input: MyBlockStatisticsInput;
};

export type QuerymyBlockSellerStatisticsArgs = {
  input: MyBlockStatisticsInput;
};

export type QuerynotificationConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  messageType?: InputMaybe<EmailMessageType>;
  to?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<NotificationType>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerypaymentArgs = {
  id: Scalars['String']['input'];
};

export type QuerypaymentBankFilesConnectionArgs = {
  accountingSystemId?: InputMaybe<Scalars['String']['input']>;
  after: Scalars['String']['input'];
  before?: InputMaybe<Scalars['String']['input']>;
  createdGTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdLTE?: InputMaybe<Scalars['DateTime']['input']>;
  first: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<Sort>>;
  types?: InputMaybe<Array<PaymentBankFileType>>;
};

export type QuerypaymentDepositsConnectionArgs = {
  accountingSystemId?: InputMaybe<Scalars['String']['input']>;
  after: Scalars['String']['input'];
  before?: InputMaybe<Scalars['String']['input']>;
  createdGTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdLTE?: InputMaybe<Scalars['DateTime']['input']>;
  first: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<Sort>>;
};

export type QuerypaymentTypesArgs = {
  auctionId: Scalars['String']['input'];
  auctionItemId?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  includeStaffUseOnlyPaymentTypes?: InputMaybe<Scalars['Boolean']['input']>;
  paymentSubType?: InputMaybe<PaymentSubType>;
};

export type QuerypaymentsConnectionArgs = {
  accountingSystemId?: InputMaybe<Scalars['String']['input']>;
  after: Scalars['String']['input'];
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  companyNames?: InputMaybe<Array<Scalars['String']['input']>>;
  createdGTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdLTE?: InputMaybe<Scalars['DateTime']['input']>;
  first: Scalars['Int']['input'];
  isPrinted?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  paymentSubTypes?: InputMaybe<Array<PaymentSubType>>;
  paymentTypeName?: InputMaybe<Array<Scalars['String']['input']>>;
  sort?: InputMaybe<Array<Sort>>;
  status?: InputMaybe<Array<PaymentStatus>>;
};

export type QueryplacesAutocompleteQueryArgs = {
  countryCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  keyword: Scalars['String']['input'];
};

export type QueryplacesDetailsQueryArgs = {
  id: Scalars['String']['input'];
};

export type QueryrequestedInviteArgs = {
  requestedInviteId: Scalars['String']['input'];
};

export type QueryrequestedInviteConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryreservedLaneSlotsArgs = {
  auctionTimeSlotLaneId: Scalars['String']['input'];
};

export type QueryserviceFeesConnectionArgs = {
  accountingSystemId?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  after: Scalars['String']['input'];
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  companyNames?: InputMaybe<Array<Scalars['String']['input']>>;
  currentAuctionItemStatus?: InputMaybe<Array<AuctionItemStatus>>;
  dateCreatedGTE?: InputMaybe<Scalars['DateTime']['input']>;
  dateCreatedLTE?: InputMaybe<Scalars['DateTime']['input']>;
  feeName?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  flagged?: InputMaybe<Scalars['Boolean']['input']>;
  invoiced?: InputMaybe<Scalars['Boolean']['input']>;
  invoicingIndicators?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<Sort>>;
};

export type QuerysuperDispatchCarriersArgs = {
  keyword: Scalars['String']['input'];
};

export type QuerytaskArgs = {
  taskId: Scalars['String']['input'];
};

export type QuerytaskConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  assignedTo?: InputMaybe<Array<Scalars['String']['input']>>;
  auctionName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  awaitingOn?: InputMaybe<Array<InputMaybe<WaitingOn>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  buyer?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  buyerId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  createdBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateCreatedGTE?: InputMaybe<Scalars['DateTime']['input']>;
  dateCreatedLTE?: InputMaybe<Scalars['DateTime']['input']>;
  dateUpdatedGTE?: InputMaybe<Scalars['DateTime']['input']>;
  dateUpdatedLTE?: InputMaybe<Scalars['DateTime']['input']>;
  filterBy?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locationCountry?: InputMaybe<Array<Scalars['String']['input']>>;
  seller?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sellerId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
  status?: InputMaybe<Array<InputMaybe<TaskStatus>>>;
  subTaskNameAndStatus?: InputMaybe<Array<Scalars['String']['input']>>;
  taskIds?: InputMaybe<Array<Scalars['String']['input']>>;
  typeName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QuerytaskTypesArgs = {
  entityType?: InputMaybe<EntityType>;
};

export type QuerytransportFeeQuoteArgs = {
  auctionItemId: Scalars['String']['input'];
  dropOffLocationId: Scalars['String']['input'];
};

export type QuerytransportJobArgs = {
  transportJobId: Scalars['String']['input'];
};

export type QuerytransportRequestArgs = {
  transportRequestId: Scalars['String']['input'];
};

export type QuerytransportRequestConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  assignedTo?: InputMaybe<Array<Scalars['String']['input']>>;
  auctionTitle?: InputMaybe<Array<Scalars['String']['input']>>;
  before?: InputMaybe<Scalars['String']['input']>;
  buyer?: InputMaybe<Array<Scalars['String']['input']>>;
  buyerId?: InputMaybe<Array<Scalars['String']['input']>>;
  carrier?: InputMaybe<Array<Scalars['String']['input']>>;
  carrierId?: InputMaybe<Array<Scalars['String']['input']>>;
  createdBy?: InputMaybe<Array<Scalars['String']['input']>>;
  deliveredDateGTE?: InputMaybe<Scalars['Date']['input']>;
  deliveredDateLTE?: InputMaybe<Scalars['Date']['input']>;
  destinationCity?: InputMaybe<Array<Scalars['String']['input']>>;
  destinationCountry?: InputMaybe<Array<Scalars['String']['input']>>;
  destinationLocation?: InputMaybe<Array<Scalars['String']['input']>>;
  destinationRegion?: InputMaybe<Array<Scalars['String']['input']>>;
  estimatedDeliveryDateGTE?: InputMaybe<Scalars['Date']['input']>;
  estimatedDeliveryDateLTE?: InputMaybe<Scalars['Date']['input']>;
  estimatedPickupDateGTE?: InputMaybe<Scalars['Date']['input']>;
  estimatedPickupDateLTE?: InputMaybe<Scalars['Date']['input']>;
  filterBy?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  flagged?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  pickupCity?: InputMaybe<Array<Scalars['String']['input']>>;
  pickupCountry?: InputMaybe<Array<Scalars['String']['input']>>;
  pickupLocation?: InputMaybe<Array<Scalars['String']['input']>>;
  pickupRegion?: InputMaybe<Array<Scalars['String']['input']>>;
  quoteCount?: InputMaybe<Array<Scalars['String']['input']>>;
  seller?: InputMaybe<Array<Scalars['String']['input']>>;
  sellerId?: InputMaybe<Array<Scalars['String']['input']>>;
  sort?: InputMaybe<Array<Sort>>;
  status?: InputMaybe<Array<TransportRequestStatus>>;
  transportRequestIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryuserArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryuserBidBadgesArgs = {
  userId: Scalars['String']['input'];
};

export type QueryuserCompanyConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  auctionAccessStatus?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  distance?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  region?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status?: InputMaybe<Array<InputMaybe<UserCompanyRelationshipStatus>>>;
  subType?: InputMaybe<Array<InputMaybe<CompanySubType>>>;
  type?: InputMaybe<Array<InputMaybe<CompanyType>>>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryuserConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  auctionAccessStatus?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  consignerId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  consignerName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  createdGTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdLTE?: InputMaybe<Scalars['DateTime']['input']>;
  dataCaptureRepsOnly?: Scalars['Boolean']['input'];
  email?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  firstName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  omvicStatus?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
  phoneVerified?: InputMaybe<Scalars['Boolean']['input']>;
  photoStatus?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  region?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  timeZone?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updatedGTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedLTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QueryuserRoleConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryuserValidateAuctionAccessArgs = {
  auctionAccessId: Scalars['Int']['input'];
  auctionAccessLast4GovId: Scalars['String']['input'];
};

export type QueryvehicleCarfaxAnnouncementsArgs = {
  inventoryItemId: Scalars['String']['input'];
};

export type Question = {
  __typename?: 'Question';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parameters: Array<QuestionParameter>;
};

export type QuestionParameter = {
  __typename?: 'QuestionParameter';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parameterName: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
  selectedValues: Array<QuestionParameterOption>;
  type?: Maybe<QuestionParameterType>;
};

export type QuestionParameterOption = {
  __typename?: 'QuestionParameterOption';
  name: Scalars['String']['output'];
  selected: Scalars['Boolean']['output'];
  value: Scalars['String']['output'];
};

export enum QuestionParameterType {
  DATE = 'DATE',
  DECIMAL = 'DECIMAL',
  MULTI_SELECT = 'MULTI_SELECT',
  NUMBER = 'NUMBER',
  SELECT = 'SELECT',
  TEXT = 'TEXT',
}

export enum ReleaseBlocker {
  ARBITRATION_IN_PROGRESS = 'ARBITRATION_IN_PROGRESS',
  BUYER_FEES_NOT_INVOICED = 'BUYER_FEES_NOT_INVOICED',
  NOT_AT_DESTINATION = 'NOT_AT_DESTINATION',
  NO_SALE = 'NO_SALE',
  PAYMENT_NOT_RECEIVED = 'PAYMENT_NOT_RECEIVED',
  PSI_IN_PROGRESS = 'PSI_IN_PROGRESS',
  RECEIVE_REFUND_IN_PROGRESS = 'RECEIVE_REFUND_IN_PROGRESS',
  SALE_CANCELLED = 'SALE_CANCELLED',
  SELLER_FEES_NOT_INVOICED = 'SELLER_FEES_NOT_INVOICED',
  TRANSPORT_IN_PROGRESS = 'TRANSPORT_IN_PROGRESS',
  TRANSPORT_TO_COMPOUND_IN_PROGRESS = 'TRANSPORT_TO_COMPOUND_IN_PROGRESS',
  VEHICLE_ON_HOLD = 'VEHICLE_ON_HOLD',
}

export type ReleaseForBuyerPickupInput = {
  auctionItemId: Scalars['String']['input'];
  emailContent: EmailContentInput;
};

export type ReleaseForSellerPickupInput = {
  emailContent: EmailContentInput;
  inventoryItemId: Scalars['String']['input'];
};

export type ReleaseForTransportInput = {
  auctionItemId: Scalars['String']['input'];
  emailContent: EmailContentInput;
};

export enum ReleaseStatus {
  NOT_RELEASED = 'NOT_RELEASED',
  ON_HOLD = 'ON_HOLD',
  RELEASED = 'RELEASED',
  RELEASED_FOR_TRANSPORT = 'RELEASED_FOR_TRANSPORT',
}

export type RepInput = {
  /** Reference to the rep type. Can be fetched from auction.representativeTypes */
  typeId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export enum RepaintPanels {
  FIVE_TO_ENTIRE_VEHICLE = 'FIVE_TO_ENTIRE_VEHICLE',
  ONE_TO_TWO_PANELS = 'ONE_TO_TWO_PANELS',
  THREE_TO_FOUR_PANELS = 'THREE_TO_FOUR_PANELS',
}

export enum RepairAction {
  AIRBRUSH = 'AIRBRUSH',
  ALIGN_OR_ADJUST = 'ALIGN_OR_ADJUST',
  CALIBRATE = 'CALIBRATE',
  CHECK_AND_ADVISE = 'CHECK_AND_ADVISE',
  CLAY = 'CLAY',
  CLEAN_UP_INOPERABLE_UNIT = 'CLEAN_UP_INOPERABLE_UNIT',
  DEALER_DIAGNOSTIC = 'DEALER_DIAGNOSTIC',
  DETAIL = 'DETAIL',
  DETAIL_EXTRA_STEP = 'DETAIL_EXTRA_STEP',
  DETAIL_FULL = 'DETAIL_FULL',
  DETAIL_SECOND_DETAIL = 'DETAIL_SECOND_DETAIL',
  DETAIL_WASH_AND_VACUUM = 'DETAIL_WASH_AND_VACUUM',
  DETRASH_INOPERABLE_UNIT = 'DETRASH_INOPERABLE_UNIT',
  DE_IDENTIFY = 'DE_IDENTIFY',
  DYE = 'DYE',
  ENHANCED_INTERIOR_CLEANING = 'ENHANCED_INTERIOR_CLEANING',
  ENHANCED_SEAT_CLEANING = 'ENHANCED_SEAT_CLEANING',
  ESTIMATE_ONLY = 'ESTIMATE_ONLY',
  EXTRA_STEP_FULL_PRE_CERT = 'EXTRA_STEP_FULL_PRE_CERT',
  FLOOD_CHECK = 'FLOOD_CHECK',
  FRAME_CHECK = 'FRAME_CHECK',
  FRONT_LINE_READY_INSPECTION = 'FRONT_LINE_READY_INSPECTION',
  INCLUDED_IN_OTHER_ITEM = 'INCLUDED_IN_OTHER_ITEM',
  INSTALL = 'INSTALL',
  LASER_FRAME_CHECK = 'LASER_FRAME_CHECK',
  LIGHT_BRIGHTENING = 'LIGHT_BRIGHTENING',
  LIGHT_COMPOUND = 'LIGHT_COMPOUND',
  MECHANICAL_CHECK = 'MECHANICAL_CHECK',
  MECH_CHECK_OR_NOT_APPROVED = 'MECH_CHECK_OR_NOT_APPROVED',
  MECH_CHECK_OR_PRE_APPROVED = 'MECH_CHECK_OR_PRE_APPROVED',
  NO_ACTION_REQUIRED = 'NO_ACTION_REQUIRED',
  OVERALL_INTERIOR_CLEANING = 'OVERALL_INTERIOR_CLEANING',
  OVERHAUL = 'OVERHAUL',
  PARTIAL_DETAIL = 'PARTIAL_DETAIL',
  PARTIAL_PAINT_REFINISH = 'PARTIAL_PAINT_REFINISH',
  PARTIAL_TEXTURED_REFINISH = 'PARTIAL_TEXTURED_REFINISH',
  PDR = 'PDR',
  PERFORM_SAFETY_INSPECTION = 'PERFORM_SAFETY_INSPECTION',
  REFINISH = 'REFINISH',
  REMOVE = 'REMOVE',
  REMOVE_OR_INSTALL = 'REMOVE_OR_INSTALL',
  REPAINT = 'REPAINT',
  REPAIR = 'REPAIR',
  REPLACE = 'REPLACE',
  REPLACE_USED = 'REPLACE_USED',
  REPORT = 'REPORT',
  REWORK = 'REWORK',
  RE_DETAIL_OR_MARSHAL = 'RE_DETAIL_OR_MARSHAL',
  SUBLET = 'SUBLET',
  TOUCH_UP = 'TOUCH_UP',
  TRANSPORT = 'TRANSPORT',
  TRUNK_DETAIL = 'TRUNK_DETAIL',
  VISUAL_FRAME_CHECK = 'VISUAL_FRAME_CHECK',
  WASH = 'WASH',
  WASTE_DISPOSAL = 'WASTE_DISPOSAL',
  WATER_SPOT_REMOVAL = 'WATER_SPOT_REMOVAL',
  WET_SAND = 'WET_SAND',
  WET_SAND_AND_BRUSH_TOUCH = 'WET_SAND_AND_BRUSH_TOUCH',
}

export enum RepairApprovalSource {
  AUCTION = 'AUCTION',
  CONSIGNER = 'CONSIGNER',
}

export enum RepairApprovalStatus {
  APPROVED = 'APPROVED',
  APPROVED_VIA_AUCTION = 'APPROVED_VIA_AUCTION',
  NOT_REVIEWED = 'NOT_REVIEWED',
  REJECTED = 'REJECTED',
  REQUEST_REQUOTE = 'REQUEST_REQUOTE',
  UNDER_REVIEW = 'UNDER_REVIEW',
}

export enum RepairChargeable {
  DEDUCTIBLE = 'DEDUCTIBLE',
  NO = 'NO',
  SUPPLEMENTAL = 'SUPPLEMENTAL',
  TRANSPORTATION = 'TRANSPORTATION',
  YES = 'YES',
}

export type RepairItemInput = {
  action?: InputMaybe<RepairAction>;
  approvable?: InputMaybe<Scalars['Boolean']['input']>;
  approvalComment?: InputMaybe<Scalars['String']['input']>;
  approvalName?: InputMaybe<Scalars['String']['input']>;
  approvalSource?: InputMaybe<RepairApprovalSource>;
  approvalStatus?: InputMaybe<RepairApprovalStatus>;
  /** Required if id not specified */
  autoGradeDamageCode?: InputMaybe<Scalars['Int']['input']>;
  chargeable?: InputMaybe<RepairChargeable>;
  id?: InputMaybe<Scalars['String']['input']>;
  /** For reusing an existing repair item */
  inventoryItemId: Scalars['String']['input'];
  labourAmount?: InputMaybe<Scalars['BigDecimal']['input']>;
  labourHours?: InputMaybe<Scalars['BigDecimal']['input']>;
  labourRate?: InputMaybe<Scalars['BigDecimal']['input']>;
  orderComment?: InputMaybe<Scalars['String']['input']>;
  paintHours?: InputMaybe<Scalars['BigDecimal']['input']>;
  parts?: InputMaybe<Scalars['String']['input']>;
  partsAmount?: InputMaybe<Scalars['BigDecimal']['input']>;
  quoteCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<RepairStatus>;
  suggested?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum RepairStatus {
  NOT_REPAIRED = 'NOT_REPAIRED',
  REPAIRED = 'REPAIRED',
}

export type ReportGroup = {
  __typename?: 'ReportGroup';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  questions: Array<Question>;
};

export type RequestTitleInput = {
  auctionItemId: Scalars['String']['input'];
  emailContent: EmailContentInput;
};

/** Lead awaiting verification by a sales representative. */
export type RequestedInvite = {
  __typename?: 'RequestedInvite';
  created: Scalars['DateTime']['output'];
  dealerName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  notes?: Maybe<Array<Maybe<Note>>>;
  phone: Scalars['String']['output'];
  status: RequestedInviteStatus;
  updated: Scalars['DateTime']['output'];
};

/** A connection to a list of items. */
export type RequestedInviteConnection = {
  __typename?: 'RequestedInviteConnection';
  edges: Array<RequestedInviteEdge>;
  facetGroups: Array<FacetGroup>;
  filterSections: Array<FilterSection>;
  pageInfo: FacetedPageInfo;
  sort: Array<SortOption>;
};

/** A connection to a list of items. */
export type RequestedInviteConnectionfacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type RequestedInviteEdge = {
  __typename?: 'RequestedInviteEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: RequestedInvite;
};

export enum RequestedInviteStatus {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export type RequestedInviteSuccess = {
  __typename?: 'RequestedInviteSuccess';
  message: Scalars['String']['output'];
};

export enum ReserveType {
  FIRM = 'FIRM',
  FLEXIBLE = 'FLEXIBLE',
  UNRESERVED = 'UNRESERVED',
}

export type ReservedLaneSlot = {
  __typename?: 'ReservedLaneSlot';
  displayRunNumber: Scalars['String']['output'];
  inventoryItem?: Maybe<InventoryItem>;
  laneName: Scalars['String']['output'];
  runNumber: Scalars['Int']['output'];
  seller: Company;
  type: LaneSlotType;
};

export type RunNumber = {
  __typename?: 'RunNumber';
  reservedForSeller: Scalars['Boolean']['output'];
  runNumber: Scalars['Int']['output'];
};

export type SDCarrierDetail = {
  __typename?: 'SDCarrierDetail';
  address?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  guid: Scalars['String']['output'];
  legalName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  usDot?: Maybe<Scalars['String']['output']>;
};

export enum SaleLight {
  GREEN = 'GREEN',
  RED = 'RED',
  YELLOW = 'YELLOW',
}

export type SavedFilter = {
  created: Scalars['DateTime']['output'];
  defaultFilter: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
};

export enum SellHoldStatus {
  ARBITRATION = 'ARBITRATION',
  BANKRUPTCY = 'BANKRUPTCY',
  CORRECTIONS = 'CORRECTIONS',
  DEALER_REPURCHASE = 'DEALER_REPURCHASE',
  ERROR = 'ERROR',
  INSPECTION = 'INSPECTION',
  INSURANCE = 'INSURANCE',
  KEYS = 'KEYS',
  LEGAL = 'LEGAL',
  MECHANICAL = 'MECHANICAL',
  MILITARY = 'MILITARY',
  MOVED = 'MOVED',
  NOTICE_OF_INTENT = 'NOTICE_OF_INTENT',
  NOTICE_OF_SALE = 'NOTICE_OF_SALE',
  NO_HOLD = 'NO_HOLD',
  OTHER = 'OTHER',
  OTHER_BUYER = 'OTHER_BUYER',
  PAINT_AND_BODY = 'PAINT_AND_BODY',
  RECALL = 'RECALL',
  REDEMPTION = 'REDEMPTION',
  REPO = 'REPO',
  SPECIAL_PROMOTION = 'SPECIAL_PROMOTION',
  THIRD_PARTY = 'THIRD_PARTY',
  TITLE_PROBLEM = 'TITLE_PROBLEM',
  TRANSPORTATION_DELAY = 'TRANSPORTATION_DELAY',
  UPSTREAM_OR_ONLINE_SALE = 'UPSTREAM_OR_ONLINE_SALE',
  VEHICLE_HISTORY = 'VEHICLE_HISTORY',
}

export type SellerIntent = {
  __typename?: 'SellerIntent';
  createdBy?: Maybe<User>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<AuctionItemStatus>;
};

export type ServiceFee = {
  __typename?: 'ServiceFee';
  active: Scalars['Boolean']['output'];
  amount: MonetaryAmount;
  auctionServiceMetadata: AuctionServiceMetadata;
  companyId?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  /** @deprecated likely want intendedAuctionItemStatus */
  currentAuctionItemStatus?: Maybe<AuctionItemStatus>;
  description: Scalars['String']['output'];
  flagged: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  intendedAuctionItemId?: Maybe<Scalars['String']['output']>;
  intendedAuctionItemStatus?: Maybe<AuctionItemStatus>;
  inventoryItemId?: Maybe<Scalars['String']['output']>;
  invoiced: Scalars['Boolean']['output'];
  invoicingIndicators: Array<FeeInvoicingIndicator>;
};

/** A connection to a list of items. */
export type ServiceFeeConnection = {
  __typename?: 'ServiceFeeConnection';
  edges: Array<ServiceFeeEdge>;
  facetGroups: Array<FacetGroup>;
  filterSections: Array<FilterSection>;
  pageInfo: FacetedPageInfo;
  sort: Array<SortOption>;
};

/** A connection to a list of items. */
export type ServiceFeeConnectionfacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ServiceFeeEdge = {
  __typename?: 'ServiceFeeEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: ServiceFee;
};

export type Sort = {
  ascending: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
};

export type SortOption = {
  __typename?: 'SortOption';
  ascending: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  selected: Scalars['Boolean']['output'];
};

export type SubModel = {
  __typename?: 'SubModel';
  id: Scalars['String']['output'];
  modelId: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SubTask = {
  __typename?: 'SubTask';
  completed?: Maybe<Scalars['DateTime']['output']>;
  completedBy?: Maybe<User>;
  id: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  status?: Maybe<SubTaskStatus>;
  type: SubTaskType;
};

export enum SubTaskStatus {
  BLOCKED = 'BLOCKED',
  COMPLETED = 'COMPLETED',
  OPEN = 'OPEN',
}

export type SubTaskType = {
  __typename?: 'SubTaskType';
  key: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  parentTaskType: TaskType;
  waitingOn: WaitingOn;
};

export enum SurchargeType {
  FLAT = 'FLAT',
  NONE = 'NONE',
  PERCENTAGE = 'PERCENTAGE',
}

export type Task = {
  __typename?: 'Task';
  assignedTo?: Maybe<User>;
  auctionItem?: Maybe<AuctionItem>;
  completion: Scalars['Float']['output'];
  created: Scalars['DateTime']['output'];
  entityId?: Maybe<Scalars['String']['output']>;
  entityType?: Maybe<EntityType>;
  feeMetadata?: Maybe<AuctionServiceMetadata>;
  followUp?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  inventoryItem?: Maybe<InventoryItem>;
  lastCompletedSubTask?: Maybe<SubTask>;
  mostRecentComment?: Maybe<TimelineAction>;
  name: Scalars['String']['output'];
  status: TaskStatus;
  subTasks: Array<SubTask>;
  timelines: Array<TimelineAction>;
  type: TaskType;
  updated: Scalars['DateTime']['output'];
  waitingOn?: Maybe<WaitingOn>;
};

/** A connection to a list of items. */
export type TaskConnection = {
  __typename?: 'TaskConnection';
  edges: Array<TaskEdge>;
  facetGroups: Array<FacetGroup>;
  filterSections: Array<FilterSection>;
  pageInfo: FacetedPageInfo;
  sort: Array<SortOption>;
};

/** A connection to a list of items. */
export type TaskConnectionfacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TaskCreateInput = {
  entityId: Scalars['String']['input'];
  entityType: EntityType;
  taskKey: Scalars['String']['input'];
};

export type TaskEdge = {
  __typename?: 'TaskEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: Task;
};

export enum TaskStatus {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export type TaskType = {
  __typename?: 'TaskType';
  entityType?: Maybe<EntityType>;
  key: Scalars['String']['output'];
  subTaskTypes: Array<SubTaskType>;
};

export type TaskUpdateInput = {
  /** The user id to assign the task to, empty string clears value if set */
  assignedToId?: InputMaybe<Scalars['String']['input']>;
  /** The followup datetime */
  followUp?: InputMaybe<ClearableDateTimeInput>;
  taskIds: Array<Scalars['String']['input']>;
};

export enum Terms {
  DUE_ON_RECEIPT = 'DUE_ON_RECEIPT',
  DUE_UPON_RESALE = 'DUE_UPON_RESALE',
  NET_7 = 'NET_7',
  NET_10 = 'NET_10',
  NET_15 = 'NET_15',
  NET_30 = 'NET_30',
  NET_48_HR = 'NET_48_HR',
  NET_60 = 'NET_60',
  NET_90 = 'NET_90',
}

export type TimedAuctionSettings = {
  __typename?: 'TimedAuctionSettings';
  auctionCutOff: Scalars['Int']['output'];
  checkoutExpiration: Scalars['Int']['output'];
  ifBidExpiration: Scalars['Int']['output'];
  numberOfFutureEvents: Scalars['Int']['output'];
  schedules: Array<Scalars['String']['output']>;
};

export type TimedAuctionSettingsInput = {
  auctionCutOff?: InputMaybe<Scalars['Int']['input']>;
  checkoutExpiration?: InputMaybe<Scalars['Int']['input']>;
  ifBidExpiration?: InputMaybe<Scalars['Int']['input']>;
  numberOfFutureEvents?: InputMaybe<Scalars['Int']['input']>;
  schedules?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TimedOffer = {
  __typename?: 'TimedOffer';
  actionBy?: Maybe<User>;
  amount: MonetaryAmount;
  comment?: Maybe<Scalars['String']['output']>;
  company: Company;
  created: Scalars['DateTime']['output'];
  createdBy: User;
  expiry?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  status: IfBidStatus;
};

export type TimedOfferAuctionSettings = {
  __typename?: 'TimedOfferAuctionSettings';
  auctionCutOff: Scalars['Int']['output'];
  checkoutExpiration: Scalars['Int']['output'];
  minimumRunTime: Scalars['Int']['output'];
  numberOfFutureEvents: Scalars['Int']['output'];
  offerExpiration: Scalars['Int']['output'];
  schedules: Array<Scalars['String']['output']>;
};

export type TimedOfferAuctionSettingsInput = {
  auctionCutOff?: InputMaybe<Scalars['Int']['input']>;
  checkoutExpiration?: InputMaybe<Scalars['Int']['input']>;
  minimumRunTime?: InputMaybe<Scalars['Int']['input']>;
  numberOfFutureEvents?: InputMaybe<Scalars['Int']['input']>;
  offerExpiration?: InputMaybe<Scalars['Int']['input']>;
  schedules?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TimedOfferTimeline = {
  __typename?: 'TimedOfferTimeline';
  offerThreads: Array<Array<TimedOffer>>;
  waitingOnThem: Scalars['Boolean']['output'];
  waitingOnYou: Scalars['Boolean']['output'];
};

export type TimelineAction = {
  __typename?: 'TimelineAction';
  action: TimelineActionType;
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  content?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  entityId: Scalars['String']['output'];
  entityType?: Maybe<EntityType>;
  id: Scalars['String']['output'];
  subTask?: Maybe<SubTask>;
  task?: Maybe<Task>;
};

export type TimelineActionAddInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  entityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  entityType: EntityType;
  filesAndSizes?: InputMaybe<Array<FileAndSize>>;
  subTaskKey?: InputMaybe<Scalars['String']['input']>;
  /** Omitting this will default to OPEN */
  subTaskStatus?: InputMaybe<SubTaskStatus>;
  taskKey?: InputMaybe<Scalars['String']['input']>;
};

export enum TimelineActionType {
  ARBITRATION = 'ARBITRATION',
  ASSIGNED = 'ASSIGNED',
  BLOCKED = 'BLOCKED',
  CANCELLED = 'CANCELLED',
  CHECK_IN_COMMENT = 'CHECK_IN_COMMENT',
  CHECK_OUT_COMMENT = 'CHECK_OUT_COMMENT',
  CHECK_OUT_SERVICE_COMMENT = 'CHECK_OUT_SERVICE_COMMENT',
  COMMENT = 'COMMENT',
  COMPLETE = 'COMPLETE',
  DELETED = 'DELETED',
  INCOMPLETE = 'INCOMPLETE',
  RELEASE_STATUS = 'RELEASE_STATUS',
  TITLE_INVALID = 'TITLE_INVALID',
  TITLE_STATUS = 'TITLE_STATUS',
  TITLE_VALID = 'TITLE_VALID',
  UNASSIGNED = 'UNASSIGNED',
}

export enum TireGeneralCondition {
  AVERAGE = 'AVERAGE',
  GOOD = 'GOOD',
  POOR = 'POOR',
}

export type TireTread = {
  __typename?: 'TireTread';
  formatted: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type TitleInput = {
  /** New attachments */
  attachments?: InputMaybe<Array<FileUpload>>;
  inventoryItemId: Scalars['String']['input'];
  number?: InputMaybe<Scalars['String']['input']>;
  regionCode?: InputMaybe<Scalars['String']['input']>;
  status: TitleStatus;
  valid: Scalars['Boolean']['input'];
};

export enum TitleStatus {
  AWAITING_BUYER = 'AWAITING_BUYER',
  AWAITING_REPLACEMENT_FROM_SELLER = 'AWAITING_REPLACEMENT_FROM_SELLER',
  AWAITING_SELLER = 'AWAITING_SELLER',
  COLLECTED = 'COLLECTED',
  COLLECTED_REPLACEMENT = 'COLLECTED_REPLACEMENT',
  ELECTRONIC_COLLECTED = 'ELECTRONIC_COLLECTED',
  NOT_REQUIRED_FOR_SALE = 'NOT_REQUIRED_FOR_SALE',
  REQUIRE_REPLACEMENT = 'REQUIRE_REPLACEMENT',
  REQUIRE_RETURN_FROM_BUYER = 'REQUIRE_RETURN_FROM_BUYER',
  RETURNED_TO_SELLER = 'RETURNED_TO_SELLER',
  SENT_TO_BUYER = 'SENT_TO_BUYER',
  SENT_TO_FINANCE_COMPANY = 'SENT_TO_FINANCE_COMPANY',
}

export type TopOffer = {
  __typename?: 'TopOffer';
  actionBy?: Maybe<User>;
  amount: MonetaryAmount;
  bidType: Scalars['String']['output'];
  company?: Maybe<Company>;
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  id: Scalars['String']['output'];
  type: OfferType;
};

export type TransactionDetails = {
  __typename?: 'TransactionDetails';
  billPaid?: Maybe<Scalars['Boolean']['output']>;
  buyBillOfSale?: Maybe<Scalars['String']['output']>;
  buyFeesTotal?: Maybe<MonetaryAmount>;
  buyTotal?: Maybe<MonetaryAmount>;
  buyerLineItems?: Maybe<Array<TransactionLineItem>>;
  created: Scalars['DateTime']['output'];
  invoicePaid?: Maybe<Scalars['Boolean']['output']>;
  purchasePrice: MonetaryAmount;
  sellBillOfSale?: Maybe<Scalars['String']['output']>;
  sellFeeCredit?: Maybe<MonetaryAmount>;
  sellFeesTotal?: Maybe<MonetaryAmount>;
  sellTotal?: Maybe<MonetaryAmount>;
  sellerLineItems?: Maybe<Array<TransactionLineItem>>;
  transactionNumber: Scalars['String']['output'];
  updated?: Maybe<Scalars['DateTime']['output']>;
};

export type TransactionLineItem = {
  __typename?: 'TransactionLineItem';
  amount: MonetaryAmount;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum TransactionType {
  ALL = 'ALL',
  BUYING = 'BUYING',
  SELLING = 'SELLING',
}

export enum TransportCancellationInitiator {
  CARRIER = 'CARRIER',
  EBLOCK = 'EBLOCK',
}

export enum TransportCancellationReason {
  CANNOT_FIND_KEYS = 'CANNOT_FIND_KEYS',
  CUSTOMER_WILL_PICK_UP_DIRECTLY = 'CUSTOMER_WILL_PICK_UP_DIRECTLY',
  DOES_NOT_RUN__CANNOT_DRIVE_ONTO_HAULER = 'DOES_NOT_RUN__CANNOT_DRIVE_ONTO_HAULER',
  FOUND_A_BETTER_PRICE = 'FOUND_A_BETTER_PRICE',
  OTHER = 'OTHER',
  SALE_CANCELLED = 'SALE_CANCELLED',
  UNIT_ALREADY_PICKED_UP = 'UNIT_ALREADY_PICKED_UP',
  UNIT_NOT_READY = 'UNIT_NOT_READY',
}

export type TransportDetail = {
  __typename?: 'TransportDetail';
  id: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type TransportFeeQuote = {
  __typename?: 'TransportFeeQuote';
  amount?: Maybe<MonetaryAmount>;
  disclaimers?: Maybe<Scalars['String']['output']>;
  dropOffLocation: Location;
};

export type TransportJob = {
  __typename?: 'TransportJob';
  acceptedQuote?: Maybe<TransportJobQuote>;
  activeRequestCount: Scalars['Int']['output'];
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  declined: Scalars['Boolean']['output'];
  destinationLocations: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isCancellable: Scalars['Boolean']['output'];
  jobNumber: Scalars['String']['output'];
  notes?: Maybe<Array<Maybe<Note>>>;
  pickupLocations: Array<Scalars['String']['output']>;
  quotes: Array<TransportJobQuote>;
  quotesCompleteCount: Scalars['Int']['output'];
  quotesCount: Scalars['Int']['output'];
  status: TransportJobStatus;
  syncedWithSuperDispatch: Scalars['Boolean']['output'];
  transportRequests: Array<TransportRequest>;
  updated: Scalars['DateTime']['output'];
  vins: Array<Scalars['String']['output']>;
};

export type TransportJobQuote = {
  __typename?: 'TransportJobQuote';
  carrier?: Maybe<Carrier>;
  chargedAmount?: Maybe<MonetaryAmount>;
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  /** all requests on accepted offer are declined (i.e. SuperDispatch offer declined) */
  declined: Scalars['Boolean']['output'];
  estimatedAmount?: Maybe<MonetaryAmount>;
  estimatedDeliveryDateMax?: Maybe<Scalars['Date']['output']>;
  estimatedDeliveryDateMin?: Maybe<Scalars['Date']['output']>;
  estimatedPickupDateMax?: Maybe<Scalars['Date']['output']>;
  estimatedPickupDateMin?: Maybe<Scalars['Date']['output']>;
  finalAmount?: Maybe<MonetaryAmount>;
  finalDeliveryDateMax?: Maybe<Scalars['Date']['output']>;
  finalDeliveryDateMin?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  /** @deprecated not used for anything */
  notes?: Maybe<Scalars['String']['output']>;
  /** carrier declined to quote */
  quoteDeclined: Scalars['Boolean']['output'];
  requestQuotes?: Maybe<Array<Maybe<TransportRequestQuote>>>;
  shipmentMode?: Maybe<CarrierShipmentMode>;
  updated: Scalars['DateTime']['output'];
};

export enum TransportJobStatus {
  AWAITING_ORDER = 'AWAITING_ORDER',
  AWAITING_QUOTES = 'AWAITING_QUOTES',
  CANCELLED = 'CANCELLED',
  DELIVERED = 'DELIVERED',
  PENDING = 'PENDING',
  PENDING_PICKUP = 'PENDING_PICKUP',
  PICKED_UP = 'PICKED_UP',
}

export enum TransportQuoteType {
  CITY_TO_CITY = 'CITY_TO_CITY',
  DISTANCE = 'DISTANCE',
}

export enum TransportRateType {
  CITY = 'CITY',
  DISTANCE = 'DISTANCE',
}

export type TransportRequest = {
  __typename?: 'TransportRequest';
  assignedTo?: Maybe<User>;
  auction?: Maybe<Auction>;
  auctionItem?: Maybe<AuctionItem>;
  cancellationDate?: Maybe<Scalars['DateTime']['output']>;
  cancellationInitiator?: Maybe<Scalars['String']['output']>;
  cancellationReason?: Maybe<Scalars['String']['output']>;
  cancellationReasonComment?: Maybe<Scalars['String']['output']>;
  cancelledBy?: Maybe<User>;
  carrierNote?: Maybe<Scalars['String']['output']>;
  chargeInstructions: Array<ChargeInstruction>;
  company?: Maybe<Company>;
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  declined: Scalars['Boolean']['output'];
  destinationLocation?: Maybe<Location>;
  distance?: Maybe<Scalars['Float']['output']>;
  flagged: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  inventoryItem?: Maybe<InventoryItem>;
  isCancellable: Scalars['Boolean']['output'];
  isCloneable: Scalars['Boolean']['output'];
  make: Scalars['String']['output'];
  makeId?: Maybe<Scalars['String']['output']>;
  model: Scalars['String']['output'];
  modelId?: Maybe<Scalars['String']['output']>;
  notes: Array<Note>;
  pickupLocation: Location;
  status: TransportRequestStatus;
  transportJob?: Maybe<TransportJob>;
  type: TransportRequestType;
  updated: Scalars['DateTime']['output'];
  vin?: Maybe<Scalars['String']['output']>;
};

export type TransportRequestBulkInput = {
  assignedToId?: InputMaybe<Scalars['String']['input']>;
  destinationLocationId?: InputMaybe<Scalars['String']['input']>;
  flagged?: InputMaybe<Scalars['Boolean']['input']>;
  ids: Array<Scalars['String']['input']>;
};

/** A connection to a list of items. */
export type TransportRequestConnection = {
  __typename?: 'TransportRequestConnection';
  edges: Array<TransportRequestEdge>;
  facetGroups: Array<FacetGroup>;
  filterSections: Array<FilterSection>;
  pageInfo: FacetedPageInfo;
  sort: Array<SortOption>;
};

/** A connection to a list of items. */
export type TransportRequestConnectionfacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TransportRequestEdge = {
  __typename?: 'TransportRequestEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: TransportRequest;
};

export type TransportRequestInput = {
  /** Empty string clears assignment */
  assignedToId?: InputMaybe<Scalars['String']['input']>;
  auctionId?: InputMaybe<Scalars['String']['input']>;
  auctionItemId?: InputMaybe<Scalars['String']['input']>;
  carrierNote?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  destinationLocationId?: InputMaybe<Scalars['String']['input']>;
  flagged?: InputMaybe<Scalars['Boolean']['input']>;
  inventoryItemId?: InputMaybe<Scalars['String']['input']>;
  makeId?: InputMaybe<Scalars['String']['input']>;
  modelId?: InputMaybe<Scalars['String']['input']>;
  pickupLocationId?: InputMaybe<Scalars['String']['input']>;
  type: TransportRequestType;
  /** Optional full or partial vin */
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type TransportRequestNoteInput = {
  carrierNote: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type TransportRequestQuote = {
  __typename?: 'TransportRequestQuote';
  additionalNotes?: Maybe<Scalars['String']['output']>;
  adjustedDeliveryDate?: Maybe<Scalars['Date']['output']>;
  adjustedPickupDate?: Maybe<Scalars['Date']['output']>;
  chargedAmount?: Maybe<MonetaryAmount>;
  estimatedAmount?: Maybe<MonetaryAmount>;
  estimatedDeliveryDateMax?: Maybe<Scalars['Date']['output']>;
  estimatedDeliveryDateMin?: Maybe<Scalars['Date']['output']>;
  estimatedPickupDateMax?: Maybe<Scalars['Date']['output']>;
  estimatedPickupDateMin?: Maybe<Scalars['Date']['output']>;
  finalAmount?: Maybe<MonetaryAmount>;
  finalDeliveryDate?: Maybe<Scalars['Date']['output']>;
  pickupDate?: Maybe<Scalars['Date']['output']>;
  selected: Scalars['Boolean']['output'];
  transportRequestId?: Maybe<Scalars['String']['output']>;
};

export type TransportRequestQuoteInput = {
  additionalNotes?: InputMaybe<Scalars['String']['input']>;
  chargedAmount?: InputMaybe<Scalars['Float']['input']>;
  estimatedAmount?: InputMaybe<Scalars['Float']['input']>;
  estimatedDeliveryDateMax?: InputMaybe<Scalars['Date']['input']>;
  estimatedDeliveryDateMin?: InputMaybe<Scalars['Date']['input']>;
  /** Use estimatedPickupDateMin/Max instead. */
  estimatedPickupDate?: InputMaybe<Scalars['Date']['input']>;
  estimatedPickupDateMax?: InputMaybe<Scalars['Date']['input']>;
  estimatedPickupDateMin?: InputMaybe<Scalars['Date']['input']>;
  finalAmount?: InputMaybe<Scalars['Float']['input']>;
  finalDeliveryDate?: InputMaybe<Scalars['Date']['input']>;
  selected?: InputMaybe<Scalars['Boolean']['input']>;
  transportRequestId: Scalars['String']['input'];
};

export enum TransportRequestStatus {
  AWAITING_JOB = 'AWAITING_JOB',
  AWAITING_ORDER = 'AWAITING_ORDER',
  AWAITING_QUOTE = 'AWAITING_QUOTE',
  CANCELLED = 'CANCELLED',
  DELIVERED = 'DELIVERED',
  PENDING = 'PENDING',
  PENDING_DESTINATION = 'PENDING_DESTINATION',
  PENDING_PICKUP = 'PENDING_PICKUP',
  PICKED_UP = 'PICKED_UP',
}

export enum TransportRequestType {
  ADDON = 'ADDON',
  COMPOUND = 'COMPOUND',
  OPEN = 'OPEN',
}

export type TransportRequestUpdateInput = {
  /** Empty string clears assignment */
  assignedToId?: InputMaybe<Scalars['String']['input']>;
  auctionId?: InputMaybe<Scalars['String']['input']>;
  carrierNote?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  destinationLocationId?: InputMaybe<Scalars['String']['input']>;
  flagged?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  makeId?: InputMaybe<Scalars['String']['input']>;
  modelId?: InputMaybe<Scalars['String']['input']>;
  pickupLocationId?: InputMaybe<Scalars['String']['input']>;
  /** Optional full or partial vin */
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type Trim = {
  __typename?: 'Trim';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  subModelId: Scalars['String']['output'];
  year: Scalars['Int']['output'];
};

export type User = {
  __typename?: 'User';
  auctionAccessId?: Maybe<Scalars['Int']['output']>;
  auctionAccessIssueLogs?: Maybe<Array<AuctionAccessIssueLog>>;
  auctionAccessLastVerified?: Maybe<Scalars['DateTime']['output']>;
  auctionAccessStatus?: Maybe<AuctionAccessStatus>;
  /** Only returned for the logged in user. */
  authenticationToken?: Maybe<Scalars['String']['output']>;
  cellPhone?: Maybe<Scalars['String']['output']>;
  /** Notification channels to listen on. */
  channels?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  companyRelationships?: Maybe<UserCompanyRelationshipList>;
  created: Scalars['DateTime']['output'];
  /** User preference in client for which route to start in - used for Truffle (web / mobile) only right now */
  defaultClientRoute?: Maybe<Scalars['String']['output']>;
  distanceUnit?: Maybe<DistanceUnit>;
  email: Scalars['String']['output'];
  emailVerified: Scalars['Boolean']['output'];
  employee: Scalars['Boolean']['output'];
  firstName: Scalars['String']['output'];
  hasCarfaxUSASession?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  /** This is purposely vague */
  internal: Scalars['Boolean']['output'];
  lastLoggedIn?: Maybe<Scalars['DateTime']['output']>;
  lastName: Scalars['String']['output'];
  locale?: Maybe<Scalars['String']['output']>;
  locked?: Maybe<Scalars['Boolean']['output']>;
  mainPhotoUrl?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Array<Maybe<Note>>>;
  notificationDevices?: Maybe<Array<Maybe<NotificationDevice>>>;
  notificationSettings?: Maybe<NotificationSettings>;
  omvicRegExpiryDate?: Maybe<Scalars['Date']['output']>;
  omvicRegNumber?: Maybe<Scalars['String']['output']>;
  phoneVerified: Scalars['Boolean']['output'];
  photoUrl?: Maybe<Scalars['String']['output']>;
  preferredCompoundLocation?: Maybe<Location>;
  preferredLocation?: Maybe<Location>;
  savedFilters?: Maybe<Array<Maybe<SavedFilter>>>;
  secondaryPhone?: Maybe<Scalars['String']['output']>;
  sessionActingAsOtherUser: Scalars['Boolean']['output'];
  sessionId?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  sourceEnum?: Maybe<UserSource>;
  status: UserStatus;
  timeZone?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated: Scalars['DateTime']['output'];
};

export type UsermainPhotoUrlArgs = {
  height?: InputMaybe<Scalars['Int']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type UsernotificationDevicesArgs = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserphotoUrlArgs = {
  height?: InputMaybe<Scalars['Int']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type UsersavedFiltersArgs = {
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum UserAccountVerificationType {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export type UserCompanyPermission = {
  __typename?: 'UserCompanyPermission';
  category: UserCompanyPermissionCategory;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  scope: UserCompanyPermissionScope;
};

export type UserCompanyPermissionCategory = {
  __typename?: 'UserCompanyPermissionCategory';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<UserCompanyPermissionCategory>;
};

export type UserCompanyPermissionScope = {
  __typename?: 'UserCompanyPermissionScope';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type UserCompanyRelationship = {
  __typename?: 'UserCompanyRelationship';
  company: Company;
  created: Scalars['DateTime']['output'];
  department?: Maybe<Scalars['String']['output']>;
  /** A set of distinct permissions aggregated from all the relationship roles */
  permissions: Array<Maybe<UserCompanyPermission>>;
  roles: Array<Maybe<UserCompanyRole>>;
  status: UserCompanyRelationshipStatus;
  user: User;
};

/** A connection to a list of items. */
export type UserCompanyRelationshipConnection = {
  __typename?: 'UserCompanyRelationshipConnection';
  edges: Array<UserCompanyRelationshipEdge>;
  facetGroups: Array<FacetGroup>;
  filterSections: Array<FilterSection>;
  pageInfo: FacetedPageInfo;
  sort: Array<SortOption>;
};

/** A connection to a list of items. */
export type UserCompanyRelationshipConnectionfacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UserCompanyRelationshipEdge = {
  __typename?: 'UserCompanyRelationshipEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: UserCompanyRelationship;
};

export type UserCompanyRelationshipList = {
  __typename?: 'UserCompanyRelationshipList';
  count: Scalars['Int']['output'];
  list: Array<Maybe<UserCompanyRelationship>>;
};

export enum UserCompanyRelationshipStatus {
  DELETED = 'DELETED',
  ENABLED = 'ENABLED',
  PENDING = 'PENDING',
  SUSPENDED = 'SUSPENDED',
}

export type UserCompanyRole = {
  __typename?: 'UserCompanyRole';
  /** Whether this role can be assigned to a user by clients */
  assignable: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  permissions: Array<UserCompanyPermission>;
  scope: UserCompanyPermissionScope;
};

/** A connection to a list of items. */
export type UserCompanyRoleConnection = {
  __typename?: 'UserCompanyRoleConnection';
  edges: Array<UserCompanyRoleEdge>;
  facetGroups: Array<FacetGroup>;
  filterSections: Array<FilterSection>;
  pageInfo: FacetedPageInfo;
  sort: Array<SortOption>;
};

/** A connection to a list of items. */
export type UserCompanyRoleConnectionfacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UserCompanyRoleEdge = {
  __typename?: 'UserCompanyRoleEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: UserCompanyRole;
};

/** A connection to a list of items. */
export type UserConnection = {
  __typename?: 'UserConnection';
  edges: Array<UserEdge>;
  facetGroups: Array<FacetGroup>;
  filterSections: Array<FilterSection>;
  pageInfo: FacetedPageInfo;
  sort: Array<SortOption>;
};

/** A connection to a list of items. */
export type UserConnectionfacetGroupsArgs = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UserCreateAuctionAccessInput = {
  auctionAccessId: Scalars['Int']['input'];
  auctionAccessLast4GovId: Scalars['String']['input'];
  cellPhone?: InputMaybe<Scalars['String']['input']>;
  companyAuctionAccessIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryCode?: InputMaybe<CountryCode>;
  email: Scalars['String']['input'];
  jobTitle: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordAgain: Scalars['String']['input'];
  secondaryPhone?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  /** Optional value. If matches a known white label, the user is added to the associated auction roster. */
  whiteLabel?: InputMaybe<Scalars['String']['input']>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: User;
};

export type UserEmailNotification = UserNotification & {
  __typename?: 'UserEmailNotification';
  body: Scalars['String']['output'];
  ccAddresses: Array<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  messageType?: Maybe<EmailMessageType>;
  subject: Scalars['String']['output'];
  toAddresses: Array<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type UserNotification = {
  created: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  subject: Scalars['String']['output'];
  user?: Maybe<User>;
};

/** A connection to a list of items. */
export type UserNotificationConnection = {
  __typename?: 'UserNotificationConnection';
  edges: Array<UserNotificationEdge>;
  pageInfo: FacetedPageInfo;
  sort: Array<SortOption>;
};

export type UserNotificationEdge = {
  __typename?: 'UserNotificationEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: UserNotification;
};

export type UserPushNotification = UserNotification & {
  __typename?: 'UserPushNotification';
  created: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  subject: Scalars['String']['output'];
  user?: Maybe<User>;
};

export type UserSMSNotification = UserNotification & {
  __typename?: 'UserSMSNotification';
  created: Scalars['DateTime']['output'];
  fromNumber: Scalars['String']['output'];
  id: Scalars['String']['output'];
  subject: Scalars['String']['output'];
  toNumber: Scalars['String']['output'];
  user?: Maybe<User>;
};

export enum UserSource {
  ABS = 'ABS',
  AUCTION_ACCESS = 'AUCTION_ACCESS',
  CRSIMPLIFIED = 'CRSIMPLIFIED',
  EBLOCK = 'EBLOCK',
  PIPELINE_IMPORT = 'PIPELINE_IMPORT',
  PIPELINE_OAUTH = 'PIPELINE_OAUTH',
  PIPELINE_REGISTERED = 'PIPELINE_REGISTERED',
  UNKNOWN = 'UNKNOWN',
}

export enum UserStatus {
  DELETED = 'DELETED',
  ENABLED = 'ENABLED',
  PENDING = 'PENDING',
  SUSPENDED = 'SUSPENDED',
}

export enum ValidationStatus {
  ENTITY_FOUND = 'ENTITY_FOUND',
  ENTITY_FOUND_WITH_BLOCKING_ISSUES = 'ENTITY_FOUND_WITH_BLOCKING_ISSUES',
  ENTITY_FOUND_WITH_ISSUES = 'ENTITY_FOUND_WITH_ISSUES',
}

export type ValueDTO = {
  __typename?: 'ValueDTO';
  id: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type VehicleAutoGradeConditionReportInput = {
  autoGradeScore: Scalars['Float']['input'];
  damages: Array<VehicleAutoGradeDamageInput>;
};

export type VehicleAutoGradeDamageInput = {
  damageCode: Scalars['Int']['input'];
  damageName: Scalars['String']['input'];
  itemCode: Scalars['Int']['input'];
  itemName: Scalars['String']['input'];
  photo?: InputMaybe<InventoryItemPhotoInputObject>;
  severityCode: Scalars['Int']['input'];
  severityName: Scalars['String']['input'];
};

export type VehicleBuilder = {
  __typename?: 'VehicleBuilder';
  auctionLocation?: Maybe<Array<Maybe<BuilderOptionLocation>>>;
  bodyType?: Maybe<Array<Maybe<BuilderOption>>>;
  chargingCable?: Maybe<Array<Maybe<BuilderOption>>>;
  cylinders?: Maybe<Array<Maybe<BuilderOptionInt>>>;
  displacement?: Maybe<Scalars['Float']['output']>;
  driveTrain?: Maybe<Array<Maybe<BuilderOption>>>;
  exteriorColor?: Maybe<Array<Maybe<BuilderOption>>>;
  fuelType?: Maybe<Array<Maybe<BuilderOption>>>;
  interiorColor?: Maybe<Array<Maybe<BuilderOption>>>;
  inventoryItem?: Maybe<InventoryItem>;
  location?: Maybe<Array<Maybe<BuilderOption>>>;
  make?: Maybe<Array<Maybe<BuilderOption>>>;
  metadata?: Maybe<VehicleMetaData>;
  model?: Maybe<Array<Maybe<BuilderOption>>>;
  numberOfDoors?: Maybe<Array<Maybe<BuilderOptionInt>>>;
  numberOfPassengers?: Maybe<Array<Maybe<BuilderOptionInt>>>;
  options?: Maybe<Array<Maybe<BuilderOption>>>;
  pickupLocation?: Maybe<Array<Maybe<BuilderOptionLocation>>>;
  startingLocation?: Maybe<Array<Maybe<BuilderOptionLocation>>>;
  subModel?: Maybe<Array<Maybe<BuilderOption>>>;
  transmission?: Maybe<Array<Maybe<BuilderOption>>>;
  trim?: Maybe<Array<Maybe<BuilderOption>>>;
  valueTypes?: Maybe<Array<Maybe<BuilderValue>>>;
  year?: Maybe<Array<Maybe<BuilderOptionInt>>>;
};

export enum VehicleCaptureType {
  MINIMAL_CAPTURE = 'MINIMAL_CAPTURE',
  RECREATIONAL_VEHICLE_CAPTURE = 'RECREATIONAL_VEHICLE_CAPTURE',
  SELF_CAPTURE = 'SELF_CAPTURE',
  VERIFIED_AUTOGRADE_CAPTURE = 'VERIFIED_AUTOGRADE_CAPTURE',
  VERIFIED_CAPTURE = 'VERIFIED_CAPTURE',
  VERIFIED_EXTENSIVE_VEHICLE_CONDITION_CAPTURE = 'VERIFIED_EXTENSIVE_VEHICLE_CONDITION_CAPTURE',
  VERIFIED_EXTERNAL_AUTOGRADE_CAPTURE = 'VERIFIED_EXTERNAL_AUTOGRADE_CAPTURE',
}

export type VehicleConditionReport = {
  __typename?: 'VehicleConditionReport';
  carfaxCanadaClaimAmount?: Maybe<MonetaryAmount>;
  carfaxCanadaDisclosuresExist: Scalars['Boolean']['output'];
  carfaxCanadaReportStatus?: Maybe<CarfaxReportStatus>;
  carfaxCanadaReportUrl?: Maybe<Scalars['String']['output']>;
  declarations?: Maybe<Array<Maybe<Declaration>>>;
  externalDeclarations?: Maybe<Array<Scalars['String']['output']>>;
  overallConditionRating?: Maybe<Scalars['Int']['output']>;
  repaintOnHowManyPanels?: Maybe<RepaintPanels>;
};

export type VehicleConditionReportdeclarationsArgs = {
  selected?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VehicleConditionReportInput = {
  carfaxCanadaClaimAmount?: InputMaybe<Scalars['Float']['input']>;
  carfaxCanadaReportUrl?: InputMaybe<Scalars['String']['input']>;
  declarations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Value between 1 and 100. */
  overallConditionRating: Scalars['Int']['input'];
  repaintOnHowManyPanels?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleInput = {
  auctionLocationId?: InputMaybe<Scalars['String']['input']>;
  auctionTimeSlotLaneId?: InputMaybe<Scalars['String']['input']>;
  autoGradeConditionReport?: InputMaybe<VehicleAutoGradeConditionReportInput>;
  availableAuctionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  bodyType?: InputMaybe<Scalars['String']['input']>;
  captureDurationDelta?: InputMaybe<Scalars['Int']['input']>;
  captureType?: InputMaybe<VehicleCaptureType>;
  chargingCable?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  conditionReport?: InputMaybe<VehicleConditionReportInput>;
  cylinders?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Defines whether backend should call Chrome VIN Decoder to parse the VIN number and fill vehicle information,
   * such as YMMST, transmission, drivetrain, etc. If the provided value is null, a default backend implicit logic
   * applies, doing decoding only of certain vehicle fields are not provided
   */
  decodeVin?: InputMaybe<Scalars['Boolean']['input']>;
  displacement?: InputMaybe<Scalars['Float']['input']>;
  driveTrain?: InputMaybe<Scalars['String']['input']>;
  drivetrainBuyerShield?: InputMaybe<Scalars['Boolean']['input']>;
  engineBuyerShield?: InputMaybe<Scalars['Boolean']['input']>;
  extensiveVehicleConditionScore?: InputMaybe<Scalars['Float']['input']>;
  exteriorColor?: InputMaybe<Scalars['String']['input']>;
  exteriorNote?: InputMaybe<Scalars['String']['input']>;
  exteriorRating?: InputMaybe<Scalars['Float']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  fuelType?: InputMaybe<Scalars['String']['input']>;
  interiorColor?: InputMaybe<Scalars['String']['input']>;
  interiorNote?: InputMaybe<Scalars['String']['input']>;
  interiorRating?: InputMaybe<Scalars['Float']['input']>;
  keyTag?: InputMaybe<Scalars['String']['input']>;
  lastKnownMileage?: InputMaybe<MileageInput>;
  lastKnownMileageDate?: InputMaybe<Scalars['Date']['input']>;
  legalSaleDate?: InputMaybe<Scalars['DateTime']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  lotLocation?: InputMaybe<Scalars['String']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  makeId?: InputMaybe<Scalars['String']['input']>;
  mechanicalNote?: InputMaybe<Scalars['String']['input']>;
  mechanicalRating?: InputMaybe<Scalars['Float']['input']>;
  mileage?: InputMaybe<MileageInput>;
  minimalCR?: InputMaybe<Scalars['Boolean']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  modelId?: InputMaybe<Scalars['String']['input']>;
  numberOfDoors?: InputMaybe<Scalars['Int']['input']>;
  numberOfPassengers?: InputMaybe<Scalars['Int']['input']>;
  operable?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  paintCondition?: InputMaybe<VehiclePaintConditionInput>;
  persist: Scalars['Boolean']['input'];
  /**
   * Provide a list of all photos. Null will cause no effect. An empty array will delete all.
   * Otherwise all ids must be provided for existing photos.
   */
  photos?: InputMaybe<Array<InputMaybe<InventoryItemPhotoInputObject>>>;
  pickupLocationId?: InputMaybe<Scalars['String']['input']>;
  /** Please set '0000-01-01' to nullify the value */
  redeemed?: InputMaybe<Scalars['Date']['input']>;
  redemptionRequested?: InputMaybe<Scalars['Date']['input']>;
  runNumber?: InputMaybe<Scalars['Int']['input']>;
  saleLights?: InputMaybe<Array<SaleLight>>;
  sellHoldStatus?: InputMaybe<SellHoldStatus>;
  sellHoldUntil?: InputMaybe<Scalars['DateTime']['input']>;
  sellerNotes?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<InventoryItemSource>;
  status?: InputMaybe<VehicleStatus>;
  stockNumber?: InputMaybe<Scalars['String']['input']>;
  styleId?: InputMaybe<Scalars['Int']['input']>;
  subModel?: InputMaybe<Scalars['String']['input']>;
  subModelId?: InputMaybe<Scalars['String']['input']>;
  tireCondition?: InputMaybe<VehicleTireConditionInput>;
  tiresNote?: InputMaybe<Scalars['String']['input']>;
  tiresRating?: InputMaybe<Scalars['Float']['input']>;
  transmission?: InputMaybe<Scalars['String']['input']>;
  transmissionBuyerShield?: InputMaybe<Scalars['Boolean']['input']>;
  transportDetails?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  trim?: InputMaybe<Scalars['String']['input']>;
  trimId?: InputMaybe<Scalars['String']['input']>;
  /** Defines whether backend should check that the VIN is valid and throw an error if it is not */
  validateVin?: InputMaybe<Scalars['Boolean']['input']>;
  values?: InputMaybe<Array<InputMaybe<InventoryItemValueInput>>>;
  vin?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type VehicleMetaData = {
  __typename?: 'VehicleMetaData';
  bodyType?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  chargingCable?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  cylinders?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  declarations: Array<Declaration>;
  driveTrain?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  exteriorColor?: Maybe<Array<Maybe<ColorOption>>>;
  fuelType?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  interiorColor?: Maybe<Array<Maybe<ColorOption>>>;
  mmt?: Maybe<Array<Maybe<InventoryItemMake>>>;
  numberOfDoors?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  numberOfPassengers?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  options?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  photoDamageLocation?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  photoDamageLocations?: Maybe<Array<Maybe<ValueDTO>>>;
  transmission?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  transportDetails: Array<TransportDetail>;
  year?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
};

export type VehicleMetaDatadeclarationsArgs = {
  countryCode: CountryCode;
};

export type VehicleMetaDatammtArgs = {
  makeId?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type VehiclePaintCondition = {
  __typename?: 'VehiclePaintCondition';
  driverFenderDepth?: Maybe<Scalars['Float']['output']>;
  driverFenderSubStandardRepair?: Maybe<Scalars['Boolean']['output']>;
  driverFrontDoorDepth?: Maybe<Scalars['Float']['output']>;
  driverFrontDoorSubStandardRepair?: Maybe<Scalars['Boolean']['output']>;
  driverQuarterPanelDepth?: Maybe<Scalars['Float']['output']>;
  driverQuarterPanelSubStandardRepair?: Maybe<Scalars['Boolean']['output']>;
  driverRearDoorDepth?: Maybe<Scalars['Float']['output']>;
  driverRearDoorSubStandardRepair?: Maybe<Scalars['Boolean']['output']>;
  frontBumperSubStandardRepair?: Maybe<Scalars['Boolean']['output']>;
  hoodDepth?: Maybe<Scalars['Float']['output']>;
  hoodSubStandardRepair?: Maybe<Scalars['Boolean']['output']>;
  leftRockerPanelSubStandardRepair?: Maybe<Scalars['Boolean']['output']>;
  passengerFenderDepth?: Maybe<Scalars['Float']['output']>;
  passengerFenderSubStandardRepair?: Maybe<Scalars['Boolean']['output']>;
  passengerFrontDoorDepth?: Maybe<Scalars['Float']['output']>;
  passengerFrontDoorSubStandardRepair?: Maybe<Scalars['Boolean']['output']>;
  passengerQuarterPanelDepth?: Maybe<Scalars['Float']['output']>;
  passengerQuarterPanelSubStandardRepair?: Maybe<Scalars['Boolean']['output']>;
  passengerRearDoorDepth?: Maybe<Scalars['Float']['output']>;
  passengerRearDoorSubStandardRepair?: Maybe<Scalars['Boolean']['output']>;
  rearBumperSubStandardRepair?: Maybe<Scalars['Boolean']['output']>;
  rightRockerPanelSubStandardRepair?: Maybe<Scalars['Boolean']['output']>;
  roofDepth?: Maybe<Scalars['Float']['output']>;
  roofSubStandardRepair?: Maybe<Scalars['Boolean']['output']>;
  trunkDepth?: Maybe<Scalars['Float']['output']>;
  trunkSubStandardRepair?: Maybe<Scalars['Boolean']['output']>;
};

export type VehiclePaintConditionInput = {
  driverFenderDepth?: InputMaybe<Scalars['Float']['input']>;
  driverFenderSubStandardRepair?: InputMaybe<Scalars['Boolean']['input']>;
  driverFrontDoorDepth?: InputMaybe<Scalars['Float']['input']>;
  driverFrontDoorSubStandardRepair?: InputMaybe<Scalars['Boolean']['input']>;
  driverQuarterPanelDepth?: InputMaybe<Scalars['Float']['input']>;
  driverQuarterPanelSubStandardRepair?: InputMaybe<Scalars['Boolean']['input']>;
  driverRearDoorDepth?: InputMaybe<Scalars['Float']['input']>;
  driverRearDoorSubStandardRepair?: InputMaybe<Scalars['Boolean']['input']>;
  frontBumperSubStandardRepair?: InputMaybe<Scalars['Boolean']['input']>;
  hoodDepth?: InputMaybe<Scalars['Float']['input']>;
  hoodSubStandardRepair?: InputMaybe<Scalars['Boolean']['input']>;
  leftRockerPanelSubStandardRepair?: InputMaybe<Scalars['Boolean']['input']>;
  passengerFenderDepth?: InputMaybe<Scalars['Float']['input']>;
  passengerFenderSubStandardRepair?: InputMaybe<Scalars['Boolean']['input']>;
  passengerFrontDoorDepth?: InputMaybe<Scalars['Float']['input']>;
  passengerFrontDoorSubStandardRepair?: InputMaybe<Scalars['Boolean']['input']>;
  passengerQuarterPanelDepth?: InputMaybe<Scalars['Float']['input']>;
  passengerQuarterPanelSubStandardRepair?: InputMaybe<Scalars['Boolean']['input']>;
  passengerRearDoorDepth?: InputMaybe<Scalars['Float']['input']>;
  passengerRearDoorSubStandardRepair?: InputMaybe<Scalars['Boolean']['input']>;
  rearBumperSubStandardRepair?: InputMaybe<Scalars['Boolean']['input']>;
  rightRockerPanelSubStandardRepair?: InputMaybe<Scalars['Boolean']['input']>;
  roofDepth?: InputMaybe<Scalars['Float']['input']>;
  roofSubStandardRepair?: InputMaybe<Scalars['Boolean']['input']>;
  trunkDepth?: InputMaybe<Scalars['Float']['input']>;
  trunkSubStandardRepair?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VehiclePublic = {
  __typename?: 'VehiclePublic';
  autoGradeScore?: Maybe<Scalars['Float']['output']>;
  autoGradeScoreData?: Maybe<AutoGradeScoreData>;
  captureType: VehicleCaptureType;
  carfaxCanadaDisclosuresExist: Scalars['Boolean']['output'];
  conditionReport?: Maybe<VehicleConditionReport>;
  driveTrain?: Maybe<Scalars['String']['output']>;
  engine?: Maybe<Scalars['String']['output']>;
  extensiveVehicleConditionScore?: Maybe<Scalars['BigDecimal']['output']>;
  exteriorColor?: Maybe<Scalars['String']['output']>;
  fuelType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  interiorColor?: Maybe<Scalars['String']['output']>;
  location?: Maybe<LocationPublic>;
  make?: Maybe<Scalars['String']['output']>;
  mileage?: Maybe<Mileage>;
  model?: Maybe<Scalars['String']['output']>;
  numberOfDoors?: Maybe<Scalars['Int']['output']>;
  numberOfPassengers?: Maybe<Scalars['Int']['output']>;
  options?: Maybe<Array<InventoryItemOption>>;
  photos: Array<Photo>;
  primaryPhoto?: Maybe<Photo>;
  subModel?: Maybe<Scalars['String']['output']>;
  transmission?: Maybe<Scalars['String']['output']>;
  trim?: Maybe<Scalars['String']['output']>;
  verified?: Maybe<Scalars['Boolean']['output']>;
  vin?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type VehiclePublicoptionsArgs = {
  selected?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VehiclePublicphotosArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Array<PhotoStatus>>;
  type?: InputMaybe<PhotoType>;
};

export type VehicleRepairItem = {
  __typename?: 'VehicleRepairItem';
  action?: Maybe<RepairAction>;
  approvable: Scalars['Boolean']['output'];
  approvalComment?: Maybe<Scalars['String']['output']>;
  approvalDate?: Maybe<Scalars['DateTime']['output']>;
  approvalName?: Maybe<Scalars['String']['output']>;
  approvalSource?: Maybe<RepairApprovalSource>;
  approvalStatus?: Maybe<RepairApprovalStatus>;
  approvalUpdated?: Maybe<Scalars['DateTime']['output']>;
  chargeable?: Maybe<RepairChargeable>;
  completed?: Maybe<Scalars['DateTime']['output']>;
  created: Scalars['DateTime']['output'];
  createdByUser: User;
  estimate?: Maybe<MonetaryAmount>;
  id: Scalars['String']['output'];
  inventoryService?: Maybe<InventoryService>;
  labourAmount?: Maybe<MonetaryAmount>;
  labourHours?: Maybe<Scalars['BigDecimal']['output']>;
  labourRate?: Maybe<MonetaryAmount>;
  orderComment?: Maybe<Scalars['String']['output']>;
  orderNumber: Scalars['Int']['output'];
  paintHours?: Maybe<Scalars['BigDecimal']['output']>;
  parts?: Maybe<Scalars['String']['output']>;
  partsAmount?: Maybe<MonetaryAmount>;
  quoteCompleted?: Maybe<Scalars['DateTime']['output']>;
  status: RepairStatus;
  suggested: Scalars['Boolean']['output'];
  updated: Scalars['DateTime']['output'];
};

export enum VehicleStatus {
  AWAITING_CAPTURE = 'AWAITING_CAPTURE',
  CAPTURED = 'CAPTURED',
  CAPTURE_IN_PROGRESS = 'CAPTURE_IN_PROGRESS',
  REGISTERED = 'REGISTERED',
}

export type VehicleTireCondition = {
  __typename?: 'VehicleTireCondition';
  afterMarketTires?: Maybe<Scalars['Boolean']['output']>;
  driverFrontBrand?: Maybe<Scalars['String']['output']>;
  driverFrontSize?: Maybe<Scalars['String']['output']>;
  driverFrontTread?: Maybe<TireTread>;
  driverRearBrand?: Maybe<Scalars['String']['output']>;
  driverRearSize?: Maybe<Scalars['String']['output']>;
  driverRearTread?: Maybe<TireTread>;
  fourMatchingTires?: Maybe<Scalars['Boolean']['output']>;
  generalCondition?: Maybe<TireGeneralCondition>;
  passengerFrontBrand?: Maybe<Scalars['String']['output']>;
  passengerFrontSize?: Maybe<Scalars['String']['output']>;
  passengerFrontTread?: Maybe<TireTread>;
  passengerRearBrand?: Maybe<Scalars['String']['output']>;
  passengerRearSize?: Maybe<Scalars['String']['output']>;
  passengerRearTread?: Maybe<TireTread>;
  secondSetOfTires?: Maybe<Scalars['Boolean']['output']>;
  secondSetOfTiresCondition?: Maybe<TireGeneralCondition>;
  secondSetOfTiresHasRims?: Maybe<Scalars['Boolean']['output']>;
  studdedWinterTires?: Maybe<Scalars['Boolean']['output']>;
  tirePressureMonitoringSystem?: Maybe<Scalars['Boolean']['output']>;
  tirePressureMonitoringSystemPhotoUrl?: Maybe<Scalars['String']['output']>;
  winterTires?: Maybe<Scalars['Boolean']['output']>;
};

export type VehicleTireConditiontirePressureMonitoringSystemPhotoUrlArgs = {
  height?: InputMaybe<Scalars['Int']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type VehicleTireConditionInput = {
  afterMarketTires?: InputMaybe<Scalars['Boolean']['input']>;
  driverFrontBrand?: InputMaybe<Scalars['String']['input']>;
  driverFrontSize?: InputMaybe<Scalars['String']['input']>;
  driverFrontTread?: InputMaybe<Scalars['Float']['input']>;
  driverRearBrand?: InputMaybe<Scalars['String']['input']>;
  driverRearSize?: InputMaybe<Scalars['String']['input']>;
  driverRearTread?: InputMaybe<Scalars['Float']['input']>;
  fourMatchingTires?: InputMaybe<Scalars['Boolean']['input']>;
  generalCondition?: InputMaybe<TireGeneralCondition>;
  passengerFrontBrand?: InputMaybe<Scalars['String']['input']>;
  passengerFrontSize?: InputMaybe<Scalars['String']['input']>;
  passengerFrontTread?: InputMaybe<Scalars['Float']['input']>;
  passengerRearBrand?: InputMaybe<Scalars['String']['input']>;
  passengerRearSize?: InputMaybe<Scalars['String']['input']>;
  passengerRearTread?: InputMaybe<Scalars['Float']['input']>;
  secondSetOfTires?: InputMaybe<Scalars['Boolean']['input']>;
  secondSetOfTiresCondition?: InputMaybe<TireGeneralCondition>;
  secondSetOfTiresHasRims?: InputMaybe<Scalars['Boolean']['input']>;
  studdedWinterTires?: InputMaybe<Scalars['Boolean']['input']>;
  tirePressureMonitoringSystem?: InputMaybe<Scalars['Boolean']['input']>;
  winterTires?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VehicleTitle = {
  __typename?: 'VehicleTitle';
  attachments: Array<Attachment>;
  created: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  number?: Maybe<Scalars['String']['output']>;
  regionCode?: Maybe<Scalars['String']['output']>;
  status?: Maybe<TitleStatus>;
  updated: Scalars['DateTime']['output'];
  valid: Scalars['Boolean']['output'];
};

export type VideoStreamConfig = {
  __typename?: 'VideoStreamConfig';
  accountId?: Maybe<Scalars['String']['output']>;
  publishingToken?: Maybe<Scalars['String']['output']>;
  subscriptionToken?: Maybe<Scalars['String']['output']>;
};

export enum WaitingOn {
  THEM = 'THEM',
  US = 'US',
}

export type Watchers = {
  __typename?: 'Watchers';
  /** Only accessible by sales representatives. */
  count?: Maybe<Scalars['Int']['output']>;
  isWatched: Scalars['Boolean']['output'];
  /** Only accessible by sales representatives. */
  list: Array<User>;
};

export type WorkflowProcess = {
  __typename?: 'WorkflowProcess';
  created: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  status: Scalars['String']['output'];
  statusColorScheme: ColorSchemeEnum;
  updated: Scalars['DateTime']['output'];
};
