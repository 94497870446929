import ApiRequest from 'store/shared/api/apiRequest';
import { AuctionItemOrdersResponse } from 'store/shared/api/graph/interfaces/types';
import { auctionItemOrderFragment } from 'store/shared/api/graph/fragments/auctionItemOrder';

const auctionItemOrderSchemaPartial = `
  {
    auctionItemOrders {
      auctionItems {
        id
      }
    }
  }
`;

const auctionItemOrderSchemaFull = `
  {
    auctionItemOrders {
      auctionItems {
        id
        status
        format
        auction {
          id
        }
        inventoryItem {
          id
          year
          make
          model
          subModel
          photos(type: EXTERIOR) {
            thumb: url (width: 110, height: 110)
          }
        }
        checkoutExpiry
        buyer {
          company {
            id
          }
        }
        topOffer {
          amount {
            formattedAmount
            formattedAmountRounded
          }
        }
        order {
          ...auctionItemOrder
        }
      }
      credit {
        formattedAmount
      }
      subtotal {
        formattedAmount
      }
      taxes {
        formattedAmount
      }
      total {
        formattedAmount
      }
      totalFeeTax {
        formattedAmount
      }
      totalFees {
        formattedAmount
      }
    }
  }
  ${auctionItemOrderFragment}
`;

export const getAuctionItemOrders = (shouldFetchAll = true) =>
  ApiRequest<{ auctionItemOrders: AuctionItemOrdersResponse }>({
    data: {
      operationName: shouldFetchAll ? 'auctionItemOrderSchemaFull' : 'auctionItemOrderSchemaPartial',
      query: shouldFetchAll ? auctionItemOrderSchemaFull : auctionItemOrderSchemaPartial,
    },
  });
