import { topOffer } from 'store/shared/api/graph/schema/auctionItem';

export const auctionItemListSchema = `
  id
  archived
  asIs
  auction {
    settings {
      saleLightsOverride
    }
    featureFlagKeys
    id
    title
  }
  auctionTimeSlot {
    id
    startTime
  }
  auctionTimeSlotLane {
    controlType
    name
  }
  autoBids {
    count
    myAutoBid {
      maxBid {
        formattedAmountRounded
      }
    }
  }
  bidTimeline {
    count
    winning
    outbid
    list {
      company {
        id
        name
      }
      amount {
        amount
        formattedAmountRounded
      }
      created
    }
  }
  buyNowPrice {
    amount
    formattedAmountRounded
  }
  checkoutExpiry
  colorScheme
  deliveryDate
  format
  furtherBidIncrement {
    amount
    currencyCode
    formattedAmountRounded
  }
  history {
    count
  }
  holdbackActive
  ifBidClaimedByUserId
  ifBidClaimedByUserName
  ifBidTimeline {
    ending
    amountApart {
      formattedAmountRounded
    }
    awaitingMyResponse
    list {
      amount {
        formattedAmountRounded
      }
    }
  }
  inventoryItem {
    id
    autoGradeScore
    captureType
    company {
      id
      name
      primaryLocation {
        regionCode
      }
      rolePermissionsEnabled
    }
    conditionReport {
      carfaxCanadaClaimAmount {
        amount
      }
      declarations: declarations(selected: true) {
        name
      }
      overallConditionRating
    }
    cylinders
    description
    displacement
    extensiveVehicleConditionScore
    fuelType
    location {
      ...location
    }
    make
    mileage {
      formattedAmount
    }
    model
    notes {
      id
      content
      created
      createdBy {
        firstName
        lastName
      }
    }
    photos(first: 1) {
      ...listItemPhotoFragment
    }
    subModel
    transmission
    trim
    vehicleBuyerShield
    vin
    year
  }
  isAssured
  isMyItem
  listPrice {
    amount
    formattedAmountRounded
  }
  nextBidAmount {
    amount
    formattedAmountRounded
  }
  reserveMet
  displayRunNumber
  saleLights
  sellerTier
  startingBid {
    amount
    formattedAmountRounded
  }
  status
  timerEnd
  timerText
  buyer {
    company {
      name
    }
  }
  ${topOffer}
  timedOfferTimeline {
    waitingOnThem
    waitingOnYou
  }
  transactionDetails {
    purchasePrice {
      formattedAmountRounded
    }
    created
  }
  transactionType
  watchers {
    count
    isWatched
  }
  updated
`;
