import classnames from 'classnames';
import { ReactNode, useMemo } from 'react';

import AccountVerificationFormContainer from 'containers/account/forms/accountVerification/accountVerificationFormContainer';
import EnvironmentModeWarning from 'components/ui/shared/environmentModeWarning';
import Error429 from 'components/core/errors/429/429';
import Head from 'containers/pageHead/HeadContainer';
import MobileGate from 'components/core/errors/mobileGate/mobileGate';
import NavigationContainer from 'containers/navigation/navigationContainer';
import Offline from 'components/core/errors/offline/offline';
import SystemTimeout from 'containers/notifications/systemTimeout';
import { isMobileDevice } from 'utils/deviceInfoUtils';
import { useAuthConnection } from 'hooks/useAuthConnection';
import { usePubSub } from 'hooks/usePubSub';

import style from './coreLayouts.scss';

export interface Props {
  /** The component's child elements to be rendered. */
  children?: ReactNode;
  /** True route path is an enabled mobile path. */
  isMobileEnabledPath: boolean;
  /** True when the navigation shouldn't be displayed */
  isNavigationDisabled?: boolean;
  /** True when a 429 error status code has been received */
  isError429Active?: boolean;
  /** True when the network is up. */
  isNetworkUp: boolean;
}

const CoreLayout = ({ children, isMobileEnabledPath, isNavigationDisabled, isNetworkUp, isError429Active }: Props) => {
  // Connect to PubSub service
  usePubSub();

  // Listen for auth changes
  useAuthConnection();

  const className = classnames(style.coreLayout, isMobileEnabledPath && style.isMobileEnabledPath);
  const isMobileDeviceUser = useMemo(() => isMobileDevice(), []);

  if (!isMobileEnabledPath && isMobileDeviceUser) {
    return <MobileGate />;
  }

  if (isError429Active) {
    return <Error429 />;
  }

  return (
    <div className={className} data-testid="core-layout">
      <Head />
      {!isNavigationDisabled && <NavigationContainer />}
      <main className={style.main}>
        <SystemTimeout />
        <AccountVerificationFormContainer />
        {isNetworkUp ? children : <Offline />}
      </main>
      <EnvironmentModeWarning />
    </div>
  );
};

export default CoreLayout;
