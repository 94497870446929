import CheckboxFilter from 'components/ui/lists/filters/filterSections/filters/checkboxFilter';
import { FilterSection } from 'store/shared/api/graph/interfaces/types';

interface Props {
  /** The available filter sections/filters */
  filterSections?: FilterSection[];
}

const FilterSections = ({ filterSections }: Props) => {
  if (!filterSections?.length) {
    return null;
  }

  // We can assume this is the "Show Appraisals" filter on the Browse page
  // since it's the only supported `FilterSection` at moment.
  //
  // More to come here as we migrate away from `FacetGroups`
  return <CheckboxFilter filterSection={filterSections[0]} />;
};

export default FilterSections;
