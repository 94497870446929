import { handleActions } from 'redux-actions';

import { InitialState } from './marketGuideModels';
import {
  marketGuideListCleared,
  marketGuideListIsLoading,
  marketGuideListLoaded,
} from 'store/marketGuide/marketGuideActions';
import { parseQueryConnectionResponse } from 'utils/apiUtils';

export const marketGuideReducer = handleActions(
  {
    [marketGuideListCleared().type]: () => new InitialState(),

    [marketGuideListIsLoading().type]: (state) => state.set('isLoading', true),

    [marketGuideListLoaded().type]: (state, action) => {
      const auctionItems = parseQueryConnectionResponse(action.payload?.marketGuideConnection);

      return state
        .setLoaded()
        ?.set('resultList', auctionItems ?? [])
        ?.set('facetGroups', action.payload?.marketGuideConnection?.facetGroups ?? [])
        ?.set('pageInfo', action.payload?.marketGuideConnection?.pageInfo)
        ?.set('metadata', action.payload?.marketGuideConnection?.metadata);
    },
  },
  new InitialState()
);
