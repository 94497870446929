import AuctionItem from 'constants/auctionItem';
import BaseClass from 'components/ui/shared/base';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import Select from 'forms/shared/select';
import SelectUser from 'forms/shared/selectUser';
import TextArea from 'forms/shared/textArea';
import { AuctionItemStatus } from 'store/shared/api/graph/interfaces/types';
import { CancelOptions } from 'components/sections/auctionItem/operations/cancel/cancel';
import { ErrorMessages } from 'constants/errors';
import { STATUS } from 'constants/enums/auctionItem';
import { SelectOption } from 'utils/interfaces/SelectOption';
import { t } from 'utils/intlUtils';

import style from './dialog.scss';

const titles = Object.freeze({
  [STATUS.IN_IF_BID]: 'cancel_if_bid',
  [STATUS.PENDING_DELIVERY]: 'mark_unit_as_lost',
  [STATUS.AWAITING_CHECKOUT]: 'cancel_sale',
  [STATUS.SOLD]: 'cancel_sale',
});

const descriptions = Object.freeze({
  [STATUS.IN_IF_BID]: 'cancel_if_bid_dialog_question',
  [STATUS.PENDING_DELIVERY]: 'mark_unit_as_lost_dialog_message',
  [STATUS.AWAITING_CHECKOUT]: 'cancel_sale_dialog_message',
  [STATUS.SOLD]: 'cancel_sale_dialog_message',
});

const labels = Object.freeze({
  [STATUS.IN_IF_BID]: 'cancel_if_bid',
  [STATUS.PENDING_DELIVERY]: 'mark_unit_as_lost',
  [STATUS.AWAITING_CHECKOUT]: 'cancel_sale',
  [STATUS.SOLD]: 'cancel_sale',
});

interface Props {
  /** The auction item. */
  auctionItem: AuctionItem;

  /** Validation errors. */
  errorMessages: ErrorMessages | undefined;

  /** True when the dialog is open. */
  isOpen: boolean;

  /** True when form is being submitted. */
  isSubmitting: boolean;

  /** Function invoked to cancel sale. */
  onSubmit: (shouldSubmit: boolean, options: CancelOptions) => void;
}

interface State {
  /** The id of the selected company. */
  companyId: string | undefined;

  /** The text of the attached message. */
  message: string | undefined;

  /** The id of the selected user. */
  userId: string | undefined;
}

class Dialog extends BaseClass<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { companyId: undefined, userId: undefined, message: undefined };
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;
    const { isOpen: isOpenPrev } = prevProps;

    if (isOpenPrev && !isOpen) {
      this.setState({ companyId: undefined, userId: undefined, message: undefined });
    }
  }

  onSubmit = (shouldSubmit) => {
    const {
      auctionItem: { id, status },
      onSubmit,
    } = this.props;
    const { companyId, userId, message } = this.state;
    let options: CancelOptions;

    if (status === AuctionItemStatus.IN_IF_BID) {
      options = {
        auctionItemId: id,
        comment: message,
        consignerId: companyId,
        status,
        userId,
      };
    } else {
      options = { auctionItemId: id, status };
    }
    onSubmit(shouldSubmit, options);
  };

  onCompanyChange = ({ value: companyId }) => {
    this.setState({ companyId, userId: undefined });
  };

  onUserChange = (user: SelectOption) => {
    this.setState({ userId: user?.value });
  };

  onMessageChange = (e) => {
    this.setState({ message: e.target.value });
  };

  renderIfBid() {
    const { auctionItem } = this.props;
    const { companyId } = this.state;
    const buyer = auctionItem.ifBidTimeline?.inIfBidWithCompany;
    const seller = auctionItem.inventoryItem.company;
    const companiesDropdownData = [
      { value: buyer?.id, label: buyer?.name },
      { value: seller.id, label: seller.name },
    ];

    return (
      <>
        <Select
          className={style.consigners}
          id="selectConsigner"
          onChange={this.onCompanyChange}
          options={companiesDropdownData}
          placeholder={t('select_company')}
          theme="green"
        />
        <SelectUser
          companyId={companyId}
          id="selectUser"
          isDisabled={!companyId}
          onChange={this.onUserChange}
          placeholder={t('select_user')}
        />
        <TextArea className={style.message} onChange={this.onMessageChange} placeholder={t('attach_message')} />
      </>
    );
  }

  render() {
    const { message, userId } = this.state;
    const { auctionItem, errorMessages, isOpen, isSubmitting } = this.props;
    const { status } = auctionItem;
    const title = t(titles[status]);
    const description = t(descriptions[status]);
    const actionLabel = t(labels[status]);
    const actionable = status === AuctionItemStatus.IN_IF_BID ? !!(userId && message) : true;

    return (
      <ConfirmDialog
        actionable={actionable}
        actionLabel={actionLabel}
        actionProgress={isSubmitting}
        isOpen={isOpen}
        onConfirm={this.onSubmit}
        theme="red"
        title={title}
      >
        <p className={style.instructions}>{description}</p>
        {status === AuctionItemStatus.IN_IF_BID && this.renderIfBid()}
        {errorMessages &&
          errorMessages.map((errorMessage, index) => (
            <p key={`error${index}`} className={style.errorMessage}>
              {errorMessage.message}
            </p>
          ))}
      </ConfirmDialog>
    );
  }
}

export default Dialog;
