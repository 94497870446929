import classnames from 'classnames';
import { createRef } from 'react';

import nextArrowGlyph from 'glyphs/next-arrow.svg';
import prevArrowGlyph from 'glyphs/prev-arrow.svg';

import BaseClass from 'components/ui/shared/base';
import Button from 'components/ui/shared/button';
import Description from 'components/sections/inventoryItem/addModify/vehicleFormPanes/description';
import DialogContent from 'layouts/inventoryItemLayouts/addModify/dialogContentLayouts';
import PercentProgressBar from 'components/ui/progressBars/progressBar';
import PhotoDetails from 'components/sections/inventoryItem/addModify/vehicleFormPanes/photoDetails';
import Sprite from 'components/ui/shared/sprite';
import VehicleDetails, {
  FUEL_TYPES_CHARGING_CABLE,
} from 'components/sections/inventoryItem/addModify/vehicleFormPanes/vehicleDetails';
import { AddModifyVehicleProps, InventoryItemPropsJs } from 'store/inventoryItem/addModify/addModifyModels';
import { ErrorMessages } from 'constants/errors';
import {
  LegacyInventoryPhotoOptions,
  UploadInventoryPhotoOptions,
} from 'store/inventoryItem/addModify/addModifyActions';
import { MutationinventoryItemCarfaxCanadaAttachReportArgs, PhotoType } from 'store/shared/api/graph/interfaces/types';
import { Overlay } from 'constants/enums/addModifyInventoryItem';
import { Pane, Tabs } from 'components/ui/shared/tabs/tabs';
import { SectionNavigation } from 'components/sections/inventoryItem/addModify/interfaces/vehicleForm';
import { Spinner } from 'components/ui/loading/loading';
import { Tires } from './vehicleFormPanes/tires';
import { t } from 'utils/intlUtils';

import style from './vehicleForm.scss';

export const PaneIndex = Object.freeze({
  VEHICLE_DETAILS: 0,
  TIRES: 1,
  PHOTOS: 2,
  DESCRIPTION: 3,
});

const TABS_COUNT = Object.keys(PaneIndex).length;

interface Props {
  /** Callback function to change overlay. */
  changeOverlay: (overlay: Overlay) => void;
  /** Callback function to clear error messages. */
  clearError: () => void;
  /** Error messages. */
  errorMessages: ErrorMessages;
  /** Callback function to create inventory item updates. */
  handleSubmitCreateInventoryItem: (object?: AddModifyVehicleProps, persist?: boolean) => void;
  /** Callback function to submit inventory item updates. */
  handleSubmitUpdateInventoryItem: (object?: AddModifyVehicleProps, persist?: boolean) => void;
  /** Inventory item information. */
  inventoryItem: InventoryItemPropsJs;
  /** Whether is new inventory item or not. */
  isNewInventoryItem: boolean;
  /** Navigate to section information. */
  navigateToSection: SectionNavigation;
  /** Overlay type. */
  overlay: Overlay | null;
  /** Callback function to set photo error messages. */
  setPhotoErrorMessages: (errorMessages: ErrorMessages) => void;
  /** Callback function to set vehicle information. */
  setVehicle: (vehicle: AddModifyVehicleProps) => void;
  /** Callback function to attach Carfax. */
  submitAttachCarfax: (option: MutationinventoryItemCarfaxCanadaAttachReportArgs) => void;
  /** Callback function to upload inventory photos. */
  uploadInventoryPhoto: (option: LegacyInventoryPhotoOptions | UploadInventoryPhotoOptions) => void;
}

interface State {
  /** Whether has highlighted section or not. */
  hasHighlightedSection: boolean;
  /** Whether modal is disabled or not. */
  isDisabledModal: boolean;
  /** Whether is saving next or not. */
  isSavingNext: boolean;
  /** Scroll to section name. */
  scrollToSection: string;
  /** Selected tab index. */
  selectedTabIndex: number;
}

class VehicleForm extends BaseClass<Props, State> {
  private initialScrollPosition = 0;
  private modalScrollRef = createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    const {
      navigateToSection: { paneIndex, section },
    } = props;

    this.state = {
      hasHighlightedSection: !!section,
      isDisabledModal: false,
      isSavingNext: false,
      scrollToSection: section,
      selectedTabIndex: paneIndex ?? 0,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    if (this.modalScrollRef.current) {
      this.modalScrollRef.current.scrollTop = this.initialScrollPosition - 112;
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps?.isNewInventoryItem) {
      if (this.props?.inventoryItem?.results?.vehicle?.id && !prevProps?.inventoryItem?.results?.vehicle?.id) {
        this.setState({ isSavingNext: false });
        this.handlePaneChange(1);
      }
      if (!prevProps?.errorMessages?.length && this.props?.errorMessages.length) {
        this.setState({ isSavingNext: false });
      }
    }

    if (!prevProps?.errorMessages?.length && this.props?.errorMessages.length) {
      this.scrollToTop();
    }
  }

  getNextTabIndex = () => {
    const { selectedTabIndex } = this.state;

    return selectedTabIndex < TABS_COUNT ? selectedTabIndex + 1 : 0;
  };

  getPrevTabIndex = () => {
    const { selectedTabIndex } = this.state;

    return selectedTabIndex > 0 ? selectedTabIndex - 1 : TABS_COUNT;
  };

  getFieldsObject() {
    const {
      inventoryItem: {
        results,
        results: { vehicle },
      },
    } = this.props;
    const hasField = (field) => results[field].some((item) => item.selected);
    const hasVehicleField = (field) => vehicle[field] || hasField(field);

    const fields = [
      hasVehicleField('bodyType'),
      hasVehicleField('cylinders'),
      !!vehicle.displacement,
      hasVehicleField('driveTrain'),
      hasVehicleField('exteriorColor'),
      hasVehicleField('fuelType'),
      hasVehicleField('interiorColor'),
      hasField('startingLocation'),
      hasVehicleField('make'),
      !!vehicle.mileage.amount,
      hasVehicleField('model'),
      hasVehicleField('numberOfDoors'),
      hasVehicleField('numberOfPassengers'),
      hasVehicleField('subModel'),
      hasVehicleField('transmission'),
      vehicle.trimId === 'OTHER' ? !!vehicle.trim : !!vehicle.trimId || hasField('trim'),
      (vehicle.photos || []).some((o) => o.type === PhotoType.EXTERIOR),
      !!vehicle.vin,
      hasVehicleField('year'),
      !!vehicle.conditionReport?.overallConditionRating,
    ];

    const fuelType = vehicle.fuelType || results.fuelType?.find((item) => item?.selected)?.id;
    if (fuelType && FUEL_TYPES_CHARGING_CABLE.includes(fuelType)) {
      fields.push(!!vehicle.chargingCable);
    }

    return { fieldsCompleted: fields.filter((item) => !!item).length, fieldsTotal: fields.length };
  }

  scrollToTop() {
    if (this.modalScrollRef.current) {
      this.modalScrollRef.current.scrollTop = 0;
    }
  }

  handlePaneChange(tabIndex: number) {
    this.props.clearError();
    this.setState({ selectedTabIndex: tabIndex }, () => this.scrollToTop());
  }

  handleSaveClick = () => {
    const {
      changeOverlay,
      handleSubmitCreateInventoryItem,
      handleSubmitUpdateInventoryItem,
      inventoryItem: {
        results: { vehicle },
      },
    } = this.props;
    const hasInventoryItemId = !!vehicle.id;

    changeOverlay(Overlay.SAVING);
    if (hasInventoryItemId) {
      handleSubmitUpdateInventoryItem();
    } else {
      handleSubmitCreateInventoryItem();
    }
  };

  handleNavClick = (index: number) => {
    const {
      handleSubmitCreateInventoryItem,
      inventoryItem: {
        results: { vehicle },
      },
      errorMessages,
    } = this.props;
    const hasInventoryItemId = !!vehicle.id;

    if (hasInventoryItemId) {
      this.handlePaneChange(index);
    } else if (!errorMessages.length) {
      this.setState({ isSavingNext: true });
      handleSubmitCreateInventoryItem();
    }
  };

  handlePrevClick = () => {
    const index = this.getPrevTabIndex();
    this.handleNavClick(index);
  };

  handleNextClick = () => {
    const index = this.getNextTabIndex();
    this.handleNavClick(index);
  };

  renderFooterButtons = () => {
    const { isDisabledModal, isSavingNext, selectedTabIndex } = this.state;
    const {
      inventoryItem: {
        overlay,
        results: { vehicle },
      },
    } = this.props;
    const isSaving = overlay === Overlay.SAVING;

    return (
      <div className={style.footerContainer}>
        {vehicle.id && (
          <Button
            dataTestId="saveButton"
            disabled={isDisabledModal || isSaving || isSavingNext}
            onClick={() => this.handleSaveClick()}
          >
            {isSaving ? <Spinner spinnerStyleClassName={style.spinnerStyle} /> : <span>{t('save')}</span>}
          </Button>
        )}
        <div className={style.prevNextContainer}>
          <Button
            className={style.smallButton}
            disabled={isDisabledModal || selectedTabIndex === PaneIndex.VEHICLE_DETAILS || isSaving}
            onClick={this.handlePrevClick}
            theme="green"
          >
            <Sprite className={style.sprite} glyph={prevArrowGlyph} />
          </Button>
          <Button
            className={style.smallButton}
            disabled={isDisabledModal || selectedTabIndex === PaneIndex.DESCRIPTION || isSaving || isSavingNext}
            onClick={this.handleNextClick}
            theme="green"
          >
            {isSavingNext ? (
              <Spinner spinnerStyleClassName={style.spinnerStyle} />
            ) : (
              <Sprite className={style.sprite} glyph={nextArrowGlyph} />
            )}
          </Button>
        </div>
      </div>
    );
  };

  render() {
    const { hasHighlightedSection, isDisabledModal, scrollToSection, selectedTabIndex } = this.state;
    const {
      changeOverlay,
      clearError,
      errorMessages,
      handleSubmitCreateInventoryItem,
      handleSubmitUpdateInventoryItem,
      inventoryItem: {
        isUpdating,
        results,
        results: { vehicle },
      },
      navigateToSection,
      overlay,
      setPhotoErrorMessages,
      setVehicle,
      submitAttachCarfax,
      uploadInventoryPhoto,
    } = this.props;
    const { fieldsCompleted, fieldsTotal } = this.getFieldsObject();
    const isDisabledTabs = !vehicle.id;

    const getSectionRefByPaneIndex = (paneIndex) => {
      const hasPaneIndex = navigateToSection.paneIndex === paneIndex;

      return hasHighlightedSection && navigateToSection && hasPaneIndex ? scrollToSection : '';
    };

    return (
      <>
        <DialogContent
          ref={this.modalScrollRef}
          className={classnames(style.modalContainer, style.modalCustom)}
          onClick={() => this.setState({ hasHighlightedSection: false })}
          onKeyDown={() => this.setState({ hasHighlightedSection: false })}
          role="presentation"
        >
          <Tabs
            key="tabs"
            className={style.tabsContainer}
            onChange={(index) => this.handlePaneChange(index)}
            selected={selectedTabIndex}
          >
            <Pane disabled={isDisabledModal} label={t('vehicle_details')}>
              <VehicleDetails
                clearError={clearError}
                errorMessages={errorMessages}
                handleSubmitCreateInventoryItem={handleSubmitCreateInventoryItem}
                handleSubmitUpdateInventoryItem={handleSubmitUpdateInventoryItem}
                inventoryItem={results}
                inventoryItemId={vehicle.id}
                scrollModalByValue={(contentTop) => {
                  this.initialScrollPosition = contentTop;
                }}
                sectionRef={getSectionRefByPaneIndex(PaneIndex.VEHICLE_DETAILS)}
                setVehicle={setVehicle}
              />
            </Pane>
            <Pane disabled={isDisabledTabs} label={t('tires')}>
              <Tires
                clearError={clearError}
                errorMessages={errorMessages}
                inventoryItem={results}
                sectionRef={getSectionRefByPaneIndex(PaneIndex.TIRES)}
                setVehicle={setVehicle}
              />
            </Pane>
            <Pane disabled={isDisabledTabs} label={t('photos_and_damage')}>
              <PhotoDetails
                clearError={clearError}
                errorMessages={errorMessages}
                handleDisableModal={(isDisabled) => this.setState({ isDisabledModal: isDisabled })}
                handleSubmitUpdateInventoryItem={handleSubmitUpdateInventoryItem}
                inventoryItem={results}
                scrollModalByValue={(contentTop) => {
                  this.initialScrollPosition = contentTop;
                }}
                sectionRef={getSectionRefByPaneIndex(PaneIndex.PHOTOS)}
                setError={setPhotoErrorMessages}
                setVehicle={setVehicle}
                uploadInventoryPhoto={uploadInventoryPhoto}
              />
            </Pane>
            <Pane disabled={isDisabledModal || isDisabledTabs} label={t('description')}>
              <Description
                changeOverlay={changeOverlay}
                clearError={clearError}
                errorMessages={errorMessages}
                inventoryItem={results}
                isUpdating={isUpdating}
                overlay={overlay}
                scrollModalByValue={(contentTop) => {
                  this.initialScrollPosition = contentTop;
                }}
                sectionRef={getSectionRefByPaneIndex(PaneIndex.DESCRIPTION)}
                setVehicle={setVehicle}
                submitAttachCarfax={submitAttachCarfax}
              />
            </Pane>
          </Tabs>
        </DialogContent>
        <div
          className={style.footer}
          data-testid="footer"
          onClick={() => this.setState({ hasHighlightedSection: false })}
          onKeyDown={() => this.setState({ hasHighlightedSection: false })}
          role="presentation"
        >
          <div className={style.footerContainer}>
            <PercentProgressBar percentage={(fieldsCompleted / fieldsTotal) * 100} />
            <p className={style.progressText}>{t('x_of_y_complete', [fieldsCompleted, fieldsTotal])}</p>
          </div>
          {this.renderFooterButtons()}
        </div>
      </>
    );
  }
}

export default VehicleForm;
