import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import { AuctionTimeSlot, FacetGroup, FacetedPageInfo } from 'store/shared/api/graph/interfaces/types';

interface EventModel {
  /** Facet groups used to filter search results. */
  facetGroups: FacetGroup[];
  /** Information about pagination. */
  pageInfo: FacetedPageInfo;
  /** Result list of auction time slot */
  resultList: AuctionTimeSlot[];
}

export type EventProps = ServerRecordOf<EventModel>;

export const InitialState = ServerRecord<EventModel>({
  resultList: [],
  facetGroups: [],
  pageInfo: null,
});
