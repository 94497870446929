import { MouseEventHandler, useMemo } from 'react';

import AuctionItem from 'constants/auctionItem';
import AuctionLocationRow from 'components/sections/auctionItem/details/status/statusRows/auctionLocationRow';
import AuditLogRow from 'components/sections/auctionItem/details/status/statusRows/auditLogRow';
import BidsRow from 'components/sections/auctionItem/details/status/statusRows/bidsRow';
import IfBidsRow from 'components/sections/auctionItem/details/status/statusRows/ifBidsRow';
import RanBeforeRow from 'components/sections/auctionItem/details/status/statusRows/ranBeforeRow';
import ReservePriceRow from 'components/sections/auctionItem/details/status/statusRows/reservePriceRow';
import StaffUserRows from 'components/sections/auctionItem/details/status/statusRows/staffUserRows/staffUserRows';
import User from 'constants/user';
import { Row, Rows } from 'components/sections/auctionItem/details/status/statusRows/statusRows';
import { TransactionType } from 'store/shared/api/graph/interfaces/types';
import { formatCalendarDate, formatCalendarTime } from 'utils/dateUtils';
import { getAuctionNameByDetails } from 'utils/formatting/auctionItemFormatUtils';
import { isAuctionFormat } from 'utils/auctionItemUtils';
import { isAuctionStaff } from 'utils/userUtils';
import { t } from 'utils/intlUtils';

import style from './pendingDelivery.scss';

interface Props {
  /** The auction item  */
  details: AuctionItem;
  /** Click handler for show audit log. */
  onShowAuditLog: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show auto-bids. */
  onShowAutobids: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show bids. */
  onShowBids: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show buyer. */
  onShowBuyer: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show if bids. */
  onShowIfBids: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show ran before. */
  onShowRanBefore: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show seller. */
  onShowSeller: MouseEventHandler<HTMLButtonElement>;
  /** The current user. */
  user: User;
}

const PendingDelivery = ({
  details,
  details: {
    auction,
    buyer,
    deliveryDate,
    format,
    guaranteedAuctionPrice,
    inventoryItem,
    isMyItem,
    ranOn,
    topOffer,
    transactionType,
  },
  onShowAuditLog,
  onShowAutobids,
  onShowBids,
  onShowBuyer,
  onShowIfBids,
  onShowRanBefore,
  onShowSeller,
  user,
}: Props) => {
  const auctionName = getAuctionNameByDetails(details);
  const purchasePrice = topOffer?.amount.formattedAmountRounded;
  const dateRan = formatCalendarTime(ranOn, 'MMMM DD, YYYY [at] h:mm A');
  const estimatedDelivery = deliveryDate && formatCalendarDate(deliveryDate, false);
  const isAuction = isAuctionFormat(format);
  const isStaffUser = useMemo(() => isAuctionStaff(user, auction.id), [auction.id, user]);

  return (
    <div className={style.pendingDelivery} data-testid="pending-delivery">
      <Rows>
        {transactionType === TransactionType.BUYING && (
          <Row name={t('purchased_from')} onClick={onShowSeller}>
            {inventoryItem.company.name}
          </Row>
        )}
        {transactionType === TransactionType.SELLING && (
          <Row name={t('sold_to')} onClick={onShowBuyer}>
            {buyer?.company?.name}
          </Row>
        )}
        {purchasePrice && <Row name={isMyItem ? t('sold_for') : t('purchased_for')}>{purchasePrice}</Row>}
        {estimatedDelivery && <Row name={t('est_vehicle_delivery')}>{estimatedDelivery}</Row>}
        <IfBidsRow details={details} onClick={onShowIfBids} />
        {(isMyItem || isStaffUser) && <BidsRow details={details} onClick={isAuction ? onShowBids : undefined} />}
        <StaffUserRows auctionItem={details} onShowAutobids={onShowAutobids} />
        <Row name={t('auction')}>{auctionName}</Row>
        {guaranteedAuctionPrice && (
          <Row dataTestId="status-row-gap" name={t('guaranteed_auction_price')}>
            {guaranteedAuctionPrice?.amount === -1 ? '-' : guaranteedAuctionPrice?.formattedAmount}
          </Row>
        )}
        <AuctionLocationRow inventoryItem={inventoryItem} />
        <Row name={t('date_ran')}>{dateRan}</Row>
        <ReservePriceRow auctionItem={details} user={user} />
        <RanBeforeRow details={details} onClick={onShowRanBefore} />
        <AuditLogRow auctionId={auction.id} onClick={onShowAuditLog} user={user} />
      </Rows>
    </div>
  );
};

export default PendingDelivery;
