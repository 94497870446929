import MyBlockList from 'components/sections/myBlock/list/myBlockList';
import MyBlockStats from 'components/sections/myBlock/stats/myBlockStats';
import { MyBlockProps } from 'store/myBlock/list/myBlockModels';
import { MyBlockVariant } from 'components/sections/myBlock/myBlock';
import { UserProps } from 'store/user/userModels';
import { useMyBlockPrintContent } from 'contexts/myBlockPrintContentContext';

import style from './myBlock.scss';

interface Props {
  /** My block list. */
  list: MyBlockProps;
  /** Callback to clear filters */
  onClearFilters?: () => void;
  /** The logged-in user */
  user: UserProps;
  /** The variation of my block view */
  variant: MyBlockVariant;
}

const MyBlockContent = ({ list, onClearFilters, user, variant }: Props) => {
  const myBlockPrintContent = useMyBlockPrintContent();

  return (
    <div ref={myBlockPrintContent?.setContentRef} className={style.listContainerInner}>
      <MyBlockStats stats={list?.sellerStats || list?.buyerStats} variant={variant} />
      <MyBlockList
        auctionItems={list.resultList}
        onClearFilters={onClearFilters}
        pageInfo={list.pageInfo}
        user={user}
        variant={variant}
      />
    </div>
  );
};

export default MyBlockContent;
