import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { omit } from 'lodash-es';

import SentryLogger from 'logging/SentryLogger';
import { deviceInfoHeaders } from 'utils/deviceInfoUtils';
import { getAuthToken } from 'utils/authUtils';
import { getGraphBaseURL } from 'utils/apiUtils';
import { localeStorage } from 'utils/intlUtils';
import { setUserSessionExpiryTime } from 'containers/notifications/userSessionExpiry/userSessionExpiryHelpers';

// The minimum average threshold our API requests should rely on (in ms).
export const MIN_AVG_API_REQUEST_THRESHOLD = 100;

interface GraphQLConfigData {
  /** Identifier for the graphql query or mutation */
  operationName?: string;
  /** GraphQL query */
  query: string;
  /** Graphql query variables */
  variables?: Record<string, any>;
}

/**
 * Extends the existing AxiosRequestConfig
 *
 * @template IsGraphQL If the response type is a graphql response or regular http response. GraphQL response returns in a format of `{ data: TData }`.
 * @template TConfigData The type of the axios request config (optional)
 */
export interface ApiRequestConfig<
  IsGraphQL extends boolean = true,
  TConfigData extends GraphQLConfigData | string = IsGraphQL extends true ? GraphQLConfigData : string,
> extends AxiosRequestConfig<TConfigData> {
  /** Request sequence key */
  seqKey?: string;
}

/**
 * Extends the existing `AxiosResponse` to return `stats?.requestSequence` as part of the response payload
 *
 * @template TData The type of the data that is returned with the ApiResponse.
 * @template IsGraphQL If the response type is a graphql response or regular http response. GraphQL response returns in a format of `{ data: TData }`.
 * @template TConfigData The type of the axios request config (optional)
 * @template TResponse The type of the ApiResponse (optional)
 */
interface ExtendedAxiosResponse<
  TData extends Record<string, unknown> | unknown = Record<string, unknown>,
  IsGraphQL extends boolean = true,
  TConfigData extends GraphQLConfigData | string = IsGraphQL extends true ? GraphQLConfigData : string,
  TResponse = IsGraphQL extends true ? { data: TData } : TData,
> extends AxiosResponse<TResponse, TConfigData> {
  stats?: {
    requestSequence: {
      current: number | null;
      latest: number | null;
    };
  };
}

/**
 * Potentially `ApiResponse` will return `Promise<undefined>` if and when the users auth token
 * is expired or removed.
 *
 * @template TData The type of the data that is returned with the ApiResponse.
 * @template IsGraphQL If the response type is a graphql response or regular http response. GraphQL response returns in a format of `{ data: TData }`.
 * @template TConfigData The type of the axios request config (optional)
 * @template TResponse The type of the ApiResponse (optional)
 *
 * @see {@link ApiRequest} line 145
 */
export type ApiResponse<
  TData extends Record<string, unknown> | unknown = Record<string, unknown>,
  IsGraphQL extends boolean = true,
  TConfigData extends GraphQLConfigData | string = IsGraphQL extends true ? GraphQLConfigData : string,
  TResponse = IsGraphQL extends true ? { data: TData } : TData,
> = Promise<undefined | ExtendedAxiosResponse<TData, IsGraphQL, TConfigData, TResponse>>;

type ApiErrorHandler = <
  TData extends Record<string, unknown> | unknown = Record<string, unknown>,
  IsGraphQL extends boolean = true,
>(
  error: AxiosError<IsGraphQL extends true ? { data: TData | null } : TData>
) => void;

const seqsByKey: Record<string, number> = {};
const nextSeqByKey = (seqKey: string) => {
  seqsByKey[seqKey] = (seqsByKey[seqKey] || 0) + 1;
  return seqsByKey[seqKey];
};

const errorHandlers: ApiErrorHandler[] = [];
export const onError = (fn: ApiErrorHandler) => {
  errorHandlers.push(fn);
};

// A dictionary of our ApiRequest timing history
export const requestedOperations: { [key: string]: number[] } = {};

/**
 * A validation layer to ensure we're not spamming our API with too many concurrent, identical requests.
 */
export const validateApiRequest = (operationName: string | undefined) => {
  if (!operationName) {
    // Skip check, and continue on with the process.
    return;
  }

  // Set the current time;
  // Create an entry for the operation if it doesn't already exist, and;
  // Push the newly created time into its history bank.
  const now = Date.now();
  if (!requestedOperations[operationName]?.length) {
    requestedOperations[operationName] = [];
  }
  requestedOperations[operationName].push(now);

  // Get the last 4 (max) request times of the current query, and average them out
  const prevRequestTimes = requestedOperations[operationName].slice(-4);
  const avgRequestTime = prevRequestTimes.reduce((sum, v) => sum + v, 0) / prevRequestTimes.length;

  if (prevRequestTimes.length > 3 && avgRequestTime > 0) {
    if (now - avgRequestTime < MIN_AVG_API_REQUEST_THRESHOLD) {
      // If there's more than 3 previous request times, and the average
      // value is below the set threshold, the request should fail.
      const error = new Error('ApiRequest - Too many concurrent requests');
      // TODO: Potentially add breadcrumbs for better debugging
      // SentryLogger.captureException(error, {
      //   avgRequestTime: now - avgRequestTime,
      //   now,
      //   operationName,
      //   prevRequestTimes,
      // });
      throw error;
    }
  }
};

export const getDefaultHeaders = <
  IsGraphQL extends boolean = true,
  TConfigData extends GraphQLConfigData | string = IsGraphQL extends true ? GraphQLConfigData : string,
>(): AxiosRequestConfig<TConfigData>['headers'] => ({
  'Content-Security-Policy':
    "default-src 'self'; form-action 'self'; object-src 'none'; frame-ancestors 'none'; upgrade-insecure-requests; block-all-mixed-content",
  'Content-Type': 'application/json',
  'Channel-Type': 'WEB',
  'EBlock-Accept-Language': localeStorage.get(),
  'Accept-Language': localeStorage.get(),
  'Access-Control-Max-Age': 600, // Preflight requests are cached for 10 minutes
  Authorization: `Bearer ${getAuthToken()}`,
  'Cache-Control': 'no-cache',
  Expires: 'Mon, 26 Jul 1997 05:00:00 GMT', // All cached responses expired in 1997.
  Pragma: 'no-cache',
  'X-Frame-Options': 'DENY',
  'X-Permitted-Cross-Domain-Policies': 'none',
  ...deviceInfoHeaders,
});

/**
 * Sends a request to the API and returns the response.
 *
 * @template TData The type of the data that is returned with the ApiResponse.
 * @template IsGraphQL If the response type is a graphql response or regular http response. GraphQL response returns in a format of `{ data: TData }`.
 * @template TConfigData The type of the axios request config (optional)
 * @template TResponse The type of the ApiResponse (optional)
 *
 * @param {ApiRequestConfig<IsGraphQL, TConfigData>} ApiRequestConfig - Request config
 */
const ApiRequest = <
  TData extends Record<string, unknown> | unknown = Record<string, unknown>,
  IsGraphQL extends boolean = true,
  TConfigData extends GraphQLConfigData | string = IsGraphQL extends true ? GraphQLConfigData : string,
  TResponse = IsGraphQL extends true ? { data: TData } : TData,
>({
  seqKey,
  ...options
}: ApiRequestConfig<IsGraphQL, TConfigData>): ApiResponse<TData, IsGraphQL, TConfigData, TResponse> => {
  const defaults: AxiosRequestConfig<TConfigData> = {
    url: `${getGraphBaseURL()}/graphql`,
    headers: getDefaultHeaders(),
    method: 'POST',
  };

  // Validate we're not spamming ourselves
  validateApiRequest(typeof options?.data === 'string' ? undefined : options?.data?.operationName);

  const seq = seqKey ? nextSeqByKey(seqKey) : null;

  return axios
    .create()
    .request<TData, AxiosResponse<TResponse, TConfigData>, TConfigData>({
      ...defaults,
      ...options,
    })
    .then<ExtendedAxiosResponse<TData, IsGraphQL, TConfigData, TResponse>>((resp) => {
      const userSessionExpiryTime = resp?.headers?.['eblock-expires-at'];
      if (userSessionExpiryTime) {
        setUserSessionExpiryTime(userSessionExpiryTime);
      }

      const requestSequence = { current: seq, latest: seqKey ? seqsByKey[seqKey] : null };
      return { ...resp, ...(!!seq && { stats: { requestSequence } }) };
    })
    .catch((err) => {
      errorHandlers.forEach((errorHandler) => {
        try {
          errorHandler<TData, IsGraphQL>(err);
        } catch (errorHandlerError) {
          console.warn('Error handling error', errorHandlerError);
        }
      });

      if (err.response && err.response.status === 401) {
        console.warn('Unauthorized: Redirecting to /login');
        return undefined;
      }

      const extensiveError = {
        data: options?.data,
        response: {
          headers: omit(err?.response?.config?.headers, ['Authorization']),
          errors: err?.response?.data?.errors,
        },
      };

      SentryLogger.captureException(err?.message, extensiveError);
      throw err;
    });
};

export default ApiRequest;
