export const getCommonStaticTypes = `
  $vin: String,
  $stockNumber: String,
  $auctionLocationId: String,
  $locationId: String,
  $pickupLocationId: String,
  $year: Int,
  $makeId: String,
  $minimalCR: Boolean,
  $modelId: String,
  $subModelId: String,
  $trimId: String,
  $trim: String,
  $mileageAmount: Int,
  $mileageUnit: String,
  $lastKnownMileageAmount: Int,
  $lastKnownMileageUnit: String,
  $lastKnownMileageDate: Date,
  $bodyType: String,
  $chargingCable: String,
  $captureType: VehicleCaptureType,
  $exteriorColor: String,
  $interiorColor: String,
  $options: [String],
  $numberOfDoors: Int,
  $numberOfPassengers: Int,
  $transmission: String,
  $driveTrain: String,
  $displacement: Float,
  $cylinders: Int,
  $fuelType: String,
  $validateVin: Boolean,
  $decodeVin: Boolean,
  $persist: Boolean!,
  $overallConditionRating: Int!,
  $carfaxCanadaClaimAmount: Float,
  $carfaxCanadaReportUrl: String,
  $tireCondition: VehicleTireConditionInput,
  $sellerNotes: String,
  $declarations: [String],
  $photos: [InventoryItemPhotoInputObject],
`;

export const getCommonVehicleProperties = `
  vehicle: {
    vin: $vin,
    stockNumber: $stockNumber,
    auctionLocationId: $auctionLocationId,
    locationId: $locationId,
    pickupLocationId: $pickupLocationId,
    year: $year,
    makeId: $makeId,
    minimalCR: $minimalCR,
    modelId: $modelId,
    subModelId: $subModelId,
    trimId: $trimId,
    trim: $trim,
    mileage: {
      amount: $mileageAmount,
      unit: $mileageUnit,
    },
    lastKnownMileage: {
      amount: $lastKnownMileageAmount,
      unit: $lastKnownMileageUnit,
    },
    lastKnownMileageDate: $lastKnownMileageDate
    bodyType: $bodyType,
    chargingCable: $chargingCable,
    captureType: $captureType,
    exteriorColor: $exteriorColor,
    interiorColor: $interiorColor,
    options: $options,
    numberOfDoors: $numberOfDoors,
    numberOfPassengers: $numberOfPassengers,
    transmission: $transmission,
    driveTrain: $driveTrain,
    displacement: $displacement,
    cylinders: $cylinders,
    fuelType: $fuelType,
    validateVin: $validateVin,
    decodeVin: $decodeVin,
    persist: $persist,
    conditionReport: {
      overallConditionRating: $overallConditionRating,
      carfaxCanadaClaimAmount: $carfaxCanadaClaimAmount,
      carfaxCanadaReportUrl: $carfaxCanadaReportUrl,
      declarations: $declarations,
    },
    tireCondition: $tireCondition,
    sellerNotes: $sellerNotes,
    photos: $photos,
    status: CAPTURE_IN_PROGRESS,
  }
`;

export const getVehicleBuilderSchema = (isInit = false) => `
  ... on VehicleBuilder {
    vehicle: inventoryItem {
      id
      captureType
      minimalCR
      status
      vin
      mileage {
        unit
        amount
      }
      lastKnownMileage {
        amount
        unit
      }
      lastKnownMileageDate
      stockNumber
      ${isInit ? 'makeId' : ''}
      ${isInit ? 'modelId' : ''}
      ${isInit ? 'subModelId' : ''}
      ${isInit ? 'trimId' : ''}
      ${isInit ? 'trim' : ''}
      conditionReport {
        overallConditionRating
        carfaxCanadaReportStatus
        carfaxCanadaReportUrl
        declarations {
          id
          name
          type
          selected
        }
        carfaxCanadaClaimAmount {
          amount
        }
      }
      extensiveVehicleConditionScore
      tireCondition {
        tirePressureMonitoringSystem
        fourMatchingTires
        afterMarketTires
        winterTires
        studdedWinterTires
        secondSetOfTires
        secondSetOfTiresHasRims
        generalCondition
        driverFrontBrand
        driverFrontSize
        driverFrontTread {
          value
        }
        driverRearBrand
        driverRearSize
        driverRearTread {
          value
        }
        passengerFrontBrand
        passengerFrontSize
        passengerFrontTread {
          value
        }
        passengerRearBrand
        passengerRearSize
        passengerRearTread {
          value
        }
      }
      incompleteFieldMessages {
        field
        message
      }
      sellerNotes
      photos {
        id
        type
        location
        locationKey
        url
        shotCode
        status
      }
      pickupLocation {
        countryCode
      }
    }
    location {
      ...builderOption
    }
    auctionLocation {
      id
    }
    startingLocation {
      id
      selected
      location {
        name
        public
        address1
        address2
        city
        regionCode
        countryCode
      }
    }
    pickupLocation {
      id
      selected
      location {
        name
        public
        address1
        address2
        city
        regionCode
        countryCode
      }
    }
    year {
      id
      value
      selected
    }
    make {
      ...builderOption
    }
    model {
      ...builderOption
    }
    subModel {
      ...builderOption
    }
    trim {
      ...builderOption
    }
    bodyType {
      ...builderOption
    }
    chargingCable {
      ...builderOption
    }
    numberOfDoors {
      ...builderOptionInt
    }
    numberOfPassengers {
      ...builderOptionInt
    }
    transmission {
      ...builderOption
    }
    cylinders {
      ...builderOptionInt
    }
    displacement
    driveTrain {
      ...builderOption
    }
    fuelType {
      ...builderOption
    }
    exteriorColor {
      ...builderOption
    }
    interiorColor {
      ...builderOption
    }
    options {
      ...builderOption
    }
    metadata {
      photoDamageLocations {
        id
        value
      }
    }
  }
`;

export const getBuilderOptionFragments = `
  fragment builderOption on BuilderOption {
    id
    value
    selected
  }

  fragment builderOptionInt on BuilderOptionInt {
    id
    value
    selected
  }
`;

export const inventoryItemSchema = `
  captureType
  photos (first: 1) {
    ...thumbnailListFrag
  }
`;

export const duplicateVehicleSchema = `
  edges {
    node {
      id
      created
      inventoryItem {
        ${inventoryItemSchema}
      }
    }
  }
`;
