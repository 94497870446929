import { ComponentType, useCallback, useEffect, useState } from 'react';
import { clamp } from 'lodash-es';

import Cards from 'components/ui/productTours/cards/cards';
import FocusElementMask from 'components/ui/masks/focusElementMask';
import { Key } from 'constants/enums/key';
import { storageManager } from 'utils/storageUtils';
import { useElementQuerySelector } from 'hooks/useElementQuerySelector';

import style from './productTour.scss';

interface ProductTourType {
  /**
   * The id of the product tour in storage:
   * `<userId>-<productTourName>` // "super_admin-browse"
   */
  [id: string]: number;
}

// ProductTour info persisted in localStorage so tours aren't displayed multiple times
export const productToursStorage = storageManager.createOrFetchStorage<ProductTourType>('productTours');

export enum ProductTourName {
  BROWSE = 'browse',
}

/**
 * Sets a value in localStorage to acknowledge the user
 * has already viewed a product tour for the supplied feature.
 */
export const setProductTourStorage = (userId: string, productTourName: ProductTourName) => {
  productToursStorage.setIn(`${userId}-${productTourName}`, new Date().getTime());
};

/**
 * Returns the value of the productTour in localStorage.
 */
export const getProductTourStorage = (userId: string, productTourName: ProductTourName) => {
  return productToursStorage.get()?.[`${userId}-${productTourName}`];
};

export interface StepConfig {
  card: ComponentType;
  cssText?: string;
  elementPadding?: number;
  elementQuerySelector?: string;
}

export interface ProductTourConfig {
  steps: StepConfig[];
}

interface Props {
  config: ProductTourConfig;
  onClose: () => void;
}

const ProductTour = ({ config, onClose }: Props) => {
  const [currentStep, setCurrentStep] = useState<number>(0);

  const stepConfig = config.steps[currentStep];
  const elementRef = useElementQuerySelector(stepConfig.elementQuerySelector);

  /**
   * onKeyUp
   */
  const onKeyUp = useCallback(
    (e) => {
      if (e.key === Key.ArrowRight) {
        setCurrentStep(clamp(currentStep + 1, 0, config?.steps?.length - 1));
      } else if (e.key === Key.ArrowLeft) {
        setCurrentStep(clamp(currentStep - 1, 0, config?.steps?.length - 1));
      }
    },
    [config, currentStep]
  );

  useEffect(() => {
    window.addEventListener('keyup', onKeyUp);
    return () => {
      window.removeEventListener('keyup', onKeyUp);
    };
  }, [onKeyUp]);

  return (
    <div className={style.container}>
      <FocusElementMask element={elementRef} elementPadding={stepConfig.elementPadding} />
      <Cards currentStep={currentStep} onClose={onClose} setCurrentStep={setCurrentStep} steps={config.steps} />
    </div>
  );
};

export default ProductTour;
