import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import AuctionItem from 'constants/auctionItem';
import Loading from 'components/ui/loading/loading';
import NoResults from 'components/ui/noResults/noResults';
import SlideOut from 'components/ui/slideOuts/slideOut';
import WatchlistItem from 'components/sections/watchlist/watchlistItem';
import usePaginatedConnectionQuery from 'components/sections/watchlist/usePaginatedConnectionQuery';
import { AppState } from 'store/configureStore';
import {
  AuctionItemFormat,
  AuctionItemStatus,
  QueryauctionItemConnectionArgs,
} from 'store/shared/api/graph/interfaces/types';
import { AuctionItemSort } from 'constants/enums/auctionItem';
import { UserAction } from 'logging/analytics/events/userActions';
import { getErrors } from 'utils/apiUtils';
import { getWatchlist } from 'store/shared/api/graph/queries/watchlist';
import { t } from 'utils/intlUtils';
import { trackUserActionWithUserAttributes } from 'utils/analyticsUtils';
import { useGlobalDialog } from 'contexts/globalDialogContext';

import style from './watchlistSlideOut.scss';

interface Props {
  /** True when slide out is open. */
  isOpen: boolean;
  /** Function called when slide out is closed. */
  onClose: () => void;
}

const WatchlistSlideOut = ({ isOpen, onClose }: Props) => {
  const timeOffset = useSelector((state: AppState) => state.app.system.timeOffset);
  const { setConfig } = useGlobalDialog();

  /**
   * Get list items
   */
  const getItems = useCallback(
    (options: QueryauctionItemConnectionArgs = {}) =>
      getWatchlist({
        ...options,
        filterBy: 'Watching',
        sort: [{ id: AuctionItemSort.DATE_RAN, ascending: true }],
        status: [AuctionItemStatus.LIVE, AuctionItemStatus.UPCOMING],
        formats: [
          AuctionItemFormat.APPRAISAL,
          AuctionItemFormat.GROUNDED,
          AuctionItemFormat.AUCTION,
          AuctionItemFormat.AUCTION_PHYSICAL,
          AuctionItemFormat.TIMED_OFFER,
        ],
      }),
    []
  );

  /**
   * onOpen
   */
  const onOpen = useCallback(() => {
    trackUserActionWithUserAttributes(UserAction.MY_WATCHLIST);
  }, []);

  /**
   * onError
   */
  const onError = useCallback(
    (error: Error) => {
      setConfig({ errorsOverride: getErrors(error) });
      onClose();
    },
    [onClose, setConfig]
  );

  const {
    /** True when the list is being loaded or re-loaded */
    isLoading,
    /** The rendered pagination UI */
    paginationUI,
    /** The loaded query response */
    queryResponse,
  } = usePaginatedConnectionQuery({ getItems, isOpen, onError, onOpen });

  /**
   * Close slide out and clear watched items.
   */
  const onCloseSlideOut = useCallback(() => {
    onClose();
  }, [onClose]);

  /**
   * Render list of watched items.
   */
  const renderList = useMemo(() => {
    if (!queryResponse?.edges?.length) {
      return <NoResults title={t('no_items_to_show')} />;
    }

    return (
      <>
        <div>
          {queryResponse?.edges?.map(({ node: auctionItem }) => (
            <WatchlistItem
              key={`watched-item-${auctionItem.id}`}
              auctionItem={auctionItem as AuctionItem}
              timeOffset={timeOffset}
            />
          ))}
        </div>
        {paginationUI}
      </>
    );
  }, [timeOffset, queryResponse, paginationUI]);

  return (
    <SlideOut isOpen={isOpen} onClose={onCloseSlideOut} title={t('watchlist')}>
      {!isLoading && <div className={style.container}>{renderList}</div>}
      <Loading hasFullWidth isLoading={isLoading} isModalTransparent />
    </SlideOut>
  );
};

export default WatchlistSlideOut;
