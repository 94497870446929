import { MouseEventHandler, useMemo } from 'react';
import { useSelector } from 'react-redux';

import AuctionItem from 'constants/auctionItem';
import AutobidsRow from 'components/sections/auctionItem/details/status/statusRows/autobidsRow';
import PotentialBuyersRow from 'components/sections/auctionItem/details/status/statusRows/potentialBuyersRow/potentialBuyersRow';
import ViewedByRow from 'components/sections/auctionItem/details/status/statusRows/viewedByRow/viewedByRow';
import WatchedByRow from 'components/sections/auctionItem/details/status/statusRows/watchedByRow/watchedByRow';
import { AppState } from 'store/configureStore';
import { isAuctionStaff } from 'utils/userUtils';

interface Props {
  /** The auction item details. */
  auctionItem: AuctionItem;
  /** Click handler for show auto-bids. */
  onShowAutobids?: MouseEventHandler<HTMLButtonElement>;
}

const StaffUserRows = ({ auctionItem, onShowAutobids }: Props) => {
  const user = useSelector((state: AppState) => state.app.user);
  const isStaffUser = useMemo(() => isAuctionStaff(user, auctionItem.auction.id), [auctionItem.auction.id, user]);

  if (!isStaffUser) {
    return null;
  }

  return (
    <>
      {onShowAutobids && <AutobidsRow details={auctionItem} onClick={onShowAutobids} />}
      <WatchedByRow auctionItem={auctionItem} user={user} />
      <ViewedByRow auctionItem={auctionItem} user={user} />
      <PotentialBuyersRow auctionItem={auctionItem} />
    </>
  );
};

export default StaffUserRows;
