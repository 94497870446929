import { useMemo } from 'react';

import warningGlyph from 'glyphs/warning.svg';

import SlideOut from 'components/ui/slideOuts/slideOut';
import Sprite from 'components/ui/shared/sprite';
import { AutocheckException } from 'store/shared/api/graph/interfaces/types';
import { SlideOutComponentProps } from 'components/sections/inventoryItem/details/slideOut/slideOut';
import { joinStrings } from 'utils/stringUtils';
import { t } from 'utils/intlUtils';

import style from './autoCheckExceptionsSlideOut.scss';

interface Props extends SlideOutComponentProps {
  /** AutoCheck exceptions to be displayed. */
  autoCheckExceptions: AutocheckException[];
  /** Whether the slide out is open or not. */
  isOpen?: boolean;
  /** Callback function triggered when slide out closes. */
  onClose?: () => void;
}

const AutoCheckExceptionsSlideOut = ({ autoCheckExceptions, isOpen, onClose }: Props) => {
  /**
   * Memorized list items
   */
  const listItems = useMemo(() => {
    return autoCheckExceptions.map((exception, index) => (
      <li key={`${exception.description}-${index}`} className={style.listItem}>
        <div className={style.itemContainer}>
          <div>
            <div>{exception.description}</div>
            <div className={style.itemInfo}>{joinStrings([exception.date, exception.stateCode], ' • ')}</div>
          </div>
          <div>
            <Sprite className={style.warning} glyph={warningGlyph} />
          </div>
        </div>
      </li>
    ));
  }, [autoCheckExceptions]);

  return (
    <SlideOut contentInnerClassName={style.content} isOpen={isOpen} onClose={onClose} title={t('autocheck_exceptions')}>
      <ul>{listItems}</ul>
    </SlideOut>
  );
};

export default AutoCheckExceptionsSlideOut;
