import { SetNonNullable } from 'type-fest';

import AuctionItem from 'constants/auctionItem';
import AuctionList from 'components/sections/auctionItem/operations/submitToAuction/pages/auctionList';
import BaseClass from 'components/ui/shared/base';
import FormDialog from 'components/ui/shared/dialogs/formDialog';
import InventoryItem from 'constants/inventoryItem';
import SubmitForm from 'components/sections/auctionItem/operations/submitToAuction/pages/submitForm';
import SuccessfulSubmission from 'components/sections/auctionItem/operations/submitToAuction/pages/successfulSubmission';
import { AuctionDetails, AuctionSubmissionList } from 'store/auctionSubmission/auctionSubmissionModels';
import {
  AuctionFormatType,
  AuctionDates,
} from 'components/sections/auctionItem/operations/submitToAuction/constants/submitToAuction';
import {
  InventoryItemValue,
  MutationauctionItemCreateArgs,
  QueryauctionArgs,
} from 'store/shared/api/graph/interfaces/types';
import { Route } from 'store/routing/routes';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { t } from 'utils/intlUtils';

export enum FormStepType {
  AUCTION_LIST = 'AUCTION_LIST',
  SUBMIT_FORM = 'SUBMIT_FORM',
  SUCCESSFUL_SUBMISSION = 'SUCCESSFUL_SUBMISSION',
}

export interface AuctionData {
  /** The auction details. */
  auction: AuctionDetails | null;
  /** Date details for the selected auction type. */
  auctionDates: AuctionDates;
  /** The selected auction format. */
  auctionFormatType: AuctionFormatType | null;
  /** The mmr values of the inventory item. */
  inventoryItemValues?: InventoryItemValue[];
  /** True when loading the inventory item values. */
  isFetchingInventoryItemValues: boolean;
}

interface Props extends RouterProps {
  /** The auction Item details. */
  auctionItem?: AuctionItem;
  /** The auction item submission id. */
  auctionItemId?: string;
  /** Function invoked on dialog closed. */
  closeModal: () => void;
  /** Function invoked to create auction item. */
  createAuctionItem: (options: MutationauctionItemCreateArgs) => Promise<void>;
  /** Function to get the upcoming timeslots of the selected auction */
  getUpcomingTimeSlots: (options: QueryauctionArgs) => Promise<void>;
  /** The details of the inventory item. */
  inventoryItemDetails: InventoryItem;
  /** True when the dialog is open. */
  isOpen: boolean;
  /** The list of auctions available to submit to. */
  list: AuctionSubmissionList;
}

interface AuctionListState {
  /** The current form step. */
  page: FormStepType.AUCTION_LIST;
  /** The details of the auction to submit to. */
  data: AuctionData;
}

interface SubmitFormState {
  /** The current form step. */
  page: FormStepType.SUBMIT_FORM;
  /** The details of the auction to submit to. */
  data: SetNonNullable<AuctionData, 'auction' | 'auctionFormatType'>;
}

interface SuccessfulSubmissionState {
  /** The current form step. */
  page: FormStepType.SUCCESSFUL_SUBMISSION;
  /** The details of the auction to submit to. */
  data: SetNonNullable<AuctionData, 'auction' | 'auctionFormatType'>;
}

type State = AuctionListState | SubmitFormState | SuccessfulSubmissionState;

const initialState: State = {
  page: FormStepType.AUCTION_LIST,
  data: {
    auction: null,
    auctionDates: [],
    auctionFormatType: null,
    inventoryItemValues: undefined,
    isFetchingInventoryItemValues: false,
  },
};

class Dialog extends BaseClass<Props, State> {
  static defaultProps = {
    auctionItemId: '',
  };

  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = prevProps;
    const { isOpen: isOpenNext } = this.props;

    if (isOpenNext && !isOpen) {
      this.setState(initialState);
    }
  }

  getAuctionTitle = () => {
    const { data, page } = this.state;
    let auctionTitle = 'submit_to_auction';

    if (page === FormStepType.SUBMIT_FORM) {
      switch (data?.auctionFormatType) {
        case AuctionFormatType.LIVE:
          auctionTitle = 'submit_to_live_auction';
          break;
      }
    }

    return t(auctionTitle);
  };

  getUrlPath = () => {
    const { auctionFormatType } = this.state.data;
    const { auctionItemId } = this.props;

    switch (auctionFormatType) {
      case AuctionFormatType.LIVE:
        return `${Route.BUY_RUN_LIST}?id=${auctionItemId}`;

      case AuctionFormatType.TIMED_OFFER:
        return `${Route.BUY_BUY_NOW}?id=${auctionItemId}`;

      case AuctionFormatType.TIMED:
      default:
        return `${Route.BUY_TIMED_AUCTION}?id=${auctionItemId}`;
    }
  };

  handleCloseModal = () => {
    const { page } = this.state;
    const { router } = this.props;

    if (page === FormStepType.SUCCESSFUL_SUBMISSION) {
      router.push(this.getUrlPath());
    }

    this.setState(initialState, this.props.closeModal);
  };

  render() {
    const { auctionItem, createAuctionItem, getUpcomingTimeSlots, inventoryItemDetails, isOpen, list, location } =
      this.props;
    const { page: currentPage, data: pageData } = this.state;
    const title = this.getAuctionTitle();

    return (
      <FormDialog isOpen={isOpen} onClose={this.handleCloseModal} title={title}>
        {currentPage === FormStepType.AUCTION_LIST && (
          <AuctionList
            defaultAuctionFormatType={pageData.auctionFormatType}
            getUpcomingTimeSlots={getUpcomingTimeSlots}
            intendedAuctionTimeSlotLane={inventoryItemDetails.auctionTimeSlotLane || null}
            inventoryItemId={inventoryItemDetails.id}
            list={list}
            make={inventoryItemDetails.make || undefined}
            model={inventoryItemDetails.model || undefined}
            onPageChange={(page, data) => this.setState({ page, data })}
            subModel={inventoryItemDetails.subModel || undefined}
            year={inventoryItemDetails.year || undefined}
          />
        )}
        {currentPage === FormStepType.SUBMIT_FORM && (
          <SubmitForm
            auction={pageData.auction}
            auctionDates={pageData.auctionDates}
            auctionFormatType={pageData.auctionFormatType}
            auctionItem={auctionItem}
            createAuctionItem={createAuctionItem}
            inventoryItem={inventoryItemDetails}
            inventoryItemValues={pageData.inventoryItemValues}
            onPageChange={(page) => this.setState({ page })}
          />
        )}
        {currentPage === FormStepType.SUCCESSFUL_SUBMISSION && (
          <SuccessfulSubmission location={location} urlPath={this.getUrlPath()} />
        )}
      </FormDialog>
    );
  }
}

export default withRouter(Dialog);
