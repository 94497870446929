import { useCallback, useMemo, useState } from 'react';

import AuctionItem from 'constants/auctionItem';
import AuctionItemBadges from 'components/sections/auctionItem/details/auctionItemBadges';
import HeaderActionButtons from 'components/sections/inventoryItem/details/header/headerActionButtons';
import SaleLightsButton from 'components/ui/shared/saleLights/saleLightsButton';
import SaleLightsSlideOut from 'components/ui/shared/saleLights/saleLightsSlideOut';
import User from 'constants/user';
import { AuctionItemFormat, CompanyTier } from 'store/shared/api/graph/interfaces/types';
import { RunNumberBadge } from 'components/sections/inventoryItem/details/inventoryItemBadges';
import { companyTierTranslationMap } from 'constants/enums/company';
import { getMileage } from 'utils/numberUtils';
import { getMileageUnit, isAuctionStaff, isGroupManagerRole } from 'utils/userUtils';
import { joinStrings } from 'utils/stringUtils';
import { t } from 'utils/intlUtils';

import style from './header.scss';

export enum HeaderType {
  COMPACT = 'compact',
  FULL = 'full',
  LIVE_LANES = 'live-lanes',
}

interface Props {
  /** Auction item details. */
  details: AuctionItem;
  /** Callback function to toggle notes. */
  onNotesClick: () => void;
  /** Function invoked to refresh the list. */
  onRefreshList?: () => void;
  /** Current user. */
  user: User;
}

const Header = ({ user, details, onNotesClick, onRefreshList }: Props) => {
  const [isSaleLightSlideOutOpen, setSaleLightSlideOutOpen] = useState<boolean>(false);
  const { displayRunNumber, inventoryItem, isMyItem, sellerTier } = details;
  const { company, location, make, mileage, model, trim, year } = inventoryItem;
  const isStaffUser = isAuctionStaff(user, details?.auction?.id);
  const isGroupManager = isGroupManagerRole(user);
  const { distanceAway } = location ?? {};
  const mileageUnit = getMileageUnit(user)?.toLowerCase();
  const distanceAwayFormatted = getMileage(distanceAway, mileageUnit);
  const subtitle = joinStrings([model, trim].filter(Boolean), ' ');
  const title = [year, make].join(' ');

  const saleLightsOverride = useMemo(
    () => details?.auction?.settings?.saleLightsOverride ?? undefined,
    [details?.auction?.settings]
  );

  const headerLabels = useMemo(() => {
    const labels: string[] = [];
    labels.push(t('x_distance_away', [`${distanceAwayFormatted} ${mileageUnit}`]));
    if (sellerTier && sellerTier !== CompanyTier.NONE) {
      labels.push(t(companyTierTranslationMap[sellerTier]));
    }
    return labels;
  }, [sellerTier, distanceAwayFormatted, mileageUnit]);

  const headerAuxLabel = useMemo(() => {
    return !isStaffUser && isMyItem ? (
      joinStrings([t('yours'), isGroupManager && company?.name], ' - ')
    ) : (
      <>
        <strong>{t('sold_by')}</strong> {company?.name}
      </>
    );
  }, [isStaffUser, isMyItem, isGroupManager, company?.name]);

  /**
   * Handles the sale lights edit click event.
   */
  const onEditSaleLightsClick = useCallback(() => {
    setSaleLightSlideOutOpen(true);
  }, []);

  /**
   * Handles the sale lights slide close event.
   */
  const onCloseSaleLightSlideOut = useCallback(() => {
    setSaleLightSlideOutOpen(false);
  }, []);

  return (
    <>
      <header className={style.headerBar} title={title}>
        <div>
          {headerLabels.map((labelComp, i) => (
            <span key={i} className={style.labelComp}>
              {labelComp}
            </span>
          ))}
        </div>
        <div>{headerAuxLabel}</div>
      </header>
      <div className={style.header}>
        <div>
          {(!!displayRunNumber || !!details?.saleLights?.length || !!saleLightsOverride?.length) && (
            <div className={style.top}>
              {!!displayRunNumber && <RunNumberBadge className={style.runNumberBadge} text={displayRunNumber} />}
              <SaleLightsButton
                activeSaleLights={details?.saleLights || []}
                className={style.saleLightsButton}
                onClick={
                  isStaffUser &&
                  (details?.format === AuctionItemFormat.AUCTION_PHYSICAL || !!saleLightsOverride?.length)
                    ? onEditSaleLightsClick
                    : undefined
                }
                saleLightsOverride={saleLightsOverride}
              />
            </div>
          )}
          <div>
            <h3 className={style.title} title={title}>
              {title}
            </h3>
            {subtitle && (
              <div className={style.subtitle} title={subtitle}>
                {subtitle}
              </div>
            )}
            <div className={style.badgesContainer}>
              {mileage && <div className={style.mileage}>{mileage.formattedAmount}</div>}
              <AuctionItemBadges auctionItem={details} user={user} />
            </div>
          </div>
        </div>
        <HeaderActionButtons
          auctionItem={details}
          isStaffUser={isStaffUser}
          onRefreshList={onRefreshList}
          toggleNotes={onNotesClick}
        />
      </div>

      <SaleLightsSlideOut
        activeSaleLights={details?.saleLights?.filter(Boolean) || []}
        asIs={!!details?.asIs}
        auctionItemId={details?.id}
        inventoryItemId={details?.inventoryItem?.id}
        isOpen={isSaleLightSlideOutOpen}
        onClose={onCloseSaleLightSlideOut}
        saleLightsOverride={saleLightsOverride}
      />
    </>
  );
};

export default Header;
