import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import User from 'constants/user';
import { UserCompanyRelationship } from 'store/shared/api/graph/interfaces/types';

interface UserDetailsModel {
  /** User's information. */
  details: User;
  /** Whether is loading user company relationships or not. */
  isLoadingUserRelationships: boolean;
  /** User company relationships. */
  userRelationships: UserCompanyRelationship[];
}

export type UserDetailsProps = ServerRecordOf<UserDetailsModel>;

export const InitialState = ServerRecord<UserDetailsModel>({
  details: null,
  isLoadingUserRelationships: false,
  userRelationships: [],
});
