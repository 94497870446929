import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import LoginMFAScenario1 from 'components/sections/auth/loginMFA/loginMFAScenario1';
import LoginMFAScenario2 from 'components/sections/auth/loginMFA/loginMFAScenario2';
import LoginMFAScenario3 from 'components/sections/auth/loginMFA/loginMFAScenario3';
import { AppState } from 'store/configureStore';

export const ONE_TIME_CODE_LENGTH = 6;

const LoginMFA = () => {
  const authResponse = useSelector((state: AppState) => state.app.auth.authResponse);

  // Determines which mfa scenario should be rendered based on the user's verification state
  const loginMFAScenario = useMemo(() => {
    if (!authResponse) {
      return null;
    }

    const { cellPhoneVerified, emailVerified } = authResponse.userInfo;

    // Scenario 1: Email AND phone are verified
    if (emailVerified && cellPhoneVerified) {
      return <LoginMFAScenario1 authResponse={authResponse} />;
    }

    // Scenario 2: Email OR phone are verified
    if (emailVerified || cellPhoneVerified) {
      return <LoginMFAScenario2 authResponse={authResponse} />;
    }

    // Scenario 3: Neither email OR phone are verified
    return <LoginMFAScenario3 authResponse={authResponse} />;
  }, [authResponse]);

  return loginMFAScenario;
};

export default LoginMFA;
