import InventoryItem from 'constants/inventoryItem';
import ServerRecord from 'store/shared/models/serverRecord';
import { FacetGroup, FacetedPageInfo } from 'store/shared/api/graph/interfaces/types';

interface InventoryItemsResultsModel {
  /** Inventory Items facet groups */
  facetGroups: FacetGroup[];
  /** Faceted page info */
  pageInfo: FacetedPageInfo;
  /** Inventory Items list */
  resultList: InventoryItem[];
}

export const InventoryItemsResults = ServerRecord<InventoryItemsResultsModel>({
  facetGroups: [],
  resultList: [],
  pageInfo: null,
});
