// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#378ccb","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","type-body-1":"type-body-1_eb3304e","comments":"comments_d597409","type-body-2":"type-body-2_b832f52","type-body-3":"type-body-3_a0a72f9","type-body-4":"type-body-4_fa377c6","type-section-title":"type-section-title_cc0de2a","type-notes":"type-notes_b053ce6","type-item-title":"type-item-title_f585f9b","type-item-subtitle":"type-item-subtitle_fcde1c9","type-subtitle":"type-subtitle_ed10ca4","type-title":"type-title_a86ce53","container":"container_c801495","section":"section_ccc6b07","title":"title_a32b16f","addOnItem":"addOnItem_a6a8cd7","addOnItemRow":"addOnItemRow_be12172","addOnItemPickupLocation":"addOnItemPickupLocation_e0383c5"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#378ccb\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"type-body-1\":\"type-body-1_eb3304e\",\"comments\":\"comments_d597409\",\"type-body-2\":\"type-body-2_b832f52\",\"type-body-3\":\"type-body-3_a0a72f9\",\"type-body-4\":\"type-body-4_fa377c6\",\"type-section-title\":\"type-section-title_cc0de2a\",\"type-notes\":\"type-notes_b053ce6\",\"type-item-title\":\"type-item-title_f585f9b\",\"type-item-subtitle\":\"type-item-subtitle_fcde1c9\",\"type-subtitle\":\"type-subtitle_ed10ca4\",\"type-title\":\"type-title_a86ce53\",\"container\":\"container_c801495\",\"section\":\"section_ccc6b07\",\"title\":\"title_a32b16f\",\"addOnItem\":\"addOnItem_a6a8cd7\",\"addOnItemRow\":\"addOnItemRow_be12172\",\"addOnItemPickupLocation\":\"addOnItemPickupLocation_e0383c5\"}";
        // 1743691160355
        var cssReload = require("../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  