import classnames from 'classnames';
import { debounce } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import InteractiveText from 'components/ui/shared/interactiveText';
import InterpolatedTranslation from 'components/ui/shared/i18n/interpolatedTranslation';
import LinkButton from 'components/ui/shared/buttons/linkButton';
import NotificationBanner from 'components/ui/shared/notifications/notificationBanner';
import OneTimeCodeInput from 'forms/shared/oneTimeCodeInput';
import { AuthContent } from 'layouts/authLayouts/authLayouts';
import { ErrorMessages } from 'constants/errors';
import { FormErrors } from 'layouts/formLayouts/formDialogLayouts';
import {
  AuthResponse,
  MutationuserAccountSendVerificationCodeArgs,
  MutationuserVerifyAuthenticationArgs,
  UserAccountVerificationType,
} from 'store/shared/api/graph/interfaces/types';
import { ONE_TIME_CODE_LENGTH } from 'components/sections/auth/loginMFA/loginMFA';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { SubmitButton } from 'components/sections/auth/auth';
import { cacheAuth } from 'utils/authUtils';
import { onApiError } from 'utils/apiUtils';
import { processTryLogin } from 'store/auth/authActions';
import { t } from 'utils/intlUtils';
import { useMountEffect } from 'hooks/useMountEffect';
import {
  userAccountSendVerificationCode,
  userVerifyAuthentication,
} from 'store/shared/api/graph/mutations/userAccountSendVerificationCode';

import style from './loginMFA.scss';

interface Props extends RouterProps {
  /** The user auth response */
  authResponse: AuthResponse;
}

const LoginMFAScenario2 = ({ authResponse, router }: Props) => {
  const dispatch = useDispatch();

  const [errorMessages, setErrorMessages] = useState<ErrorMessages>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [isAutoSubmittable, setIsAutoSubmittable] = useState<boolean>(true);
  const [showResendBanner, setShowResendBanner] = useState<boolean>(false);

  const isPhoneVerified = !!authResponse?.userInfo?.cellPhoneVerified;
  const verificationType = isPhoneVerified ? UserAccountVerificationType.SMS : UserAccountVerificationType.EMAIL;

  /**
   * onSendCode
   */
  const onSendCode = useCallback(() => {
    const options: MutationuserAccountSendVerificationCodeArgs = {
      userId: authResponse?.userInfo?.userId,
      verificationType,
    };

    return userAccountSendVerificationCode(options)?.catch((error) => onApiError(error, setErrorMessages));
  }, [authResponse, verificationType]);

  /**
   * onResendCode
   */
  const onResendCode = useCallback(() => {
    onSendCode()?.then(() => setShowResendBanner(true));
  }, [onSendCode]);

  /**
   * onResendCode - debounced
   */
  const onResendCodeDebounced = useMemo(() => debounce(onResendCode, 250), [onResendCode]);

  /**
   * onVerifyAuth
   */
  const onVerifyAuth = useCallback(() => {
    const options: MutationuserVerifyAuthenticationArgs = {
      userId: authResponse?.userInfo?.userId,
      verificationCode: inputValue,
      verificationType,
    };

    // Send verification code; show error state when necessary
    userVerifyAuthentication(options)
      ?.then((response) => {
        const token = response?.data?.data?.userVerifyAuthentication?.token;
        if (token) {
          cacheAuth(token);
          processTryLogin(dispatch)?.then(() => router?.replace('/'));
        }
      })
      ?.catch((error) => onApiError(error, setErrorMessages));
  }, [authResponse, dispatch, inputValue, router, verificationType]);

  /**
   * onOTCInputChange
   */
  const onOTCInputChange = useCallback((value) => {
    setInputValue(value);
  }, []);

  /**
   * On mount, send verification code
   */
  useMountEffect(() => {
    onSendCode();
  });

  /**
   * Auto-submit code input form when user first enters their code
   */
  useEffect(() => {
    if (isAutoSubmittable && inputValue?.length === ONE_TIME_CODE_LENGTH) {
      setIsAutoSubmittable(false);
      onVerifyAuth();
    }
  }, [inputValue, isAutoSubmittable, onVerifyAuth]);

  return (
    <>
      <FormErrors className={style.formErrors} errorMessages={errorMessages} isSmallDialog />
      <AuthContent>
        <div className={style.authForm} data-testid="loginMFA-scenario2">
          <h2 className={style.header}>{t('enter_verification_code')}</h2>
          <p className={style.subHeader}>
            <InterpolatedTranslation
              args={[
                `<strong>${verificationType === UserAccountVerificationType.SMS ? authResponse?.userInfo?.maskedCellphone : authResponse?.userInfo?.maskedEmail}</strong>`,
              ]}
              namespace="mfa_verification_code_sent_message"
            />
          </p>
          <OneTimeCodeInput
            className={style.codeInput}
            inputLength={ONE_TIME_CODE_LENGTH}
            onChange={onOTCInputChange}
          />
          {showResendBanner && (
            <NotificationBanner primaryGlyphType="info" theme="green-solid">
              {t('verification_code_sent')}
            </NotificationBanner>
          )}
          <LinkButton className={style.linkButton} onClick={onResendCodeDebounced} theme="white">
            {t('resend_code')}
          </LinkButton>
          <SubmitButton dataTestId="submit" disabled={inputValue?.length < ONE_TIME_CODE_LENGTH} onClick={onVerifyAuth}>
            {t('send_code')}
          </SubmitButton>
          <div className={classnames(style.altLinks, style.supportText)}>
            <p>
              <InteractiveText linkClassName={style.altLink}>{t('mfa_need_help_message')}</InteractiveText>
            </p>
          </div>
        </div>
      </AuthContent>
    </>
  );
};

export default withRouter(LoginMFAScenario2);
