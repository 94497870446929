import { ActionPayload, handleActions } from 'redux-actions';

import { InitialState } from './userDetailsModels';
import { User } from 'store/shared/api/graph/interfaces/types';
import {
  clearUser,
  isLoaded,
  isNotUpdating,
  isUpdating,
  preloadUser,
  setUserData,
  updateUser,
  userRelationshipsAreLoading,
  userRelationshipsLoaded,
} from './userDetailsActions';

export const userDetailsReducer = handleActions(
  {
    [isUpdating().type]: (state) => state.setUpdating(),

    [isNotUpdating().type]: (state) => state.unsetUpdating(),

    [clearUser().type]: () => new InitialState(),

    [isLoaded().type]: (state, action) => {
      return state.setLoaded().merge({ details: action.payload.user });
    },

    [preloadUser().type]: (state, action: ActionPayload<User>) => {
      if (action.payload && action.payload.id) {
        const user = action.payload;
        return state.setLoaded().set('details', user);
      }
      return state;
    },

    [setUserData().type]: (state, action) => {
      const { data, path } = action.payload;
      return state.setIn(['details'].concat(path), data);
    },

    [updateUser().type]: (state, action) => {
      if (!state.details) {
        return state;
      }

      return state.merge({
        details: {
          ...state.details,
          ...action.payload,
        },
      });
    },

    [userRelationshipsAreLoading().type]: (state) => {
      return state.set('isLoadingUserRelationships', true);
    },

    [userRelationshipsLoaded().type]: (state, { payload }) => {
      return state.merge({
        isLoadingUserRelationships: false,
        userRelationships: payload.edges.map(({ node }) => node),
      });
    },
  },
  new InitialState()
);
