import { handleActions } from 'redux-actions';
import { merge } from 'lodash-es';

import { InitialState } from './broadcastsModels';
import {
  broadcastsListIsLoading,
  broadcastsListLoaded,
  broadcastsListCleared,
  broadcastsListUpdateItem,
} from './broadcastsActions';
import { parseQueryConnectionResponse } from 'utils/apiUtils';
import { setInArray } from 'utils/arrayUtils';

export const broadcastsReducer = handleActions(
  {
    [broadcastsListIsLoading().type]: (state) => state.set('isLoading', true),

    [broadcastsListLoaded().type]: (state, action) => {
      return state.setLoaded().merge({
        resultList: parseQueryConnectionResponse(action?.payload) || [],
        facetGroups: action?.payload?.facetGroups,
        pageInfo: action?.payload?.pageInfo,
      });
    },

    [broadcastsListCleared().type]: () => new InitialState(),

    [broadcastsListUpdateItem().type]: (state, action) => {
      const listItemNext = action.payload;
      const listItemIndex = state.resultList.findIndex((item) => item?.id === listItemNext?.id);

      if (listItemIndex === -1) {
        return state.merge({ resultList: [listItemNext, ...state.resultList] });
      }

      const existingItem = state.resultList[listItemIndex];
      const updatedItem = merge({}, existingItem, listItemNext);
      return state.merge({ resultList: setInArray(state.resultList, listItemIndex, updatedItem) });
    },
  },
  new InitialState()
);
