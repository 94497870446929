import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import { AuctionItemPublic } from 'constants/auctionItem';
import {
  FacetedPageInfo,
  FacetGroup,
  MarketGuideMetadata as MarketGuideMetadataType,
} from 'store/shared/api/graph/interfaces/types';

interface MarketGuideModel {
  /** Collection facet groups. */
  facetGroups: FacetGroup[];
  /** Market guide metadata */
  metadata: MarketGuideMetadataType | null;
  /** Information about pagination. */
  pageInfo: FacetedPageInfo | null;
  /** List of AuctionItemPublic. */
  resultList: AuctionItemPublic[];
}

export type MarketGuideProps = ServerRecordOf<MarketGuideModel>;

export const InitialState = ServerRecord<MarketGuideModel>({
  facetGroups: [],
  pageInfo: null,
  resultList: [],
  metadata: null,
});
