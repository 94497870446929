import moment, { Moment } from 'moment';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import Checkbox from 'forms/shared/checkbox';
import LastKnownMileageDialog, {
  lastKnownMileageDeclarationTriggers,
  MileageValues,
} from 'components/sections/inventoryItem/addModify/vehicleFormPanes/segments/lastKnownMileageDialog';
import LastKnownMileageRowButton from 'components/sections/inventoryItem/addModify/vehicleFormPanes/segments/lastKnownMileageRowButton';
import { AddModifyVehicleProps, InventoryItemPropsJs } from 'store/inventoryItem/addModify/addModifyModels';
import { ClearableFieldInput } from 'constants/enums/forms';
import { CountryCode, Declaration } from 'store/shared/api/graph/interfaces/types';
import { DateFormat } from 'constants/enums/dateAndTime';
import { MileageType } from 'forms/shared/mileageInput';
import { formatMomentDate } from 'utils/dateUtils';
import { getCheckboxArray } from 'utils/formUtils';
import { getDeclarationColor } from 'utils/formatting/auctionItemFormatUtils';
import { getDeclarationsMeta } from 'store/inventoryItem/addModify/addModifyApi';

import style from './declarations.scss';

interface Props {
  /** Search value to filter declarations. */
  filterValue?: string;
  /** The vehicle form details. */
  inventoryItem?: InventoryItemPropsJs['results'];
  /** Function invoked to update vehicle values. */
  setVehicle?: (vehicle: AddModifyVehicleProps) => void;
}

const Declarations = ({ inventoryItem, filterValue, setVehicle }: Props) => {
  const { vehicle } = inventoryItem || {};
  const conditionReport = vehicle?.conditionReport;
  const declarations = conditionReport?.declarations;
  const checkOption = (id) => declarations && declarations.indexOf(id) > -1;

  const [countryCode, setCountryCode] = useState<CountryCode>();
  const [declarationsOptions, setDeclarationsOptions] = useState<Declaration[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [lastKnownMileageAmount, setLastKnownMileageAmount] = useState<number | null>(
    vehicle?.lastKnownMileage?.amount || null
  );
  const [lastKnownMileageDate, setLastKnownMileageDate] = useState<Moment | null>(
    vehicle?.lastKnownMileageDate ? moment(vehicle?.lastKnownMileageDate) : null
  );
  const [lastKnownMileageUnit, setLastKnownMileageUnit] = useState<MileageType>(
    (vehicle?.lastKnownMileage?.unit || 'km') as MileageType
  );

  /**
   * Query for declaration options when country code changes.
   */
  useEffect(() => {
    if (countryCode) {
      getDeclarationsMeta({ countryCode }).then((response) => {
        const declarationsMeta = response?.data?.data?.inventoryItemMetaData?.vehicleMetaData?.declarations;
        setDeclarationsOptions(declarationsMeta || []);
      });
    }
  }, [countryCode]);

  /**
   * Determine active countryCode by the selected starting/pickup locations.
   */
  useEffect(() => {
    const selectedStartingLocation = inventoryItem?.startingLocation?.filter(Boolean).find((l) => l.selected);
    const selectedPickupLocation = inventoryItem?.pickupLocation?.filter(Boolean).find((l) => l.selected);
    const selectedCountryCode =
      selectedPickupLocation?.location?.countryCode ||
      selectedStartingLocation?.location?.countryCode ||
      inventoryItem?.vehicle?.pickupLocation?.countryCode ||
      CountryCode.CA;

    setCountryCode(selectedCountryCode as CountryCode);
  }, [
    inventoryItem?.pickupLocation,
    inventoryItem?.startingLocation,
    inventoryItem?.vehicle?.pickupLocation?.countryCode,
  ]);

  /**
   * Filters the declaration options by the filter value.
   */
  const getFilteredValues = useMemo(() => {
    return filterValue
      ? declarationsOptions
          ?.filter(Boolean)
          .filter(({ name }) => name.toLowerCase().includes(filterValue.toLowerCase()))
      : declarationsOptions;
  }, [declarationsOptions, filterValue]);

  /**
   * onConfirm - Invoked on confirm dialog.
   */
  const onConfirm = useCallback(
    (shouldSubmit: boolean, options: MileageValues) => {
      setLastKnownMileageAmount(options.mileageAmount);
      setLastKnownMileageUnit(options.mileageUnit);
      setLastKnownMileageDate(options.mileageDate);
      setVehicle?.({
        lastKnownMileage: {
          amount: shouldSubmit
            ? options.mileageAmount || ClearableFieldInput.NUMBER
            : (vehicle?.lastKnownMileage?.amount ?? null),
          unit: shouldSubmit ? options.mileageUnit : vehicle?.lastKnownMileage?.unit || '-',
        },
        lastKnownMileageDate: shouldSubmit
          ? formatMomentDate(options.mileageDate, DateFormat.YEAR_MONTH_DAY_DASH_FORMAT) || ClearableFieldInput.DATE
          : vehicle?.lastKnownMileageDate || '-',
      });
      setIsOpen(false);
    },
    [setVehicle, vehicle?.lastKnownMileage, vehicle?.lastKnownMileageDate]
  );

  /**
   * onDeclarationChange - Invoked on declaration option click.
   */
  const onDeclarationChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, item: Declaration) => {
      const declarationsNext = getCheckboxArray(e, item.id, declarations);
      const conditionReportNext = { ...conditionReport, declarations: declarationsNext };
      const hasLastKnownDeclarationTrigger = declarationsNext?.some((declaration) =>
        lastKnownMileageDeclarationTriggers?.includes(declaration)
      );

      if (vehicle?.lastKnownMileageDate === null && hasLastKnownDeclarationTrigger) {
        setIsOpen(true);
      }

      // Update vehicle store
      setVehicle?.({
        conditionReport: conditionReportNext,
        // Reset `lastKnownMileage` fields if none of the triggers are selected
        ...(!hasLastKnownDeclarationTrigger && {
          lastKnownMileage: { amount: -1 },
          lastKnownMileageDate: null,
        }),
      });
    },
    [conditionReport, declarations, setVehicle, vehicle?.lastKnownMileageDate]
  );

  return (
    <>
      <div className={style.container}>
        <div className={style.declarations}>
          {getFilteredValues
            ?.filter(Boolean)
            .map((item) => (
              <Checkbox
                key={item.id}
                id={item.id}
                isButtonTheme
                onChange={(e) => onDeclarationChange(e, item)}
                setChecked={checkOption(item.id)}
                text={item.name}
                theme={getDeclarationColor(item.type)}
              />
            ))}
        </div>
        {((vehicle?.lastKnownMileageDate && vehicle?.lastKnownMileageDate !== ClearableFieldInput.DATE) ||
          Number(vehicle?.lastKnownMileage?.amount) > -1) && (
          <LastKnownMileageRowButton
            lastKnownMileageAmount={lastKnownMileageAmount}
            lastKnownMileageDate={lastKnownMileageDate}
            lastKnownMileageUnit={lastKnownMileageUnit}
            onClick={() => setIsOpen(true)}
          />
        )}
      </div>
      <LastKnownMileageDialog
        isOpen={isOpen}
        lastKnownMileage={vehicle?.lastKnownMileage}
        lastKnownMileageDate={lastKnownMileageDate}
        onConfirm={onConfirm}
      />
    </>
  );
};

export default Declarations;
