import ApiRequest from 'store/shared/api/apiRequest';
import {
  AuctionConnection,
  AuctionTimeSlotConnection,
  LiveLane,
  Query,
  QueryauctionConnectionArgs,
  QueryauctionItemConnectionArgs,
  QueryliveLanesArgs,
} from 'store/shared/api/graph/interfaces/types';

export const getLiveLanes = ({ laneId = [], auctionIds = null }: QueryliveLanesArgs) =>
  ApiRequest<{ liveLanes: LiveLane[] }>({
    data: {
      query: `
      query ($laneId: [String], $auctionIds: [String]) {
        liveLanes(laneId: $laneId, auctionIds: $auctionIds) {
          id
          status
          name
          auctionId
          auctionName
          startTime
          timerStart
          timerEnd
          timeSlotId
          auctionItemIdsWatching
          auctionItemIdsWithAutoBids
          controlType
          itemsRemaining
          nextItemId
          type
          liveItem {
            id
            consignerId
            currencyCode
            state
            displayRunNumber
            runNumber
            photoThumb
            photoFull
            name1
            name2
            autoGradeScore
            conditionScore
            captureType
            odometer {
              amount
              unit
              formattedAmount
            }
            tier
            atAmount
            nextAmount
            furtherBidIncrement
            isReserveMet
            isVerified
            isAssured
            hasBuyerShield
            asIs
            userIdsWatching
            saleLights
            totalBids
            uniqueBidders
            winningBid {
              consigner
              consignerId
              amount
            }
            latitude
            longitude
            regionCode
          }
        }
      }
    `,
      variables: {
        auctionIds,
        laneId,
      },
    },
  });

export const getAuctionVideoStreamConfigs = ({ auctionIds = [] }: QueryauctionConnectionArgs) =>
  ApiRequest<{ auctionConnection: AuctionConnection }>({
    data: {
      query: `
      query ($auctionIds: [String!], $first: Int) {
        auctionConnection(auctionIds: $auctionIds, first: $first) {
          edges {
            node {
              id
              videoStreamConfig {
                accountId
                subscriptionToken
              }
            }
          }
        }
      }
    `,
      variables: {
        auctionIds,
        first: auctionIds?.length || 0,
      },
    },
  });

export type LiveAuctionUpcomingItem = QueryauctionItemConnectionArgs & {
  auctionTimeSlotLaneId: string;
  isFetchingMore?: boolean;
};

export const getUpcomingItems = ({
  after = '0',
  auctionTimeSlotLaneId,
  filterBy,
  first = 10,
  keyword,
}: LiveAuctionUpcomingItem) =>
  ApiRequest<Pick<Query, 'auctionItemConnection'>>({
    data: {
      query: `
      {
        auctionItemConnection(
          after: "${after}",
          auctionTimeSlotLaneIds: ["${auctionTimeSlotLaneId}"],
          filterBy: "${filterBy}",
          first: ${first},
          formats: [AUCTION, AUCTION_PHYSICAL],
          keyword: ${keyword ? `"${keyword}"` : null},
          sort: { id: "AUCTION", ascending: true },
          status: UPCOMING
        ) {
          pageInfo {
            totalEdges
          }
          edges {
            node {
              id
              isMyItem
              displayRunNumber
              runNumber
              sellerTier
              auctionTimeSlotLane {
                id
                name
              }
              auction {
                id
              }
              notes {
                id
              }
              startingBid {
                formattedAmountRounded
              }
              watchers {
                isWatched
              }
              autoBids {
                myAutoBid {
                  maxBid {
                    formattedAmountRounded
                  }
                }
              }
              saleLights
              inventoryItem {
                year
                make
                model
                subModel
                trim
                description
                captureType
                company {
                  id
                  name
                  auctionRelationships {
                    list {
                      auction {
                        id
                      }
                    }
                  }
                }
                location {
                  city
                  name
                  distanceAway
                  regionCode
                }
                mileage {
                  formattedAmount
                }
                options(selected: true) {
                  name
                }
                photos(type: EXTERIOR, first: 1) {
                  main: url(width: 800, height: 600)
                  thumb: url(width: 160, height: 130)
                  squareThumb: url(width: 180, height: 180)
                }
              }
            }
          }
        }
      }
      `,
    },
  });

export const getNextAuctions = ({
  auctionIds = null,
  first = 10,
  sort = { id: 'START_TIME', ascending: true },
  status = ['WAITING'],
}) => {
  return ApiRequest<{ auctionTimeSlotConnection: AuctionTimeSlotConnection }>({
    data: {
      query: `
        query($auctionIds: [String], $first: Int, $sort: [Sort], $status: [AuctionTimeSlotStatus]) {
          auctionTimeSlotConnection(auctionIds: $auctionIds, first: $first, sort: $sort, status: $status) {
            edges {
              node {
                id
                auction {
                  title
                }
                startTime
                itemCount
              }
            }
          }
        }
      `,
      variables: {
        auctionIds,
        first,
        sort,
        status,
      },
    },
  });
};
