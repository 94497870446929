import { ActionPayload, handleActions } from 'redux-actions';

import { AuctionTimeSlotConnection } from 'store/shared/api/graph/interfaces/types';
import { InitialState } from 'store/admin/events/list/eventsModels';
import { eventsListCleared, eventsListIsLoading, eventsListLoaded } from 'store/admin/events/list/eventsActions';
import { parseQueryConnectionResponse } from 'utils/apiUtils';

export const eventsReducer = handleActions(
  {
    [eventsListIsLoading().type]: (state) => state.set('isLoading', true),

    [eventsListLoaded().type]: (state, action: ActionPayload<AuctionTimeSlotConnection | undefined>) => {
      return state.setLoaded().merge({
        facetGroups: action?.payload?.facetGroups || [],
        pageInfo: action?.payload?.pageInfo,
        resultList: parseQueryConnectionResponse(action?.payload) || [],
      });
    },

    [eventsListCleared().type]: () => new InitialState(),
  },
  new InitialState()
);
