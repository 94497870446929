import { ReactNode } from 'react';

import style from './filterSectionHelpers.scss';

interface FilterSectionHeadingProps {
  children: ReactNode;
}

export const FilterSectionHeading = ({ children }: FilterSectionHeadingProps) => {
  return <div className={style.heading}>{children}</div>;
};
