import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import autobidGlyph from 'glyphs/operationIcons/set-autobid.svg';

import AuctionItem from 'constants/auctionItem';
import AuthService from 'store/shared/services/authService';
import Dialog from './dialog';
import OperationButton from 'components/sections/inventoryItem/operations/operationButton';
import { AppDispatch, AppState } from 'store/configureStore';
import { BidSelection } from 'utils/interfaces/bidSelection';
import { ErrorMessages } from 'constants/errors';
import {
  InventoryItemValue,
  LiveAuctionItemState,
  MutationauctionItemSetAutoBidArgs,
} from 'store/shared/api/graph/interfaces/types';
import { UserAction } from 'logging/analytics/events/userActions';
import { getInventoryItemValues } from 'store/shared/api/graph/queries/inventoryItemValues';
import { getEnabledCompanyIds, isAuctionStaff, isGroupManagerRole } from 'utils/userUtils';
import { getErrorMessages, onApiError } from 'utils/apiUtils';
import { processSetAndPersistBiddingAsSelection } from 'store/user/userActions';
import { processSetAuctionItemAutobid } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { t } from 'utils/intlUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';

const stateConnect = (state: AppState) => ({
  /** True if the logged-in user has multiple company-auction relationships  */
  isGroupManager: isGroupManagerRole(state.app.user),
  /** True if the logged-in user represents auction staff */
  isStaffUser: isAuctionStaff(state.app.user),
  /** All the current live lanes */
  lanes: state.app.liveLanes.resultList,
});

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Dispatch function to set autobid. */
  setAutobid: (options: MutationauctionItemSetAutoBidArgs) => processSetAuctionItemAutobid(options, dispatch),
  /** Dispatch function to set current bid selection. */
  setCurrentBiddingAsSelection: (options) => processSetAndPersistBiddingAsSelection(options, dispatch),
});

const connector = connect(stateConnect, dispatchConnect);

export interface AutoBidOptions {
  /** The company associated to the user making the autobid. */
  company?: BidSelection['company'];
  /** The autobid amount. */
  maxBidPrice?: number;
  /** The user making the autobid. */
  user?: BidSelection['user'];
}

interface Props extends ConnectedProps<typeof connector> {
  /** The auction item details. */
  auctionItem: AuctionItem;
  /** CSS styling to overwrite default style. */
  className?: string;
  /** True when autobid button is in a list view. */
  isListView?: boolean;
  /** Function invoked when setAutobid dispatch has completed. */
  onDone?: (value?: boolean) => void;
}

const SetAutobid = ({
  auctionItem,
  className,
  isGroupManager,
  isListView,
  isStaffUser,
  lanes,
  onDone,
  setAutobid,
  setCurrentBiddingAsSelection,
}: Props) => {
  const [errorMessages, setErrorMessages] = useState<ErrorMessages | undefined>();
  const [inventoryItemValues, setInventoryItemValues] = useState<InventoryItemValue[]>();
  const [isFetchingInventoryItemValues, setIsFetchingInventoryItemValues] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  /**
   * Determines if the Set Autobid button should be disabled
   */
  const isDisabled = useMemo((): boolean => {
    const lane = lanes?.find(({ id }) => auctionItem?.auctionTimeSlotLane?.id === id);
    return (
      !!lane?.liveItem?.state && [LiveAuctionItemState.PAUSED, LiveAuctionItemState.END].includes(lane?.liveItem?.state)
    );
  }, [auctionItem, lanes]);

  /**
   * onSubmit
   */
  const onSubmit = useCallback(
    (shouldSubmit: boolean, options: AutoBidOptions = {}) => {
      if (shouldSubmit) {
        setIsSubmitting(true);

        const formattedOptions = {
          auctionItemId: auctionItem?.id,
          consignerId: options?.company?.id,
          maxBidPrice: options?.maxBidPrice,
          userId: options?.user?.id,
        };

        const enabledIds = getEnabledCompanyIds(AuthService.user) ?? [];
        if (enabledIds.length === 1 && formattedOptions.consignerId) {
          trackUserActionWithAuctionItemAttributes(UserAction.__EMERGENCY_SET_AUTOBID_LOGGER, auctionItem, {
            formattedOptions,
          });
        }

        setAutobid(formattedOptions)
          .then(() => {
            const action = isListView
              ? UserAction.RUN_LIST_AUTOBID_CONFIRM_CLICK
              : UserAction.VDP_AUTOBID_CONFIRM_CLICK;
            trackUserActionWithAuctionItemAttributes(action, auctionItem);

            if (options?.company) {
              const biddingSelection = {
                [auctionItem?.auction?.id]: { company: options?.company, user: options?.user },
              };
              setCurrentBiddingAsSelection(biddingSelection);
            }

            setErrorMessages(undefined);
            setIsOpen(false);
            onDone?.(true);
          })
          .catch((error) => {
            const _err = getErrorMessages(error)?.[0];
            if (
              [
                'User should be a member of the company.',
                'L’utilisateur doit être un membre de l’entreprise.',
              ].includes(_err?.message)
            ) {
              trackUserActionWithAuctionItemAttributes(UserAction.__EMERGENCY_SET_AUTOBID_ERROR_LOGGER, auctionItem, {
                formattedOptions,
              });
            }

            return onApiError(error, setErrorMessages);
          })
          .finally(() => setIsSubmitting(false));
      } else {
        setErrorMessages(undefined);
        setIsOpen(false);
        onDone?.(false);
      }
    },
    [auctionItem, isListView, onDone, setAutobid, setCurrentBiddingAsSelection]
  );

  /**
   * onClick
   */
  const onClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      // Fetch inventory item values
      if (!isFetchingInventoryItemValues) {
        setIsFetchingInventoryItemValues(true);
        getInventoryItemValues({
          auctionId: auctionItem.auction.id,
          inventoryItemId: auctionItem.inventoryItem.id,
        })
          ?.then((response) => {
            setErrorMessages(undefined);
            setInventoryItemValues(response?.data?.data?.inventoryItemValues);
          })
          .catch((error) => onApiError(error, setErrorMessages))
          .finally(() => {
            setIsFetchingInventoryItemValues(false);
            setIsOpen(true);
          });
      }

      const action = isListView ? UserAction.RUN_LIST_AUTOBID_CLICK : UserAction.VDP_AUTOBID_CLICK;
      trackUserActionWithAuctionItemAttributes(action, auctionItem);
    },
    [auctionItem, isFetchingInventoryItemValues, isListView]
  );

  return (
    <>
      <OperationButton
        className={className}
        dataTestId="setAutoBid-button"
        disabled={isDisabled}
        glyph={autobidGlyph}
        onClick={onClick}
        theme="blue"
      >
        {t('set_autobid')}
      </OperationButton>
      {isOpen && (
        <Dialog
          auctionItem={auctionItem}
          errorMessages={errorMessages}
          inventoryItemValues={inventoryItemValues}
          isGroupManager={isGroupManager}
          isOpen={isOpen}
          isPreventingDefaultOnClick
          isStaffUser={isStaffUser}
          isSubmitting={isSubmitting}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};

export default connector(SetAutobid);
