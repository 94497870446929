import ServerRecord from 'store/shared/models/serverRecord';
import { Auction, FacetGroup, FacetedPageInfo } from 'store/shared/api/graph/interfaces/types';

interface AuctionsModel {
  /** Auctions facet groups. */
  facetGroups: FacetGroup[];
  /** Faceted page info. */
  pageInfo: FacetedPageInfo | null;
  /** Transport job/request list. */
  resultList: Auction[];
}

export const InitialState = ServerRecord<AuctionsModel>({
  resultList: [],
  facetGroups: [],
  pageInfo: null,
});
