import classnames from 'classnames';

import buyerShieldGlyph from 'glyphs/buyerShield.svg';

import InventoryItem from 'constants/inventoryItem';
import ScoreText from 'components/ui/shared/scores/scoreText';
import Sprite from 'components/ui/shared/sprite';
import { SlideOutComponentProps } from 'components/sections/inventoryItem/details/slideOut/slideOut';
import { isVerifiedExtensiveVehicleCondition } from 'utils/auctionItemUtils';
import { rangeCA, rangeUS } from 'components/ui/shared/scores/scoreRanges';
import { t } from 'utils/intlUtils';

import style from './score.scss';

interface Props extends SlideOutComponentProps {
  inventoryDetailsData: InventoryItem;
}

const InventoryItemDetailsScore = ({ inventoryDetailsData }: Props) => {
  /**
   * Render score list
   */
  const renderScoreList = () => {
    const score = inventoryDetailsData?.conditionReport?.overallConditionRating || 0;

    return (
      <div>
        <ul className={style.list}>
          <li className={classnames(style.listItem, score >= 86 && style.selected)}>
            <span className={style.scoreRating}>86 – 100</span>
            <span className={style.scoreRatingInfo}>{t('condition_very_clean')}</span>
          </li>
          <li className={classnames(style.listItem, score >= 71 && score < 86 && style.selected)}>
            <span className={style.scoreRating}>71 – 85</span>
            <span className={style.scoreRatingInfo}>{t('condition_clean')}</span>
          </li>
          <li className={classnames(style.listItem, score >= 56 && score < 71 && style.yellow)}>
            <span className={classnames(style.scoreRating, style.yellow)}>56 – 70</span>
            <span className={style.scoreRatingInfo}>{t('condition_average')}</span>
          </li>
          <li className={classnames(style.listItem, score >= 41 && score < 56 && style.yellow)}>
            <span className={classnames(style.scoreRating, style.yellow)}>41 – 55</span>
            <span className={style.scoreRatingInfo}>{t('condition_rough')}</span>
          </li>
          <li className={classnames(style.listItem, score >= 21 && score < 41 && style.red)}>
            <span className={classnames(style.scoreRating, style.red)}>21 – 40</span>
            <span className={style.scoreRatingInfo}>{t('condition_very_rough')}</span>
          </li>
          <li className={classnames(style.listItem, score >= 1 && score < 21 && style.red)}>
            <span className={classnames(style.scoreRating, style.red)}>1 – 20</span>
            <span className={style.scoreRatingInfo}>{t('condition_salvage')}</span>
          </li>
        </ul>
      </div>
    );
  };

  /**
   * Render extensive score list - legacy version
   */
  const renderExtensiveScoreListLegacy = () => {
    const inventoryItem = inventoryDetailsData;
    const score = inventoryItem?.extensiveVehicleConditionScore;
    const range = inventoryItem?.location?.countryCode === 'US' ? rangeUS : rangeCA;

    return (
      <div>
        <ul>
          <li className={style.listItemExtensive}>
            <div className={style.scoreExtensive}>
              <span>{t('overall_score')}</span>
              <ScoreText range={range} score={score} />
            </div>
          </li>

          <li className={style.listItemExtensive}>
            <div className={style.scoreExtensive}>
              <span>
                {t('engine_score')}
                {inventoryItem?.engineBuyerShield && (
                  <Sprite className={style.buyerShieldBadge} glyph={buyerShieldGlyph} />
                )}
              </span>
              <ScoreText range={range} score={inventoryItem?.mechanicalEngineRating} />
            </div>
            {inventoryItem?.mechanicalEngineNote && (
              <div className={style.noteExtensive}>{inventoryItem?.mechanicalEngineNote}</div>
            )}
          </li>

          <li className={style.listItemExtensive}>
            <div className={style.scoreExtensive}>
              <span>
                {t('transmission_score')}
                {inventoryItem?.transmissionBuyerShield && (
                  <Sprite className={style.buyerShieldBadge} glyph={buyerShieldGlyph} />
                )}
              </span>
              <ScoreText range={range} score={inventoryItem?.mechanicalTransmissionRating} />
            </div>
            {inventoryItem?.mechanicalTransmissionNote && (
              <div className={style.noteExtensive}>{inventoryItem?.mechanicalTransmissionNote}</div>
            )}
          </li>

          <li className={style.listItemExtensive}>
            <div className={style.scoreExtensive}>
              <span>
                {t('drivetrain_score')}
                {inventoryItem?.drivetrainBuyerShield && (
                  <Sprite className={style.buyerShieldBadge} glyph={buyerShieldGlyph} />
                )}
              </span>
              <ScoreText range={range} score={inventoryItem?.mechanicalDrivetrainRating} />
            </div>
            {inventoryItem?.mechanicalDrivetrainNote && (
              <div className={style.noteExtensive}>{inventoryItem?.mechanicalDrivetrainNote}</div>
            )}
          </li>

          <li className={style.listItemExtensive}>
            <div className={style.scoreExtensive}>
              <span>{t('exterior_score')}</span>
              <ScoreText range={range} score={inventoryItem?.exteriorRating} />
            </div>
            {inventoryItem?.exteriorNote && <div className={style.noteExtensive}>{inventoryItem?.exteriorNote}</div>}
          </li>

          <li className={style.listItemExtensive}>
            <div className={style.scoreExtensive}>
              <span>{t('interior_score')}</span>
              <ScoreText range={range} score={inventoryItem?.interiorRating} />
            </div>
            {inventoryItem?.interiorNote && <div className={style.noteExtensive}>{inventoryItem?.interiorNote}</div>}
          </li>

          <li className={style.listItemExtensive}>
            <div className={style.scoreExtensive}>
              <span>{t('tires_score')}</span>
              <ScoreText range={range} score={inventoryItem?.tiresRating} />
            </div>
            {inventoryItem?.tiresNote && <div className={style.noteExtensive}>{inventoryItem?.tiresNote}</div>}
          </li>
        </ul>
      </div>
    );
  };

  /**
   * Render extensive score list
   */
  const renderExtensiveScoreList = () => {
    const inventoryItem = inventoryDetailsData;
    const score = inventoryItem?.extensiveVehicleConditionScore;
    const range = inventoryItem?.location?.countryCode === 'US' ? rangeUS : rangeCA;

    return (
      <div>
        <ul>
          <li className={style.listItemExtensive}>
            <div className={style.scoreExtensive}>
              <span>{t('overall_score')}</span>
              <ScoreText range={range} score={score} />
            </div>
          </li>

          <li className={style.listItemExtensive}>
            <div className={style.scoreExtensive}>
              <span>{t('mechanical_score')}</span>
              <ScoreText range={range} score={inventoryItem?.mechanicalRating} />
            </div>
            {inventoryItem?.mechanicalNote && (
              <div className={style.noteExtensive}>{inventoryItem?.mechanicalNote}</div>
            )}
          </li>

          <li className={style.listItemExtensive}>
            <div className={style.scoreExtensive}>
              <span>{t('interior_score')}</span>
              <ScoreText range={range} score={inventoryItem?.interiorRating} />
            </div>
            {inventoryItem?.interiorNote && <div className={style.noteExtensive}>{inventoryItem?.interiorNote}</div>}
          </li>

          <li className={style.listItemExtensive}>
            <div className={style.scoreExtensive}>
              <span>{t('exterior_score')}</span>
              <ScoreText range={range} score={inventoryItem?.exteriorRating} />
            </div>
            {inventoryItem?.exteriorNote && <div className={style.noteExtensive}>{inventoryItem?.exteriorNote}</div>}
          </li>
        </ul>
      </div>
    );
  };

  const { captureType, mechanicalDrivetrainRating, mechanicalEngineRating, mechanicalTransmissionRating, tiresRating } =
    inventoryDetailsData;
  const verifiedExtensiveVehicleCondition = isVerifiedExtensiveVehicleCondition(captureType);

  if (verifiedExtensiveVehicleCondition) {
    if (!mechanicalDrivetrainRating && !mechanicalEngineRating && !mechanicalTransmissionRating && !tiresRating) {
      return renderExtensiveScoreList();
    }
    return renderExtensiveScoreListLegacy();
  }
  return renderScoreList();
};

export default InventoryItemDetailsScore;
