import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import { AuctionItemConnection, FacetedPageInfo } from 'store/shared/api/graph/interfaces/types';

interface HomeDashboardAuctionItemConnection extends Pick<AuctionItemConnection, 'edges'> {
  /** Page info */
  pageInfo: Pick<FacetedPageInfo, 'totalEdges'> | null;
}

export interface HomeDashboardSearchResultModel {
  /** Timed auction items */
  timedAuction: HomeDashboardAuctionItemConnection;
  /** Run list auction items */
  runList: HomeDashboardAuctionItemConnection;
  /** Timed offer items */
  timedOffer: HomeDashboardAuctionItemConnection;
  /** Parked auction items */
  parked: HomeDashboardAuctionItemConnection;
  /** In if bid auction items */
  inIfBid: HomeDashboardAuctionItemConnection;
  /** Pending delivery auction items */
  pendingDelivery: HomeDashboardAuctionItemConnection;
  /** Awaiting checkout auction items */
  awaitingCheckout: HomeDashboardAuctionItemConnection;
  /** Sold auction items */
  sold: HomeDashboardAuctionItemConnection;
  /** Purchased auction items */
  purchased: HomeDashboardAuctionItemConnection;
  /** No sale auction items */
  noSale: HomeDashboardAuctionItemConnection;
  /** Cancelled auction items */
  cancelled: HomeDashboardAuctionItemConnection;
  /** Archived auction items */
  archived: HomeDashboardAuctionItemConnection;
}

export interface HomeDashboardSearchModel {
  /** Home dashboard search result. */
  results: HomeDashboardSearchResultModel | null;
}

export type HomeDashboardSearchProps = ServerRecordOf<HomeDashboardSearchModel>;

const BASE_LIST_RESULT: HomeDashboardAuctionItemConnection = {
  edges: [],
  pageInfo: {
    totalEdges: 0,
  },
};

export const HomeDashboardSearchInitialState = ServerRecord<HomeDashboardSearchModel>({
  results: null,
});

export const HomeDashboardSearchResult: HomeDashboardSearchResultModel = {
  timedAuction: BASE_LIST_RESULT,
  runList: BASE_LIST_RESULT,
  timedOffer: BASE_LIST_RESULT,
  parked: BASE_LIST_RESULT,
  inIfBid: BASE_LIST_RESULT,
  pendingDelivery: BASE_LIST_RESULT,
  awaitingCheckout: BASE_LIST_RESULT,
  sold: BASE_LIST_RESULT,
  purchased: BASE_LIST_RESULT,
  noSale: BASE_LIST_RESULT,
  cancelled: BASE_LIST_RESULT,
  archived: BASE_LIST_RESULT,
};
