import ServerRecord from 'store/shared/models/serverRecord';
import { FacetGroup, FacetedPageInfo, Broadcast } from 'store/shared/api/graph/interfaces/types';

interface BroadcastsModel {
  /** Broadcast facet groups. */
  facetGroups: FacetGroup[];
  /** Faceted page info. */
  pageInfo: FacetedPageInfo | null;
  /** List of broadcast. */
  resultList: Broadcast[];
}

export const InitialState = ServerRecord<BroadcastsModel>({
  resultList: [],
  facetGroups: [],
  pageInfo: null,
});
